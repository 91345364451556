import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import UserRequestDTO from "../../../shared/dtos/userRequestDTO";
import { createEmptyTripApi } from "../../../services/emptyTripApi";
import { useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { userRequestListAsync } from "../../../features/userRequest/userRequestListSlice";
import EmptyTripDTO from "../../../shared/dtos/emptyTripDto";

interface Props {
  userRequest: UserRequestDTO;
}

const CreateEmptyTrip = ({ userRequest }: Props) => {
  const [creating, setCreating] = useState(false);
  const dispatch = useAppDispatch();

  const createEmptyTrip = async (e: any) => {
    const emptyTrip: EmptyTripDTO = {
      userRequestId: userRequest.id,
      originAddress: userRequest.destinationAddress,
      originLatitude: userRequest.destinationLatitude,
      originLongitude: userRequest.destinationLongitude,
      destinationAddress: userRequest.originAddress,
      destinationLatitude: userRequest.originLatitude,
      destinationLongitude: userRequest.originLongitude,
    };
    setCreating(true);
    const directionsService = new google.maps.DirectionsService();
    if (userRequest.originAddress && userRequest.destinationAddress) {
      let waypoints: any = [];
      if (
        userRequest?.destinations?.length &&
        userRequest?.destinations?.length > 1
      ) {
        let tmp = [...userRequest?.destinations];
        tmp.shift();
        waypoints = tmp?.map((destination) => {
          return { location: destination.address, stopover: true };
        });
      }

      const originalRoute = await directionsService.route({
        origin: userRequest.originAddress,
        destination: userRequest.destinationAddress,
        waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      });

      emptyTrip.durationOriginalTrip = originalRoute.routes[0]?.legs.reduce(
        (result: number, el: google.maps.DirectionsLeg) => {
          if (el.duration?.value) return (result += el.duration.value);
          return result;
        },
        0
      );
    }

    if (emptyTrip.originAddress && emptyTrip.destinationAddress) {
      const route = await directionsService.route({
        origin: emptyTrip.originAddress,
        destination: emptyTrip.destinationAddress,
        waypoints: [],
        travelMode: google.maps.TravelMode.DRIVING,
      });

      emptyTrip.distance = route.routes[0]?.legs.reduce(
        (result: number, el: google.maps.DirectionsLeg) => {
          if (el.distance?.value) return (result += el.distance.value);
          return result;
        },
        0
      );

      emptyTrip.duration = route.routes[0]?.legs.reduce(
        (result: number, el: google.maps.DirectionsLeg) => {
          if (el.duration?.value) return (result += el.duration.value);
          return result;
        },
        0
      );
    }

    try {
      await createEmptyTripApi(emptyTrip);
      message.success("Viaje vacío creado satisfactoriamente.");
    } catch (error) {
      message.error("Ha ocurrido un error al intentar crear el viaje vacío.");
    }
    await dispatch(userRequestListAsync({}));
    setCreating(false);
  };

  return (
    <Button
      size="small"
      type="primary"
      icon={<PlusSquareOutlined />}
      onClick={createEmptyTrip}
      disabled={creating}
      loading={creating}
    >
      viaje vacío
    </Button>
  );
};

export default CreateEmptyTrip;
