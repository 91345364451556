import { useAppDispatch } from "../../../app/hooks";
import { importPassengersAsync } from "../../../features/groupTrip/passeger/groupTripPassegerSlide";
import { useEffect } from "react";
import { groupTripsDirectionList } from "../../../features/groupTrip/direction/groupTripDirectionSlide";
import { parseDataFromXMLS, readUploadXLSXFile } from "../common/utils";

interface Props {
  onChange?: (data: any) => void;
}

const UploadPassengers = ({ onChange }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(groupTripsDirectionList());
  }, [dispatch]);

  const readUploadFile = (e: any) => {
    e.preventDefault();
    readUploadXLSXFile(e, (data) => {
      if (onChange) {
        dispatch(
          importPassengersAsync(parseDataFromXMLS(data), async (response) => {
            onChange(response);
          })
        );
      }
    });
  };

  return (
    <input type="file" name="upload" id="upload" onChange={readUploadFile} />
  );
};

export default UploadPassengers;
