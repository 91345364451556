import { notification } from "antd";
import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

interface PaginatedBannerProps {
  page: number;
  take: number;
  status?: boolean;
}

interface ChangeStatusProps {
  id: number;
  status: boolean;
}

export const getPaginatedBanners = async ({
  page,
  take,
  status,
}: PaginatedBannerProps) => {
  const result = await axios.get(
    `${apiUrl}banners?page=${page}&take=${take}${
      status !== undefined ? `&status=${status}` : ""
    }`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const createBanner = async (formData: FormData) => {
  const result = await axios
    .post(`${apiUrl}banners`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        auth: selectCurrentAdminToken(),
      },
    })
    .then((response: any) => {
      notification.success({
        message: `El banner se ha creado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });
  return result.data;
};

export const changeStatus = async ({ id, status }: ChangeStatusProps) => {
  const result = await axios.post(
    `${apiUrl}banners/change-status/${id}`,
    { status },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};
