import { Button, Card, Col, Input, Row, Space, Table, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { WebRoutes } from "../../shared/constants/WebRoutes";
import { useNavigate } from "react-router-dom";
import { setFiltersAdminPanel } from "../../features/adminPanel/adminPanelSlice";
import { StatusTranslations } from "../../shared/constants/Status";
import { getUserRequestsExcel } from "../../services/userRequestApi";
import moment from "moment";
import { selectCurrentAdminPartner } from "../../utils/helpers/selectorHelper";
import { getBackgroundByStatus } from "../userRequest/list/utils";

const { Column } = Table;
const { Search } = Input;

const AdminPanelTrips = () => {
  const adminPanel = useAppSelector((state) => state.adminPanel.value);
  const filters = useAppSelector((state) => state.adminPanel.filtersAdminPanel);
  const loading = useAppSelector((state) => state.adminPanel.loading);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSearch = (searchText: string) => {
    const newFilters = { ...filters, page: 1, searchText };
    dispatch(setFiltersAdminPanel(newFilters));
  };

  const handleExportExcel = async () => {
    const result = await getUserRequestsExcel({
      startDate: moment(filters.startDate).toDate(),
      endDate: moment(filters.endDate).toDate(),
      shouldSendCompany: false,
      adminId: filters.adminId,
    });

    const link = document.createElement("a");
    link.href = result;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onChangePagination = (currentPage: number, pageSize: number) => {
    const newFilters = { ...filters, page: currentPage, take: pageSize };
    dispatch(setFiltersAdminPanel(newFilters));
  };

  const showTotal = (total: number) => `Total de viajes ${total} `;

  const isAdminPartner = selectCurrentAdminPartner();

  return (
    <Card className="custom-card-trip-details-admin">
      <Row gutter={[0, 16]}>
        <Col span={24}>Viajes recientes</Col>
        <Col span={24} className="custom-export-search-column">
          <Space.Compact block>
            {/* <Button style={{ borderRadius: "10px 0px 0px 10px" }}>Copy</Button> */}
            <Button onClick={handleExportExcel}>Excel</Button>
            {/* <Button>CSV</Button> */}
            {/* <Button style={{ borderRadius: "0px 10px 10px 0px" }}>PDF</Button> */}
          </Space.Compact>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ maxWidth: 400 }}
            allowClear
          />
        </Col>
        <Col span={24}>
          <Table
            dataSource={adminPanel?.userRequestAdminPanel || []}
            rowKey="id"
            pagination={{
              total: adminPanel?.total,
              onChange: onChangePagination,
              hideOnSinglePage: true,
              showSizeChanger: false,
              current: filters.page,
              showTotal,
            }}
            loading={loading}
          >
            <Column title="Hora de finalizacion" dataIndex="finishedAt" />
            <Column title="ID" dataIndex="id" />
            <Column title="Cliente" dataIndex="passengerName" />
            <Column title="Departamento" dataIndex="department" />
            <Column title="Conductor" dataIndex="providerName" />
            <Column
              title="Det. Viaje"
              render={(record) => {
                return (
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      navigate(WebRoutes.AdminPanelTripDetails, {
                        state: { ...record, navigationType: "Admin"  },
                        replace: true,
                      });
                    }}
                  >
                    Ver detalles del viaje
                  </Button>
                );
              }}
            />
            <Column
              title="Estado"
              dataIndex="status"
              render={(item) => {
                const status = StatusTranslations.find((i) => i.name === item);
                if (status) {
                  const color = getBackgroundByStatus(status?.translation);
                  return (
                    <Tag color={color || ""}>
                      {status?.translation?.toUpperCase()}
                    </Tag>
                  );
                }
                return <Tag>{item}</Tag>;
              }}
            />
            <Column
              title={`${isAdminPartner ? "Costo neto" : "Costo"}`}
              dataIndex="formattedTotal"
              render={(formattedTotal, record: any) => {
                return (
                  <>
                    {record.qrFee > 0 ? (
                      <p className="paragraph">Efectivo</p>
                    ) : (
                      formattedTotal
                    )}
                  </>
                );
              }}
            />
            {isAdminPartner && (
              <Column
                title="Código"
                dataIndex="comission"
                render={(comission) => {
                  return comission?.toLocaleString("es-UY", {
                    style: "currency",
                    currency: "UYU",
                  });
                }}
              />
            )}
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

export default AdminPanelTrips;
