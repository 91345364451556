import { Button, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { NavigateFunction } from "react-router-dom";
import { currencyFormat } from "../../../utils/helpers/currency";

export interface DataType {
  id: number;
  name: string;
  price: number;
}

export const columns = (
  navigate: NavigateFunction,
  handleClick: any
): ColumnsType<DataType> => [
  {
    title: "ID",
    dataIndex: "id",
    sorter: false,
    width: "10%",
  },
  {
    title: "Región A",
    sorter: false,
    width: "20%",
    dataIndex: "origin",
    render: (origin) => origin.name,
  },
  {
    title: "Región B",
    sorter: false,
    width: "20%",
    dataIndex: "destination",
    render: (destination) => destination.name,
  },
  {
    title: "Precio",
    sorter: false,
    width: "20%",
    dataIndex: "price",
    render: (price) => currencyFormat(+price),
  },
  {
    title: "Precio conductor",
    sorter: false,
    width: "20%",
    dataIndex: "driverPrice",
    render: (price) => currencyFormat(+price),
  },
  {
    title: "Empresa",
    sorter: false,
    width: "10%",
    dataIndex: "company",
  },
  {
    title: "Prioridad",
    dataIndex: "priority",
    sorter: false,
    width: "10%",
  },
  {
    title: "Acciones",
    key: "action",
    render: (_, record) => (
      <Button onClick={() => handleClick(record)}>
        <Space>Editar Precios</Space>
      </Button>
    ),
  },
];
