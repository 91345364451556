export const CALCULATOR_TYPE = [
    {
        value: "MIN",
        label: "MIN"
    },
    {
        value: "HOUR",
        label: "HOUR"
    },
    {
        value: "DISTANCE",
        label: "DISTANCE"
    },
    {
        value: "DISTACENMIN",
        label: "DISTACENMIN"
    },
    {
        value: "DISTANCEHOUR",
        label: "DISTANCEHOUR"
    }
]