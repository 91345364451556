import {
  Layout,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { FilterValue } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import "../../shared/styles/list.scss";
import { currencyColumns } from "./columns";
import { getCurrencies } from "../../services/currencyApi";

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters: Record<string, FilterValue | null>;
}

export interface DataType {
  id: number;
  name?: string;
  code?: string;
  base: string;
  rate: number;
}

const CurrencyList = () => {
  const title = "Lista de Denominaciones";
  const [data, setData] = useState();

  const fetchData = async () => {
    const data = await getCurrencies();
    setData(data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={currencyColumns()}
            rowKey={(record) => record.id}
            dataSource={data}
          />
        </Row>
      </Content>
    </Layout>
  );
};

export default CurrencyList;
