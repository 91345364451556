import { NotificationOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import { useState } from "react";
import { CheckPoint } from "../../../shared/dtos/groupTripDTO";
import { sendNotificationTripPassengeres } from "../../../services/notificationWhatsappApi";

interface Props {
  checkPoints: Array<CheckPoint>;
  trip: number | string;
  date: string;
}

const NotificationGroup = ({ checkPoints, trip, date }: Props): JSX.Element => {
  const [sending, setSending] = useState(false);

  const notificationTripPassengers = () => {
    setSending(true);
    sendNotificationTripPassengeres(checkPoints, trip, date)
      .then((resp: any) => {
        setSending(false);
        let ok = 0;
        resp.forEach((element: any) => {
          if (element.status === "success") {
            ok += 1;
          }
        });
        if (ok === resp.length) {
          message.success(resp[0]?.data.message);
        } else {
          message.warning(
            `${ok} notificaciones fueron enviadas satisfactoriamente.`
          );
        }
      })
      .catch((err) => {
        setSending(false);
        console.log(err);
      });
  };

  return (
    <Tooltip
      title="Enviar notificación de confirmación de viaje."
      placement="topRight"
    >
      <Button
        type="link"
        size="small"
        icon={
          sending ? (
            <SyncOutlined spin />
          ) : (
            <NotificationOutlined onClick={notificationTripPassengers} />
          )
        }
      />
    </Tooltip>
  );
};

export default NotificationGroup;
