import { createSlice } from "@reduxjs/toolkit";
import { DestinationDTO } from "../../shared/dtos/destinationsDTO";
import MapDTO from "../../shared/dtos/mapDTO";
import providerDTO from "../../shared/dtos/providerDTO";
import { OriginDTO } from "../../shared/dtos/originDTO";
import { GoogleMapsCountryDTO } from "../../shared/dtos/googleMapsCountryDTO";
import { COUNTRIES } from "../../utils/constants/countries";

const dirverList: Array<providerDTO> = [
  {
    id: 1,
    name: "Manual Perez",
    phone: "2542131313",
    rating: 5,
    coordinates: {
      lat: -34.88036,
      lng: -56.14928,
    },
  },
  {
    id: 2,
    name: "Alejandro Caballero",
    phone: "2542131313",
    rating: 5,
    coordinates: {
      lat: -34.88552,
      lng: -56.15028,
    },
  },
  {
    id: 3,
    name: "Salvador Gutierrez",
    phone: "2542131313",
    rating: 3,
    coordinates: {
      lat: -34.90519,
      lng: -56.18099,
    },
  },
  {
    id: 4,
    name: "Francisco Ortiz",
    phone: "2542131313",
    rating: 5,
    coordinates: {
      lat: -34.88365,
      lng: -56.15519,
    },
  },
  {
    id: 5,
    name: "Xavier Vidal",
    phone: "2542131313",
    rating: 4,
    coordinates: {
      lat: -34.884,
      lng: -56.15061,
    },
  },
  {
    id: 6,
    name: "Ruben Nieto",
    phone: "2542131313",
    rating: 3,
    coordinates: {
      lat: -34.84183,
      lng: -56.20727,
    },
  },
  {
    id: 7,
    name: "Hector Rubio",
    phone: "2542131313",
    rating: 3,
    coordinates: {
      lat: -34.85991,
      lng: -56.22875,
    },
  },
  {
    id: 8,
    name: "Mario Reyes",
    phone: "2542131313",
    rating: 3,
    coordinates: {
      lat: -34.89923,
      lng: -56.15998,
    },
  },
];

export interface MapState {
  list: Array<providerDTO>;
  show: boolean;
  destinations: MapDTO | null;
  distance: number;
  duration: number;
  country: GoogleMapsCountryDTO;
}

const initialState: MapState = {
  list: dirverList,
  show: false,
  destinations: null,
  distance: 0,
  duration: 0,
  country: COUNTRIES[0],
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    set: (state, action) => {
      state.list = action.payload;
    },
    reset: (state) => {
      state.list = [];
    },
    showProviders: (state, action) => {
      state.show = action.payload;
    },
    setDestinations: (state, action) => {
      state.destinations = action.payload;
    },
    resetTrip: (state) => {
      state.destinations = null;
    },
    setDistance: (state, action) => {
      state.distance = action.payload.distance;
      state.duration = action.payload.duration;
    },
    setGoogleMapsCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});

export const {
  set,
  reset,
  showProviders,
  setDestinations,
  resetTrip,
  setDistance,
  setGoogleMapsCountry,
} = mapSlice.actions;
export const selectDrivers = (state: any) => state.map.list;

export const setDestination =
  (value: DestinationDTO | undefined) => (dispatch: any, getState: any) => {
    dispatch(
      setDestinations({ ...getState().map.destinations, dropOff: value })
    );
  };

export const setOrigin =
  (value: OriginDTO | undefined) => (dispatch: any, getState: any) => {
    dispatch(
      setDestinations({ ...getState().map.destinations, pickUp: value })
    );
  };

export const setCountry =
  (value: GoogleMapsCountryDTO) => (dispatch: any, getState: any) => {
    dispatch(setGoogleMapsCountry(value));
  };

export default mapSlice.reducer;
