export const listDays = [
  {
    id: 1,
    name: "Lunes",
    value: "Lunes",
  },
  {
    id: 2,
    name: "Martes",
    value: "Martes",
  },
  {
    id: 3,
    name: "Miercoles",
    value: "Miercoles",
  },
  {
    id: 4,
    name: "Jueves",
    value: "Jueves",
  },
  {
    id: 5,
    name: "Viernes",
    value: "Viernes",
  },
  {
    id: 6,
    name: "Sabado",
    value: "Sabado",
  },
  {
    id: 7,
    name: "Domingo",
    value: "Domingo",
  },
];
