import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdmin } from "../../services/adminApi";
import NewAdminDTO from "../../shared/dtos/newAdminDTO";
import ModelState from "../../shared/states/modelState";

const initialState: ModelState<NewAdminDTO> = {
  value: {
    name: "",
    email: "",
    mobile: "",
    active: true,
    adminTypeId: 0,
    password: "",
    picture: "",
  } as NewAdminDTO,
  status: "idle",
};

interface getAdminByIdProps {
  id: number;
  byToken: boolean;
}

export const getAdminByIdAsync = createAsyncThunk(
  "admin/",
  async ({ id, byToken = false }: getAdminByIdProps) => {
    const response: NewAdminDTO = await getAdmin(id, byToken);

    return response;
  }
);

export const getAdminByIdSlice = createSlice({
  name: "getAdminById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdminByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(getAdminByIdAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default getAdminByIdSlice.reducer;
