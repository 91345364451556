import axios from "axios";
import UserRequestByDateListProps from "../propInterfaces/userRequestListExportProps";
import UserRequestByDateRequestDTO from "../shared/dtos/userRequestListExportRequestDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const getUserRequestsStatementAdminPanelApi = async ({
  startDate,
  endDate,
  page,
  take,
  bookingId,
  searchText,
  adminId,
  departmentName,
}: UserRequestByDateListProps) => {
  const userRequestListExportRequestDTO: UserRequestByDateRequestDTO = {
    startDate,
    endDate,
    bookingId,
    searchText,
    adminId,
    departmentName,
  };

  const result = await axios.post(
    `${apiUrl}user-request/report/statement/dashboard?page=${page}&take=${take}`,
    userRequestListExportRequestDTO,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};
