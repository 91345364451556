import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const getAdminServiceTypeByServiceTypeId = async (serviceTypeId: number) => {
  const result = await axios.get(
    `${apiUrl}admin/servicetype/${serviceTypeId}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.data;
};

export const addAdminServiceTypes = async (serviceTypeId: number, adminIds: Array<number>) => {
  const result = await axios.post(
    `${apiUrl}admin/servicetype/${serviceTypeId}`,
    { adminIds },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};