import { Popconfirm, Space, Table, Tooltip } from "antd";
import { PassengerGroupDTO } from "../../../shared/dtos/PassengerGroupDTO";
import {
  DeleteOutlined,
  EditOutlined,
  FolderAddOutlined,
  StopOutlined,
} from "@ant-design/icons";
import "./tablePassenger.css";
import { useRef, useCallback } from "react";
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const { Column } = Table;

interface Props {
  passengers: Array<any>;
  allowDisablePassenger?: boolean;
  allowEdit?: boolean;
  actionEdit?: () => void;
  showIdPassenger?: boolean;
  sortable?: boolean;
  updateDataAfterSortAction?: (passengers: PassengerGroupDTO[]) => void;
  deleteAction?: (id: string) => void;
  validateAddress?: (record: any, direction: string) => void;
}

interface DraggableBodyRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = "DraggableBodyRow";

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const TablePassengerList = ({
  passengers,
  allowEdit = true,
  allowDisablePassenger = true,
  actionEdit,
  showIdPassenger = true,
  sortable = false,
  updateDataAfterSortAction,
  deleteAction,
  validateAddress,
}: Props): JSX.Element => {
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = passengers[dragIndex];
      if (updateDataAfterSortAction) {
        updateDataAfterSortAction(
          update(passengers, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        );
      }
    },
    [passengers, updateDataAfterSortAction]
  );

  return (
    <div id="components-table-demo-drag-sorting">
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          dataSource={passengers}
          pagination={{ pageSize: 8, hideOnSinglePage: true }}
          components={!sortable ? undefined : components}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <p style={{ margin: 0 }}>Email: {record?.email}</p>
                <p style={{ margin: 0 }}>
                  Telefono: {record?.phone ? record?.phone : "No tiene"}
                </p>
                {/*<p style={{ margin: 0 }}>Hora de llegada: {record?.entry_time}</p> */}
                <p style={{ margin: 0 }}>Descripcion: {record?.description}</p>
              </>
            ),
          }}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr as React.HTMLAttributes<any>;
          }}
        >
          {showIdPassenger && (
            <Column title="ID" dataIndex="passenger_id" key="passenger_id" />
          )}
          <Column title="Nombre y Apellidos" dataIndex="name" key="name" />
          <Column title="Celular" dataIndex="cellphone" key="cellphone" />
          <Column
            title="Origen"
            dataIndex="origin"
            key="origen"
            render={(_: any, record: PassengerGroupDTO) => {
              //Verificar si existe el destino predefinido?
              //Si: Poner tooltip con la direccion completa y marcar en el mapa como destino
              //No:Poner valor que hay en el excell y poner boton que permita editar y guardar los cambios
              return record.origin.address !== "No validado" ? (
                <Tooltip placement="top" title={record.origin.address}>
                  <b>
                    {record?.origin.name.slice(0, 25)}{" "}
                    {record.origin.name.length > 25 && "..."}
                  </b>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Asignar direccion">
                  <FolderAddOutlined
                    onClick={() =>
                      validateAddress ? validateAddress(record, "origin") : null
                    }
                    style={{ marginLeft: "40%", color: "red", fontSize: 30 }}
                  />
                  <br />
                  {record?.origin?.name && record?.origin.name.slice(0, 30)}
                  {record?.origin?.name &&
                    record.origin.name.length > 30 &&
                    "..."}
                </Tooltip>
              );
            }}
          />

          <Column
            title="Destino"
            dataIndex="destiny"
            key="destiny"
            render={(_: any, record: PassengerGroupDTO) => {
              //Verificar si existe el destino predefinido?
              //Si: Poner tooltip con la direccion completa y marcar en el mapa como destino
              //No:Poner valor que hay en el excell y poner boton que permita editar y guardar los cambios
              return record.destiny.address !== "No validado" ? (
                <Tooltip placement="top" title={record.destiny.address}>
                  <b>
                    {record?.destiny.name.slice(0, 25)}{" "}
                    {record.destiny.name.length > 25 && "..."}
                  </b>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Asignar destino">
                  <FolderAddOutlined
                    onClick={() =>
                      validateAddress
                        ? validateAddress(record, "destiny")
                        : null
                    }
                    style={{ marginLeft: "40%", color: "red", fontSize: 30 }}
                  />
                  {record?.destiny.name}
                </Tooltip>
              );
            }}
          />
          <Column
            title="Action"
            key="action"
            render={(_: any, record: any) => (
              <Space size="middle">
                {allowDisablePassenger && (
                  <Tooltip placement="top" title="Desabilitar pasajero">
                    <StopOutlined style={{ color: "red" }} />
                  </Tooltip>
                )}

                {allowEdit && (
                  <Tooltip placement="top" title="Editar datos pasajero">
                    <EditOutlined style={{ color: "blue" }} />
                  </Tooltip>
                )}

                <Tooltip placement="top" title="Cancelar pasajero">
                  <Popconfirm
                    title="Está seguro(a) que desea eliminar este pasajero?"
                    onConfirm={() => {
                      if (deleteAction) {
                        deleteAction(record?.id);
                      }
                      // deletePasseger(record?.id)}
                    }}
                    placement="topRight"
                    okText="Si"
                    cancelText="No"
                  >
                    <DeleteOutlined style={{ color: "red" }} />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
      </DndProvider>
    </div>
  );
};

export default TablePassengerList;
