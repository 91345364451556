import axios from "axios";
import { GroupTripRequestDTO } from "../shared/dtos/groupTripRequestDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_GROUP_TRIPS_MOCROSERVICE_API;
const apiUrlMowi = process.env.REACT_APP_API_URL;

export const getGroupTripsRequest = async ({ clientId }: any) => {
  const result = await axios.get(`${apiUrl}request`, {
    params: {
      clientId,
    },
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const addGroupTripsRequest = async (request: GroupTripRequestDTO) => {
  const result = await axios.post(`${apiUrl}request`, request, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const editGroupTripsRequest = async (request: GroupTripRequestDTO) => {
  const result = await axios.put(`${apiUrl}request/${request.id}`, request, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const deleteGroupTripsRequest = async (id: string) => {
  const result = await axios.delete(`${apiUrl}request/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const generateTrips = async (id: string, end_date: string) => {
  console.log("fecha :", end_date);

  const result = await axios.post(
    `${apiUrl}request/generate-groups/${id}`,
    { end_date },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export interface ManagersParameters {
  type: string;
  page?: number;
  take?: number;
  search?: string;
  lote: boolean;
}

export const getManagersList = async ({
  type,
  page,
  take,
  search,
  lote,
}: ManagersParameters) => {
  const result = await axios.get(`${apiUrlMowi}users/type/${type}`, {
    params: { page, take, search, lote },
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};
