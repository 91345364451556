/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DatePicker,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from "antd";
import * as multiDatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment";
import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import {
  serviceTypeListAsync,
  updateServiceTypeListValue,
} from "../../../../features/serviceType/serviceTypeListSlice";
import { AutoCompleteUser } from "../../../../services/userAPI";
import { WebRoutes } from "../../../../shared/constants/WebRoutes";
import ServiceTypeDTO from "../../../../shared/dtos/serviceTypeDTO";
import UserRequestDTO from "../../../../shared/dtos/userRequestDTO";
import AutoCompleteDTO from "../../../../shared/dtos/autoCompleteDTO";
import ComponentPlacesAutocomplete from "../../../map/PlaceAutocomplete";
import {
  CorporativoDescription,
  EmptyString,
} from "../../../../shared/constants/Resources";
import { v4 as uuidv4 } from "uuid";
import { setCountry, setDestination } from "../../../../features/map/mapSlice";
import { userRequestPostAsync } from "../../../../features/userRequest/userRequestPostSlice";
import { COUNTRIES } from "../../../../utils/constants/countries";

interface reducerAction {
  type: string;
  value?: string;
  data?: any;
  newState?: AutoCompleteDTO;
}

const UserRequestForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const serviceTypeListValue: ServiceTypeDTO[] = useSelector(
    (state: RootState) => state.serviceTypeList.value
  );

  const { destinations, distance } = useSelector(
    (state: RootState) => state?.map
  );

  const [shouldAutocomplete, setShouldAutocomplete] = useState<boolean>(true);
  const [isMultipleDates, setIsMultipleDates] = useState<boolean>(false);
  const [showRecurrentDays, setShowRecurrentDays] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(updateServiceTypeListValue());
  }, [dispatch]);

  useEffect(() => {
    dispatch(serviceTypeListAsync()).then((res: any) => {
      form.setFieldsValue({
        serviceTypeId: res.payload.find((x: any) =>
          x.name ? x.name.toLowerCase() === CorporativoDescription : EmptyString
        ).id,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      countryId: COUNTRIES[0].label,
    });
  }, [dispatch]);

  const [userData, dispatchUserData] = useReducer(
    (state: AutoCompleteDTO, action: reducerAction) => {
      switch (action.type) {
        case "LAST_NAME":
          return { ...state, lastName: action.value };
        case "FIRST_NAME":
          return { ...state, firstName: action.value };
        case "EMAIL":
          return { ...state, email: action.value };
        case "MOBILE":
          return { ...state, mobile: action.value };
        case "OVERRIDE":
          return { ...state, ...action.newState };
        case "ADD_DESTINATION":
          return {
            ...state,
            destinations: state.destinations.concat({ id: uuidv4() }),
          };
        case "EDIT_DESTINATION":
          return {
            ...state,
            destinations: state.destinations.map((destination) =>
              destination.id !== action.value
                ? destination
                : {
                    ...action.data,
                    id: action.value,
                  }
            ),
          };
        case "REMOVE_DESTINATION":
          return {
            ...state,
            destinations: state.destinations.filter(
              (destination) => destination.id !== action.value
            ),
          };
        default:
          return state;
      }
    },
    { lastName: "initial", destinations: [{ id: uuidv4() }] }
  );

  useEffect(() => {
    dispatch(
      setDestination(
        userData.destinations[userData.destinations.length - 1].directionData
      )
    );
  }, [userData.destinations]);

  const onBlurOut = async () => {
    if (shouldAutocomplete) {
      const res: AutoCompleteDTO = await AutoCompleteUser(userData);
      if (res) {
        dispatchUserData({ type: "OVERRIDE", newState: res });
        form.setFieldsValue({
          mobile: res.mobile,
          email: res.email,
          firstName: res.firstName,
          lastName: res.lastName,
        });
        setShouldAutocomplete(false);
      }
    }
  };

  const onFinish = (values: any): void => {
    const dateList: Array<string> = new Array<string>();

    if (values.scheduledDates) {
      for (var i of values.scheduledDates as multiDatePicker.DateObject[]) {
        i as multiDatePicker.DateObject;
        dateList.push(moment(i.toDate()).format("YYYY-MM-DD HH:mm:ss"));
      }
    }

    const userRequest: UserRequestDTO = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      autoAssign: values.autoAssign ?? true,
      recurrentDays: values.recurrentDays,
      description: values.description,
      reason: values.reason,
      paymentMode: values.paymentMode ?? "MOWI",
      scheduleAt:
        !isMultipleDates && values.scheduleAt
          ? moment(values.scheduleAt).format("YYYY-MM-DD HH:mm:ss")
          : null,
      serviceTypeId: values.serviceTypeId,
      originLatitude: destinations?.pickUp?.originLatitude,
      originLongitude: destinations?.pickUp?.originLongitude,
      destinationLatitude: destinations?.dropOff?.destinationLatitude,
      destinationLongitude: destinations?.dropOff?.destinationLongitude,
      distance: distance ? distance : 0,
      originAddress: destinations?.pickUp?.originAddress,
      destinationAddress: destinations?.dropOff?.destinationAddress,
      destinations: userData.destinations.map((d) => ({
        latitude: d?.directionData.destinationLatitude,
        longitude: d?.directionData.destinationLongitude,
        address: d?.directionData.destinationAddress,
      })),
      scheduledDates: isMultipleDates && dateList ? dateList : null,
      departmentCode: values.departmentCode,
      passengersData:
        values.passengerName && values.passengerPhone
          ? [
              {
                name: values.passengerName,
                phone: values.passengerPhone,
              },
            ]
          : [],
      countryId:
        values.countryId === "Uruguay"
          ? 229
          : COUNTRIES[values.countryId].value,
    };

    dispatch(userRequestPostAsync(userRequest)).finally(() => {
      navigate(WebRoutes.TripListRoute, {
        replace: true,
      });
    });
  };

  const prefixSelector: JSX.Element = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }}>
        <Select value="86">+598</Select>
        <Select value="87">+54</Select>
      </Select>
    </Form.Item>
  );

  const onChange = (value: any) => {
    const country = COUNTRIES[value];
    dispatch(setCountry(country));
  };

  const onScheduleAtChange = (value: any) => {
    setShowRecurrentDays(true);
  }

  return (
    <div style={{ padding: "5px" }}>
      <h1>Detalle viaje</h1>
      <Form
        layout="vertical"
        form={form}
        name="trip"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="Nombre"
              tooltip="Cual es su nombre?"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte su nombre!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder="Pepe"
                value={userData.firstName ?? ""}
                onChange={(e) =>
                  dispatchUserData({
                    type: "FIRST_NAME",
                    value: e.target.value,
                  })
                }
                onBlur={() => {
                  onBlurOut();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Apellido"
              tooltip="Cual es su apellido?"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte su apellido!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder="Perez"
                value={userData.lastName ?? ""}
                onChange={(e) =>
                  dispatchUserData({
                    type: "LAST_NAME",
                    value: e.target.value,
                  })
                }
                onBlur={() => {
                  onBlurOut();
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="email"
              label="E-mail"
              tooltip="Cual es su correo?"
              rules={[
                {
                  type: "email",
                  message: "Inserte un correo válido!",
                },
                {
                  required: true,
                  message: "Inserte su correo!",
                },
              ]}
            >
              <Input
                placeholder="roge@mowi.com"
                value={userData.email ?? ""}
                onChange={(e) =>
                  dispatchUserData({
                    type: "EMAIL",
                    value: e.target.value,
                  })
                }
                onBlur={() => {
                  onBlurOut();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobile"
              label="Telefono"
              tooltip="Cual es su telefono?"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte su número de telefono!",
                },
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                style={{ width: "100%" }}
                placeholder="52013994"
                value={userData.mobile ?? ""}
                onChange={(e) =>
                  dispatchUserData({
                    type: "MOBILE",
                    value: e.target.value,
                  })
                }
                onBlur={() => {
                  onBlurOut();
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              name="passengerName"
              label="Nombre del Pasajero"
              tooltip="Cual es el nombre del Pasajero?"
              rules={[
                {
                  required: false,
                  message: "Por favor inserte el nombre del Pasajero!",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Pepe" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="passengerPhone"
              label="Telefono del Pasajero"
              tooltip="Cual es el telefono del pasajero? Recuerda no agregar '+', ni espacios. Ejemplo: 59899017660"
              rules={[
                {
                  required: false,
                  message: "Por favor inserte su nombre!",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="59899017660" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="description"
          label="Descripcion"
          tooltip="Algùn dato de interes?"
        >
          <Input.TextArea
            showCount
            maxLength={1000}
            placeholder="Carga fràgil"
          />
        </Form.Item>
        <Form.Item name="reason" label="Motivo" tooltip="Motivo del viaje">
          <Input placeholder="Viaje de Trabajo" />
        </Form.Item>
        <Form.Item name="countryId" label="Pais" tooltip="Pais del viaje">
          <Select placeholder="Selecione un pais" onChange={(e) => onChange(e)}>
            {COUNTRIES.map((country: any, index: number) => (
              <Select key={index} value={country?.id}>
                {country?.label}
              </Select>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="originAddress"
          label="Direccion de origen"
          tooltip="Lugar de salida?"
          rules={
            destinations?.pickUp
              ? [{ required: false }]
              : [
                  {
                    required: true,
                    message: "Inserte una dirección!",
                  },
                ]
          }
        >
          <ComponentPlacesAutocomplete type="origin" />
        </Form.Item>
        {userData.destinations.map((destination, index) => (
          <>
            <Form.Item
              name="destinationAddress"
              label={`Dirección Destino ${index + 1}`}
              tooltip="Lugar de destino?"
              rules={
                destinations?.dropOff
                  ? [{ required: false }]
                  : [
                      {
                        required: true,
                        message: "Inserte una dirección!",
                      },
                    ]
              }
            >
              <Row justify="start">
                <Col flex="auto">
                  <ComponentPlacesAutocomplete
                    key={uuidv4()}
                    type="multi"
                    callback={(data: any) => {
                      dispatchUserData({
                        type: "EDIT_DESTINATION",
                        data: data,
                        value: destination.id,
                      });
                    }}
                    placeholder={destination.value ? destination : null}
                  />
                </Col>
                {index > 0 && (
                  <Col flex="150px">
                    <Button
                      onClick={() =>
                        dispatchUserData({
                          type: "REMOVE_DESTINATION",
                          value: destination.id.toString(),
                        })
                      }
                      style={{
                        display:
                          userData.destinations.length > 1 ? "block" : "none",
                        margin: "0 auto",
                      }}
                      danger
                      type="primary"
                    >
                      Quitar Destino
                    </Button>
                  </Col>
                )}
              </Row>
            </Form.Item>
          </>
        ))}
        <Form.Item>
          <Row justify="start">
            <Col>
              <Button
                onClick={() =>
                  dispatchUserData({
                    type: "ADD_DESTINATION",
                  })
                }
                ghost
                type="primary"
              >
                Añadir Destino
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="departmentCode"
          label="Codigo de Departamento"
          tooltip="El viaje tiene algun codigo de departamento?"
        >
          <Input placeholder="CODIGO" />
        </Form.Item>

        {!isMultipleDates && (
          <>
            <Form.Item
              name="scheduleAt"
              label="Fecha a agendar"
              tooltip="Fecha en la que va a hacer el viaje?"
            >
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Fecha"
                style={{ width: "100%" }}
                onChange={onScheduleAtChange}
              />
            </Form.Item>
          </>
        )}

        {isMultipleDates && (
          <>
            <Form.Item
              name="scheduledDates"
              label="Fechas a agendar"
              tooltip="En que fechas quiere agendar un viaje?"
            >
              <multiDatePicker.Calendar
                multiple
                plugins={[<TimePicker position="right" />]}
              />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Row justify="start">
            <Col>
              <Button
                onClick={() => setIsMultipleDates(!isMultipleDates)}
                ghost
                type="primary"
              >
                {!isMultipleDates
                  ? "Agendar multiples fechas"
                  : "Agendar una fecha"}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        {showRecurrentDays ? (
          <Form.Item
            name="recurrentDays"
            label="Dias recurrentes"
            tooltip="Que dias de la semana se repetira?"
          >
            <Checkbox.Group
              style={{
                width: "100%",
              }}
            >
              <Checkbox value="1">Lunes</Checkbox>
              <Checkbox value="2">Martes</Checkbox>
              <Checkbox value="3">Miercoles</Checkbox>
              <Checkbox value="4">Jueves</Checkbox>
              <Checkbox value="5">Viernes</Checkbox>
              <Checkbox value="6">Sabado</Checkbox>
              <Checkbox value="7">Domingo</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        ) : null}
        {serviceTypeListValue ? (
          <Form.Item
            name="serviceTypeId"
            label="Tipo Servicio"
            tooltip="En que vehiculo desea viajar?"
            rules={[
              { required: true, message: "Por favor seleccione un servicio!" },
            ]}
          >
            <Select placeholder="Selecione un tipo">
              {serviceTypeListValue.map(
                (serviceType: ServiceTypeDTO, index: number) => (
                  <Select key={index} value={serviceType?.id}>
                    {serviceType?.name}
                  </Select>
                )
              )}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item
          name="autoAssign"
          label="Auto-Asignar Driver"
          valuePropName="checked"
        >
          <Switch defaultChecked={true} />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Col>
              <Button
                onClick={() =>
                  navigate(WebRoutes.TripListRoute, { replace: true })
                }
              >
                Cancelar
              </Button>
            </Col>
            <Col offset={1}>
              <Button type="primary" htmlType="submit">
                Solicitar
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserRequestForm;
