import axios from "axios";
import { MultipleLoadDTO } from "../shared/dtos/multipleLoadDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const multipleLoadApi = async (data: MultipleLoadDTO) => {
  const result = await axios.post(
    `${apiUrl}trip/bulk/request`,
    {
      ...data,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const mergeLoadApi = async (data: MultipleLoadDTO) => {
  const result = await axios.post(
    `${apiUrl}trip/merge/request`,
    {
      ...data,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};
