export const AdminTypesConstants = [
  {
    label: "Super Admin",
    value: 1,
  },
  {
    label: "Admin",
    value: 2,
  },
  {
    label: "Operador",
    value: 3,
  },
];
