import {
  Alert,
  Button,
  Col,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { QRCodeCanvas } from "qrcode.react";
import ContainerLayout from "../common/containerLayout";
import mowi from "./mowi.png";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  createAdminQr,
  deleteAdminQr,
  getAdminQrByAdminId,
  updateAdminQr,
} from "../../services/AdminQrApi";
import { selectCurrentAdminId } from "../../utils/helpers/selectorHelper";
import { AdminQrDTO } from "../../shared/dtos/adminQrDTO";
import LabelAndInput from "../common/labelAndInput";
import DatePicker from "../common/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import ComponentPlacesAutocomplete from "../map/PlaceAutocomplete";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import PatchAdminQrDTO from "../../shared/dtos/PatchAdminQrDTO";

const qrUrlValue = process.env.REACT_APP_QR_CODE_URL;

const expiration = dayjs().add(30, "day").toDate();

const defaultQR: AdminQrDTO = {
  bgColor: "#FFFFFF",
  errorLevel: "M",
  fgColor: "#000000",
  imgQrSize: 32,
  qrSize: 256,
  expiration,
  fee: 10,
};

const GenerateQR = () => {
  const [qr, setQr] = useState<AdminQrDTO | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { destinations } = useSelector((state: RootState) => state?.map);

  const onChangeErrorLevel = (value: string | null) => {
    if (value)
      setQr((prev) => {
        return {
          ...prev,
          errorLevel: value,
        };
      });
  };

  const onChangeSize = (size: number | null) => {
    if (size) {
      const imgQrSize = (15 * size) / 100;
      setQr((prev) => {
        return {
          ...prev,
          qrSize: size,
          imgQrSize,
        };
      });
    }
  };

  const onChangeColor = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    const color = e.target.value;
    if (color && name)
      setQr((prev) => {
        return {
          ...prev,
          [name]: color,
        };
      });
  };

  const onChangeExpiration = (date: Dayjs | null) => {
    if (date)
      setQr((prev) => {
        return {
          ...prev,
          expiration: date.toDate(),
        };
      });
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.querySelector("#qr-code") as HTMLCanvasElement;
    if (!canvas) throw new Error("<canvas> not found in the DOM");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    const getQr = async () => {
      setLoading(true);
      const response: AdminQrDTO = await getAdminQrByAdminId(
        selectCurrentAdminId()
      );
      if (response?.id) {
        setQr(response);
      } else {
        setQr(defaultQR);
      }
      setLoading(false);
    };

    getQr();
  }, []);

  const update = async () => {
    try {
      const patchAdminQr: PatchAdminQrDTO = new PatchAdminQrDTO();

      if (qr) {
        patchAdminQr.address = destinations?.pickUp.originAddress;
        patchAdminQr.longitude = destinations?.pickUp.originLongitude;
        patchAdminQr.latitude = destinations?.pickUp.originLatitude;
        patchAdminQr.fee = qr.fee;
      }

      await updateAdminQr(patchAdminQr);

      setQr({
        ...qr,
        address: patchAdminQr.address,
        longitude: patchAdminQr.longitude,
        latitude: patchAdminQr.latitude,
        fee: patchAdminQr.fee,
      });
      message.success("QR actualizado satisfactoriamente.");
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 409)
        message.error("Ya existe un QR para este cliente.");
      setLoading(false);
    }
  };

  const save = async () => {
    try {
      setLoading(true);

      if (qr) {
        qr.address = destinations?.pickUp.originAddress;
        qr.longitude = destinations?.pickUp.originLongitude;
        qr.latitude = destinations?.pickUp.originLatitude;
      }

      const response = await createAdminQr({
        ...qr,
        adminId: selectCurrentAdminId(),
      });

      setQr(response?.data);
      message.success("QR generado satisfactoriamente.");
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 409)
        message.error("Ya existe un QR para este cliente.");
      setLoading(false);
    }
  };

  const deleteQRCode = async () => {
    if (qr?.id) {
      try {
        setLoading(true);
        await deleteAdminQr(qr.id);
        setQr(defaultQR);
        setLoading(false);
        message.success("El QR se ha eliminado satisfactoriamente.");
      } catch (error) {
        message.error("Ha ocurrido un error al imntentar eliminar el QR.");
        setLoading(false);
      }
    }
  };

  const qrExpired = useMemo(() => {
    return qr?.expiration && dayjs(qr.expiration).isBefore(dayjs());
  }, [qr?.expiration]);

  const onChangeFee = (fee: number | null) => {
    if (fee) {
      setQr((prev) => {
        return {
          ...prev,
          fee,
        };
      });
    }
  };

  return (
    <ContainerLayout title="Generar código QR" showNotification={false}>
      <Spin spinning={!qr && loading}>
        {!qr && loading ? (
          <Row style={{ minHeight: 200 }}></Row>
        ) : (
          <Row style={{ minHeight: 200 }}>
            {qr?.adminId ? (
              <Col flex="300px">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <LabelAndInput label="Código">
                    <InputNumber
                      defaultValue={qr?.fee}
                      onChange={onChangeFee}
                      size="middle"
                      style={{ width: 250 }}
                      addonBefore="%"
                      min={1}
                    />
                  </LabelAndInput>
                  <LabelAndInput
                    label="Origen: "
                    boldText={`${qr?.address}`}
                    style={{ width: 250 }}
                  >
                    <ComponentPlacesAutocomplete type="origin" />
                  </LabelAndInput>
                  <Button
                    type="primary"
                    onClick={update}
                    loading={loading}
                    style={{ width: 250 }}
                  >
                    Editar QR
                  </Button>
                </Space>
              </Col>
            ) : (
              <Col flex="300px">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <LabelAndInput label="Tamaño">
                    <InputNumber
                      defaultValue={qr?.qrSize}
                      onChange={onChangeSize}
                      size="middle"
                      style={{ width: 250 }}
                      addonBefore="px"
                    />
                  </LabelAndInput>

                  <LabelAndInput label="Nivel">
                    <Select
                      defaultValue={qr?.errorLevel}
                      style={{ width: 250 }}
                      size="middle"
                      onChange={onChangeErrorLevel}
                      options={[
                        {
                          value: "L",
                          label: "L",
                        },
                        {
                          value: "M",
                          label: "M",
                        },
                        {
                          value: "Q",
                          label: "Q",
                        },
                        {
                          value: "H",
                          label: "H",
                        },
                      ]}
                    />
                  </LabelAndInput>

                  <LabelAndInput label="Fecha expiración">
                    <DatePicker
                      defaultValue={dayjs(qr?.expiration)}
                      onChange={onChangeExpiration}
                      size="middle"
                      style={{ width: 250 }}
                      format={"DD-MM-YYYY"}
                    />
                  </LabelAndInput>

                  <LabelAndInput label="Color">
                    <input
                      type="color"
                      defaultValue={qr?.fgColor}
                      style={{ width: 250 }}
                      onChange={(e) => onChangeColor(e, "fgColor")}
                    />
                  </LabelAndInput>
                  <LabelAndInput label="Código">
                    <InputNumber
                      defaultValue={qr?.fee}
                      onChange={onChangeFee}
                      size="middle"
                      style={{ width: 250 }}
                      addonBefore="%"
                      min={1}
                    />
                  </LabelAndInput>

                  <LabelAndInput label="Origen" style={{ width: 250 }}>
                    <ComponentPlacesAutocomplete type="origin" />
                  </LabelAndInput>

                  <Button
                    type="primary"
                    onClick={save}
                    loading={loading}
                    style={{ width: 250 }}
                  >
                    Generar QR
                  </Button>
                </Space>
              </Col>
            )}
            <Col
              flex="auto"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                {qr?.adminId && qrExpired ? (
                  <Alert message="El Código QR ha expirado." type="error" />
                ) : qr?.adminId ? (
                  <Alert
                    message={`Fecha de expiración ${dayjs(
                      qr?.expiration
                    ).format("DD-MM-YYYY HH:mm:ss")}`}
                    type="info"
                  />
                ) : null}

                <QRCodeCanvas
                  id="qr-code"
                  value={`${qrUrlValue}${qr?.code}`}
                  size={qr?.qrSize}
                  bgColor={qr?.bgColor || defaultQR.bgColor}
                  fgColor={qr?.fgColor || defaultQR.fgColor}
                  level={qr?.errorLevel}
                  includeMargin={false}
                  imageSettings={{
                    src: mowi,
                    x: undefined,
                    y: undefined,
                    height: qr?.imgQrSize || 32,
                    width: qr?.imgQrSize || 32,
                    excavate: true,
                  }}
                />
                {!qr?.adminId && (
                  <Alert
                    message={
                      <div style={{ textAlign: "center" }}>
                        Vista previa del QR
                        <br />
                        <b>No válido para ser usado!</b>
                      </div>
                    }
                    type="warning"
                  />
                )}
                {qr?.adminId && !qrExpired && (
                  <Button
                    type="primary"
                    style={{ width: 200 }}
                    onClick={downloadQRCode}
                  >
                    Descargar
                  </Button>
                )}
                {qr?.adminId && (
                  <Button
                    style={{ width: 200 }}
                    onClick={deleteQRCode}
                    loading={loading}
                  >
                    Eliminar
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Spin>
    </ContainerLayout>
  );
};

export default GenerateQR;
