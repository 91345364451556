import { Button, Popconfirm, Space, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import { NavigateFunction } from "react-router-dom";
import { deleteRegion } from "../../../services/regionsApi";

export interface DataType {
  id: number;
  name: string;
  price: number;
}

const confirm = async (id: number, navigate: NavigateFunction) => {
  try {
    await deleteRegion(id);
    notification.success({
      message: `La región ha eliminada correctamente!`,
      placement: "bottom",
      duration: 2,
      onClose() {
        navigate(0);
      },
    });
  } catch (error: any) {
    notification.error({
      message: error.response.data.status,
      description:
        error.response.data.status === 401
          ? "Usted no tiene permiso para realizar esta operacion"
          : "Error en el servidor",
      placement: "bottom",
    });
  }
};

const ConfirmDeleteRegion = ({
  id,
  navigate,
}: {
  id: number;
  navigate: NavigateFunction;
}) => (
  <Popconfirm
    title="Estás seguro de eliminar esta región?"
    onConfirm={() => confirm(id, navigate)}
    okText="Si"
    cancelText="No"
  >
    <Button danger>Eliminar</Button>
  </Popconfirm>
);

export const columns = (navigate: NavigateFunction): ColumnsType<DataType> => [
  {
    title: "ID",
    dataIndex: "id",
    sorter: false,
    width: "20%",
  },
  {
    title: "Nombre",
    dataIndex: "name",
    sorter: false,
    width: "40%",
  },
  {
    title: "Acciones",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button
          type="link"
          onClick={() =>
            navigate(`/regions/edit/${record.id}`, {
              replace: true,
            })
          }
        >
          Editar
        </Button>
        <ConfirmDeleteRegion id={record.id} navigate={navigate} />
      </Space>
    ),
  },
];
