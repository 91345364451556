import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProviderList } from "../../services/providerApi";
import ProviderDTO from "../../shared/dtos/providerDTO";
import { ListState } from "../../shared/states/listState";

const initialState: ListState<ProviderDTO> = {
  value: new Array<ProviderDTO>(),
  status: "idle",
};

export const providerListAsync = createAsyncThunk("providers/", async () => {
  const response = await getProviderList();
  return response;
});

export const providerListSlice = createSlice({
  name: "providerList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(providerListAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(providerListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = state.value.concat(action.payload);
      })
      .addCase(providerListAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default providerListSlice.reducer;
