import React, { useEffect, useState } from "react";
import ProviderDTO from "../../shared/dtos/providerDTO";
import { marketIcon } from "../../shared/constants/Images";
import { Button, Rate } from "antd";
import { Marker, InfoWindow } from "@react-google-maps/api";

interface MapProps {
  providers: Array<ProviderDTO>;
  assignProvider?: (provider: ProviderDTO) => void;
  setProvider?: (provider: ProviderDTO | null) => void;
  selectedProvider?: ProviderDTO | null;
  map?: google.maps.Map | null;
}

export const ProviderMarkers: React.FunctionComponent<MapProps> = (props) => {
  const { providers, assignProvider, selectedProvider, setProvider, map } =
    props;
  const [selected, setSelected] = useState<ProviderDTO | null>(null);

  useEffect(() => {
    if (selectedProvider && selected !== selectedProvider) {
      setSelected(selectedProvider);
    }
    if (map && selectedProvider) {
      map.setCenter({
        lat: selectedProvider.coordinates.lat,
        lng: selectedProvider.coordinates.lng,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, setSelected, selectedProvider]);

  return (
    <>
      {providers?.map((provider, index) => {
        return (
          <Marker
            key={index}
            position={{
              ...provider?.coordinates,
            }}
            title={provider?.name && provider.name}
            icon={marketIcon}
            onClick={() => {
              setSelected(provider);
              setProvider && setProvider(provider);
            }}
          />
        );
      })}
      {selected && (
        <InfoWindow
          position={{
            lat: selected.coordinates.lat,
            lng: selected.coordinates.lng,
          }}
          onCloseClick={() => {
            setSelected(null);
            setProvider && setProvider(null);
          }}
        >
          <div>
            <h2>Nombre: {selected.name}</h2>
            <h3>
              Rankin :{<Rate disabled={true} defaultValue={selected.rating} />}{" "}
            </h3>
            <h3>Telefono :{selected.phone}</h3>
            <h3>Tipo de servicio : Corporativo</h3>
            <h3>
              Modelo de carro :
              {selected?.service?.serviceModel || "Corporativo"}
            </h3>
            {assignProvider && (
              <Button type="primary" onClick={() => assignProvider(selected)}>
                Asignar este proveedor
              </Button>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  );
};
