import { useEffect, useState } from "react";
import NotificationItem from "./notificationItem";
import { useAppDispatch } from "../../../app/hooks";
import { updateCurrentConversation } from "../../../features/notifications/conversationSlice";

interface Props {
  messages: Array<any>;
}

const NotificationItemList = ({ messages }: Props): JSX.Element => {
  const [list, setList] = useState<Array<Array<any>>>([[]]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (messages) {
      const texts = Object.entries(messages);
      setList(texts);
      dispatch(updateCurrentConversation(messages));
    }
  }, [dispatch, messages]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        minHeight: "calc(100vh - 250px)",
        // overflowY: "auto",
      }}
    >
      {list.map((message, i) => {
        const id = String(i + 1);
        const number = message[0] || "";
        const messagesList = message.length > 1 ? message[1] : [];
        return (
          <NotificationItem key={id} phone={number} messages={messagesList} />
        );
      })}
    </div>
  );
};

export default NotificationItemList;
