import { Button, Col, Popconfirm, Row, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import "../styles.scss";

export interface DataType {
  id: number;
  name: string;
  image: string;
  price: number;
  isActiveForAllAdmins: boolean;
}

export const columns = (
  navigate: any,
  handleDelete: any,
  openModal: any,
  activateServiceTypeForAll: any,
  isAssignAllLoading: boolean
): ColumnsType<DataType> => [
  {
    title: "ID",
    dataIndex: "id",
    sorter: false,
  },
  {
    title: "Nombre",
    sorter: false,
    dataIndex: "name",
  },
  {
    title: "Imagen",
    sorter: false,
    dataIndex: "image",
    render: (_, record) => (
      <Row>
        {record.image ? (
          <img
            className="image-container"
            src={record.image}
            alt="this is a test"
          />
        ) : (
          <Space className="image-container">Imagen no disponible</Space>
        )}
      </Row>
    ),
  },
  {
    title: "Precio",
    sorter: false,
    dataIndex: "price",
  },
  {
    title: "Acciones",
    key: "action",
    render: (_, record) => (
      <Row>
        <Col span={4}>
          <Button
            size="small"
            onClick={() =>
              navigate(`/servicetypes/edit/${record.id}`, {
                replace: true,
              })
            }
          >
            <Space>Editar</Space>
          </Button>
        </Col>
        <Col span={4}>
          <Popconfirm
            title="Estas seguro de eliminar este tipo de servicio?"
            onConfirm={async () => {
              handleDelete(record.id);
            }}
            okText="Si"
            cancelText="No"
            placement="bottom"
          >
            <Button size="small">Borrar</Button>
          </Popconfirm>
        </Col>
        <Col span={4}>
          <Button size="small" onClick={() => openModal(record.id)}>
            <Space>Activar Servicio</Space>
          </Button>
        </Col>
        <Col span={2}>
          <Button loading={isAssignAllLoading} size="small" onClick={() => activateServiceTypeForAll(record.id, record.isActiveForAllAdmins)}>
            <Space>Activar Servicio para todos</Space>
          </Button>
        </Col>
      </Row>
    ),
  },
];
