import {
  CarFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Button,
  Tooltip,
  List,
  Typography,
  Card,
  Row,
  Col,
  Space,
  Statistic,
} from "antd";
import { useState, useMemo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { StarFilled } from "@ant-design/icons";
import {
  getDrivers,
  setSelectedDriver,
} from "../../../features/groupTrip/groupTrip/driverAssignModalSlice";

const { Text } = Typography;

type NumUndNull = number | undefined | null;

interface Props {
  assigned: boolean;
  userRequestId?: NumUndNull;
  driverId?: NumUndNull | string;
  latitude?: NumUndNull;
  longitude?: NumUndNull;
  service: NumUndNull;
  onAssignDriver?: (
    userRequestId: NumUndNull,
    selectDriverId: NumUndNull | string,
    closeModal: () => void,
    setConfirmLoading: (flag: boolean) => void
  ) => void | undefined;
  disabled?: boolean;
  onlyBtnIcon?: boolean;
  btnText?: string;
  block?: boolean;
  show?: boolean;
  onShow?: (flag: boolean) => void;
  allowSkipAction?: boolean;
  skipAction?: () => void;
}

const DriverAssignModal = ({
  assigned,
  userRequestId,
  driverId,
  latitude,
  longitude,
  service,
  disabled,
  onAssignDriver,
  onlyBtnIcon,
  btnText,
  block,
  show,
  onShow,
  allowSkipAction = false,
  skipAction,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [distance, setDistance] = useState(20);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { list, loading } = useAppSelector((state) => state.driverAssignModal);
  const selectedDriver = useAppSelector(
    (state) => state.driverAssignModal.selectedDriver
  );

  useEffect(() => {
    if (show) {
      setOpen(true);
    }
  }, [show]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (open) {
      dispatch(
        getDrivers(
          userRequestId,
          driverId,
          latitude,
          longitude,
          distance,
          service
        )
      );
    }
  }, [
    dispatch,
    open,
    userRequestId,
    driverId,
    latitude,
    longitude,
    distance,
    service,
  ]);

  const handleOnClick = () => {
    setOpen(!open);
  };

  const title = useMemo(() => {
    return `${assigned ? "Reasignar driver." : "Asignar driver."}`;
  }, [assigned]);

  const handleSelect = (driver: any) => {
    dispatch(setSelectedDriver(driver));
  };

  const afterClose = () => {
    dispatch(setSelectedDriver(undefined));
  };

  const onOk = () => {
    const close = (): void => {
      setOpen(false);
    };
    let driverId = selectedDriver?.id;
    if (onAssignDriver) {
      onAssignDriver(userRequestId, driverId, close, setConfirmLoading);
      if (skipAction) {
        dispatch(setSelectedDriver(undefined));
      }
    }
  };

  const assignLater = () => {
    if (skipAction) {
      skipAction();
    }
    dispatch(setSelectedDriver(undefined));
  };

  return (
    <>
      <Tooltip title={onlyBtnIcon ? title : ""} placement="topRight">
        <Button
          type={onlyBtnIcon ? "link" : "default"}
          // size={onlyBtnIcon ? "small" : "middle"}
          icon={
            <CarFilled
              style={{
                color: assigned ? "#de8531" : "green",
              }}
            />
          }
          onClick={handleOnClick}
          disabled={disabled}
          block={block}
          hidden={typeof show !== "undefined"}
        >
          {!onlyBtnIcon && <>{btnText || "Asignar driver"}</>}
        </Button>
      </Tooltip>
      <Modal
        open={open}
        title={title}
        onCancel={handleOnClick}
        destroyOnClose
        afterClose={afterClose}
        confirmLoading={confirmLoading}
        closable={!confirmLoading && !allowSkipAction}
        maskClosable={!confirmLoading && !allowSkipAction}
        cancelButtonProps={{ disabled: confirmLoading }}
        footer={
          <>
            <Space>
              {!allowSkipAction && (
                <Button onClick={handleOnClick} disabled={confirmLoading}>
                  <CloseCircleOutlined />
                  Cancelar
                </Button>
              )}
              {allowSkipAction && (
                <Button onClick={assignLater} disabled={confirmLoading}>
                  <CloseCircleOutlined />
                  Asignar en otro momento
                </Button>
              )}
              <Button
                type="primary"
                onClick={onOk}
                disabled={selectedDriver ? false : true}
              >
                <CheckCircleOutlined /> Aceptar
              </Button>
            </Space>
          </>
        }
      >
        <div className="custome-drivers-list-item">
          <List
            dataSource={list}
            loading={loading}
            renderItem={(item) => {
              return (
                <List.Item>
                  <Card
                    style={{
                      width: "100%",
                      background: `${
                        selectedDriver?.id === item?.id ? "#dde3ed" : ""
                      }`,
                    }}
                    bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
                    hoverable
                    onClick={() => handleSelect(item)}
                  >
                    <Row>
                      <Col span={16}>
                        <Row gutter={[12, 5]}>
                          <Col span={24}>
                            <Space>
                              <UserOutlined />
                              <Text mark strong>
                                {`${item?.firstName} ${item?.lastName}`}
                              </Text>
                            </Space>
                          </Col>
                          <Col span={24}>
                            <Space>
                              <PhoneOutlined />
                              <Text>{item?.mobile}</Text>
                            </Space>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Statistic
                          title={
                            <div style={{ textAlign: "center" }}>
                              <Text strong>Calificación</Text>
                            </div>
                          }
                          value={item?.rating}
                          valueStyle={{
                            fontSize: 18,
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                          prefix={<StarFilled style={{ color: "#ffcb32" }} />}
                        />
                      </Col>
                    </Row>
                  </Card>
                </List.Item>
              );
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default DriverAssignModal;
