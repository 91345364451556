import { Navigate, Outlet } from "react-router-dom";
import { selectPasswordUpdatedAt } from "./selectorHelper";
import { WebRoutes } from "../../shared/constants/WebRoutes";

const VerifyAdminPasswordUpdated = () => {
  const passwordUpdated: Date | null = selectPasswordUpdatedAt();
  let isPasswordUpdated = false;

  if (passwordUpdated) isPasswordUpdated = true

  return isPasswordUpdated ? <Outlet /> : <Navigate to={WebRoutes.UpdatePasswordRoute} replace />;
};

export default VerifyAdminPasswordUpdated;
