import {
  CarOutlined,
  DownOutlined,
  EditOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { NavigateFunction } from "react-router-dom";
import DriverStatusSwitch from "./utils/DriverStatusSwitch";
import { TableParams } from ".";
import ProviderSwitch from "./utils/ProviderSwitch";

interface ProviderProfile {
  address?: string;
  city?: string;
  country?: string;
}

interface Service {
  id: number;
  providerId: number;
  serviceTypeId: number;
  status: string;
  serviceNumber: string;
  serviceModel: string;
  createdAt: string;
  updatedAt: string;
}

interface Bank {
  id: number;
  bank: string;
  accountType: string;
  number: string;
}

export interface DataType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dni: string;
  mobile: string;
  rating: string;
  status: string;
  profile: ProviderProfile | null;
  services: Service[];
  bank?: Bank;
  isAdminProvider: boolean;
  isBlackListed: boolean;
}

const items = (
  record: DataType,
  navigate: NavigateFunction
): MenuProps["items"] => {
  return [
    {
      label: "Editar",
      key: "1",
      icon: <EditOutlined />,
      onClick: () => {
        navigate(`/drivers/edit/${record.id}`, {
          replace: true,
        });
      },
    },
    {
      label: "Servicios",
      key: "2",
      icon: <CarOutlined />,
      onClick: () => {
        navigate(`/drivers/services/${record.id}`, {
          replace: true,
        });
      },
    },
  ];
};

export const driverColumns = (
  navigate: NavigateFunction,
  tableParams: TableParams,
  search: string
): ColumnsType<DataType> => [
  {
    title: "ID",
    dataIndex: "id",
    sorter: false,
    width: "10%",
  },
  {
    title: "Nombre",
    dataIndex: "firstName",
    sorter: false,
    width: "10%",
  },
  {
    title: "Apellido",
    dataIndex: "lastName",
    sorter: false,
    width: "10%",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "15%",
  },
  {
    title: "Celular",
    dataIndex: "mobile",
    width: "10%",
  },
  {
    title: "Ciudad",
    width: "5%",
    render: (_, { profile }) => profile?.city || "No registra",
  },
  {
    title: "Rating",
    dataIndex: "rating",
    width: "5%",
    render: (rating) => (+rating).toFixed(1),
  },
  {
    title: "Bank",
    colSpan: 0,
    dataIndex: "bank",
    render: (bank) => (bank ? bank.bank : ""),
  },
  {
    title: "Cuenta bancaria",
    colSpan: 2,
    dataIndex: "bank",
    render: (bank) => (bank ? `${bank.accountType} ${bank.number}` : ""),
  },
  {
    title: "Acciones",
    key: "action",
    render: (_, record) => (
      <Dropdown menu={{ items: items(record, navigate) }}>
        <Button>
          <Space>
            Elegir
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ),
  },
  {
    title: "Aprobado",
    key: "approved",
    render: (_, record) => (
      <Space size="small">
        <DriverStatusSwitch
          providerId={record.id}
          disabled={record.services.length === 0}
          status={record.status}
          tableParams={tableParams}
          search={search}
        />
        {record.services.length === 0 && (
          <Tooltip title="Sin servicios">
            <WarningOutlined style={{ color: "orange" }} />
          </Tooltip>
        )}
      </Space>
    ),
  },
  {
    title: "Conductor Administrador",
    key: "isAdminProvider",
    render: (_, record) => (
      <Space size="small">
        <ProviderSwitch
          providerId={record.id}
          switchValue={record.isAdminProvider}
          tableParams={tableParams}
          search={search}
          switchType="adminProvider"
        />
      </Space>
    ),
  },
  {
    title: "Prohibir toma de viajes",
    key: "isBlackListed",
    render: (_, record) => (
      <Space size="small">
        <ProviderSwitch
          providerId={record.id}
          switchValue={record.isBlackListed}
          tableParams={tableParams}
          search={search}
          switchType="blacklist"
        />
      </Space>
    ),
  },
];
