import { Button, Layout, Modal, Row, Space, Table } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getServicesByProviderId } from "../../../../services/providerService";
import DriverServiceForm from "../form";

import "../../../../shared/styles/list.scss";

interface ServiceType {
  id: number;
  name: string;
}
export interface DataType {
  id: number;
  providerId: number;
  serviceTypeId: number;
  status: string;
  serviceNumber: string;
  serviceModel: string;
  serviceType: ServiceType;
}

const DriverServiceList = () => {
  const title = "Servicios asociados al conductor";

  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [providerService, setProviderService] = useState<DataType>(
    {} as DataType
  );

  let { driverId } = useParams();

  const getServices = async () => {
    if (driverId) {
      setLoading(true);
      const data: Array<DataType> = await getServicesByProviderId(+driverId);
      data.forEach(x => {
        if (x.serviceType == null) {
          x.serviceType = { id: 0, name: "Servicio eliminado" }
        }
      });
      setData(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreatedService = () => {
    setOpen(false);
    getServices();
  };

  const onUpdatedService = () => {
    setOpenEditModal(false);
    getServices();
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Servicio",
      render: (_, { serviceType }) => serviceType.name,
    },
    {
      title: "N° servicio",
      dataIndex: "serviceNumber",
    },
    {
      title: "Modelo",
      dataIndex: "serviceModel",
    },
    {
      title: "Estado",
      dataIndex: "status",
    },
    {
      title: "Editar",
      key: "edit",
      render: (record) => {
        return (
          <>
            <Button
              type="primary"
              onClick={() => {
                setOpenEditModal(true);
                setProviderService(record);
              }}
            >
              Editar
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row" justify="end">
          <Space>
            <Button type="primary" onClick={() => setOpen(true)}>
              + Agregar servicio
            </Button>
            <Modal
              title="Agregar nuevo servicio"
              open={open}
              onCancel={() => setOpen(false)}
              footer={null}
            >
              <DriverServiceForm
                driverId={+(driverId || 0)}
                onSuccess={onCreatedService}
              />
            </Modal>
          </Space>
        </Row>
        <Modal
          title="Editar servicio"
          open={openEditModal}
          onCancel={() => setOpenEditModal(false)}
          footer={null}
        >
          <DriverServiceForm
            driverId={+(driverId || 0)}
            onSuccess={onUpdatedService}
            providerService={providerService}
          />
        </Modal>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            pagination={false}
          />
        </Row>
      </Content>
    </Layout>
  );
};

export default DriverServiceList;
