import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import adminListReducer from "../features/admin/adminListSlice";
import userRequestListReducer from "../features/userRequest/userRequestListSlice";
import providerListSlice from "../features/provider/providerListSlice";
import serviceTypeList from "../features/serviceType/serviceTypeListSlice";
import userRequestPost from "../features/userRequest/userRequestPostSlice";
import authSlice from "../features/auth/authSlice";
import mapSlice from "../features/map/mapSlice";
import pendingUserRequestSlice from "../features/userRequest/pendingUserRequestSlice";
import activeProviderListSlice from "../features/provider/activeProviderListSlice";
import assignProviderToUserRequestSlice from "../features/userRequest/assingProviderToUserRequestSlice";
import removeProviderFromUserRequestSlice from "../features/userRequest/removeProviderFromUserRequestSlice";
import passeger from "../features/groupTrip/passeger/groupTripPassegerSlide";
import groupTripRequestSlide from "../features/groupTrip/groupTripRequest/groupTripRequestSlide";
import groupTripListSlide from "../features/groupTrip/groupTrip/groupTripListSlide";
import userRequestByDateListSlice from "../features/userRequest/userRequestByDateListSlice";
import groupTripsMapSlide from "../features/groupTrip/groupTripsMap/groupTripsMapSlide";
import postAdminSlice from "../features/admin/postAdminSlice";
import getAdminByIdSlice from "../features/admin/getAdminByIdSlice";
import putAdminSlice from "../features/admin/putAdminSlice";
import updatePartnerPriceTableSlice from "../features/admin/updatePartnerPriceTableSlice";
import userRequestByIdSlice from "../features/userRequest/userRequestByIdSlice";
import pendingCalculationUserRequestSlice from "../features/userRequest/pendingCalculationUserRequestSlice";
import groupTripDirectionSlide from "../features/groupTrip/direction/groupTripDirectionSlide";
import driverAssignModalSlice from "../features/groupTrip/groupTrip/driverAssignModalSlice";
import userListSlice from "../features/user/userListSlice";
import conversationSlice from "../features/notifications/conversationSlice";
import notificationTripSlice from "../features/notifications/notificationTripSlice";
import driverSlice from "../features/driver/driverSlice";
import groupTripsClientsSlide from "../features/groupTrip/groupTripsClients/grpupTripsClientsSlice";
import currentAdminSlice from "../features/admin/currentAdminSlice";
import adminPanelSlice from "../features/adminPanel/adminPanelSlice";
import departmentsSlice from "../features/departments/departmentsSlice";
import departmentsByAdminSlice from "../features/departments/deparmentsByAdminSlice";
import getAdminWalletSlice from "../features/adminWallet/adminWalletSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/es/storage";
import getAdminComissionSlice from "../features/adminComission/adminComission";
import adminsForSelectSlice from "../features/admin/adminsForSelectSlice";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["userRequestByDateList"],
};

const rootReducer = combineReducers({
  adminList: adminListReducer,
  userRequestList: userRequestListReducer,
  providerList: providerListSlice,
  serviceTypeList: serviceTypeList,
  userRequestPost: userRequestPost,
  map: mapSlice,
  auth: authSlice,
  pendingUserRequest: pendingUserRequestSlice,
  activeProviderList: activeProviderListSlice,
  assignProviderToUserRequest: assignProviderToUserRequestSlice,
  removeProviderFromUserRequest: removeProviderFromUserRequestSlice,
  passeger: passeger,
  groupTripsRequest: groupTripRequestSlide,
  groupTrip: groupTripListSlide,
  groupTripDirection: groupTripDirectionSlide,
  userRequestByDateList: userRequestByDateListSlice,
  groupTripsMap: groupTripsMapSlide,
  postAdmin: postAdminSlice,
  getAdminById: getAdminByIdSlice,
  putAdmin: putAdminSlice,
  updatePartnerPriceTable: updatePartnerPriceTableSlice,
  userRequestById: userRequestByIdSlice,
  pendingCalculationUserRequest: pendingCalculationUserRequestSlice,
  driverAssignModal: driverAssignModalSlice,
  userList: userListSlice,
  conversation: conversationSlice,
  notificationTrip: notificationTripSlice,
  drivers: driverSlice,
  groupTripsClients: groupTripsClientsSlide,
  currentAdmin: currentAdminSlice,
  adminPanel: adminPanelSlice,
  departments: departmentsSlice,
  departmentsByAdmin: departmentsByAdminSlice,
  adminWallet: getAdminWalletSlice,
  adminComission: getAdminComissionSlice,
  adminsForSelect: adminsForSelectSlice,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        serializableCheck: false,
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);
