import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UpdatePartnerPriceTableProps from "../../propInterfaces/updatePartnerPriceTableProps";
import { updatePartnerPriceTable } from "../../services/adminApi";

export const initialState = {
  value: {},
  status: "idle",
};

export const updatePartnerPriceTableAsync = createAsyncThunk(
  "admins/",
  async ({ id, formData }: UpdatePartnerPriceTableProps) => {
    const response = await updatePartnerPriceTable({ id, formData });

    return response;
  }
);

export const updatePartnerPriceTableSlice = createSlice({
  name: "updatepartnerpricetableslice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePartnerPriceTableAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updatePartnerPriceTableAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updatePartnerPriceTableAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default updatePartnerPriceTableSlice.reducer;
