import { GoogleMapsCountryDTO } from "../../shared/dtos/googleMapsCountryDTO";

export const COUNTRIES: GoogleMapsCountryDTO[] = [
  {
    label: 'Uruguay',
    value: 229,
    code: 'UY',
    lat: -34.901112,
    lng: -56.164532,
    zoom: 13,
  },
  {
    label: 'Argentina',
    value: 13,
    code: 'AR',
    lat: -34.603722,
    lng: -58.381592,
    zoom: 8,
  },
  {
    label: 'Brasil',
    value: 33,
    code: 'BR',
    lat: -15.793889,
    lng: -47.882778,
    zoom: 8,
  },
  {
    label: 'Chile',
    value: 46,
    code: 'CL',
    lat: -33.447487,
    lng: -70.673676,
    zoom: 10,
  },
  {
    label: 'Colombia',
    value: 52,
    code: 'CO',
    lat: 4.624335,
    lng: -74.063644,
    zoom: 10,
  },
  {
    label: 'Paraguay',
    value: 172,
    code: 'PY',
    lat: -25.30066,
    lng: -57.63591,
    zoom: 10,
  },
  {
    label: 'Mexico',
    value: 146,
    code: 'MX',
    lat: 19.43944,
    lng: -99.07212,
    zoom: 10,
  },
  {
    label: 'Costa Rica',
    value: 60,
    code: 'CR',
    lat: 9.93678,
    lng: -84.08230,
    zoom: 10,
  },
  {
    label: 'Peru',
    value: 173,
    code: 'PE',
    lat: -12.04779,
    lng: -77.04283,
    zoom: 8,
  },
  {
    label: 'Ecuador',
    value: 66,
    code: 'EC',
    lat: -0.18844,
    lng: -78.46962,
    zoom: 8,
  },
];
