import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, Col, Input, Modal, Row, notification } from "antd";
import { useEffect, useState } from "react";
import { getAdminInformation } from "../../../services/adminApi";
import AdminDTO from "../../../shared/dtos/adminDTO";
import {
  addAdminServiceTypes,
  getAdminServiceTypeByServiceTypeId,
} from "../../../services/adminServiceTypeApi";
import AdminServiceTypeDTO from "../../../shared/dtos/adminServiceTypeDTO";

import "../../../shared/styles/list.scss";
import "../../admin/reportDocumentList/styles.scss";

const ActivateServiceType = ({
  serviceTypeId,
  setIsModalOpen,
  isModalOpen,
}: any) => {
  const [admins, setAdmins] = useState<Array<AdminDTO>>([]);
  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState<Array<AdminDTO>>([]);
  const [checkedAdmins, setCheckedAdmins] = useState(new Array<number>());
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkedValue, setCheckedValue] = useState(true);
  const [loading, setLoading] = useState(false);

  const filterBySearch = (event: any) => {
    const query: string = event.target.value;

    setSearchText(query);

    let updatedList = [...admins];

    updatedList = updatedList.filter((item: any) => {
      return (
        item.name
          .toLowerCase()
          .indexOf(query ? query.toLowerCase() : "") !== -1
      );
    });

    setFilteredList(updatedList);
  };

  const fetchData = async () => {
    setLoading(true);

    const results: Array<AdminServiceTypeDTO> =
      await getAdminServiceTypeByServiceTypeId(serviceTypeId);
    const adminServiceTypeIdsFromDB = new Array<number>();
    const adminResults: Array<AdminDTO> = await getAdminInformation();
    setAdmins(adminResults);

    if (results.length > 0) {
      results.forEach((adminServiceType: AdminServiceTypeDTO) => {
        if (adminServiceType.id) {
          adminServiceTypeIdsFromDB.push(adminServiceType.adminId);
        }
      });
      setCheckedAdmins(adminServiceTypeIdsFromDB);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleOk = async () => {
    setSearchText("");
    setConfirmLoading(true);
    await addAdminServiceTypes(serviceTypeId, checkedAdmins).then(() => notification.success({
      message: "El tipo de servicio",
      description: "se asigno a los administradores seleccionados exitosamente",
      placement: "bottom",
    }));
    setConfirmLoading(false);
    closeModalBehaviour();
  };

  const handleCancel = () => {
    closeModalBehaviour();
  };

  const closeModalBehaviour = () => {
    setIsModalOpen(false);
    setCheckedAdmins([]);
    setCheckAll(false);
  };

  const onChange = (event: any) => {
    setCheckedAdmins(event);
  };

  const handleCheckAll = (e: any) => {
    setCheckAll(e.target.checked);
    setCheckedAdmins(
      e.target.checked
        ? (admins.map((admin: any) => {
            return admin.id;
          }) as never[])
        : ([] as never[])
    );
  };

  return (
    <>
      <Modal
        title="Selecciona las compañias para activar el tipo de servicio"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText={confirmLoading ? "Cargando" : "Ok"}
      >
        {loading ? (
          <Row>
            <LoadingOutlined style={{ fontSize: "50px", width: '100%' }} />
          </Row>
        ) : (
          <>
            <Row className="input-margin">
              <Input
                type="text"
                value={searchText}
                placeholder="Buscar por email"
                onChange={(e) => filterBySearch(e)}
              />
            </Row>
            <Row className="input-margin">
              <Checkbox
                value={checkedValue}
                checked={checkAll}
                onChange={handleCheckAll}
              >
                Seleccionar todos
              </Checkbox>
            </Row>
            <Row>
              <Col className="checkbox-container">
                <Checkbox.Group value={checkedAdmins} onChange={onChange}>
                  {admins && searchText === "" ? (
                    admins.map((admin: any) => {
                      return (
                        <Row>
                          <Checkbox value={admin.id}>{admin.name}</Checkbox>
                        </Row>
                      );
                    })
                  ) : (
                    filteredList.map((admin: any) => {
                      return (
                        <Row>
                          <Checkbox value={admin.id}>{admin.name}</Checkbox>
                        </Row>
                      );
                    })
                  )}
                </Checkbox.Group>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default ActivateServiceType;
