import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserRequestProps from "../../propInterfaces/userRequestListExportProps";
import PendingPayEmailProps from "../../propInterfaces/pendingPayEmailProps";
import {
  getPendingCalculationUserRequest,
  sendPaymentEmail,
  skipPendingUserRequest,
} from "../../services/userRequestApi";
import UserRequestByDateDTO from "../../shared/dtos/userRequestExportDTO";

const initialState = {
  value: {
    userRequestByDateDto: new Array<UserRequestByDateDTO>(),
    total: 0,
    take: 10,
  },
  status: "idle",
};

export const pendingCalculationUserRequestAsync = createAsyncThunk(
  "userrequest/pending/calculation",
  async ({ page, take = 10, searchText }: UserRequestProps) => {
    const response: any = await getPendingCalculationUserRequest({
      page,
      take,
      searchText,
    });

    const userRequestObject = {
      userRequestExportDto: response.data,
      total: response.total,
      take: take,
      totalSpent: response.totalSpent,
    };

    return userRequestObject;
  }
);

export const removeFromPendingListAsync = createAsyncThunk(
  "userrequest/remove/pending",
  async (request: PendingPayEmailProps) => {
    const response = await sendPaymentEmail(request);

    return response;
  }
);

export const skipFromPendingListAsync = createAsyncThunk(
  "userrequest/skip/pending",
  async (requestId: number) => {
    const response = await skipPendingUserRequest(requestId);

    return response;
  }
);

export const userRequestSlice = createSlice({
  name: "userRequestListExport",
  initialState,
  reducers: {
    updateUserRequestByDateValue: (state, action) => {
      state.value.userRequestByDateDto = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pendingCalculationUserRequestAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(
        pendingCalculationUserRequestAsync.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.value.userRequestByDateDto =
            action.payload.userRequestExportDto;
          state.value.total = action.payload.total;
        }
      )
      .addCase(pendingCalculationUserRequestAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { updateUserRequestByDateValue } = userRequestSlice.actions;

export default userRequestSlice.reducer;
