export const PATH = {
  AUTH_USER: "auth/user",
  AUTH_ADMIN: "auth/admin",
  AUTH_DISPATCHER: "auth/dispatcher",
  SERVICE_TYPES: "service-types",
  REQUEST_TRIP: "trip/request",
  CANCEL_TRIP: "trip/provider/cancel",
  CANCEL_TRIP_USER: "trip/user/cancel",
  GET_CURRENT_TRIPS: "user-request",
  GET_ACTIVE_TRIPS: "user-request/active",
  GET_SCHEDULED_TRIPS: "user-request/scheduled",
  FORGOT_PASSWORD_USER: "recover-password/user",
  RECOVER_PASSWORD_USER: "reset-password/user",
  GET_ACTIVE_ADMINS: "admins",
  PHP_FORGOT_PASSWORD_USER: "user/forgot/password/email",
  PHP_RESET_PASSWORD_EMAIL: "user/reset/password/email",
  AUTOCOMPLETE_USER: "users/autocomplete",
  GET_USERS: "users/",
  GET_ADMIN_USERS: "admin/users/",
  USER_PERMISSIONS: "user-permission/",
  ADMIN_PASSWORD_RESET: "admins/password/reset",
};

export default PATH;
