import {
  CheckPoint,
  Passenger,
  Coordinate,
} from "../../../shared/dtos/groupTripDTO";
import dayjs from "dayjs";

export const buildPoint = (
  addressDescription: string,
  coordinate: Coordinate,
  passengers: Passenger[],
  checkIn: string,
  ckeckOut: string
): CheckPoint => {
  return {
    address: {
      address: addressDescription,
      coordinate,
      name: "",
    },
    passengers,
    checkIn,
    ckeckOut,
  };
};

export enum TimeActions {
  Add = "add",
  Substarct = "substract",
}

export const addSubstractTime = (
  action: TimeActions,
  dateHour: string,
  time: number
): string => {
  if (action === "add") {
    return dayjs(dateHour, "YYYY-MM-DD HH:mm").add(time, "s").format("HH:mm");
  }
  return dayjs(dateHour, "YYYY-MM-DD HH:mm")
    .subtract(time, "s")
    .format("HH:mm");
};
