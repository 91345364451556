import { useCallback } from "react";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import {
  Collapse,
  Space,
  Button,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  Popover,
} from "antd";
import { GroupTripDTO } from "../../../shared/dtos/groupTripDTO";
import GroupTripsTimeline from "./groupTripsTimeline";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  calculateRoute,
  setDirectionResponse,
} from "../../../features/groupTrip/groupTripsMap/groupTripsMapSlide";
import {
  generateTripMowi,
  setActivePanelKey,
  deleteTrip,
} from "../../../features/groupTrip/groupTrip/groupTripListSlide";
import DriverAssignModal from "./driverAssignModal";
import NotificationGroup from "./notificationGroup";
import { assignDriver } from "../../../features/groupTrip/groupTrip/driverAssignModalSlice";
import { getDataFromGroupTrip, getPassengersData } from "../common/utils";

const { Panel } = Collapse;
type ActivePanel = string | string[];

interface TripDTO {
  groups: Array<GroupTripDTO>;
  onChange?: (activePanel: ActivePanel) => void;
  showActions?: boolean;
}

const CollapseGroupTrip = ({
  groups,
  onChange,
  showActions = true,
}: TripDTO): JSX.Element => {
  console.log("groups", groups);
  const activePanelKey = useAppSelector(
    (state) => state.groupTrip.activePanelKey
  );
  const selectedRequest = useAppSelector(
    (state) => state.groupTripsRequest.selected
  );
  const dispatch = useAppDispatch();

  const getTripData = useCallback(
    (activePanel: ActivePanel) => {
      const tmpArray = activePanel.toString().split("-");
      const activeGroupTrips = tmpArray[1];
      const groupTrip: any = { ...groups };
      const selectedGroupTrip = groupTrip[Number(activeGroupTrips)];
      return getDataFromGroupTrip(selectedGroupTrip);
    },
    [groups]
  );

  const handleOnChange = (activePanel: ActivePanel) => {
    if (activePanel) {
      dispatch(setActivePanelKey(activePanel));
      const {
        originLatitude,
        originLongitude,
        destinationLatitude,
        destinationLongitude,
        wayptsList,
      } = getTripData(activePanel);

      const origin = {
        lat: Number(originLatitude),
        lng: Number(originLongitude),
      };
      const destination = {
        lat: Number(destinationLatitude),
        lng: Number(destinationLongitude),
      };

      const tmpWaypts = wayptsList.map((i: any) => ({
        location: {
          lat: Number(i?.address.coordinate.lat),
          lng: Number(i?.address.coordinate.lng),
        },
        stopover: true,
      }));

      const waypts: google.maps.DirectionsWaypoint[] = tmpWaypts || [];
      dispatch(calculateRoute(origin, destination, waypts));
    } else {
      dispatch(setActivePanelKey(undefined));
      dispatch(setDirectionResponse(null));
    }
  };

  const generateTrip = (activePanel: string) => {
    const {
      id,
      checkPoints,
      originLatitude,
      originLongitude,
      destinationLatitude,
      destinationLongitude,
      originAddress,
      destinationAddress,
      distance,
      scheduleAt,
      wayptsList,
    } = getTripData(activePanel);

    const destinations = wayptsList.map((waypt: any) => ({
      // requestId: request,
      latitude: Number(waypt.address.coordinate?.lat),
      longitude: Number(waypt.address.coordinate?.lng),
      address: waypt.address?.address,
    }));

    const passengersData = getPassengersData(checkPoints);

    const trip = {
      originLatitude,
      originLongitude,
      destinationLatitude,
      destinationLongitude,
      originAddress,
      destinationAddress,
      distance,
      serviceTypeId: selectedRequest?.service, // default Vans,
      paymentMode: "MOWI", // default MOWI,
      scheduleAt,
      description: "",
      firstName: selectedRequest?.client?.firstName || "-",
      lastName: selectedRequest?.client?.lastName || "-",
      mobile: selectedRequest?.client?.mobile || "-", // corregir este valor
      email: selectedRequest?.client?.email,
      autoAssign: false,
      recurrentDays: [], // days of the week
      destinations,
      passengersData,
    };

    const requestId = selectedRequest?.id || "";
    dispatch(generateTripMowi(trip, id, requestId));
  };

  const handleDeleteTrips = (
    idMowi: string | null,
    tripId: string,
    requestId?: string
  ) => {
    dispatch(deleteTrip(idMowi, tripId, requestId));
  };

  type NumUndNull = number | undefined | null;
  const onAssignDriver = (
    userRequestId: NumUndNull,
    driverId: NumUndNull | string,
    close: () => void,
    setConfirmLoading: (flag: boolean) => void
  ) => {
    const id = driverId ? Number(driverId) : undefined;
    dispatch(assignDriver(userRequestId, id, close, setConfirmLoading));
  };

  return (
    <>
      <Collapse
        accordion
        collapsible={"header"}
        bordered={false}
        activeKey={activePanelKey}
        className="site-collapse-custom-collapse"
        onChange={(activePanel) => {
          if (onChange) {
            dispatch(setActivePanelKey(activePanel));
            onChange(activePanel);
          } else {
            handleOnChange(activePanel);
          }
        }}
        style={{ backgroundColor: "transparent" }}
        destroyInactivePanel={true}
      >
        <>
          {groups.map((trip, index) => {
            const id = `${trip.id}-${index}`;
            const origin = trip.checkPoints[0] || {};
            const latitude = origin?.address?.coordinate?.lat;
            const longitude = origin?.address?.coordinate?.lng;
            const driverName = `${trip?.driver?.firstName || ""} ${
              trip?.driver?.lastName || ""
            }`;
            const distance = trip.distance
              ? Number(trip.distance).toFixed(2)
              : "";

            return (
              <Panel
                key={id}
                header={
                  <Row>
                    <Col xs={0} xxl={24}>
                      <Space size="middle" style={{ paddingLeft: 5 }}>
                        {trip?.id_mowi && <b>{trip?.id_mowi}</b>}
                        <b>{trip.hour}</b>
                        <b>{`Pasajeros: ${trip.passegers}`}</b>
                        <b>{`Driver: ${driverName}`}</b>
                        <b>{`Distancia: ${distance}km`}</b>
                        <b>{`Tiempo: ${trip.time}minutos`}</b>
                      </Space>
                    </Col>
                    <Col xs={24} lg={0}>
                      <Space size="middle" style={{ paddingLeft: 5 }}>
                        {trip?.id_mowi && <b>{trip?.id_mowi}</b>}
                        <b>{trip.hour}</b>
                        <b>{`Pasajeros: ${trip.passegers}`}</b>
                      </Space>
                    </Col>
                    <Col xs={0} lg={24} xxl={0}>
                      <Space size="middle" style={{ paddingLeft: 5 }}>
                        {trip?.id_mowi && <b>{trip?.id_mowi}</b>}
                        <b>{trip.hour}</b>
                        <b>{`Pasajeros: ${trip.passegers}`}</b>
                        <b>{`Driver: ${driverName}`}</b>
                      </Space>
                    </Col>
                  </Row>
                }
                className="site-collapse-custom-panel"
                extra={
                  <Row>
                    <Space style={{ zIndex: 1 }}>
                      <Row>
                        <Col xs={24} xxl={0}>
                          <Popover
                            content={
                              <Row>
                                <Col xs={24} lg={0}>
                                  <Space
                                    size="middle"
                                    style={{ paddingLeft: 5 }}
                                  >
                                    <b>{`Driver: ${driverName}`}</b>
                                    <b>{`Distancia: ${distance}km`}</b>
                                    <b>{`Tiempo: ${trip.time}minutos`}</b>
                                  </Space>
                                </Col>
                                <Col xs={0} lg={24} xxl={0}>
                                  <Space
                                    size="middle"
                                    style={{ paddingLeft: 5 }}
                                  >
                                    <b>{`Distancia: ${distance}km`}</b>
                                    <b>{`Tiempo: ${trip.time}minutos`}</b>
                                  </Space>
                                </Col>
                              </Row>
                            }
                          >
                            <Button type="link" style={{ padding: 0 }}>
                              <InfoCircleOutlined />
                            </Button>
                          </Popover>
                        </Col>
                      </Row>
                      {showActions && (
                        <Row>
                          <Space>
                            {trip.id_mowi ? null : (
                              <Tooltip
                                title="Activar viaje en grupo."
                                placement="topRight"
                              >
                                <Button
                                  type="link"
                                  size="small"
                                  icon={
                                    <PlayCircleOutlined
                                      style={{ color: "green" }}
                                    />
                                  }
                                  onClick={() => generateTrip(id)}
                                />
                              </Tooltip>
                            )}
                            {trip.id_mowi ? (
                              <DriverAssignModal
                                assigned={trip.driver ? true : false}
                                userRequestId={Number(trip?.id_mowi)}
                                driverId={trip?.driver?.id}
                                latitude={Number(latitude)}
                                longitude={Number(longitude)}
                                service={Number(selectedRequest?.service)}
                                onAssignDriver={onAssignDriver}
                                onlyBtnIcon
                              />
                            ) : null}
                            {trip.id_mowi ? (
                              <NotificationGroup
                                checkPoints={trip.checkPoints}
                                trip={trip.id_mowi}
                                date={trip.scheduleAt}
                              />
                            ) : null}
                            <Popconfirm
                              title="Está seguro(a) que desea cancelar este viaje en grupo?"
                              onConfirm={() =>
                                handleDeleteTrips(
                                  trip?.id_mowi,
                                  trip.id,
                                  selectedRequest?.id
                                )
                              }
                              placement="topRight"
                              okText="Si"
                              cancelText="No"
                            >
                              <Tooltip
                                title="Cancelar este viaje en grupo."
                                placement="topRight"
                              >
                                <Button
                                  type="link"
                                  size="small"
                                  icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                  }
                                />
                              </Tooltip>
                            </Popconfirm>
                          </Space>
                        </Row>
                      )}
                    </Space>
                  </Row>
                }
              >
                <GroupTripsTimeline trip={trip} />
              </Panel>
            );
          })}
        </>
      </Collapse>
    </>
  );
};

export default CollapseGroupTrip;
