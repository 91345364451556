const root = window.location.origin;

export const marketIcon = `${root}/images/marker-car.png`;
export const pickUpIcon = `${root}/images/marker-start.png`;
export const dropOffIcon = `${root}/images/marker-end.png`;
export const NoteIcon = `${root}/images/userRequest/note.svg`;
export const ProviderIcon = `${root}/images/userRequest/steeringwheel.svg`;
export const OriginIcon = `${root}/images/userRequest/origin.svg`;
export const DestinationIcon = `${root}/images/userRequest/destination.svg`;
export const MobileIcon = `${root}/images/userRequest/userphone.svg`;
export const UserIcon = `${root}/images/userRequest/user.svg`;
export const PaymentIcon = `${root}/images/userRequest/card.svg`;
export const CarIcon = `${root}/images/userRequest/car.svg`;
export const HashtagIcon = `${root}/images/userRequest/hashtag.svg`;
export const MowiLogo = `${root}/images/mowi.png`;
export const TimeIcon = `${root}/images/userRequest/time.svg`;
export const KmIcon = `${root}/images/userRequest/km.svg`;
export const carPeople = `${root}/images/car-people.png`;
export const personTools = `${root}/images/person-tools.png`;
export const Calendar = `${root}/images/userRequest/calendar.svg`;
export const DoorIcon = `${root}/images/userRequest/door.svg`;
export const PlaneIcon = `${root}/images/userRequest/plane.svg`;
export const HotelIcon = `${root}/images/userRequest/hotel.svg`;
export const ManagerIcon = `${root}/images/userRequest/manager.svg`;
export const CompanyIcon = `${root}/images/userRequest/company.svg`;
export const DestinoIcon = `${root}/images/destino.png`;
export const OrigenIcon = `${root}/images/origen.png`;
