import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import { useMemo, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import Markers from "./markers";

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;

interface InitialState {
  type?: string;
  trip?: any;
  originAddress?: string;
  destinationAddress?: string;
}

const GroupTripsMap = ({
  type = "trip",
  trip,
  originAddress,
  destinationAddress,
}: InitialState): JSX.Element => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  let passegers = useAppSelector((state) => state.passeger.value);
  const dispatch = useAppDispatch();

  const directionsResponse = useAppSelector(
    (state) => state.groupTripsMap.directionsResponse
  );

  const center = useMemo<LatLngLiteral>(
    () => ({ lat: -34.88582, lng: -56.17122 }),
    []
  );

  const options = useMemo<MapOptions>(
    () => ({
      streetViewControl: false,
    }),
    []
  );

  useEffect(() => {
    const markMap = () => {
      const tripCoordinates = [];
      const bounds = new google.maps.LatLngBounds();
      const tripLength = trip?.length;
      for (let i = 0; i < tripLength; i++) {
        var point = new google.maps.LatLng(
          trip[i]?.latitude,
          trip[i]?.longitude
        );
        bounds.extend(point);
        tripCoordinates.push(point);
      }

      var tripPath = new google.maps.Polyline({
        path: tripCoordinates,
        geodesic: true,
        strokeColor: "blue",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      tripPath.setMap(map);
      if (map) map.fitBounds(bounds);
    };

    if (type === "tripDetails") {
      markMap();
    }
  }, [map, trip, type]);

  useEffect(() => {
    if (directionsResponse === null) {
      map?.panTo(center);
      map?.setZoom(12);
    }
  }, [directionsResponse, center, map, dispatch]);

  return (
    <>
      <GoogleMap
        center={center}
        zoom={12}
        mapContainerStyle={{
          margin: "0 10px 0 10px",
          height: "80vh",
          width: "100%",
        }}
        options={options}
        onLoad={(map) => setMap(map)}
      >
        {directionsResponse && type !== "tripDetails" && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
        {type === "passeger" &&
          passegers.map((p) => {
            return (
              <>
                <Markers
                  id={`${p.id}-origin`}
                  key={`${p.id}-origin`}
                  lat={p.origin.coordinate.lat}
                  lng={p.origin.coordinate.lng}
                  name={p.name}
                  origin={true}
                />
                <Markers
                  id={`${p.id}-destiny`}
                  key={`${p.id}-destiny`}
                  lat={p.destiny.coordinate.lat}
                  lng={p.destiny.coordinate.lng}
                  name={p.destiny.name}
                  origin={false}
                />
              </>
            );
          })}
        {type === "tripDetails" &&
          trip &&
          originAddress &&
          destinationAddress && (
            <>
              <Markers
                id="origin"
                key="origin"
                lat={trip[0]?.latitude}
                lng={trip[0]?.longitude}
                name={originAddress}
                origin={true}
              />
              <Markers
                id="destiny"
                key="destiny"
                lat={trip[trip?.length - 1]?.latitude}
                lng={trip[trip?.length - 1]?.longitude}
                name={destinationAddress}
                origin={true}
              />
            </>
          )}
      </GoogleMap>
    </>
  );
};

export default GroupTripsMap;
