import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import {
  getGroupTrips,
  generateTrip,
  editGroupTrips,
  deleteGroupTrip,
  deleteMowiTrip,
} from "../../../services/groupTripsApi";
import { GroupTripDTO } from "../../../shared/dtos/groupTripDTO";
import { message } from "antd";

export interface GroupBy {
  date: string;
  groups: Array<GroupTripDTO>;
}

interface GroupTrip {
  scheduleTrip: Array<GroupBy>;
  loading: boolean;
  activePanelKey: number | string | undefined;
  originalData: Array<any>;
}

const initialState: GroupTrip = {
  scheduleTrip: [],
  loading: false,
  activePanelKey: undefined,
  originalData: [],
};

const groupTripSlide = createSlice({
  name: "groupTrip",
  initialState,
  reducers: {
    set: (state, actions) => {
      state.scheduleTrip = actions.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActivePanelKey: (state, actions) => {
      state.activePanelKey = actions.payload;
    },
    setOriginalData: (state, actions) => {
      state.originalData = actions.payload;
    },
  },
});

export const { set, setLoading, setActivePanelKey, setOriginalData } =
  groupTripSlide.actions;

export const groupTripsList = (requestId?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await getGroupTrips({ requestId });
      dispatch(setOriginalData(response));
      const list: GroupBy = response
        .map((r: any, index: string) => {
          const tmp = { ...r };
          const checkPoints = tmp.check_points || [];
          const scheduleAt = tmp.schedule_at;
          delete tmp.check_points;
          delete tmp.schedule_at;
          return {
            ...tmp,
            scheduleAt,
            checkPoints,
          };
        })
        .reduce((acc: Array<GroupBy>, el: GroupTripDTO) => {
          //Group de array by scheduleAt
          const exist = acc.some((i) => i.date === el.scheduleAt);
          if (exist) {
            const tmp = acc.map((i) => {
              if (i.date === el.scheduleAt) {
                const group = i.groups;
                return {
                  date: i.date,
                  groups: [...group, el],
                };
              }
              return i;
            });
            return tmp;
          }
          const tmpEl: GroupBy = {
            date: el.scheduleAt,
            groups: [el],
          };
          acc.push(tmpEl);

          return acc;
        }, []);
      dispatch(set(list));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const generateTripMowi = (
  data: any,
  idTrip: string,
  requestId: string
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { originalData } = getState().groupTrip;
      const generated = await generateTrip(data);
      if (generated.requestId) {
        const tmpTrip = originalData.find((t) => t.id === idTrip);
        const trip = { ...tmpTrip };
        trip.id_mowi = generated.requestId;
        await editGroupTrips(trip);
        dispatch(groupTripsList(requestId));
        message.success("El viaje se ha generado satisfactoriamente.");
      }
    } catch (error) {
      console.log(error);
      message.error("Ha ocurrido un error al intentar crear el viaje.");
    }
  };
};

export const deleteTrip = (
  idMowi: string | null,
  tripId: string,
  requestId?: string
): AppThunk => {
  return async (dispatch) => {
    try {
      if (idMowi && tripId && requestId) {
        const response = await deleteMowiTrip(idMowi);
        if (response?.request?.id) {
          await deleteGroupTrip(tripId);
          dispatch(groupTripsList(requestId));
        }
      } else if (tripId && requestId) {
        await deleteGroupTrip(tripId);
        dispatch(groupTripsList(requestId));
        message.success("El viaje se ha eliminado satisfactoriamente.");
      }
    } catch (error) {
      console.log(error);
      message.error("Ha ocurrido un error al intentar eliminar el viaje.");
    }
  };
};

export default groupTripSlide.reducer;
