import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  notification,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import { updateAdminListValue } from "../../../features/admin/adminListSlice";
import { postAdminAsync } from "../../../features/admin/postAdminSlice";
import { putAdminAsync } from "../../../features/admin/putAdminSlice";
import { updatePartnerPriceTableAsync } from "../../../features/admin/updatePartnerPriceTableSlice";
import { AdminTypesConstants } from "../../../shared/constants/adminTypesConstants";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload";
import {
  AdminCreate,
  AdminTypeIdDescription,
  AdminUpdate,
  EmailDescription,
  MobileDescription,
  NameDescription,
  PasswordDescription,
  PasswordRegexExpression,
  PasswordRequirementText,
} from "../../../shared/constants/Resources";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import NewAdminDTO from "../../../shared/dtos/newAdminDTO";

import "./styles.scss";

export const AdminForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  const [admin, setAdmin] = useState<NewAdminDTO | null>(null);
  const [form] = Form.useForm();
  const [file, setFile] = useState<UploadFile>();
  const [loading, setLoading] = useState(false);

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
        notification.error({
          message: "Solo puedes subir archivos con formato JPG y PNG",
          placement: "bottom",
        });

        return;
      }

      setFile(file);

      return false;
    },
    maxCount: 1,
  };

  useEffect(() => {
    const checkIfAdminId = async () => {
      if (state && state.adminDTO && state.adminDTO?.id > 0 && admin === null) {
        setAdmin(state.adminDTO);
      }
      setIsLoading(false);
    };
    checkIfAdminId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: any) => {
    const newAdminDTO: NewAdminDTO = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      active: true,
      adminTypeId: values.adminTypeId,
      picture: values.picture,
      password: values.password,
    };

    setAdmin(null);

    if (admin && admin.id !== undefined) {
      await dispatch(putAdminAsync({ id: admin.id, newAdminDTO }));
      dispatch(updateAdminListValue());
      navigate(WebRoutes.AdminTableRoute, {
        replace: true,
      });
    } else {
      await dispatch(postAdminAsync(newAdminDTO));
      dispatch(updateAdminListValue());
      navigate(WebRoutes.AdminTableRoute, {
        replace: true,
      });
    }
  };

  const onUpdatePriceTable = async () => {
    const formData = new FormData();

    if (admin?.id) {
      if (file) {
        formData.append("file", file as RcFile);
      } else {
        notification.error({
          message: "Debes seleccionar una imagen",
          placement: "bottom",
        });
        return;
      }

      setLoading(true);

      await dispatch(updatePartnerPriceTableAsync({ id: admin.id, formData }));

      setLoading(false);
    }
  };

  return (
    <>
      <Header>Dispatcher</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row justify="center">
          <Col span={15} className="sub-container create-container">
            {isLoading === true ? (
              <LoadingOutlined className="loading" />
            ) : (
              <>
                <h1>Informacion del administrador</h1>
                <Form
                  layout="vertical"
                  form={form}
                  name="admin"
                  onFinish={onFinish}
                  scrollToFirstError
                  fields={
                    admin
                      ? [
                          {
                            name: [NameDescription],
                            value: admin?.name,
                          },
                          {
                            name: [EmailDescription],
                            value: admin?.email,
                          },
                          {
                            name: [MobileDescription],
                            value: admin?.mobile,
                          },
                          {
                            name: [AdminTypeIdDescription],
                            value: admin?.adminTypeId,
                          },
                        ]
                      : []
                  }
                >
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={NameDescription}
                        label="Nombre"
                        tooltip="Cual es su nombre?"
                        rules={[
                          {
                            required: true,
                            message: "Por favor inserte su nombre!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder="Pepe" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={EmailDescription}
                        label="Correo electronico"
                        tooltip="Cual es su correo electronico?"
                        rules={[
                          {
                            type: EmailDescription,
                            message: "Inserte un correo válido!",
                          },
                          {
                            required: true,
                            message: "Por favor inserte su correo electronico!",
                          },
                        ]}
                      >
                        <Input placeholder="prueba@mowi.com" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={MobileDescription}
                        label="Telefono"
                        tooltip="Cual es su telefono?"
                        rules={[
                          {
                            required: true,
                            message: "Por favor inserte su número de telefono!",
                          },
                        ]}
                      >
                        <Input placeholder="52013994" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={AdminTypeIdDescription}
                        label="Tipo de administrador"
                        tooltip="Que tipo de administrador quieres elegir?"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor seleccione un tipo de administrador!",
                          },
                        ]}
                      >
                        <Select placeholder="Selecione un tipo">
                          {AdminTypesConstants.map(
                            (adminType: any, index: number) => (
                              <Select key={index} value={adminType.value}>
                                {adminType.label}
                              </Select>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {admin ? null : (
                    <Row justify="center">
                      <Col span={15}>
                        <Form.Item
                          name={PasswordDescription}
                          label="Contraseña"
                          tooltip="Cual es su contraseña?"
                          rules={[
                            {
                              required: true,
                              message: "Por favor inserte contraseña",
                            },
                            {
                              pattern: PasswordRegexExpression,
                              message: PasswordRequirementText,
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row justify="center">
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(WebRoutes.AdminTableRoute, {
                            replace: true,
                          })
                        }
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col offset={2}>
                      <Button type="primary" htmlType="submit">
                        {admin ? AdminUpdate : AdminCreate}
                      </Button>
                    </Col>
                  </Row>

                  {admin?.partner ? (
                    <div style={{ marginTop: "100px" }}>
                      <Row justify="center">
                        <h1>Informacion del partner</h1>
                      </Row>
                      {admin?.priceTable && (
                        <Row justify="center">
                          <img
                            src={admin?.priceTable}
                            style={{ maxWidth: "300px", maxHeight: "200px" }}
                            alt="price table"
                          />
                        </Row>
                      )}

                      <Row justify="center">
                        <Col span={15}>
                          <Form.Item
                            label="Lista de precios"
                            valuePropName="file"
                            rules={[
                              {
                                required: true,
                                message: "Por favor selecciona un archivo",
                              },
                            ]}
                          >
                            <Upload {...uploadProps}>
                              <Button icon={<UploadOutlined />}>
                                Seleccionar imagen
                              </Button>
                            </Upload>

                            <br />

                            <Alert
                              message="Porfavor subir una imagen de la lista de precios para este partner"
                              type="info"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Button
                          type="primary"
                          loading={loading}
                          onClick={() => onUpdatePriceTable()}
                        >
                          Actualizar
                        </Button>
                      </Row>
                    </div>
                  ) : null}
                </Form>
              </>
            )}
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default AdminForm;
