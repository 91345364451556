import { useEffect } from "react";
import { Col, Row, Space, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ListTravelDates from "./listTravelDates";
import {
  GroupBy,
  groupTripsList,
  setActivePanelKey,
} from "../../../features/groupTrip/groupTrip/groupTripListSlide";
import { setDirectionResponse } from "../../../features/groupTrip/groupTripsMap/groupTripsMapSlide";
import { setSelectedRequest } from "../../../features/groupTrip/groupTripRequest/groupTripRequestSlide";
import SelectRequest from "../common/selectRequest";
const { Title } = Typography;

const AdminTrip = (): JSX.Element => {
  const groupTrip: GroupBy[] = useAppSelector(
    (state) => state.groupTrip.scheduleTrip
  );
  const selected = useAppSelector((state) => state.groupTripsRequest.selected);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selected?.id) {
      dispatch(groupTripsList(selected?.id));
    }
  }, [dispatch, selected?.id]);

  useEffect(() => {
    return () => {
      //reset map state
      dispatch(setDirectionResponse(null));
      // reset collapsible active panel state
      dispatch(setActivePanelKey(undefined));
      dispatch(setSelectedRequest({}));
    };
  }, [dispatch]);

  const totalGroups = groupTrip.reduce((total: number, index: any): number => {
    const tmp = (total + index.groups?.length) | 0;
    return tmp;
  }, 0);

  const totalPassengers = groupTrip
    .map((group) => {
      return [...group.groups];
    })
    .flat()
    .reduce((total: number, index: any): number => {
      return Number(total) + Number(index?.passegers | 0);
    }, 0);

  return (
    <>
      <Row
        gutter={[24, 24]}
        style={{
          maxHeight: "calc(100vh - 100px)",
          paddingLeft: 8,
          overflowY: "scroll",
        }}
      >
        <Col
          span={24}
          style={
            selected
              ? {}
              : {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 12,
                  height: 300,
                }
          }
        >
          <Space size="large">
            <SelectRequest />
            {selected ? (
              <Space size="large">
                <Title level={5}>{`${totalGroups} Grupos`}</Title>
                <Title level={5}>{`${totalPassengers} Pasajeros`} </Title>
              </Space>
            ) : null}
          </Space>
          {selected ? null : (
            <p style={{ fontSize: 18, fontWeight: 500, color: "gray" }}>
              Seleccione un viaje en grupo.
            </p>
          )}
        </Col>
        {selected ? (
          <Col span={24}>
            <Space>
              <Title level={4}>Grupos de viaje:</Title>
            </Space>
            <Row>
              <Col span={24}>
                <ListTravelDates groupTrip={groupTrip} />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default AdminTrip;
