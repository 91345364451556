import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const createServiceTypeFileApi = async (formData: any) => {
    const result = await axios.post(`${apiUrl}files/upload/servicetype`, formData, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    });
  
    return result.data;
  };