import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import FormPasseger from "./formPassenger";
import { ActionParams } from "./actionParams";

interface Props {
  addPassengerAction: (params: ActionParams) => void;
  disabled?: boolean;
}

const ModalPassenger = ({
  addPassengerAction,
  disabled,
}: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal} disabled={disabled}>
        + Añadir nuevo pasajero
      </Button>
      <Modal
        title="Adicionar pasajero"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="800px"
        footer={[
          <Button type="primary" key="submitModalPasseger" onClick={handleOk}>
            Aceptar
          </Button>,
          <Button
            type="primary"
            key="cancelModalPasseger"
            onClick={handleCancel}
          >
            Cancelar
          </Button>,
        ]}
      >
        <FormPasseger
          form={form}
          setIsModalOpen={setIsModalOpen}
          editValues={null}
          addPassengerAction={addPassengerAction}
        />
      </Modal>
    </>
  );
};

export default ModalPassenger;
