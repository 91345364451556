import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
  notification,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { columns, DataType } from "./columns";
import {
  deleteServiceType,
  getServiceTypePaginatedList,
  patchServiceTypeActiveForAllAdmins,
} from "../../../services/serviceTypeApi";
import ActivateServiceType from "../activateServiceType";

import "../../../shared/styles/list.scss";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

const { Text } = Typography;
const { Search } = Input;

const ServiceTypeList = () => {
  const title = "Tipos de Servicio";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [ignoreFetch, setIgnoreFetch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignAllLoading, setIsAssignAllLoading] = useState(false);
  const [serviceTypeId, setServiceTypeId] = useState(0);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    const { data, total } = await getServiceTypePaginatedList({
      page: tableParams.pagination?.current || 1,
      take: tableParams.pagination?.pageSize || 10,
      search: search,
    });

    setData(data);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
      },
    });
    setIgnoreFetch(true);
  };

  useEffect(() => {
    if (!ignoreFetch) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams), search]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    setIgnoreFetch(false);
  };

  const handleDelete = async (serviceTypeId: any) => {
    await deleteServiceType(+serviceTypeId).then(() => {
      notification.success({
        message: `Tipo de Servicio #${serviceTypeId}`,
        description: "Se ha borrado exitosamente",
        placement: "bottom",
      });
      fetchData();
    });
  };

  const onSearch = async (value: string) => {
    setIgnoreFetch(false);
    setSearch(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const openModal = (serviceTypeId: number) => {
    setServiceTypeId(serviceTypeId);
    setIsModalOpen(true);
  };

  const activateServiceTypeForAll = async (serviceTypeId: number, isActiveForAllAdmins: boolean) => {
    setIsAssignAllLoading(true);
    await patchServiceTypeActiveForAllAdmins(serviceTypeId, !isActiveForAllAdmins).then(() => {
      notification.success({
        message: `El tipo de servicio #${serviceTypeId}`,
        description: "se asigno a todos los administradores exitosamente",
        placement: "bottom",
      });
      setIsAssignAllLoading(false);
    });
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row">
          <Col className="input-container-table">
            <Button
              onClick={() =>
                navigate(WebRoutes.CreateServiceTypeRoute, {
                  replace: true,
                })
              }
            >
              Nuevo Tipo de Servicio
            </Button>
          </Col>

          <Space>
            <Text strong style={{ marginLeft: 20 }}>
              Buscar
            </Text>
            <Search
              placeholder="Nombre del Tipo de Servicio"
              allowClear
              onSearch={onSearch}
            />
          </Space>
        </Row>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={columns(
              navigate,
              handleDelete,
              openModal,
              activateServiceTypeForAll,
              isAssignAllLoading
            )}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Row>
      </Content>
      <ActivateServiceType
        serviceTypeId={serviceTypeId}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
    </Layout>
  );
};

export default ServiceTypeList;
