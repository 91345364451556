import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  groupTripsRequestList,
  setSelectedRequest,
} from "../../../features/groupTrip/groupTripRequest/groupTripRequestSlide";
import { Select } from "antd";
import { useLocation } from "react-router-dom";

interface opt {
  value: string;
  label: string;
}
export default function SelectRequest() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const request = useAppSelector((state) => state.groupTripsRequest.value);
  const [options, setOptions] = useState<Array<opt>>([]);

  useEffect(() => {
    dispatch(groupTripsRequestList());
  }, [dispatch]);

  useEffect(() => {
    console.log("Request location ... ", location);

    dispatch(setSelectedRequest(location?.state?.request));
  }, [location, request, dispatch]);

  useEffect(() => {
    setOptions(
      request.map((d) => {
        return { value: d?.id ? d?.id : "0", label: d?.name };
      })
    );
  }, [request]);

  const SelectClient = (clientId: string) => {
    console.log(clientId);
    dispatch(setSelectedRequest(clientId));
  };
  return (
    options && (
      <Select
        showSearch
        style={{ width: 200, marginLeft: "20px" }}
        placeholder="Busque para seleccionar ..."
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        options={options}
        defaultValue={location?.state?.request}
        onChange={SelectClient}
      />
    )
  );
}
