import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import { updateAdminListValue } from "../../../features/admin/adminListSlice";
import { updateUserListValue } from "../../../features/user/userListSlice";
import { postUserAsync, updateUser } from "../../../services/userAPI";
import {
  AdminCreate,
  AdminUpdate,
  AndroidDescription,
  CASHDescription,
  DepartmentIdDescription,
  EmailDescription,
  FirstNameDescription,
  LastNameDescription,
  ManualDescription,
  MobileDescription,
  PasswordDescription,
  PasswordRegexExpression,
  PasswordRequirementText,
} from "../../../shared/constants/Resources";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import UserProfileDTO from "../../../shared/dtos/userProfileDTO";
import "../../admin/list/styles.scss";
import { departmentsByAdminAsync } from "../../../features/departments/deparmentsByAdminSlice";
import DepartmentDTO from "../../../shared/dtos/departmentDTO";

export const UserForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserProfileDTO | null>(null);
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);

  useEffect(() => {
    const checkIfAdminId = async () => {
      if (state && state.userDTO?.id > 0 && user === null) {
        setUser(state.userDTO);
      }
      setIsLoading(false);
    };
    dispatch(departmentsByAdminAsync()).then((res: any) => {
      setDepartments(
        res?.payload?.map((department: any) => ({
          value: department.id,
          label: department.name,
        }))
      );
    });
    checkIfAdminId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onFinish = async (values: any) => {
    const userProfileDTO: UserProfileDTO = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      departmentId: values.departmentId.value
        ? values.departmentId.value
        : values.departmentId,
    };

    setUser(null);

    if (user && user.id !== undefined) {
      await updateUser(user.id, userProfileDTO);
      dispatch(updateUserListValue());
      navigate(WebRoutes.UserTableRoute, {
        replace: true,
      });
    } else {
      userProfileDTO.password = values.password;
      userProfileDTO.loginBy = ManualDescription;
      userProfileDTO.paymentMode = CASHDescription;
      userProfileDTO.deviceType = AndroidDescription;
      await postUserAsync(userProfileDTO);
      dispatch(updateAdminListValue());
      navigate(WebRoutes.UserTableRoute, {
        replace: true,
      });
    }

    form.resetFields();
  };

  return (
    <>
      <Header>{`${user ? AdminUpdate : AdminCreate} usuario`}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row justify="center">
          <Col span={15} className="sub-container create-container">
            {isLoading === true ? (
              <LoadingOutlined className="loading" />
            ) : (
              <>
                <h1>Informacion del usuario</h1>
                <Form
                  layout="vertical"
                  form={form}
                  name="user"
                  onFinish={onFinish}
                  scrollToFirstError
                  fields={[
                    {
                      name: [FirstNameDescription],
                      value: user ? user?.firstName : "",
                    },
                    {
                      name: [LastNameDescription],
                      value: user ? user?.lastName : "",
                    },
                    {
                      name: [EmailDescription],
                      value: user ? user?.email : "",
                    },
                    {
                      name: [MobileDescription],
                      value: user ? user?.mobile : "",
                    },
                  ]}
                >
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={FirstNameDescription}
                        label="Primer Nombre"
                        tooltip="Cual es su primer nombre?"
                        rules={[
                          {
                            required: true,
                            message: "Por favor inserte su nombre!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder="Pepe" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={LastNameDescription}
                        label="Primer Apellido"
                        tooltip="Cual es su primer apellido?"
                        rules={[
                          {
                            required: true,
                            message: "Por favor inserte su nombre!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder="Ruiz" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={EmailDescription}
                        label="Correo electronico"
                        tooltip="Cual es su correo electronico?"
                        rules={[
                          {
                            type: EmailDescription,
                            message: "Inserte un correo válido!",
                          },
                        ]}
                      >
                        <Input placeholder="prueba@mowi.com" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={MobileDescription}
                        label="Telefono"
                        tooltip="Cual es su telefono?"
                      >
                        <Input placeholder="52013994" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={DepartmentIdDescription}
                        label="Departamento"
                        tooltip="A que departamento pertenece?"
                        rules={[
                          {
                            required: true,
                            message: "Por favor selecciona un Departamento!",
                          },
                        ]}
                        initialValue={
                          user?.department
                            ? {
                                value: user?.department?.id,
                                label: user?.department?.name,
                              }
                            : null
                        }
                      >
                        <Select
                          placeholder="Seleccione el departamento."
                          options={departments}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {user ? null : (
                    <Row justify="center">
                      <Col span={15}>
                        <Form.Item
                          name={PasswordDescription}
                          label="Contraseña"
                          tooltip="Cual es su contraseña?"
                          rules={[
                            {
                              pattern: PasswordRegexExpression,
                              message: PasswordRequirementText,
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row justify="center">
                    <Col span={2}>
                      <Button type="primary" htmlType="submit">
                        {user ? AdminUpdate : AdminCreate}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(WebRoutes.UserTableRoute, {
                            replace: true,
                          })
                        }
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default UserForm;
