import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import { AdminReferralQrDTO } from "../shared/dtos/adminReferralQrDTO";

const apiUrl = process.env.REACT_APP_API_URL;

export const getAdminReferralQrByAdminId = async () => {
  const result = await axios.get(`${apiUrl}adminreferralqr/`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const getAdminReferralQrByCode = async (code: string) => {
  const result = await axios.get(`${apiUrl}adminreferralqr/code/${code}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const createAdminReferralQr = async (adminReferralQr: AdminReferralQrDTO) => {
  const result = await axios.post(`${apiUrl}adminreferralqr`, adminReferralQr, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result;
};