import { Navigate, Outlet } from "react-router-dom";
import { HandleLoginHelper } from "./verifyLoginHelper";
import {
  selectCurrentAdminToken,
  selectCurrentAdminType,
} from "./selectorHelper";
import { WebRoutes } from "../../shared/constants/WebRoutes";
import {
  OperatorTypeId,
  SuperAdminTypeId,
} from "../../shared/constants/Resources";

const LoggedInHelper = (): React.ReactElement => {
  const tokensFromHelper: string = selectCurrentAdminToken();
  const adminTypeId: number = selectCurrentAdminType();
  let isValidLogin = false;

  if (tokensFromHelper) isValidLogin = HandleLoginHelper(tokensFromHelper);

  return !isValidLogin ? (
    <Outlet />
  ) : adminTypeId === SuperAdminTypeId || adminTypeId === OperatorTypeId ? (
    <Navigate replace to={WebRoutes.TripListRoute} />
  ) : (
    <Navigate replace to={WebRoutes.TripTableRoute} />
  );
};

export default LoggedInHelper;
