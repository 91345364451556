import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import AdminWalletDTO from "../shared/dtos/adminWalletDTO";

const apiUrl = process.env.REACT_APP_API_URL;

export const createAdminWallet = async (
  id: number,
  adminWallet: AdminWalletDTO
) => {
  return await axios.post(`${apiUrl}adminwallet/${id}`, adminWallet, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
};

export const getAdminWalletByAdminId = async (id: number) => {
  return await axios.get(`${apiUrl}adminwallet/balance/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
};
