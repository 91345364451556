import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Popconfirm,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import UserDTO from "../../../shared/dtos/userDTO";
import { userListAsync } from "../../../features/user/userListSlice";
import { DeleteUser, UpdateUserPassword } from "../../../services/userAPI";
import "../../admin/list/styles.scss";
import { selectCurrentAdminType } from "../../../utils/helpers/selectorHelper";
import { PasswordRegexExpression, PasswordRequirementText, SuperAdminTypeId } from "../../../shared/constants/Resources";
import UserManagerSwitch from "../utils/userManagerSwitch";
import { TYPES_ID } from "../../../shared/constants/UserTypes";
import { getUsersExcel } from "../../../services/adminUserApi";
import { FilterValue, SorterResult } from "antd/lib/table/interface";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters: Record<string, FilterValue | null>;
}
const { Search } = Input;
const { Column } = Table;

const UserList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [ignoreFetch, setIgnoreFetch] = useState(false);
  const [data, setData] = useState(new Array<UserDTO>());
  const [tableParams, setTableParams] = useState<TableParams>({
    filters: {
      active: [true],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const title: string = "User List";
  const { loading } = useSelector((state: RootState) => state.userList);

  const [form] = Form.useForm();

  const fetchData = () => {
    dispatch(
      userListAsync({
        page: tableParams.pagination?.current || 1,
        take: tableParams.pagination?.pageSize || 10,
        searchText,
      })
    ).then(({ payload }: any) => {
      setData(payload.userDtos);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: payload.total,
        },
      });
    });

    setIgnoreFetch(true);
  };

  useEffect(() => {
    if (!ignoreFetch) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams), searchText, ignoreFetch]);

  const handleUpdatePassword = async (values: any) => {
    const { password } = values;
    if (selectedUserId && password) {
      await UpdateUserPassword(selectedUserId, password);
      setShowPasswordInput(false);
      setSelectedUserId(0);
    }
  };

  const handleExcelExport = async () => {
    setIsLoadingExcel(true);
    const result = await getUsersExcel();
    setIsLoadingExcel(false);
    const link = document.createElement("a");
    link.href = result;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserDTO> | SorterResult<UserDTO>[]
  ) => {
    setIgnoreFetch(false);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const onSearch = (value: string) => {
    setIgnoreFetch(false);
    setSearchText(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "16px",
        }}
      >
        <Row className="upper-row">
          <Col className="input-container-table" span={1}>
            <Button
              onClick={() =>
                navigate(WebRoutes.CreateUserRoute, {
                  replace: true,
                })
              }
            >
              Agregar usuario
            </Button>
          </Col>
          <Col className="input-container-table" span={4}>
            <Search
              placeholder="Buscar por nombre"
              allowClear
              onSearch={onSearch}
            />
          </Col>
          <Col span={16}>
            <Row justify="end">
              <Button
                loading={isLoadingExcel}
                onClick={() => handleExcelExport()}
              >
                Descargar lista
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className="list-container">
          <Col span={24} style={{ width: "100px" }}>
            <Table
              style={{ minWidth: "100%" }}
              rowKey={(record) => Number(record.id)}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
              scroll={{ x: 1000, y: "calc(100vh - 305px)" }}
            >
              <Column title="Id" dataIndex="id" width={100} />
              <Column
                title="Nombre"
                width={150}
                render={(record: UserDTO) => {
                  return `${record?.firstName ?? ""} ${record?.lastName ?? ""}`;
                }}
              />
              <Column
                title="Correo electronico"
                width={200}
                dataIndex="email"
              />
              <Column title="Movil" dataIndex="mobile" width={150} />
              <Column title="Calificacion" dataIndex="rating" width={150} />
              <Column
                title="Departamento"
                width={200}
                render={(record: UserDTO) => {
                  return record?.department?.name;
                }}
              />
              {selectCurrentAdminType() === SuperAdminTypeId && (
                <>
                  <Column
                    title="Id del Administrador"
                    width={150}
                    dataIndex="administrator"
                  />
                  <Column
                    title="Tipo de Pago"
                    width={150}
                    dataIndex="paymentMode"
                  />
                </>
              )}
              <Column
                title="Manager"
                width={100}
                render={(record: UserDTO) => {
                  return (
                    <UserManagerSwitch
                      userId={record.id}
                      switchValue={
                        record.type === TYPES_ID.MANAGER ? true : false
                      }
                      setIgnoreFetch={setIgnoreFetch}
                    />
                  );
                }}
              />
              <Column
                title="Notificar"
                width={100}
                render={(record: UserDTO) => {
                  return (
                    <UserManagerSwitch
                      userId={record.id}
                      switchValue={record.offerExtraTrip}
                      setIgnoreFetch={setIgnoreFetch}
                      isOfferExtraTrip={true}
                    />
                  );
                }}
              />
              <Column
                width={180}
                render={(record: UserDTO) => {
                  return (
                    <Button
                      onClick={() => {
                        navigate(`/user/privilege/${record.id}`, {
                          replace: true,
                        });
                      }}
                    >
                      Modificar Permisos
                    </Button>
                  );
                }}
              />
              <Column
                width={120}
                render={(record: UserDTO) => {
                  return (
                    <Button
                      onClick={() => {
                        navigate(WebRoutes.UpdateUserRoute, {
                          replace: true,
                          state: {
                            userDTO: record,
                          },
                        });
                      }}
                    >
                      Actualizar
                    </Button>
                  );
                }}
              />
              <Column
                width={180}
                render={(record: UserDTO) => {
                  return showPasswordInput && record.id === selectedUserId ? (
                    <Row justify="space-evenly">
                      <Form
                        form={form}
                        name="basic"
                        onFinish={handleUpdatePassword}
                        layout="vertical"
                        fields={[
                          {
                            name: "password",
                            value: "",
                          },
                        ]}
                      >
                        <Col>
                          <Form.Item
                            label="Contraseña"
                            name="password"
                            rules={[
                              {
                                pattern: PasswordRegexExpression,
                                message: PasswordRequirementText,
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Button size="small" htmlType="submit">
                            Actualizar
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            onClick={() => {
                              setShowPasswordInput(false);
                              setSelectedUserId(0);
                            }}
                          >
                            Cancelar
                          </Button>
                        </Col>
                      </Form>
                    </Row>
                  ) : (
                    <Button
                      onClick={() => {
                        setSelectedUserId(record.id as number);
                        setShowPasswordInput(true);
                      }}
                    >
                      Actualizar contraseña
                    </Button>
                  );
                }}
              />
              <Column
                width={100}
                render={(record: UserDTO) => {
                  return (
                    <Popconfirm
                      title="Estas seguro de eliminar este usuario?"
                      onConfirm={async () => {
                        await DeleteUser(record.id as number);
                        setIgnoreFetch(false);
                      }}
                      okText="Si"
                      cancelText="No"
                      placement="bottom"
                    >
                      <Button>Borrar</Button>
                    </Popconfirm>
                  );
                }}
              />
            </Table>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default UserList;
