import { Button, Form, Select, Tag, Upload, message } from "antd";
import { parseDataFromXMLSMultipleLoad } from "../utils";
import { useState, useEffect } from "react";
import { TripDataMultipleLoad } from "../../../../shared/dtos/multipleLoadDTO";
import AdminDTO from "../../../../shared/dtos/adminDTO";
import { getAdminsList } from "../../../../services/adminApi";
import { multipleLoadApi, mergeLoadApi } from "../../../../services/multipleLoadApi";
import { CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useServicesTypes } from "../../../common/hooks/useServicesTypes";
import ServiceTypeDTO from "../../../../shared/dtos/serviceTypeDTO";
import debounce from "lodash.debounce";
import DatePicker from "../../../common/DatePicker";
import { disabledDateBeforeToday } from "../../../../utils/helpers/disabledDateBeforeToday";
import { readUploadXLSXFile } from "../../../groupTrip/common/utils";

interface Props {
  setData: (data: any) => void;
  formattedData: TripDataMultipleLoad[];
  setFormattedData: (data: any) => void;
  setSelectedIds: (value: Array<number>) => void;
  selectedIds: Array<number>;
}

const FormMultipleLoad = ({
  setData,
  formattedData,
  setFormattedData,
  setSelectedIds,
  selectedIds,
}: Props): JSX.Element => {
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [adminsList, setAdminsList] = useState<Array<AdminDTO>>([]);
  const [loading, setLoading] = useState(false);
  const [serviceTypeId, setServiceTypeId] = useState<string | undefined>();
  const [adminId, setAdminId] = useState<string | undefined>();
  const [searchAdmin, setSearchAdmin] = useState("");
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [form] = Form.useForm();

  const services = useServicesTypes();

  useEffect(() => {
    const getAdmins = async () => {
      setLoadingAdmins(true);
      const admins = await getAdminsList({
        take: 100,
        filters: { name: searchAdmin },
      });
      setAdminsList(admins?.data || []);
      setLoadingAdmins(false);
    };
    getAdmins();
  }, [searchAdmin]);

  const readUploadFile = (e: any) => {
    readUploadXLSXFile(
      e,
      (data) => {
        const list = parseDataFromXMLSMultipleLoad(data);
        list.shift();
        setData(list);
      },
      false
    );
  };

  const normFile = (e: any) => {
    if (e?.fileList && e?.fileList.length > 0) {
      const tmp = { target: { files: [e?.file] } };
      setFileList(e?.file ? [e.file] : []);
      readUploadFile(tmp);
    } else {
      setFileList([]);
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onMergeTrips = async () => {
    if (!serviceTypeId || !adminId) {
      message.warning(
        "No se a seleccionado un servicio o un administrador, seleccione ambos antes de mezclar"
      );
      return;
    }

    const selectedData = formattedData.filter((data) =>
      selectedIds.includes(data.id)
    );
    const invalidData = selectedData.some(
      (i) => !i.origin?.address || !i.destiny?.address
    );

    if (invalidData) {
      message.warning("Existen direcciones sin validar");
    } else {
      const jsonData = {
        serviceTypeId: Number(serviceTypeId),
        adminId: Number(adminId),
        data: selectedData,
      };
      setLoading(true);
      try {
        const result = await mergeLoadApi(jsonData);
        if (result?.errorsCount === 0) {
          message.success("Los viajes han sido mezclados satisfactoriamente.");
          setFormattedData(
            formattedData.filter((x) => !selectedIds.includes(x.id))
          );
          setSelectedIds([]);
        } else if (result?.errorsCount !== 0 && result?.successCount !== 0) {
          message.warning(
            <>
              <Tag>8 viajes se han generado satisfactoriamente.</Tag>
              <br />
              <br />
              <Tag color="red">5 no se han podido generar.</Tag>
            </>
          );
        } else {
          message.error("Ha ocurrido un error al intentar generar los viajes.");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Ha ocurrido un error al intentar generar los viajes.");
      }
    }
  };

  const onFinish = async (values: any) => {
    const invalidData = formattedData.some(
      (i) => !i.origin?.address || !i.destiny?.address
    );

    if (invalidData) {
      message.warning("Existen direcciones sin validar");
    } else {
      const jsonData = {
        serviceTypeId: values?.serviceTypeId,
        adminId: values?.adminId,
        data: formattedData,
        repeat: values?.weeksRepetition,
        startDate: values?.startDate?.toDate(),
      };
      setLoading(true);
      try {
        const result = await multipleLoadApi(jsonData);
        if (result?.errorsCount === 0) {
          message.success("Los viajes han sido generados satisfactoriamente.");
          setData([]);
          setFormattedData([]);
          form.resetFields();
          setFileList([]);
        } else if (result?.errorsCount !== 0 && result?.successCount !== 0) {
          message.warning(
            <>
              <Tag>
                {result?.successCount} viajes se han generado
                satisfactoriamente.
              </Tag>
              <br />
              <br />
              <Tag color="red">
                {result.errorsCount} no se han podido generar.
              </Tag>
            </>
          );
        } else {
          message.error("Ha ocurrido un error al intentar generar los viajes.");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Ha ocurrido un error al intentar generar los viajes.");
      }
    }
  };

  const handleSearch = debounce((newSearch: string) => {
    setSearchAdmin(newSearch);
  }, 250);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="inline"
        disabled={loading}
      >
        <Form.Item
          name="upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="custom-upload-xlsx-file"
        >
          <Upload listType="picture" beforeUpload={() => false} maxCount={1}>
            {fileList.length === 0 && (
              <Button icon={<UploadOutlined />}>Cargar archivo excel</Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="serviceTypeId"
          label="Tipo Servicio"
          tooltip="En que vehiculo desea viajar?"
          rules={[
            {
              required: true,
              message: "Por favor seleccione un servicio!",
            },
          ]}
        >
          <Select
            placeholder="Selecione un tipo"
            style={{ width: 160 }}
            options={services.map((serviceType: ServiceTypeDTO) => ({
              label: serviceType?.name,
              value: serviceType?.id,
            }))}
            onChange={(value) => setServiceTypeId(value)}
          />
        </Form.Item>
        <Form.Item
          name="adminId"
          label="Administradores"
          rules={[{ required: true, message: "Elija el administrador!" }]}
        >
          <Select
            placeholder="Selecione un administrador"
            style={{ width: 220 }}
            options={adminsList.map((admin: AdminDTO) => ({
              label: admin?.name,
              value: admin?.id,
            }))}
            onSearch={handleSearch}
            onChange={(value) => setAdminId(value)}
            showSearch
            notFoundContent={null}
            filterOption={false}
            loading={loadingAdmins}
          />
        </Form.Item>
        <Form.Item
          name="startDate"
          label="Fecha Inicio"
          rules={[{ required: true, message: "Elija fecha de inicio!" }]}
        >
          <DatePicker
            placeholder="Seleccione"
            disabledDate={disabledDateBeforeToday}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="weeksRepetition"
          label="Repetir"
          rules={[
            { required: true, message: "Elija cuantas semana se repite." },
          ]}
          initialValue={1}
        >
          <Select
            placeholder="Selecione..."
            style={{ width: 150 }}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
              (repeat: number) => ({
                label: `${repeat} semana${repeat !== 1 ? "s" : ""}`,
                value: repeat,
              })
            )}
            showSearch
            notFoundContent={null}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
      {selectedIds.length > 0 && (
        <Button
          type="default"
          onClick={() => {
            onMergeTrips();
          }}
          disabled={formattedData?.length === 0}
          loading={loading}
          icon={<CheckCircleOutlined />}
        >
          Mezclar viajes
        </Button>
      )}
      <Button
        type="primary"
        onClick={() => {
          form.submit();
        }}
        disabled={formattedData?.length === 0}
        loading={loading}
        icon={<CheckCircleOutlined />}
      >
        Generar viajes
      </Button>
    </>
  );
};

export default FormMultipleLoad;
