import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "../../services/authApi";
import LoginDTO from "../../shared/dtos/loginDTO";
import ModelState from "../../shared/states/modelState";
import { HandleLoginHelper } from "../../utils/helpers/verifyLoginHelper";
import TokensDTO from "../../shared/dtos/tokensDTO";
import { currentAdminAsync } from "../admin/currentAdminSlice";
import { store } from "../../app/store";

const initialState: ModelState<TokensDTO> = {
  value: {} as TokensDTO,
  status: "idle",
};

export const authAsync = createAsyncThunk(
  "auth/admin/",
  async (credentials: LoginDTO) => {
    const response = await adminLogin(credentials);
    const { token } = response;
    const validLogin = HandleLoginHelper(token);

    if (validLogin) {
      await store.dispatch(currentAdminAsync(token));
      return response;
    }

    return null;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    removeAuth: (state) => {
      state.value = {} as TokensDTO;
    },
    addNewAuth: (state, action) => {
      state.status = "succeeded";
      state.value =  action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(authAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(authAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { removeAuth, addNewAuth } = authSlice.actions;

export default authSlice.reducer;
