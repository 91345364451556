import { Col, Collapse, Row, Space, Timeline, Tooltip, Typography } from "antd";
import { GroupTripDTO } from "../../../shared/dtos/groupTripDTO";
import GroupTripPassengerList from "./groupTripPassengerList";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { Title, Text } = Typography;

interface Trip {
  trip: GroupTripDTO;
}

const GroupTripsTimeline = ({ trip }: Trip): JSX.Element => {
  return (
    <Row style={{ paddingTop: 10 }}>
      <Col span={24} className="timeline_container">
        <Timeline mode="left" className="custom-group-trips-timeline-collapse">
          {trip.checkPoints.map((c, index) => {
            return (
              <Timeline.Item
                label={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Space size={4}>
                      <ArrowRightOutlined
                        style={{ fontSize: 10, color: "green" }}
                      />
                      <Text
                        style={{ color: "green", fontSize: 14 }}
                      >{`${c.checkIn}`}</Text>
                    </Space>
                    {trip.checkPoints.length - 1 !== index && (
                      <Space size={4}>
                        <Text
                          style={{ color: "red", fontSize: 14 }}
                        >{`${c.ckeckOut}`}</Text>
                        <ArrowRightOutlined
                          style={{ fontSize: 10, color: "red" }}
                        />
                      </Space>
                    )}
                  </div>
                }
              >
                <Collapse accordion bordered={false} ghost>
                  <Panel
                    key={index}
                    style={{}}
                    header={
                      <Space size="large" style={{ paddingLeft: 8 }}>
                        <Tooltip
                          placement="rightTop"
                          title={c.address?.address}
                          color="gray"
                        >
                          <Title level={5}>{c.address?.address}</Title>
                        </Tooltip>
                      </Space>
                    }
                    className="group-trips-timeline-collapse-custom-panel"
                  >
                    {trip.checkPoints?.length > 0 ? (
                      <GroupTripPassengerList passengers={c.passengers || []} />
                    ) : (
                      "null"
                    )}
                  </Panel>
                </Collapse>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Col>
    </Row>
  );
};

export default GroupTripsTimeline;
