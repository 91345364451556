import Popconfirm from "antd/lib/popconfirm";
import { QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import Dropdown from "antd/lib/dropdown/dropdown";
import { Button } from "antd";

interface UserRequestProps {
  skipRequest: Function;
  items: Function;
}

export const UserRequestColumns = ({
  skipRequest,
  items,
}: UserRequestProps): any => {
  const columns = [
    {
      title: "Booking Id",
      dataIndex: "bookingId",
      key: "bookingId",
      width: 110,
    },
    {
      title: "Inicio",
      dataIndex: "startedAt",
      key: "startedAt",
      width: 150,
    },
    {
      title: "Fin",
      dataIndex: "finishedAt",
      key: "finishedAt",
      width: 150,
    },
    {
      title: "Conductor",
      dataIndex: "providerName",
      key: "providerName",
      width: 150,
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      key: "description",
      width: 150,
    },
    {
      title: "Empresa",
      dataIndex: "company",
      key: "company",
      width: 150,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 150,
    },
    {
      title: "Cliente",
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
    },
    {
      title: "Origen",
      dataIndex: "originAddress",
      key: "originAddress",
      width: 150,
    },
    {
      title: "Destino",
      dataIndex: "destinationAddress",
      key: "destinationAddress",
      width: 150,
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      width: 150,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 100,
      render: (_: any, record: any) => {
        return +record.qrFee > 0 ? "Efectivo" : record.formattedTotal;
      },
    },
    {
      title: "Minutos",
      dataIndex: "minutes",
      key: "minutes",
      width: 100,
    },
    {
      title: "Distancia",
      dataIndex: "distance",
      key: "distance",
      width: 100,
    },
    {
      title: "Comision (%)",
      width: 110,
      render: (_: any, record: any) => {
        return +record.qrFee > 0 ? record.qrFee : record.partnerFee;
      },
    },
    {
      title: "Acciones",
      dataIndex: "accion",
      key: "accion",
      width: 150,
      render: (_: any, record: any) => {
        return (
          <Dropdown
            menu={{
              items: items(record.id ?? 0, record.qrFee),
            }}
            placement="bottom"
            arrow
          >
            <Button>Acciones</Button>
          </Dropdown>
        );
      },
      fixed: "right",
    },
    {
      title: "Omitir",
      dataIndex: "omitir",
      key: "omitir",
      width: 150,
      render: (items: any, record: any) => {
        return (
          <Popconfirm
            title="Deseas omitir esta factura?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => skipRequest(record.id)}
          >
            <a href="/#" style={{ color: "red" }}>
              {" "}
              <DeleteOutlined />
              Omitir
            </a>
          </Popconfirm>
        );
      },
      fixed: "right",
    },
  ];
  return columns;
};

export default UserRequestColumns;
