import { Progress, Spin } from "antd";
import GridPassegers from "./GridPassengers";
import { useAppSelector } from "../../../app/hooks";
import { validateDTO } from "../../../shared/dtos/PassengerGroupDTO";
import ContainerLayout from "../../common/containerLayout";

const PassegersGroup = (): JSX.Element => {
  const validate: validateDTO = useAppSelector(
    (state) => state.passeger.validating
  );
  return (
    <ContainerLayout title="Pasajeros viajes en grupos">
      <Spin
        tip={`${validate.completed}/${validate.total}`}
        spinning={validate.active}
      >
        {validate.active && (
          <Progress percent={(validate.completed * 100) / validate.total} />
        )}
        <div style={{ paddingTop: 20 }}>
          <GridPassegers />
        </div>
      </Spin>
    </ContainerLayout>
  );
};

export default PassegersGroup;
