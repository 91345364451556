import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getServiceTypeList } from "../../services/serviceTypeApi";
import ServiceTypeDTO from "../../shared/dtos/serviceTypeDTO";

interface ServiceType {
  value: Array<ServiceTypeDTO>;
  status: string;
  selectedServiceTypeId: number | undefined;
}

const initialState: ServiceType = {
  value: new Array<ServiceTypeDTO>(),
  status: "idle",
  selectedServiceTypeId: undefined,
};

export const serviceTypeListAsync = createAsyncThunk(
  "servicetype/",
  async () => {
    const response = await getServiceTypeList();
    return response;
  }
);

export const serviceTypeListSlice = createSlice({
  name: "serviceTypeList",
  initialState,
  reducers: {
    updateServiceTypeListValue: (state) => {
      state.value = [];
    },
    setSelectedServiceTypeId: (state, actions) => {
      state.selectedServiceTypeId = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(serviceTypeListAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(serviceTypeListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = state.value.concat(action.payload);
      })
      .addCase(serviceTypeListAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { updateServiceTypeListValue, setSelectedServiceTypeId } =
  serviceTypeListSlice.actions;

export default serviceTypeListSlice.reducer;
