import { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { getManagersList } from "../../../services/groupTripRequestApi";
import LabelSelectClient from "./labelSelectClient";
const debounce = require("lodash.debounce");

interface Indexado {
  [key: string | number]: any;
}

const SelectClients = (props: any): JSX.Element => {
  const [clients, setClients] = useState([]);
  const [indexClients, setIndexClients] = useState<Indexado>({});

  const getClients = useCallback(
    (name?: string) => {
      getManagersList({
        type: "manager",
        page: 1,
        take: 100,
        search: name,
        lote: true,
      }).then((response) => {
        const list = response.data.map((i: any) => {
          return { ...i, id: i.id.toString() };
        });
        const indexC = list.reduce((result: any, el: any) => {
          return {
            ...result,
            [el.id]: el,
          };
        }, {});
        setClients(list);
        setIndexClients(indexC);
      });
    },
    [setClients]
  );

  useEffect(() => {
    getClients();
  }, [getClients]);

  const handleSearch = debounce((e: string) => {
    getClients(e);
  }, 200);

  const handleOnChange = (e: any) => {
    if (props.onChange) {
      const selected = indexClients[e?.value];
      props.onChange({ ...e, selected });
      getClients();
    }
  };

  return (
    <Select
      labelInValue
      showSearch
      onSearch={handleSearch}
      filterOption={false}
      notFoundContent={null}
      options={clients.map((client: any) => ({
        label: (
          <LabelSelectClient
            firstName={client.firstName}
            lastName={client.lastName}
          />
        ),
        value: client.id,
        title: client.mobile,
      }))}
      {...props}
      onChange={handleOnChange}
    />
  );
};

export default SelectClients;
