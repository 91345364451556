import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import UserPermissionDTO from "../shared/dtos/userPermissionDTO";

const apiUrl = process.env.REACT_APP_API_URL;

export const GetUserPrivileges = async (userId: number) => {
  const result = await axios.get(
    `${apiUrl}user-permission/${userId}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data.data;
};

export const CreateUserPrivilege = async (userPermission: UserPermissionDTO) => {
  const result = await axios.post(
    `${apiUrl}user-permission/`,
    userPermission,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data.data;
};

export const UpdateUserPrivilege = async (userPermissionId: number, userPermission: UserPermissionDTO) => {
  const result = await axios.put(
    `${apiUrl}user-permission/${userPermissionId}`,
    userPermission,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data.data;
};