import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContainerLayout from "../common/containerLayout";
import { Button, Col, Descriptions, Row, Space } from "antd";
import GroupTripsMap from "../groupTrip/groupTripsMap/groupTripsMap";
import { useAppDispatch } from "../../app/hooks";
import {
  calculateRoute,
  setDirectionResponse,
} from "../../features/groupTrip/groupTripsMap/groupTripsMapSlide";
import dayjs from "dayjs";
import { WebRoutes } from "../../shared/constants/WebRoutes";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { TRIP_STATUS } from "../../shared/constants/TripStatus";
import { getTripTrackingInformation } from "../../utils/externals/aws/api";

const AdminPanelTripDetails = (): JSX.Element => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [trip, setTrip] = useState();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state.status === TRIP_STATUS.Completed) {
      getTripTrackingInformation(state.bookingId).then((res: any) => {
        setTrip(res.coords);
      });
    }
  }, [state]);

  useEffect(() => {
    const origin = state?.originAddress;
    const destiny = state?.destinationAddress;
    if (origin && destiny) {
      dispatch(calculateRoute(origin, destiny, [], false));
    }
  }, [dispatch, state?.destinationAddress, state?.originAddress]);

  const handleOnClickReturn = () => {
    dispatch(setDirectionResponse(null));

    if (state.navigationType === "Admin") {
      navigate(WebRoutes.IndexAdminPanel, {
        replace: true,
      });
    }
    else if (state.navigationType === "SuperAdmin") {
      navigate(WebRoutes.TripTableRoute, {
        replace: true,
      });
    }
  };

  return (
    <ContainerLayout title="" showNotification={false}>
      <Row style={{ background: "white", padding: 12, marginTop: 16 }}>
        <Col span={12}>
          <Descriptions
            title="Detalles del viaje"
            column={1}
            extra={
              <Button type="link" onClick={handleOnClickReturn}>
                <Space>
                  <ArrowLeftOutlined />
                  Regresar
                </Space>
              </Button>
            }
            labelStyle={{ fontWeight: "bold" }}
          >
            <Descriptions.Item label="Cliente">
              {state.customerName}
            </Descriptions.Item>
            <Descriptions.Item label="Conductor">
              {state.providerName}
            </Descriptions.Item>
            <Descriptions.Item label="Departamento">
              {state.department}
            </Descriptions.Item>
            <Descriptions.Item label="Distancia">
              {state.distance}
            </Descriptions.Item>
            <Descriptions.Item label="Hora inicio viaje">
              {state.startedAt
                ? dayjs(state.startedAt, "YYYY-MM-DD HH:mm:ss").format(
                    "DD-MM-YYYY HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Hora final viaje">
              {state.finishedAt
                ? dayjs(state.finishedAt, "YYYY-MM-DD HH:mm:ss").format(
                    "DD-MM-YYYY HH:mm"
                  )
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Dirección recogida">
              {state?.originAddress}
            </Descriptions.Item>
            <Descriptions.Item label="Dirección destino">
              {state.destinationAddress}
            </Descriptions.Item>
            <Descriptions.Item label="Cantidad total">
              {state.formattedTotal}
            </Descriptions.Item>
            <Descriptions.Item label="Estado">{state.status}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <GroupTripsMap type="tripDetails" trip={trip} originAddress={state?.originAddress} destinationAddress={state.destinationAddress} />
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default AdminPanelTripDetails;
