import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import {
  ManagersParameters,
  getManagersList,
} from "../../../services/groupTripRequestApi";
import {
  createClientApi,
  deleteClientApi,
  editClientApi,
} from "../../../services/clientsApi";
import { message } from "antd";
import UserProfileDTO from "../../../shared/dtos/userProfileDTO";

interface Pagination {
  total: number;
}

interface Initial {
  clients: UserProfileDTO[];
  loading: boolean;
  selected: UserProfileDTO | undefined;
  openModal: boolean;
  pagination: Pagination | undefined;
}

const initialState: Initial = {
  clients: [],
  loading: false,
  selected: undefined,
  openModal: false,
  pagination: undefined,
};

const groupTripsClientsSlide = createSlice({
  name: "groupTripsClients",
  initialState,
  reducers: {
    setClients: (state, actions) => {
      state.clients = actions.payload;
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
    setSelected: (state, actions) => {
      state.selected = state.clients.find((c) => c.id === actions.payload);
    },
    setOpenModal: (state, actions) => {
      state.openModal = actions.payload;
    },
    setPagination: (state, actions) => {
      state.pagination = actions.payload;
    },
  },
});

export const {
  setClients,
  setLoading,
  setSelected,
  setOpenModal,
  setPagination,
} = groupTripsClientsSlide.actions;

export const getClients = ({
  type,
  page,
  take,
  search,
  lote,
}: ManagersParameters): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await getManagersList({
        type,
        page,
        take,
        search,
        lote,
      });
      dispatch(setPagination({ total: response.total }));
      dispatch(setClients(response.data));
      dispatch(setLoading(false));
    } catch (error) {
      message.error("Ha ocurrido un error al obtener los clientes.");
    }
  };
};

interface Params {
  firstName: string;
  lastName: string;
  email: string;
  mobile: number;
  lote: boolean;
  type: number;
}

export const addClient = (data: Params, callBack?: () => void): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await createClientApi(data);
      if (response.id) {
        dispatch(getClients({ type: "manager", lote: true }));
        message.success("Cliente adicionado satisfactoriamente.");
        if (callBack) {
          callBack();
        }
      }
    } catch (error) {
      message.error("Ha ocurrido un error al intentar adicionar el cliente.");
    }
  };
};

interface EditParams {
  firstName: string;
  lastName: string;
  email: string;
  mobile: number;
}

export const editClient = (
  data: EditParams,
  id: string | number,
  callBack?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await editClientApi(data, id);
      if (response.success) {
        dispatch(getClients({ type: "manager", lote: true }));
        message.success(
          "La información del cliente se ha actualizado satisfactoriamente."
        );
        if (callBack) {
          callBack();
        }
      }
    } catch (error) {
      message.error(
        "Ha ocurrido un error al intentar actualizar la información del cliente."
      );
    }
  };
};

export const deleteClient = (id: string | number): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await deleteClientApi(id);
      if (response.success) {
        dispatch(getClients({ type: "manager", lote: true }));
        message.success("El cliente se ha eliminado satisfactoriamente.");
      }
    } catch (error) {
      message.error("Ha ocurrido un error al intentar eliminar el cliente.");
    }
  };
};

export default groupTripsClientsSlide.reducer;
