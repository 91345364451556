import { Col, Row, Select, Card, Space, Image } from "antd";
import ContainerLayout from "../common/containerLayout";
import { BarChartOutlined, ToTopOutlined } from "@ant-design/icons";
import AdminPanelTrips from "./adminPanelTrips";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectCurrentAdminId,
  selectCurrentAdminType,
  selectCurrentAdminPriceTable,
} from "../../utils/helpers/selectorHelper";
import { AdminTypeId } from "../../shared/constants/Resources";
import {
  getUserRequestAdminPanel,
  setFiltersAdminPanel,
} from "../../features/adminPanel/adminPanelSlice";
import { carPeople, personTools } from "../../shared/constants/Images";
import CustomCardAdminPanel from "./customCardAdminPanel";
import { getDepartments } from "../../features/departments/departmentsSlice";
import { getAdminByIdAsync } from "../../features/admin/getAdminByIdSlice";
import { getAdminWalletAsync } from "../../features/adminWallet/adminWalletSlice";
import { getAdminComissionAsync } from "../../features/adminComission/adminComission";

import "./styles.css";

const AdminPanel = (): JSX.Element => {
  const adminPanel = useAppSelector((state) => state.adminPanel.value);
  const filters = useAppSelector((state) => state.adminPanel.filtersAdminPanel);
  const departments = useAppSelector((state) => state.departments.values);
  const admin = useAppSelector((state) => state.getAdminById.value);
  const adminWallet = useAppSelector((state) => state.adminWallet.value);
  const adminComission = useAppSelector((state) => state.adminComission.value);
  const [showLastPeriod, setShowLastPeriod] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const priceTable = selectCurrentAdminPriceTable();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDepartments({ page: 1, take: 100 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAdminByIdAsync({ id: 0, byToken: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAdminWalletAsync(selectCurrentAdminId()));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAdminComissionAsync(showLastPeriod)).then(() =>
      setIsLoading(false)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLastPeriod]);

  useEffect(() => {
    dispatch(setFiltersAdminPanel(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (!filters?.adminId) {
      const filter = {
        ...filters,

        adminId:
          selectCurrentAdminType() === AdminTypeId
            ? selectCurrentAdminId()
            : null,
      };
      dispatch(setFiltersAdminPanel(filter));
    }
    if (filters.adminId) {
      dispatch(getUserRequestAdminPanel({ ...filters }));
    }
  }, [dispatch, filters]);

  const fetchAdminComission = (showLastPeriod = false) => {
    if (admin.partner) {
      setShowLastPeriod(showLastPeriod);
    }
  };

  const handleOnChange = (departmentName: string) => {
    const newFilters = { ...filters, page: 1, departmentName };
    dispatch(setFiltersAdminPanel(newFilters));
  };

  const handleOnClick = () => {
    setIsLoading(true);
    fetchAdminComission(!showLastPeriod);
    setShowLastPeriod(!showLastPeriod);
  };

  return (
    <ContainerLayout title="" showNotification={false} defaultContainer={false}>
      <Row
        gutter={[0, 24]}
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%", maxWidth: 820 }}>
            <Select
              placeholder="Departamentos..."
              style={{ width: 200 }}
              allowClear
              onChange={handleOnChange}
              options={departments?.departments?.map((department) => ({
                label: department.name,
                value: department.name,
              }))}
            />
          </div>
        </Col>
        <Col span={24} className="custom-statistic-admin-panel">
          {admin.partner && (
            <CustomCardAdminPanel
              title="Codigo"
              value={`${
                adminComission?.totalEarnings
                  ? `$${adminComission?.totalEarnings}`
                  : 0
              }`}
              image={personTools}
              cornerBackground="green"
              cornerIcon={
                <BarChartOutlined style={{ color: "#FFF", fontSize: 24 }} />
              }
              showSubtitle={true}
              showLastPeriod={showLastPeriod}
              handleOnClick={handleOnClick}
              isLoading={isLoading}
              dateRange={adminComission.dateRange}
            />
          )}
          {admin.wallet && (
            <CustomCardAdminPanel
              title="Balance"
              value={+adminWallet}
              image={personTools}
              cornerBackground="green"
              cornerIcon={
                <BarChartOutlined style={{ color: "#FFF", fontSize: 24 }} />
              }
            />
          )}
          <CustomCardAdminPanel
            title="Total viajes completados"
            value={adminPanel?.totalCompleted || 0}
            image={carPeople}
            cornerBackground="red"
            cornerIcon={
              <ToTopOutlined style={{ color: "#FFF", fontSize: 24 }} />
            }
          />
          <CustomCardAdminPanel
            title="Consumido"
            value={`${
              adminPanel?.totalSpent ? `$${adminPanel?.totalSpent}` : 0
            }`}
            image={personTools}
            cornerBackground="green"
            cornerIcon={
              <BarChartOutlined style={{ color: "#FFF", fontSize: 24 }} />
            }
          />
          {priceTable && (
            <Card className="custom-card-statistics-admin-panel">
              <Space direction="vertical">
                <span className="total-trips">Tabla de precios</span>{" "}
              </Space>
              <div
                className="custom-icon-position-top-right"
                style={{ background: "green" }}
              >
                <div className="custom-icon-position">
                  {<BarChartOutlined style={{ color: "#FFF", fontSize: 24 }} />}
                </div>
              </div>
              <div className="custom-img-position-bottom-right">
                <Image
                  src={priceTable}
                  alt="tabla de precios"
                  width={350}
                  height={230}
                />
              </div>
            </Card>
          )}
        </Col>
        <Col span={24}>
          <AdminPanelTrips />
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default AdminPanel;
