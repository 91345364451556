import { Col, Row, Spin } from "antd";
import ContainerLayout from "../../common/containerLayout";
import { useEffect, useState } from "react";
import GroupTripsMap from "../../groupTrip/groupTripsMap/groupTripsMap";
import { getEmptyTripsApi } from "../../../services/emptyTripApi";
import EmptyTripDTO from "../../../shared/dtos/emptyTripDto";
import EmptyTripCard from "./emptyTripCard";
import {
  calculateRoute,
  setDirectionResponse,
} from "../../../features/groupTrip/groupTripsMap/groupTripsMapSlide";
import { useAppDispatch } from "../../../app/hooks";

import "./styles.css";

const EmptyTrips = () => {
  const [emptyTripsList, setEmptyTripsLis] = useState<Array<EmptyTripDTO>>([]);
  const [selectedEmptyTrip, setSelectedEmptyTrip] = useState<
    EmptyTripDTO | undefined
  >();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const getEmptyTrips = async () => {
    setLoading(true);
    const response = await getEmptyTripsApi();
    setEmptyTripsLis(response);
    setLoading(false);
  };

  useEffect(() => {
    getEmptyTrips();
    return () => {
      dispatch(setDirectionResponse(null));
    };
  }, [dispatch]);

  const handleSelected = (selected?: EmptyTripDTO) => {
    setSelectedEmptyTrip(selected);
    dispatch(
      calculateRoute(
        selected?.originAddress || "",
        selected?.destinationAddress || "",
        [],
        false
      )
    );
  };

  return (
    <ContainerLayout
      title="Viajes vacíos"
      showNotification={false}
      defaultContainer={false}
    >
      <Row style={{ paddingTop: 25 }}>
        <Col span={16} push={8}>
          <GroupTripsMap />
        </Col>
        <Col span={8} pull={16}>
          <Spin spinning={loading} size="large">
            <div className="empty-trip-card-container">
              {emptyTripsList.map((trip) => {
                return (
                  <EmptyTripCard
                    emptyTrip={trip}
                    key={trip?.id}
                    selected={selectedEmptyTrip?.id === trip?.id}
                    onSelected={handleSelected}
                    refetch={getEmptyTrips}
                  />
                );
              })}
            </div>
          </Spin>
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default EmptyTrips;
