import {
  Button,
  Col,
  Layout,
  notification,
  Row,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import {
  ColumnsType,
  FilterValue,
  SorterResult,
} from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changeStatus, getPaginatedBanners } from "../../../services/bannerApi";
import { WebRoutes } from "../../../shared/constants/WebRoutes";

import "../../../shared/styles/list.scss";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters: Record<string, FilterValue | null>;
}

export interface DataType {
  id: number;
  name: string;
  url: string;
  fileKey: string;
  forManager: boolean;
  forApp: boolean;
  active: boolean;
  createdAt: string;
}

const validateFilters = (
  filters: Record<string, FilterValue | null>
): boolean | undefined => {
  if (!filters.active) {
    return undefined;
  }

  if (filters.active.length === 1) {
    return Boolean(filters.active[0]);
  }

  return undefined;
};

const BannerList = () => {
  const title = "Administración de Banners";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const changeStatusToBanner = async (record: DataType) => {
    try {
      await changeStatus({ id: record.id, status: !record.active });
      notification.success({
        message: `Banner ${record.name} ${
          record.active ? "inactivado" : "activado"
        } correctamente!`,
        placement: "bottom",
      });
      fetchData();
    } catch (error) {
      notification.error({
        message: "Ha ocurrido un error al cambiar el estado del banner.",
      });
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Nombre",
      dataIndex: "name",
      sorter: false,
      width: "20%",
    },
    {
      title: "Imagen",
      dataIndex: "url",
      width: "30%",
      render: (url) => (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Ver en manager",
      dataIndex: "forManager",
      render: (forManager) => (forManager ? "Si" : "No"),
      width: "10%",
    },
    {
      title: "Ver en app",
      dataIndex: "forApp",
      render: (forApp) => (forApp ? "Si" : "No"),
      width: "10%",
    },
    {
      title: "Link",
      dataIndex: "link",
      width: "10%",
      render: (url) => (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Estado",
      dataIndex: "active",
      render: (active) =>
        active ? (
          <span style={{ color: "green" }}>Activo</span>
        ) : (
          <span style={{ color: "red" }}>Inactivo</span>
        ),
      filters: [
        { text: "Activo", value: true },
        { text: "Inactivo", value: false },
      ],
    },
    {
      title: "Acciones",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => changeStatusToBanner(record)}>
            {record.active ? "Inactivar" : "Activar"}
          </Button>
        </Space>
      ),
    },
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    filters: {
      active: [true],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = async () => {
    setLoading(true);

    const { data, total } = await getPaginatedBanners({
      page: tableParams.pagination?.current || 1,
      take: tableParams.pagination?.pageSize || 10,
      status: validateFilters(tableParams.filters),
    });

    setData(data);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
      },
    });
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row">
          <Col className="input-container-table">
            <Button
              onClick={() =>
                navigate(WebRoutes.CreateBannerRoute, {
                  replace: true,
                })
              }
            >
              Crear nuevo banner
            </Button>
          </Col>
        </Row>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Row>
      </Content>
    </Layout>
  );
};

export default BannerList;
