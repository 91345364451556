import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer } from "antd";
import "./notification.scss";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setConversationOpen } from "../../../features/notifications/conversationSlice";
import { getNotificationsTrip } from "../../../features/notifications/notificationTripSlice";
import NotificationsTabPanel from "./notificationsTabPanel";

const NotificationPanel = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const notifications = useAppSelector(
    (state) => state.notificationTrip.notifications
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotificationsTrip());
  }, [dispatch]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
    dispatch(setConversationOpen(false));
    dispatch(getNotificationsTrip());
  };

  const count = useMemo(() => {
    let cant = 0;
    if (notifications?.button?.length > 0) {
      const newCancel = notifications.button.filter(
        (b: any) => b.status === "new"
      );
      cant += newCancel.length;
    }
    if (notifications?.text?.length > 0) {
      const newText = notifications?.text.filter(
        (t: any) => t.status === "new"
      );
      cant += newText.length;
    }
    return cant;
  }, [notifications?.button, notifications?.text]);

  return (
    <div className="custom-container-drawer-cls">
      <Button type="link" style={{ padding: 0 }} onClick={showDrawer}>
        <Badge count={count}>
          <BellOutlined style={{ fontSize: 25 }} />
        </Badge>
      </Button>
      <Drawer
        title={
          <h1 className="notification-drawer-title-h1-cls">Notificaciones</h1>
        }
        placement="right"
        width={650}
        onClose={onClose}
        open={open}
        push={{ distance: 0 }}
        headerStyle={{ padding: "14px 10px 16px 18px" }}
        bodyStyle={{
          padding: "22px 27px",
        }}
        destroyOnClose
      >
        <div>
          <NotificationsTabPanel notifications={notifications} />
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationPanel;
