import { Col, Row } from "antd";

interface Props {
  firstName: string | undefined | null;
  lastName: string | undefined | null;
}

const LabelSelectClient = ({
  firstName,
  lastName,
}: Props): JSX.Element => {
  return (
    <Row style={{ width: "100%" }}>
      <Col
        span={24}
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 14,
        }}
      >{`${firstName || ""} ${lastName || ""}`}</Col>
    </Row>
  );
};

export default LabelSelectClient;
