import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import ModalNewDirection from "../directionGroup/ModalNewDirection";
import {
  groupTripsPassegerList,
  deleteGroupTripsPassegerAsync,
  setSelectedPasseger,
} from "../../../features/groupTrip/passeger/groupTripPassegerSlide";
import TablePassengerList from "../common/tablePassengerList";

const PassegerList = () => {
  const dispatch = useAppDispatch();
  const { value: pasajeros, loading } = useAppSelector(
    (state) => state.passeger
  );
  const request = useAppSelector((state) => state.groupTripsRequest.selected);
  const [validate, setValidate] = useState(false);
  const [type, setType] = useState("origin");

  useEffect(() => {
    request?.id && dispatch(groupTripsPassegerList(request?.id));
  }, [request, dispatch]);

  const openModal = (record: any, type: string) => {
    console.log("Abrir modal", record);
    setType(type);
    dispatch(setSelectedPasseger(record?.id));
    setValidate(true);
  };

  const deletePasseger = (passegerId: string) => {
    console.log("Eliminar pasajero con id : ", passegerId);
    dispatch(
      deleteGroupTripsPassegerAsync((request && request?.id) || "0", passegerId)
    );
  };
  return (
    <Spin tip="Cargando..." spinning={loading}>
      <TablePassengerList
        passengers={pasajeros}
        deleteAction={deletePasseger}
        validateAddress={openModal}
      />
      <ModalNewDirection show={validate} setShow={setValidate} type={type} />
    </Spin>
  );
};

export default PassegerList;
