import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const asincronica = async ({ userRequestId, providerId }: any) => {
  const result = await axios.get(`url`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const validateAddress = async (address: string) => {
  // console.log("Validar destinos... ", address);
  const dir = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=$${address}&key=${apiKey}`
  );

  // console.log("dirrrrrrrrrrrrrrrrrr", dir);

  if (dir?.data?.results?.length === 1) {
    //>0
    const pais =
      dir.data.results[0].address_components[
        dir.data.results[0].address_components.length - 1
      ].types[0] === "country"
        ? dir.data.results[0].address_components[
            dir.data.results[0].address_components.length - 1
          ]
        : dir.data.results[0].address_components[
            dir.data.results[0].address_components.length - 2
          ];

    if (pais.short_name === "UY") {
      //Tener a futuro variables de entorno para controlar los paises permitidos
      return dir?.data?.results[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
};
