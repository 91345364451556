import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createBanner } from "../../services/bannerApi";

export const initialState = {
  value: {},
  status: "idle",
};

export const postBannerAsync = createAsyncThunk(
  "banners/",
  async (formdata: FormData) => {
    return await createBanner(formdata);
  }
);

export const postAdminSlice = createSlice({
  name: "adminList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postBannerAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postBannerAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(postBannerAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default postAdminSlice.reducer;
