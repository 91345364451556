import { Space, Table, Button, Popconfirm, Row, Col } from "antd";
import ContainerLayout from "../../common/containerLayout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect } from "react";
import {
  deleteClient,
  getClients,
  setOpenModal,
  setSelected,
} from "../../../features/groupTrip/groupTripsClients/grpupTripsClientsSlice";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import AddClientModal from "./addClientModal";

const { Column } = Table;

const GroupTripsClients = () => {
  const clients = useAppSelector((state) => state.groupTripsClients.clients);
  const pagination = useAppSelector(
    (state) => state.groupTripsClients.pagination
  );
  const loading = useAppSelector((state) => state.groupTripsClients.loading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getClients({
        type: "manager",
        page: 1,
        take: 100,
        lote: true,
      })
    );
  }, [dispatch]);

  const handleDeleteClient = (id: string) => {
    dispatch(deleteClient(id));
  };

  const handleEdit = (id: string) => {
    dispatch(setSelected(id));
    dispatch(setOpenModal(true));
  };

  const onChange = (page: number, pageSize: number) => {
    dispatch(
      getClients({
        type: "manager",
        page,
        take: pageSize,
        lote: true,
      })
    );
  };

  return (
    <ContainerLayout title="Clientes/Empresas">
      <Row gutter={[12, 16]} style={{ marginTop: 25 }}>
        <Col span={24}>
          <AddClientModal />
        </Col>
        <Col span={24}>
          <Table
            dataSource={clients}
            rowKey="id"
            loading={loading}
            pagination={{
              ...pagination,
              onChange,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} clientes`,
            }}
          >
            <Column
              title="Nombre"
              render={(item) => {
                return `${item?.firstName || ""} ${item?.lastName || ""}`;
              }}
            />
            {/* <Column title="Email" dataIndex="email" /> */}
            <Column title="Mobile" dataIndex="mobile" />
            <Column
              title=""
              width={100}
              render={(item) => {
                return (
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => handleEdit(item?.id)}
                    >
                      <EditFilled />
                    </Button>
                    <Popconfirm
                      title="Está seguro(a) que desea eliminar este cliente?"
                      onConfirm={() => handleDeleteClient(item?.id)}
                      placement="topRight"
                      okText="Si"
                      cancelText="No"
                    >
                      <Button
                        type="link"
                        size="small"
                        icon={<DeleteOutlined style={{ color: "red" }} />}
                      />
                    </Popconfirm>
                  </Space>
                );
              }}
            />
          </Table>
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default GroupTripsClients;
