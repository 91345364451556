import { List } from "antd";
import GroupSchedule from "./groupSchedule";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { GroupTripRequestDTO } from "../../../shared/dtos/groupTripRequestDTO";
import { useEffect } from "react";
import { groupTripsRequestList } from "../../../features/groupTrip/groupTripRequest/groupTripRequestSlide";

const GroupsList = (): JSX.Element => {
  const request: GroupTripRequestDTO[] = useAppSelector(
    (state) => state.groupTripsRequest.value
  );
  const loading: boolean = useAppSelector(
    (state) => state.groupTripsRequest.loading
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(groupTripsRequestList());
  }, [dispatch]);

  return (
    <>
      <List
        size="large"
        dataSource={request}
        loading={loading}
        renderItem={(item) => (
          <List.Item style={{ padding: "8px 2px" }}>
            <GroupSchedule
              id={item.id}
              name={item.name}
              totalGroups={request.length}
              // totalPassengers={item.totalPassengers}
              totalPassengers={item.totalPassengers}
              planning={item.planning}
              repeat={item.repeat}
              endDate={item.endDate}
              startDate={item.startDate}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default GroupsList;
