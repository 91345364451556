import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_GROUP_TRIPS_MOCROSERVICE_API;

interface PaginatedProps {
  page: number;
  take: number;
  name?: string;
}

export interface Region {
  id: number;
  name: string;
  polygon: string;
}

export const getRegions = async (): Promise<Array<Region>> => {
  const result = await axios.get(`${apiUrl}regions`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result.data;
};

export const getPaginatedRegions = async ({
  page,
  take,
  name,
}: PaginatedProps) => {
  const result = await axios.get(
    `${apiUrl}regions/paginated?start=${page}&limit=${take}&name=${name}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const createRegion = async (name: string, polygon: string) => {
  return await axios.post(
    `${apiUrl}regions`,
    { name, polygon },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const updateRegion = async (
  id: number,
  name: string,
  polygon: string
) => {
  return await axios.put(
    `${apiUrl}regions/${id}`,
    { name, polygon },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const deleteRegion = async (id: number) => {
  return await axios.delete(`${apiUrl}regions/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
};
