import { Switch, notification } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import {
  updateAdminPartner,
  updateAdminWallet,
  updateAdvancedFlag,
  updateIsManualEntryAuthorizedFlag,
  updateSendWhatsappMessageFlag,
} from "../../../../services/adminApi";

export interface Props {
  adminId: number;
  switchValue: boolean;
  setIgnoreFetch: Dispatch<SetStateAction<boolean>>;
  swithType: string;
}

const AdminSwitch = ({
  adminId,
  switchValue,
  setIgnoreFetch,
  swithType,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const onChange = async (value: boolean) => {
    setLoading(true);
    switch (swithType) {
      case "wallet":
        try {
          await updateAdminWallet(adminId, value);
        } catch (error) {
          notification.error({
            message: `Ha ocurrido un error al ${
              value ? "activar" : "desactivar"
            } el acceso a generar una cartera, intentelo más tarde.`,
            placement: "bottom",
          });
        }
        break;
      case "advanced":
        try {
          await updateAdvancedFlag(adminId, value);
        } catch (error) {
          notification.error({
            message: `Ha ocurrido un error al ${
              value ? "activar" : "desactivar"
            } la administración avanzada, intentelo más tarde.`,
            placement: "bottom",
          });
        }
        break;
      case "partner":
        try {
          await updateAdminPartner(adminId, value);
        } catch (error) {
          notification.error({
            message: `Ha ocurrido un error al ${
              value ? "activar" : "desactivar"
            } el acceso a generar QR, intentelo más tarde.`,
            placement: "bottom",
          });
        }
        break;
      case "coordinates":
        try {
          await updateIsManualEntryAuthorizedFlag(adminId, value);
        } catch (error) {
          notification.error({
            message: `Ha ocurrido un error al ${
              value ? "activar" : "desactivar"
            } la autorizacion para enviar coordenadas en viajes, intentelo más tarde.`,
            placement: "bottom",
          });
        }
        break;
      case "sendWhatsappMessage":
        try {
          await updateSendWhatsappMessageFlag(adminId, value);
        } catch (error) {
          notification.error({
            message: `Ha ocurrido un error al ${
              value ? "activar" : "desactivar"
            } la autorizacion para enviar coordenadas en viajes, intentelo más tarde.`,
            placement: "bottom",
          });
        }
        break;
    }
    setIgnoreFetch(false);
    setLoading(false);
  };

  return (
    <Switch
      loading={loading}
      defaultChecked={switchValue}
      onChange={onChange}
    />
  );
};

export default AdminSwitch;
