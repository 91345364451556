import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DefaultPaginationProps from "../../propInterfaces/defaultPaginationProps";
import { getAdmins } from "../../services/adminApi";
import AdminDTO from "../../shared/dtos/adminDTO";

export const initialState = {
  value: { adminDto: new Array<AdminDTO>(), total: 0, take: 10 },
  loading: false,
};

export const adminListAsync = createAsyncThunk(
  "admins/",
  async ({ page, take, searchText }: DefaultPaginationProps) => {
    const response = await getAdmins({ page, take, searchText });

    const adminListObject = {
      adminDtos: response.data,
      total: response.total,
      take: take,
    };
    return adminListObject;
  }
);

export const adminListSlice = createSlice({
  name: "adminList",
  initialState,
  reducers: {
    updateAdminListValue: (state) => {
      state.value.adminDto = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminListAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(adminListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.value.adminDto = state.value.adminDto.concat(
          action.payload.adminDtos
        );
        state.value.total = action.payload.total;
      })
      .addCase(adminListAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { updateAdminListValue } = adminListSlice.actions;

export default adminListSlice.reducer;
