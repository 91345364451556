import { notification } from "antd";
import axios from "axios";
import LoginDTO from "../shared/dtos/loginDTO";
import PATHS from "./paths";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

export const adminLogin = async (credentials: LoginDTO) => {
  const apiURL = process.env.REACT_APP_API_URL;

  credentials.client_id = process.env.REACT_APP_ADMIN_CLIENT_ID;
  credentials.client_secret = process.env.REACT_APP_ADMIN_CLIENT_SECRET;
  credentials.device_type = process.env.REACT_APP_ADMIN_DEVICE_TYPE;
  credentials.device_token = process.env.REACT_APP_ADMIN_DEVICE_TOKEN;
  credentials.grant_type = process.env.REACT_APP_GRANT_TYPE;

  const result = await axios
    .post(`${apiURL}${PATHS.AUTH_ADMIN}`, credentials)
    .then((response: any) => {
      notification.success({
        message: "Autenticacion exitosa",
        description: "",
        placement: "bottom",
      });
      return response.data;
    })
    .catch((error: any) => {
      notification.error({
        message: `Error ${error.response.status}`,
        description:
          error.response.status === 401
            ? "El usuario o contraseña son incorrectos"
            : "Error en el servidor",
        placement: "bottom",
      });
    });

  return result;
};

export const adminPasswordReset = async (newPassword: string) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios
    .patch(`${apiURL}${PATHS.ADMIN_PASSWORD_RESET}`,
      { newPassword: newPassword },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      })
    .then((response: any) => {
      notification.success({
        message: "Contraseña actualizada correctamente",
        description: "Por favor inicia sesion nuevamente con la contraseña actualizada",
        placement: "bottom",
      });
      return response.data;
    })
    .catch((error: any) => {
      notification.error({
        message: `Error ${error.response.status}`,
        description:
          error.response.status === 400
            ? "La contraseña debe contener minimo 7 caracteres, una letra, un numero y un caracter especial !+-.@#$%^&"
            : "Error en el servidor",
        placement: "bottom",
      });
    });

  return result;
};
