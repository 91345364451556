import { PlusOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import GroupsList from "./groupsList";
import "./groupTrip.scss";
import PopUpNewGroup from "./popUpNewGroup";
import { groupTripsRequestList } from "../../../features/groupTrip/groupTripRequest/groupTripRequestSlide";
import { useAppDispatch } from "../../../app/hooks";
import ContainerLayout from "../../common/containerLayout";
import SelectClients from "../common/selectClients";

interface SearchValue {
  value: string;
}

const GroupTrip = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleOnChange = ({ value }: SearchValue) => {
    dispatch(groupTripsRequestList(value));
  };

  return (
    <ContainerLayout title="Solicitudes Viajes en grupo">
      <Row
        className="group-trip-container"
        gutter={[16, 24]}
        style={{ maxHeight: "calc(100vh - 80px)", overflowY: "scroll" }}
      >
        <Col span={24}>
          <SelectClients style={{ width: 230 }} onChange={handleOnChange} />
        </Col>
        <Col span={24}>
          <PopUpNewGroup
            text="Nuevo viaje en grupo"
            modalTitle="Crear nueva planificacion"
            icon={<PlusOutlined />}
          />
        </Col>
        <Col span={24}>
          <GroupsList />
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default GroupTrip;
