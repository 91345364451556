import { Col, Row } from "antd";
import { DispatcherMap } from "../../map/Map";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { resetTrip } from "../../../features/map/mapSlice";
import { useEffect } from "react";
import { Content, Header } from "antd/lib/layout/layout";
import UserRequestForm from "./form";

import "./form/styles.scss"

export default function CreateUserRequest() {
  const { destinations } = useSelector((state: RootState) => state?.map);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(resetTrip());
  }, [dispatch]);

  return (
    <>
      <Header>Dispatcher</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row>
          <Col span={10} className="create-container">
            <UserRequestForm />
          </Col>
          <Col span={14}>
            <DispatcherMap
              destinations={destinations}
              shouldCalculateDistance={true}
            />
          </Col>
        </Row>
      </Content>
    </>
  );
}
