import {
  CheckCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  addDepartment,
  editDepartment,
} from "../../features/departments/departmentsSlice";

interface Props {
  create?: boolean;
  getValuesToEdit?: () => { id: string; name: string };
}

const DepartmentModal = ({
  create = true,
  getValuesToEdit,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleOnCancel = () => {
    setOpen(false);
  };

  const callBackAction = (flag: boolean) => {
    if (flag) {
      setLoading(true);
    } else {
      setLoading(false);
      setOpen(false);
    }
  };

  const onFinish = (values: any) => {
    if (id) {
      dispatch(editDepartment(id, values?.name, callBackAction));
    } else {
      dispatch(addDepartment(values?.name, callBackAction));
    }
  };

  const handleGetValuesToEdit = () => {
    const data = getValuesToEdit ? getValuesToEdit() : undefined;
    if (data?.id) {
      form.setFieldValue("name", data?.name);
      setId(data?.id);
      setOpen(true);
    }
  };

  return (
    <>
      <Button
        icon={!create ? <EditOutlined /> : <PlusOutlined />}
        onClick={getValuesToEdit ? handleGetValuesToEdit : () => setOpen(true)}
        type="primary"
        style={{
          backgroundColor: !create ? "#20b9ae" : "",
          borderColor: !create ? "#20b9ae" : "",
          borderRadius: 20,
        }}
      >
        {!create ? "editar" : "nuevo departamento"}
      </Button>
      <Modal
        title={!create ? "Editar departamento" : "Adicionar Departamento"}
        open={open}
        onCancel={!loading ? handleOnCancel : undefined}
        closable={!loading}
        onOk={() => form.submit()}
        okText="Aceptar"
        okButtonProps={{ loading, icon: !loading && <CheckCircleOutlined /> }}
        cancelText="Cancelar"
        cancelButtonProps={{ disabled: loading }}
        forceRender
        afterClose={() => {
          form.resetFields();
          setId(undefined);
        }}
      >
        <Form form={form} onFinish={onFinish} disabled={loading}>
          <Form.Item
            label="Nombre"
            name="name"
            rules={[{ required: true, message: "Este campo es requerido." }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DepartmentModal;
