import axios from "axios";
import ProviderServiceDTO from "../shared/dtos/providerServiceDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const getServicesByProviderId = async (providerId: number) => {
  const result = await axios.get(`${apiUrl}/provider-service/${providerId}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const associateServiceToProvider = async (
  providerServiceDTO: ProviderServiceDTO
) => {
  const result = await axios.post(
    `${apiUrl}/provider-service`,
    providerServiceDTO,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const updateProviderService = async (
  providerServiceDTO: ProviderServiceDTO
) => {
  const result = await axios.put(
    `${apiUrl}/provider-service`,
    providerServiceDTO,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};
