import { useEffect, useState } from "react";
import { Button, Col, Form, Radio, Row, Space, message } from "antd";
import DatePicker from "../../common/DatePicker";
import dayjs from "dayjs";
import DriverAssignModal from "../tripGroup/driverAssignModal";
import UploadPassengers from "./uploadPassengers";
import { PassengerGroupDTO } from "../../../shared/dtos/PassengerGroupDTO";
import { useAppDispatch } from "../../../app/hooks";
import { calculateRoute } from "../../../features/groupTrip/groupTripsMap/groupTripsMapSlide";
import useGroupTrip from "./useGroupTrip";
import CollapseGroupTrip from "../tripGroup/collapseGroupTrip";
import { getDataFromGroupTrip, getPassengersData } from "../common/utils";
import { generateTrip } from "../../../services/groupTripsApi";
import { assignDriverDispatcher } from "../../../features/groupTrip/groupTrip/driverAssignModalSlice";
import { useNavigate } from "react-router-dom";
import { disabledDateBeforeToday } from "../../../utils/helpers/disabledDateBeforeToday";
import ModalNewDirection from "../directionGroup/ModalNewDirection";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import ModalPassenger from "../common/formPassengers/modalPassenger";
import TablePassengerList from "../common/tablePassengerList";
import SelectClients from "../common/selectClients";

const FormAddGroupTrip = (): JSX.Element => {
  const [show, setShow] = useState({ show: false, userRequestId: null });
  const [passengers, setPassengers] = useState<PassengerGroupDTO[]>([]);
  const [validate, setValidate] = useState(false);
  const [type, setType] = useState("origin");
  const [passengerFromDispatcher, setPassengerFromDispatcher] = useState<
    PassengerGroupDTO | undefined
  >(undefined);
  const [form] = Form.useForm();
  const group = useGroupTrip(passengers, form);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onFinish = async (values: any) => {
    const { client } = values;

    const invalidAlidAddress = passengers.some(
      (p) => !p.origin?.available || !p.destiny?.available
    );

    if (!invalidAlidAddress && group) {
      const {
        checkPoints,
        originLatitude,
        originLongitude,
        destinationLatitude,
        destinationLongitude,
        originAddress,
        destinationAddress,
        distance,
        scheduleAt,
        wayptsList,
      } = getDataFromGroupTrip(group[0]);

      const destinations = wayptsList.map((wayPoints: any) => ({
        latitude: Number(wayPoints.address.coordinate?.lat),
        longitude: Number(wayPoints.address.coordinate?.lng),
        address: wayPoints.address?.address,
      }));
      const passengersData = getPassengersData(checkPoints);

      const trip = {
        originLatitude,
        originLongitude,
        destinationLatitude,
        destinationLongitude,
        originAddress,
        destinationAddress,
        distance,
        serviceTypeId: 26, // default Vans,
        paymentMode: "MOWI", // default MOWI,
        scheduleAt,
        description: "",
        firstName: client?.selected?.firstName || "-",
        lastName: client?.selected?.lastName || "-",
        mobile: client?.selected?.mobile || "-", // corregir este valor
        email: client?.selected?.email,
        autoAssign: false,
        recurrentDays: [], // days of the week
        destinations,
        passengersData,
      };
      try {
        const generated = await generateTrip(trip);
        if (generated?.requestId) {
          setShow({ show: true, userRequestId: generated?.requestId });
          message.success(
            "El viaje en grupo se ha creado satidsfactoriamente."
          );
        }
      } catch (error) {
        message.error(
          "Ha ocurrido un error al intentar crear el viaje en grupo"
        );
      }
    } else {
      message.error("Compruebe que no existen direcciones sin validar.");
    }
  };

  type NumUndNull = number | undefined | null;
  const onAssignDriver = (
    userRequestId: NumUndNull,
    driverId: NumUndNull | string,
    close: () => void
    // setConfirmLoading: (flag?: boolean) => void
  ) => {
    const id = driverId ? Number(driverId) : undefined;
    const redirect = () => {
      navigate(WebRoutes.TripListRoute, {
        replace: true,
      });
    };
    dispatch(
      assignDriverDispatcher(
        userRequestId,
        id,
        close,
        redirect,
        group ? group[0] : undefined
      )
    );
  };

  const onChangeImportPassenger = (data: any) => {
    setPassengers(data);
  };

  useEffect(() => {
    if (passengers.length > 0 && passengers.length <= 8) {
      const origin = passengers[0]?.origin?.address || "";
      const destiny = passengers[0]?.destiny?.address || "";

      const waypts = passengers
        .filter(
          (p) => p.origin?.googlemaps !== passengers[0]?.origin?.googlemaps
        )
        .map((p) => ({
          location: p?.origin?.address,
          stopover: true,
        }));
      dispatch(calculateRoute(origin, destiny, waypts, false));
    } else if (passengers.length > 8) {
      const updatePassengers = passengers.slice(0, 8);
      setPassengers(updatePassengers);
      message.warning(
        "El número de pasajeros a importar debe ser menor o igual a 8."
      );
    }
  }, [passengers, dispatch]);

  const deletePassenger = (id: string) => {
    const updatedPassengers = passengers.filter((p) => p.id !== id);
    setPassengers(updatedPassengers);
  };

  const openModal = (record: any, type: string) => {
    setType(type);
    setValidate(true);
    setPassengerFromDispatcher(record);
  };

  const validateAddress = (
    newAddress: any,
    passengerId: string | null,
    type: string
  ) => {
    const updatePassengers = passengers.map((p) => {
      if (p.id?.toString() === passengerId?.toString()) {
        return {
          ...p,
          [type]: newAddress,
        };
      } else {
        return p;
      }
    });
    setPassengers(updatePassengers);
    setValidate(false);
  };

  const skipAction = () => {
    navigate(WebRoutes.TripListRoute, {
      replace: true,
    });
  };

  return (
    <Form
      name="dynamic_form_nest_item_add_group_trip"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="client"
            label="Empresa"
            rules={[{ required: true, message: "Elija la empresa o cliente!" }]}
          >
            <SelectClients placeholder="Elija la empresa o cliente" />
          </Form.Item>
          <Form.Item
            name="date"
            label="Fecha y hora del viaje."
            rules={[{ required: true, message: "Seleccione fecha y hora!" }]}
          >
            <DatePicker
              format="DD-MM-YYYY HH:mm"
              disabledDate={disabledDateBeforeToday}
              //   disabledTime={disabledDateTime}
              showTime={{
                defaultValue: dayjs("00:00", "HH:mm"),
                format: "HH:mm",
              }}
              style={{ width: "100%" }}
              placeholder="Fecha y hora"
              showNow={false}
            />
          </Form.Item>
          <Form.Item
            name="isReturnTrip"
            label="Seleccione si desea llegar o salir a la hora especificada."
            rules={[{ required: true, message: "Elija el tipo de viaje!" }]}
          >
            <Radio.Group buttonStyle="solid" style={{ width: "100%" }}>
              <Radio.Button value={false} style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Entrada
                </div>
              </Radio.Button>
              <Radio.Button value={true} style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Salida
                </div>
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              {" "}
              <ModalPassenger
                disabled={passengers.length >= 8}
                addPassengerAction={({ passengerData, showModal }) => {
                  const repeatedId = passengers.some(
                    (p) => p.id === passengerData.id
                  );
                  const diferentDestiny = passengers.some(
                    (p) =>
                      p.destiny.googlemaps !== passengerData.destiny.googlemaps
                  );

                  if (repeatedId) {
                    message.error(
                      `Ya existe un passajero con el identificador ${passengerData.id}`
                    );
                  } else if (diferentDestiny) {
                    message.error({
                      content: `Compruebe que la dirección de destino "${passengerData.destiny.address}" coincida con la de los pasajeros anteriores.`,
                      duration: 2.5,
                    });
                  } else {
                    setPassengers((prev) => {
                      return [...prev, passengerData];
                    });
                    if (showModal) showModal(false);
                  }
                }}
              />
            </Col>
            <Col span={12}>
              <UploadPassengers onChange={onChangeImportPassenger} />
            </Col>
            {passengers.length > 0 && passengers.length <= 8 && (
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <TablePassengerList
                      passengers={passengers}
                      allowDisablePassenger={false}
                      showIdPassenger={false}
                      sortable
                      updateDataAfterSortAction={setPassengers}
                      deleteAction={deletePassenger}
                      allowEdit={false}
                      validateAddress={openModal}
                    />
                    <ModalNewDirection
                      show={validate}
                      setShow={setValidate}
                      type={type}
                      passengerFromDispatcher={passengerFromDispatcher}
                      updateAddres={validateAddress}
                    />
                  </Col>
                  {group && (
                    <Col span={24}>
                      <CollapseGroupTrip
                        groups={group}
                        showActions={false}
                        onChange={() => {}}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col
          span={24}
          style={{ display: "flex", justifyContent: "right", marginTop: 20 }}
        >
          <Space>
            <Button>Cancelar</Button>
            <Button htmlType="submit" type="primary">
              Aceptar
            </Button>
          </Space>
        </Col>
        <DriverAssignModal
          assigned={false}
          userRequestId={show.userRequestId}
          // driverId={trip?.driver?.id}
          // latitude={Number(latitude)}
          // longitude={Number(longitude)}
          service={26}
          disabled
          onlyBtnIcon={false}
          onAssignDriver={onAssignDriver}
          btnText="Conductor"
          block
          show={show.show}
          allowSkipAction={true}
          skipAction={skipAction}
        />
      </Row>
    </Form>
  );
};

export default FormAddGroupTrip;
