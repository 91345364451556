import CollapseGroupTrip from "./collapseGroupTrip";
import dayjs from "dayjs";
import "./tripGroup.scss";
import { GroupBy } from "../../../features/groupTrip/groupTrip/groupTripListSlide";
import { Col, Row, Spin } from "antd";
import { useAppSelector } from "../../../app/hooks";
require("dayjs/locale/es");

interface Props {
  groupTrip: GroupBy[];
}

const ListTravelDates = ({ groupTrip }: Props): JSX.Element => {
  const loading = useAppSelector((state) => state.groupTrip.loading);
  return (
    <Row>
      <Col span={24}>
        <Spin spinning={loading}>
          <div style={{ minHeight: 100 }}>
            {groupTrip.map((el) => {
              const date = dayjs(el.date, "YYYY-MM-DD");
              return (
                <section key={el.date}>
                  <h1
                    style={{ textTransform: "capitalize", fontWeight: 700 }}
                  >{`${date.locale("es").format("dddd")}  ${date.format(
                    "DD/MM/YYYY"
                  )}`}</h1>
                  <CollapseGroupTrip groups={el.groups} />
                </section>
              );
            })}
          </div>
        </Spin>
      </Col>
    </Row>
  );
};

export default ListTravelDates;
