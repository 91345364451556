import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";

const initialState = {
  directionsResponse: null,
  distance: "",
  duration: "",
};

const groupTripsMapSlide = createSlice({
  name: "groupTripsMap",
  initialState,
  reducers: {
    setDirectionResponse: (state, actions) => {
      state.directionsResponse = actions.payload;
    },
  },
});

export const { setDirectionResponse } = groupTripsMapSlide.actions;

export const calculateRoute = (
  origin: google.maps.LatLngLiteral | string,
  destination: google.maps.LatLngLiteral | string,
  waypts: google.maps.DirectionsWaypoint[],
  optimizeWaypoints?: boolean
): AppThunk => {
  return async (dispatch) => {
    const directionsService = new google.maps.DirectionsService();
    const optimize =
      typeof optimizeWaypoints === "undefined" ? true : optimizeWaypoints;
    const response = await directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: waypts,
      optimizeWaypoints: optimize,
      travelMode: google.maps.TravelMode.DRIVING,
    });
    dispatch(setDirectionResponse(response));
  };
};

export default groupTripsMapSlide.reducer;
