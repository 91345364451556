import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const getPrivacyPoliciesApi = async () => {
  const result = await axios.get(`${apiUrl}settings/97`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const updatePrivacyPoliciesApi = async (setting: any) => {
  const result = await axios.put(`${apiUrl}settings/97`, setting, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};
