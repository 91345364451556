import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import UserRequestCardProps from "../../../../propInterfaces/userRequestCardProps";
import { StatusTranslations } from "../../../../shared/constants/Status";
import {
  CarIcon,
  DestinationIcon,
  HashtagIcon,
  KmIcon,
  MobileIcon,
  NoteIcon,
  OriginIcon,
  Calendar,
  ProviderIcon,
  UserIcon,
  DoorIcon,
  PlaneIcon,
  ManagerIcon,
  CompanyIcon,
  HotelIcon,
} from "../../../../shared/constants/Images";
import moment from "moment";
import { getBackgroundByStatus } from "../utils";
import { useMemo, useState } from "react";
import { SaveTwoTone, PaperClipOutlined } from "@ant-design/icons";
import {
  updateDescription,
  updateSheduleDate,
} from "../../../../services/userRequestApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../app/store";
import { userRequestListAsync } from "../../../../features/userRequest/userRequestListSlice";
import { TRIP_STATUS } from "../../../../shared/constants/TripStatus";
import { RangePickerProps } from "antd/lib/date-picker";
import EmptyTripDetails from "../../../emptyTrip/list/emptyTripDatails";
import CreateEmptyTrip from "../../../emptyTrip/form/createEmptyTrip";
import UserRequestDTO from "../../../../shared/dtos/userRequestDTO";

import "./styles.scss";

const { Paragraph } = Typography;
const DEFAULT_DESCRIPTION = "No hay notas";

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  return current < moment().endOf("day").subtract(1, "day");
};

export const UserRequestCard = ({
  userRequest,
  selectedUserRequest,
  onSelect,
}: UserRequestCardProps) => {
  let userRequestDtoStatus!: string;

  const dispatch = useDispatch<AppDispatch>();

  const [editableDescription, setEditableDescription] = useState(
    userRequest.description || DEFAULT_DESCRIPTION
  );

  const [scheduleDate, setScheduleDate] = useState(
    moment(userRequest.scheduleAt || new Date().toString())
  );

  for (var statusTranslation of StatusTranslations) {
    if (statusTranslation.name === userRequest.status) {
      userRequestDtoStatus = statusTranslation.translation;
    }
  }

  const backgroundColor = useMemo(() => {
    return getBackgroundByStatus(userRequestDtoStatus);
  }, [userRequestDtoStatus]);

  const selectedUsers = useMemo(() => {
    if (Array.isArray(selectedUserRequest)) {
      return selectedUserRequest?.some((i) => i.id === userRequest.id);
    }
    return null;
  }, [selectedUserRequest, userRequest?.id]);

  const isLote = useMemo(() => {
    const flag = userRequest?.passengersData?.length;
    if (flag && flag > 1) return true;
    return false;
  }, [userRequest?.passengersData?.length]);

  const saveDescription = async () => {
    if (!userRequest.id) {
      return;
    }

    try {
      await updateDescription(userRequest.id, editableDescription);
      notification.success({
        message: "Descripción actualizada correctamente!",
        placement: "bottom",
      });
      dispatch(userRequestListAsync({}));
    } catch (error) {
      notification.error({
        message: "Ha ocurrido un error",
        description:
          "Ha ocurrido un error al actualizar la descripción del viaje.",
        placement: "bottom",
      });
    }
  };

  const saveScheduleDate = async () => {
    if (!userRequest.id) {
      return;
    }

    try {
      await updateSheduleDate(
        userRequest.id,
        scheduleDate.format("YYYY-MM-DD HH:mm:ss")
      );
      notification.success({
        message: "Fecha de agendamiento actualizada correctamente!",
        placement: "bottom",
      });
      dispatch(userRequestListAsync({}));
    } catch (error) {
      notification.error({
        message: "Ha ocurrido un error",
        description:
          "Ha ocurrido un error al actualizar la fecha de agendamiento del viaje.",
        placement: "bottom",
      });
    }
  };

  const copyToClipBoard = (userRequest: UserRequestDTO) => {
    notification.success({
      message: "Informacion del viaje copiada exitosamente!",
      placement: "bottom",
    });
    const passengers =
      userRequest.passengersData
        ?.map(
          (passenger) =>
            `Nombre: ${passenger.name}\r\nTelefono: ${passenger.phone}`
        )
        .join("\r\n") ||
      `Nombre: ${userRequest.user?.firstName} ${userRequest.user?.lastName}\r\nTelefono: ${userRequest.user?.mobile}`;

    let destinations;

    if (userRequest.destinations) {
      const arrayCopy = [...userRequest.destinations];

      const userRequestDestinationsToMap = arrayCopy.sort(
        (a, b) => Number(a.id) - Number(b.id)
      );

      destinations =
        userRequestDestinationsToMap && userRequestDestinationsToMap.length > 0
          ? userRequestDestinationsToMap
              .map(
                (destination, i) => `Parada ${i + 1}: ${destination.address}`
              )
              .join("\r\n") || ""
          : `Parada 1: ${userRequest.destinationAddress}`;
    }

    const scheduledData = moment(userRequest.scheduleAt).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    const driver = userRequest.provider
      ? `Provider:\r\nNombre: ${userRequest.provider.firstName} ${userRequest.provider.lastName}\r\nTelefono: ${userRequest.provider.mobile}\r\n\r\n`
      : "";

    const notes = userRequest.description
      ? `\r\n\r\nDescripcion:\r\n${userRequest.description}`
      : "";

    navigator.clipboard.writeText(
      `Booking ID: ${userRequest.bookingId}\r\n\r\nPasajeros:\r\n${passengers}\r\n\rOrigen:\r\nDireccion: ${userRequest.originAddress}\r\n\r\nParadas:\r\n${destinations}\r\n\r\n${driver}Fecha de Agenda:\r\n${scheduledData}${notes}`
    );
  };

  return (
    <div
      style={{
        margin: "5px 8px 12px 8px",
        boxShadow: selectedUsers ? "rgba(0, 0, 0, 0.80) 0px 2px 5px" : "",
      }}
    >
      <Card
        bodyStyle={{
          padding: 0,
          background: selectedUsers ? "#e5e5e5" : "",
        }}
        hoverable
      >
        <Row>
          <Col span={24} style={{ background: backgroundColor }}>
            <div className="custom-card-header-status-trip">
              {userRequestDtoStatus}
            </div>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={{ padding: "20px 18px 0px" }}>
          <Col span={22}>
            <Space className="booking-id">
              <img src={HashtagIcon} alt="bookingId" />
              <>{userRequest.bookingId}</>
            </Space>
          </Col>
          <Col span={2}>
            <Space className="booking-id">
              <Tooltip title="Copiar al porta papeles">
                <Button
                  type="ghost"
                  shape="circle"
                  icon={<PaperClipOutlined twoToneColor="#3b3c3c" />}
                  onClick={() => copyToClipBoard(userRequest)}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={{ padding: "20px 18px" }}>
          {isLote ? (
            <Col span={24}>
              <div className="info-trip-lote-card-container custom-text-card-trip">
                <Space className="user-name">
                  <img src={UserIcon} alt="usuario" />
                  {`${userRequest?.user?.firstName} ${userRequest?.user?.lastName}`}
                </Space>
                <Space className="mobile">
                  <img src={MobileIcon} alt="movil" />
                  {userRequest?.user?.mobile}
                </Space>
                <Space className="usuarios-lote">
                  <img src={UserIcon} alt="lote" />
                  {`${userRequest?.passengersData?.length} pasajeros`}
                </Space>
                <Space className="tiempo">
                  {/* <img src={TimeIcon} alt="tiempo" /> */}
                  {/* <>{`${userRequest?.time} `}</> */}
                </Space>
                <Space className="distancia">
                  <img src={KmIcon} alt="distancia" />
                  {userRequest?.distance}
                </Space>
              </div>
            </Col>
          ) : (
            <Col
              span={24}
              className="info-trip-card-container custom-text-card-trip"
            >
              <Row justify="space-between" className="booking-id">
                <Col>
                  <img src={HashtagIcon} alt="bookingId" />
                  {userRequest.bookingId}
                </Col>
                <Col>
                  {userRequest.status === TRIP_STATUS.Scheduled &&
                    !userRequest.provider && (
                      <Checkbox
                        onChange={(e) =>
                          onSelect(userRequest, e.target.checked)
                        }
                      />
                    )}
                </Col>
              </Row>
              <Space className="user-name">
                <img src={UserIcon} alt="usuario" />
                {userRequest.passengersData &&
                userRequest.passengersData.length > 0
                  ? userRequest.passengersData[0].name
                  : `${userRequest?.user?.firstName} ${userRequest?.user?.lastName}`}
              </Space>
              <Space className="mobile">
                <img src={MobileIcon} alt="movil" />
                {userRequest.passengersData &&
                userRequest.passengersData.length > 0
                  ? userRequest.passengersData[0].phone
                  : userRequest?.user?.mobile}
              </Space>
            </Col>
          )}
          <Col
            span={24}
            className="info-trip-card-container custom-text-card-trip"
          >
            {userRequest.admin && (
              <Space>
                <img src={CompanyIcon} alt="empresa" />
                <>
                  Empresa: <b>{userRequest.admin.name}</b>
                </>
              </Space>
            )}
            <Space>
              <img src={NoteIcon} alt="Nota" />
              <Paragraph
                editable={
                  userRequest.status === TRIP_STATUS.Scheduled && {
                    onChange: setEditableDescription,
                  }
                }
                style={{ marginBottom: 0 }}
              >
                {editableDescription}
              </Paragraph>
              {userRequest.status === TRIP_STATUS.Scheduled && (
                <Tooltip title="Guardar cambios">
                  <Button
                    size="small"
                    disabled={
                      editableDescription === DEFAULT_DESCRIPTION ||
                      editableDescription === userRequest.description
                    }
                    type="text"
                    icon={<SaveTwoTone twoToneColor="#52c41a" />}
                    onClick={saveDescription}
                  />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col span={24} className="info-trip-card-container">
            <div className="custom-text-card-trip" style={{ width: "100%" }}>
              <Space>
                <img src={OriginIcon} alt="origen" />
                <>{userRequest?.originAddress}</>
              </Space>
            </div>
            <div className="custom-text-card-trip" style={{ width: "100%" }}>
              <Space>
                <img src={DestinationIcon} alt="destino" />
                <>{userRequest?.destinationAddress}</>
              </Space>
            </div>
          </Col>
          <Col span={24}>
            <div className="custom-text-card-trip">
              <Space>
                <img src={CarIcon} alt="pago" />
                {userRequest?.serviceType?.name}
                {userRequest?.serviceType?.name?.includes("Urgente") ? (
                  <div className="urgent-package">!!</div>
                ) : null}
              </Space>
            </div>
          </Col>
          <Col span={24} className="info-trip-card-container">
            <div className="custom-text-card-trip">
              <Space>
                <img src={ProviderIcon} alt="conductor" />
                <>
                  {userRequest.provider
                    ? `${userRequest?.provider?.firstName} ${userRequest?.provider?.lastName}`
                    : "No hay un conductor asignado"}
                </>
              </Space>
            </div>
            <div className="custom-text-card-trip">
              <Space>
                <img src={Calendar} alt="car" />

                {userRequest.status === TRIP_STATUS.Scheduled && (
                  <DatePicker
                    style={{ width: "200px" }}
                    disabledDate={disabledDate}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    placeholder="Seleccionar fecha"
                    allowClear={false}
                    value={scheduleDate}
                    onChange={(value) => setScheduleDate(moment(value))}
                  />
                )}

                {userRequest.status === TRIP_STATUS.Scheduled && (
                  <Tooltip title="Guardar cambios">
                    <Button
                      size="small"
                      disabled={scheduleDate.isSame(
                        moment(userRequest.scheduleAt)
                      )}
                      type="text"
                      icon={<SaveTwoTone twoToneColor="#52c41a" />}
                      onClick={saveScheduleDate}
                    />
                  </Tooltip>
                )}

                {userRequest.status !== TRIP_STATUS.Scheduled &&
                  moment(
                    userRequest.scheduleAt ?? userRequest.createdAt
                  ).format("YYYY-MM-DD HH:mm:ss")}
              </Space>
            </div>
          </Col>

          <Col span={24} className="info-trip-card-container">
            {userRequest.departmentCode && (
              <div className="custom-text-card-trip">
                <Space>
                  <img src={DoorIcon} alt="conductor" />
                  <>
                    Departamento: <b>{userRequest.departmentCode}</b>
                  </>
                </Space>
              </div>
            )}
            {userRequest.flightNumber && (
              <div className="custom-text-card-trip">
                <Space>
                  <img src={PlaneIcon} alt="conductor" />
                  <>
                    Numero de Vuelo: <b>{userRequest.flightNumber}</b>
                  </>
                </Space>
              </div>
            )}
          </Col>
          <Col span={24} className="info-trip-card-container">
            {userRequest.reason && (
              <div className="custom-text-card-trip">
                <Space>
                  <img src={NoteIcon} alt="conductor" />
                  <>
                    Motivo: <b>{userRequest.reason}</b>
                  </>
                </Space>
              </div>
            )}
          </Col>
          <Col span={24} className="info-trip-card-container">
            {userRequest.responsible && (
              <div className="custom-text-card-trip">
                <Space>
                  <img src={ManagerIcon} alt="conductor" />
                  <>
                    Responsable: <b>{userRequest.responsible}</b>
                  </>
                </Space>
              </div>
            )}
          </Col>
          {userRequest.qrFee &&
            parseFloat(userRequest.qrFee.toString()) > 0 && (
              <Col>
                <div className="custom-text-card-trip">
                  <Space>
                    <Tag color="green">
                      Partner <img src={HotelIcon} alt="hotel" />
                    </Tag>
                  </Space>
                </div>
              </Col>
            )}

          {userRequest.country && userRequest.country.name !== "Uruguay" && (
            <Col>
              <div className="custom-text-card-trip">
                <Space>
                  <Tag color="magenta">Internacional</Tag>
                  <>País: {userRequest.country.name}</>
                </Space>
              </div>
            </Col>
          )}
          <Col span={24}>
            {userRequest.emptyTrip ? (
              <div className="custom-text-card-trip">
                <EmptyTripDetails emptyTrip={userRequest.emptyTrip} />
              </div>
            ) : (
              <CreateEmptyTrip userRequest={userRequest} />
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default UserRequestCard;
