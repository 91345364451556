import { MessageOutlined } from "@ant-design/icons";
import { Card, Space, Typography } from "antd";
import { useAppDispatch } from "../../../app/hooks";
import { useCallback } from "react";
import {
  setConversationOpen,
  setCurrentConversation,
} from "../../../features/notifications/conversationSlice";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;

interface Props {
  phone: string;
  messages: Array<any>;
}

const localTimeZone = dayjs.tz.guess();

const NotificationItem = ({ phone, messages }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const openConversation = useCallback(
    (messagesList: any) => {
      dispatch(setCurrentConversation(messagesList));
      dispatch(setConversationOpen(true));
    },
    [dispatch]
  );

  return (
    <Card
      hoverable
      style={{ width: "100%" }}
      bodyStyle={{ padding: 0 }}
      onClick={() => openConversation(messages)}
    >
      <div className="card-section-container-custom-cls">
        <div className="card-section-left-custom-cls">
          {/* <Space>
                  <UserOutlined />
                  <Text>Nombre del pasajero</Text>
                </Space> */}
          <Space>
            <MessageOutlined style={{ color: "green" }} />
            <Text>{phone}</Text>
          </Space>
        </div>
        <div className="card-section-rigth-custom-cls">
          <Text>
            {messages[messages.length - 1]?.create_at
              ? dayjs
                  .utc(messages[messages.length - 1].create_at)
                  .tz(localTimeZone)
                  .format("DD/MM/YYYY hh:mm A")
              : ""}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default NotificationItem;
