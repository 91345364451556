import { Marker } from "@react-google-maps/api";
import { dropOffIcon } from "../../../shared/constants/Images";

interface paramMerker {
  id: string;
  lat: number;
  lng: number;
  name: string;
  origin: boolean;
}

function Markers({ id, lat, lng, name, origin }: paramMerker) {
  return (
    <>
      <Marker
        key={id}
        position={{
          lat: lat,
          lng: lng,
        }}
        title={name}
        icon={origin ? undefined : dropOffIcon}
      />
    </>
  );
}

export default Markers;
