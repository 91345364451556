import axios from "axios";
import { PassengerGroupDTO } from "../shared/dtos/PassengerGroupDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_GROUP_TRIPS_MOCROSERVICE_API;

export const getGroupTripsPasseger = async ({ requestId }: any) => {
  console.log("Params ", requestId);

  const result = await axios.get(`${apiUrl}request/passeger/${requestId}`, {
    params: {
      //requestId,
    },
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const addGroupTripsPasseger = async (
  passegers: PassengerGroupDTO[],
  requestId: string
) => {
  const result = await axios.post(
    `${apiUrl}request/passeger/${requestId}`,
    {
      passeger: passegers,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const editGroupTripsPasseger = async (
  requestId: string,
  passeger: PassengerGroupDTO
) => {
  console.log("Request id :", requestId);

  const result = await axios.put(
    `${apiUrl}request/passeger/${requestId}`,
    passeger,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.data;
};

export const deleteGroupTripsPasseger = async (
  requestId: string,
  id: string
) => {
  const result = await axios.delete(`${apiUrl}request/passeger/${requestId}`, {
    data: { passeger: { id: id } },
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const validateDirecctionGroupTrip = async (
  requestId: string,
  directionId: string
) => {
  const result = await axios.put(
    `${apiUrl}request/validate-manual/${requestId}`,
    { directionId },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};
