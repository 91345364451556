import { Space, Button, Typography } from "antd";

const { Text } = Typography;
interface Props {
  name: string;
  phone: string;
  address: string;
}

const NotificationPassenger = ({
  name,
  phone,
  address,
}: Props): JSX.Element => {
  return (
    <div className="card-section-notification-passenger-cancel">
      <ul>
        <li style={{ listStyle: "inside" }}>
          <Space size="large">
            <span>{name}</span>
            <span>{phone}</span>
            <Text style={{ width: 170 }} ellipsis={{ tooltip: address }}>
              {address}
            </Text>
          </Space>
        </li>
      </ul>
      <Button className="btn-notification-passenger">Cancelar</Button>
    </div>
  );
};

export default NotificationPassenger;
