import { LoadingOutlined } from "@ant-design/icons";
import { Card, Space, Tooltip } from "antd";

interface Props {
  title?: string;
  value?: any;
  cornerBackground?: string;
  image?: string;
  cornerIcon?: any;
  showSubtitle?: boolean | false;
  showLastPeriod?: boolean | false;
  handleOnClick?: any;
  isLoading?: boolean | false;
  dateRange?: string;
}

const CustomCardAdminPanel = ({
  title,
  value,
  cornerBackground,
  cornerIcon,
  image,
  showSubtitle,
  showLastPeriod,
  handleOnClick,
  isLoading,
  dateRange
}: Props): JSX.Element => {
  return (
    <Card className="custom-card-statistics-admin-panel" style={{backgroundImage: `url(${image})`}}>
      <Space direction="vertical">
        <span className="total-trips">
          {title}
          {showSubtitle && (
            <span className="total-subtitle">
              {dateRange && `Periodo ${dateRange}`}
            </span>
          )}
        </span>{" "}
        <span className="total-cost">{value}</span>
        
      </Space>
      <div
        className="custom-icon-position-top-right"
        style={{ background: cornerBackground }}
      >
        <div className="custom-icon-position">{cornerIcon}</div>
      </div>
      {showSubtitle &&
        isLoading &&
        (showLastPeriod ? (
          <div className="arrow-icon-right" onClick={handleOnClick}>
            <LoadingOutlined />{" "}
          </div>
        ) : (
          <div className="arrow-icon" onClick={handleOnClick}>
            <LoadingOutlined />{" "}
          </div>
        ))}
      {showSubtitle &&
        !isLoading &&
        (showLastPeriod ? (
          <Tooltip placement="left" title={"Mostrar periodo actual"}>
            <div className="arrow-icon-right" onClick={handleOnClick}>
              {">"}
            </div>
          </Tooltip>
        ) : (
          <Tooltip placement="right" title={"Mostrar periodo anterior"}>
            <div className="arrow-icon" onClick={handleOnClick}>
              {"<"}
            </div>
          </Tooltip>
        ))}
    </Card>
  );
};

export default CustomCardAdminPanel;
