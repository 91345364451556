import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { useMemo, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  editGroupTripsRequestAsync,
  addGroupTripsRequestAsync,
  setSelectedRequest,
} from "../../../features/groupTrip/groupTripRequest/groupTripRequestSlide";
import DatePicker from "../../common/DatePicker";
import { listDays } from "./listDays";
import LabelSelectClient from "./labelSelectClient";
import { disabledDateBeforeToday } from "../../../utils/helpers/disabledDateBeforeToday";
import SelectClients from "./selectClients";

const dateFormat = "DD/MM/YYYY";

const config = {
  rules: [
    {
      type: "object" as const,
      //required: true,
      message: "hora salida!",
    },
  ],
};
const itemStyle = {
  padding: "0 5px 0 5px",
};

type CancelFunction = () => void;
interface Props {
  onCancel: CancelFunction;
}

const FormNewGroupRequest = ({ onCancel }: Props): JSX.Element => {
  const selectedRequest = useAppSelector(
    (state) => state.groupTripsRequest.selected
  );
  const [showField, setShowField] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setShowField(selectedRequest?.repeat || false);
  }, [selectedRequest?.repeat]);

  const onFinish = (values: any) => {
    // console.log("Received values of form:", values);
    const planning = values?.planning?.map((p: any) => {
      return {
        ...p,
        entryTime: p.entryTime ? p.entryTime.format("HH:mm") : null,
        departureTime: p.departureTime ? p.departureTime.format("HH:mm") : null,
      };
    });

    const newData = {
      ...values,
      planning,
      end_date: values.endDate ? values.endDate.format("YYYY-MM-DD") : null,
      repeat: typeof values.repeat !== "undefined" && values.repeat,
      client: values.client?.selected,
      start_date: values.startDate
        ? values.startDate.format("YYYY-MM-DD")
        : null,
    };
    if (selectedRequest) {
      newData.id = selectedRequest.id;
      newData.status = selectedRequest.status;
      dispatch(editGroupTripsRequestAsync(newData));
    } else {
      newData.status = "nuevo";
      dispatch(addGroupTripsRequestAsync(newData));
    }
    onCancel();
    dispatch(setSelectedRequest({}));
  };

  const initialValues = useMemo(() => {
    if (selectedRequest) {
      const { id, firstName, lastName, mobile } = selectedRequest.client;
      const tmp = {
        client: {
          label: (
            <LabelSelectClient
              firstName={firstName}
              lastName={lastName}
            />
          ),
          value: id,
          title: mobile,
          selected: selectedRequest.client,
        },
        name: selectedRequest.name,
        endDate: selectedRequest.endDate
          ? dayjs(selectedRequest.endDate, "YYYY-MM-DD")
          : null,
        startDate: selectedRequest.startDate
          ? dayjs(selectedRequest.startDate, "YYYY-MM-DD")
          : null,
        repeat: selectedRequest.repeat,
        service: selectedRequest.service?.toString(),
        region: selectedRequest.region,
      };
      const days = Array.isArray(selectedRequest.planning)
        ? selectedRequest.planning.map((r) => {
            const entryTime = r.entryTime ? dayjs(r.entryTime, "HH:mm") : null;
            const departureTime = r.departureTime
              ? dayjs(r.departureTime, "HH:mm")
              : null;
            return { day: r.day, entryTime, departureTime };
          })
        : undefined;

      return {
        initialValues: tmp,
        daysInitialValues: days,
      };
    }
    return {
      initialValues: { service: "26", region: "uy" },
    };
  }, [selectedRequest]);

  const handleOnCancel = () => {
    onCancel();
  };

  const onFieldsChange = (changedFields: any) => {
    if (changedFields[0]?.name[0] === "repeat") {
      setShowField(changedFields[0]?.value);
    }
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      onFieldsChange={onFieldsChange}
      initialValues={initialValues.initialValues}
    >
      <Row gutter={12}>
        <Col span={12} style={itemStyle}>
          <Form.Item
            name="client"
            label="Empresa"
            rules={[{ required: true, message: "Elija la empresa o cliente!" }]}
          >
            <SelectClients
              placeholder="Elija la empresa o cliente"
              style={{ minWidth: 152, height: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12} style={itemStyle}>
          <Form.Item
            name="name"
            label="Nombre"
            rules={[{ required: true, message: "Escriba un nombre!" }]}
          >
            <Input placeholder="Nombre de la planificación" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} style={itemStyle}>
          <Form.Item
            name="service"
            label="Tipo de servicio"
            rules={[{ required: true, message: "Elija el tipo de servicio!" }]}
          >
            <Select
              style={{ width: "100%" }}
              options={[
                {
                  value: "26",
                  label: "Viajes en lote",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12} style={itemStyle}>
          <Form.Item
            name="startDate"
            label="Fecha Inicio"
            rules={[{ required: true, message: "Elija la fecha de inicio!" }]}
          >
            <DatePicker
              //defaultValue={dayjs("2015/01/01", dateFormat)}
              placeholder="Seleccione"
              disabledDate={disabledDateBeforeToday}
              format={dateFormat}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={9}>Dias</Col>
        <Col span={7}>Entrada</Col>
        <Col span={8}>Salida</Col>
      </Row>
      <Form.List
        name="planning"
        initialValue={initialValues?.daysInitialValues}
        rules={[
          {
            validator: async (_, names) => {
              console.log("names", names);
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error("Debe adicionar al menos un día de la semana.")
                );
              } else if (
                names.some((n: any) => !n?.entryTime && !n?.departureTime)
              ) {
                return Promise.reject(
                  new Error(
                    "Cada día adicionado debe tener al menos una ahora de entrada o salida."
                  )
                );
              } else {
                const days = names.map((n: any) => n?.day);
                const deletedDuplicates = new Set(days).size;
                if (deletedDuplicates < days.length) {
                  return Promise.reject(
                    new Error(
                      "Verifique que no ha seleccionado el mismo día más de una vez."
                    )
                  );
                }
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "day"]}
                    rules={[{ required: true, message: "Seleccione el dia" }]}
                  >
                    <Select placeholder="Días" style={{ width: "160px" }}>
                      {listDays?.map((d) => (
                        <Select.Option key={d?.id.toString()} value={d?.value}>
                          {d?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={[name, "entryTime"]} label="" {...config}>
                    <TimePicker
                      placeholder="Entrada"
                      showNow={false}
                      format="HH:mm"
                      //defaultValue={dayjs("13:30:56", "HH:mm:ss")}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, "departureTime"]}
                    label=""
                    {...config}
                  >
                    <TimePicker
                      placeholder="Salida"
                      showNow={false}
                      format="HH:mm"
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Adicionar
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Row>
        <Col span={24} style={itemStyle}>
          <Form.Item
            name="region"
            label="Región"
            // rules={[
            //   { required: true, message: "Seleccione la región del servicio!" },
            // ]}
          >
            <Select
              style={{ width: "100%" }}
              options={[
                {
                  value: "uy",
                  label: "Uruguay",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12} style={itemStyle}>
          <Form.Item name="repeat" label="¿Se repite?" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12} style={itemStyle}>
          {showField && (
            <Form.Item name="endDate" label="Fecha de finalización">
              <DatePicker
                placeholder="Indefinido"
                format={dateFormat}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Form.Item>
        <Row style={{ paddingTop: 20 }}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Aceptar
              </Button>
              <Button type="primary" onClick={handleOnCancel}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default FormNewGroupRequest;
