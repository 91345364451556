import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAvailableProviderList } from "../../services/providerApi";
import coordinatesDTO from "../../shared/dtos/coordinatesDTO";
import ProviderDTO from "../../shared/dtos/providerDTO";
import { ListState } from "../../shared/states/listState";

const initialState: ListState<ProviderDTO> = {
  value: new Array<ProviderDTO>(),
  status: "idle",
};

export const activeProviderListAsync = createAsyncThunk(
  "providers/active",
  async (userRequestId: number | null | undefined) => {
    const response = await getAvailableProviderList(userRequestId);

    if (response.length > 0) {
      const formattedResponse = [...response].reduce(
        (result: any, provider: any) => {
          const providerId = provider?.id;

          if (providerId) {
            const exist = result[providerId];
            if (exist) {
              const services = exist?.services || [];
              return {
                ...result,
                [providerId]: {
                  ...exist,
                  services: [
                    ...services,
                    {
                      serviceModel: provider.serviceModel,
                      serviceType: provider.serviceType,
                    },
                  ],
                },
              };
            }
            const coordinates: coordinatesDTO = {
              lat: Number(provider.latitude),
              lng: Number(provider.longitude),
            };
            return {
              ...result,
              [providerId]: {
                id: provider.id,
                name: `${provider.firstName} ${provider.lastName}`,
                coordinates: coordinates,
                phone: `${provider.mobile}`,
                rating: provider.rating,
                service: {
                  serviceModel: provider.serviceModel,
                  serviceType: provider.serviceType,
                },
                services: [
                  {
                    serviceModel: provider.serviceModel,
                    serviceType: provider.serviceType,
                  },
                ],
              },
            };
          }
          return result;
        },
        {}
      );

      return Object.values(formattedResponse);
    } else {
      return response;
    }
  }
);

export const activeProviderListSlice = createSlice({
  name: "activeProviderList",
  initialState,
  reducers: {
    updateActiveProviderListValue: (state) => {
      state.value = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activeProviderListAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(activeProviderListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = state.value.concat(action.payload);
      })
      .addCase(activeProviderListAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { updateActiveProviderListValue } =
  activeProviderListSlice.actions;

export default activeProviderListSlice.reducer;
