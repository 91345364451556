import { notification } from "antd";
import axios from "axios";
import DefaultPaginationProps from "../propInterfaces/defaultPaginationProps";
import UpdatePartnerPriceTableProps from "../propInterfaces/updatePartnerPriceTableProps";
import PutAdminProps from "../propInterfaces/putAdminProps";
import NewAdminDTO from "../shared/dtos/newAdminDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import { HandleLoginHelper } from "../utils/helpers/verifyLoginHelper";
import { currentAdminAsync, removeCurrentAdminValue } from "../features/admin/currentAdminSlice";
import { store } from "../app/store";
import { addNewAuth, removeAuth } from "../features/auth/authSlice";

const apiUrl = process.env.REACT_APP_API_URL;

export const getAdmins = async ({
  page,
  take,
  searchText,
}: DefaultPaginationProps) => {
  const result = await axios.post(
    `${apiUrl}admins/orderby/type?page=${page}&take=${take}`,
    {
      searchText,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const postAdmin = async (newAdminDTO: NewAdminDTO) => {
  const result = await axios
    .post(`${apiUrl}admins`, newAdminDTO, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then((response: any) => {
      notification.success({
        message: `El administrador`,
        description: `se ha creado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });
  return result.data;
};

export const deleteAdmin = async (id: number) => {
  const result = await axios
    .delete(`${apiUrl}admins/${id}`, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then((response: any) => {
      notification.success({
        message: `El administrador ${id}`,
        description: `se ha eliminado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });
  return result.data;
};

export const getAdmin = async (id: number, byToken: boolean) => {
  const result = await axios.get(
    `${apiUrl}admins/${byToken ? "by/token" : id}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const putAdmin = async ({ id, newAdminDTO }: PutAdminProps) => {
  const result = await axios
    .put(`${apiUrl}admins/${id}`, newAdminDTO, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then((response: any) => {
      notification.success({
        message: `El administrador ${id}`,
        description: `se ha editado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });
  return result.data;
};

export const updatePartnerPriceTable = async ({
  id,
  formData,
}: UpdatePartnerPriceTableProps) => {
  const result = await axios
    .put(`${apiUrl}admins/${id}/pricetable`, formData, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then((response: any) => {
      notification.success({
        message: `El administrador ${id}`,
        description: `se ha editado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });
  return result.data;
};

export const patchAdminPassword = async (id: number, newPassword: string) => {
  const result = await axios
    .patch(
      `${apiUrl}admins/${id}/password`,
      { newPassword: newPassword },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then((response: any) => {
      notification.success({
        message: `La contraseña del administrador ${id}`,
        description: `se ha cambiado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });
  return result.data;
};

export const getAdminsList = async ({
  page,
  take,
  filters,
}: DefaultPaginationProps) => {
  const result = await axios.get(`${apiUrl}admins`, {
    params: { page, take, filters: JSON.stringify(filters) },
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const getAdminPaginatedDocuments = async ({
  page,
  take,
  searchText,
  startDate,
  endDate,
}: DefaultPaginationProps) => {
  const result = await axios.post(
    `${apiUrl}admin/trip/report/admin/document?page=${page}&take=${take}`,
    {
      search: searchText,
      startDate,
      endDate,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const getAdminEmails = async ({
  startDate,
  endDate,
}: DefaultPaginationProps) => {
  const result = await axios.post(
    `${apiUrl}admins/emails`,
    {
      startDate: startDate,
      endDate: endDate,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const getAdminExcel = async (
  search: string,
  startDate: Date,
  endDate: Date
) => {
  const result = await axios.post(
    `${apiUrl}user-request/report/statement/excel/admin`,
    {
      search,
      startDate,
      endDate,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const updateAdvancedFlag = async (adminId: number, active: boolean) => {
  const result = await axios.post(
    `${apiUrl}admins/${adminId}/advanced`,
    {
      active,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const updateAdminPartner = async (id: number, access: boolean) => {
  return await axios.patch(
    `${apiUrl}admins/${id}/partner`,
    { access },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const updateAdminPartnerFee = async (partnerFee: number) => {
  return await axios
    .patch(
      `${apiUrl}admins/partner/fee`,
      { partnerFee },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then((response: any) => {
      notification.success({
        message: `La comision de partner`,
        description: `se ha actualizado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });
};

export const getAllAdmins = async () => {
  const result = await axios.get(`${apiUrl}admins/all`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const updateAdminWallet = async (id: number, access: boolean) => {
  return await axios.patch(
    `${apiUrl}admins/${id}/wallet`,
    { access },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const getAdminsExcel = async () => {
  const result = await axios.get(
    `${apiUrl}admins/information/excel`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.file;
};

export const getAdminInformation = async () => {
  const result = await axios.get(
    `${apiUrl}admins/information`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const sendWhatsappToPassenger = async (data: Array<any>, notificationTypeId: number) => {
  const result = await axios.post(
    `${apiUrl}admins/notify/passenger`,
    {
      data,
      notificationTypeId
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const updateIsManualEntryAuthorizedFlag = async (id: number, flag: boolean) => {
  return await axios.patch(
    `${apiUrl}admins/${id}/ismanualentryauthorized`,
    { flag },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const updateSendWhatsappMessageFlag = async (id: number, flag: boolean) => {
  return await axios.patch(
    `${apiUrl}admins/${id}/sendwhatsappmessage`,
    { flag },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const getAdminsForSelect = async () => {
  const result = await axios.get(
    `${apiUrl}admins/panel/select`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const impersonateAdminLogin = async (adminId: number) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios
    .get(`${apiURL}admins/${adminId}/impersonate`,
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      })
    .then(async (response: any) => {
      notification.success({
        message: "Autenticacion exitosa",
        description: "",
        placement: "bottom",
      });
      const { token } = response.data;
      console.log(token);
      const validLogin = HandleLoginHelper(token);

      if (validLogin) {
        store.dispatch(removeAuth());
        store.dispatch(removeCurrentAdminValue());
        store.dispatch(addNewAuth(response.data));
        await store.dispatch(currentAdminAsync(token));
        return response;
      }

      return response.data;
    })
    .catch((error: any) => {
      notification.error({
        message: `Error ${error.response.status}`,
        description:
          error.response.status === 401
            ? "El usuario o contraseña son incorrectos"
            : "Error en el servidor",
        placement: "bottom",
      });
    });

  return result;
};