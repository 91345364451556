/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, notification } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import {
  activeProviderListAsync,
  updateActiveProviderListValue,
} from "../../../features/provider/activeProviderListSlice";
import { assignProviderToUserRequestAsync } from "../../../features/userRequest/assingProviderToUserRequestSlice";
import { addProviderFromLocalUserRequestValue } from "../../../features/userRequest/userRequestListSlice";
import { filterByName } from "../../../shared/components/filterByName";
import ProviderDTO from "../../../shared/dtos/providerDTO";
import UserRequestDto from "../../../shared/dtos/userRequestDTO";
import getDestinationsByUserRequestHelper from "../../../utils/helpers/getDestinationsByUserRequestHelper";
import { DispatcherMap } from "../../map/Map";
import "./styles.scss";
import { ProviderCard } from "./providerCard";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { assignProviderToUserRequests } from "../../../services/dispatcherApi";

export const AssingProvider = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectedProvider, setSelectedProvider] =
    useState<ProviderDTO | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [providerName, setProviderName] = useState("");
  const [providerList, setProviderList] = useState<
    Array<ProviderDTO> | null | undefined
  >({} as Array<ProviderDTO>);

  const dispatch: any = useDispatch<AppDispatch>();
  const activeProviderListValue: Array<ProviderDTO> = useSelector(
    (state: RootState) => state.activeProviderList.value
  );
  const pendingUserRequestValue: UserRequestDto[] = useSelector(
    (state: RootState) => state.pendingUserRequest.value
  );

  useEffect(() => {
    dispatch(updateActiveProviderListValue());
  }, [dispatch]);

  useEffect(() => {
    dispatch(activeProviderListAsync(pendingUserRequestValue[0]?.id)).then(
      () => {
        setProviderList(activeProviderListValue);
        setIsLoading(false);
      }
    );
  }, [dispatch]);

  const onChange = async (providerName: string) => {
    setProviderName(providerName);
    setProviderList(filterByName(providerName, activeProviderListValue));
  };

  const handleSelectedProvider = useCallback(
    async (provider?: ProviderDTO) => {
      if (provider) {
        setSelectedProvider(provider);
      }

      if (
        !selectedProvider ||
        !pendingUserRequestValue ||
        selectedProvider.id === 0
      )
        return null;

      if (state?.package === true && selectedProvider.id) {
        try {
          await assignProviderToUserRequests({
            userRequests: pendingUserRequestValue,
            providerId: selectedProvider.id,
          });

          navigate(WebRoutes.TripListRoute, {
            replace: true,
          });
        } catch (error) {
          notification.error({
            message:
              "Ha ocurrido un error al asignar el conductor a los viajes seleccionados.",
            placement: "bottom",
          });
        }
      } else {
        Promise.all(
          pendingUserRequestValue.map(async (p) => {
            return await dispatch(
              assignProviderToUserRequestAsync({
                userRequestId: p.id,
                providerId: selectedProvider.id,
              })
            ).then(() => {
              dispatch(
                addProviderFromLocalUserRequestValue({
                  userRequestId: p.id,
                  selectedProvider,
                })
              );
            });
          })
        );

        navigate(WebRoutes.TripListRoute, {
          replace: true,
        });
      }
    },
    [dispatch, navigate, pendingUserRequestValue, selectedProvider]
  );

  const handleSelectedProviderFromMap = (provider: ProviderDTO | null) => {
    setSelectedProvider(provider);
  };

  const map = useMemo(() => {
    return pendingUserRequestValue ? (
      <DispatcherMap
        destinations={getDestinationsByUserRequestHelper(
          pendingUserRequestValue[pendingUserRequestValue.length - 1]
        )}
        showProviders={true}
        providers={activeProviderListValue}
        assignProvider={handleSelectedProvider}
        selectedProvider={selectedProvider}
        setProvider={handleSelectedProviderFromMap}
      />
    ) : null;
  }, [
    pendingUserRequestValue,
    activeProviderListValue,
    handleSelectedProvider,
    selectedProvider,
  ]);

  return (
    <>
      <Header className="site-layout-sub-header-background">
        Listado de conductores
      </Header>
      <Content
        style={{
          margin: "16px",
        }}
      >
        <Row>
          <Col className="input-container" id="provider">
            <Input
              value={providerName}
              placeholder="Buscar por conductor"
              onChange={(e: any) => {
                onChange(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col flex="350px">
            <Col className="card-container">
              {activeProviderListValue.length === 0 ? (
                isLoading === true ? (
                  <LoadingOutlined className="loading" />
                ) : (
                  <div className="empty-table">
                    <ExclamationCircleOutlined id="icon" />
                    <p>No hay datos</p>
                  </div>
                )
              ) : (
                (providerList && providerName
                  ? providerList
                  : activeProviderListValue
                ).map((provider: ProviderDTO) => {
                  return (
                    <Col
                      key={provider.id}
                      onClick={() => setSelectedProvider(provider)}
                    >
                      <ProviderCard
                        provider={provider}
                        selectedProviderId={selectedProvider?.id}
                      />
                    </Col>
                  );
                })
              )}
            </Col>
            <Button onClick={() => handleSelectedProvider()}>
              Asignar proveedor
            </Button>
          </Col>
          <Col flex="auto">{map}</Col>
        </Row>
      </Content>
    </>
  );
};

export default AssingProvider;
