const USER_TYPES = {
  USUARIO: "USUARIO",
  MANAGER: "MANAGER",
};

const TYPES_ID = {
  USUARIO: 1,
  MANAGER: 2,
};

export { USER_TYPES, TYPES_ID };
