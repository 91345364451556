import axios from "axios";
import ProviderAdminDTO from "../shared/dtos/providerAdminDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

interface PaginatedProviderProps {
  page: number;
  take: number;
  filters?: string;
}

export const getPaginatedProviders = async ({
  page,
  take,
  filters,
}: PaginatedProviderProps) => {
  const result = await axios.get(
    `${apiUrl}admin/providers?page=${page}&take=${take}${
      filters ? `&filters=${filters}` : ""
    }`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const createProvider = async (provider: ProviderAdminDTO) => {
  const result = await axios.post(`${apiUrl}admin/providers`, provider, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result.data;
};

export const updateProvider = async (
  id: number,
  provider: Partial<ProviderAdminDTO>
) => {
  const result = await axios.post(`${apiUrl}admin/providers/${id}`, provider, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result.data;
};

export const getProviderById = async (id: number) => {
  const result = await axios.get(`${apiUrl}admin/providers/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const approveProvider = async (id: number) => {
  const result = await axios.post(
    `${apiUrl}admin/providers/approve/${id}`,
    null,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const disapproveProvider = async (id: number) => {
  const result = await axios.post(
    `${apiUrl}admin/providers/disapprove/${id}`,
    null,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const getProvidersExcel = async () => {
  const result = await axios.get(
    `${apiUrl}admin/providers/information/excel`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.file;
};

export const patchIsAdminProvider = async (id:number, isAdminProvider: boolean) => {
  const result = await axios.patch(
    `${apiUrl}admin/providers/isAdminProvider/${id}`,
    { isAdminProvider },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const patchIsBlackListed = async (id:number, flag: boolean) => {
  const result = await axios.patch(
    `${apiUrl}admin/providers/${id}/blacklist`,
    { flag },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};