import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { columns, DataType } from "./columns";
import {
  getPaginatedPrices,
  updatePriceBetweenRegions,
} from "../../../services/priceApi";

import "../../../shared/styles/list.scss";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

const { Text } = Typography;
const { Search } = Input;

const PriceList = () => {
  const title = "Administración de precios";
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [ignoreFetch, setIgnoreFetch] = useState(false);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    const { data, total } = await getPaginatedPrices({
      page: tableParams.pagination?.current || 1,
      take: tableParams.pagination?.pageSize || 10,
      name: search,
    });

    setData(data);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
      },
    });
    setIgnoreFetch(true);
  };

  useEffect(() => {
    if (!ignoreFetch) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams), search]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    setIgnoreFetch(false);
  };

  const onFinish = async (values: any) => {
    const { priceId, price, driverPrice, priority } = values;
    setLoading(true);
    const updatePriceBetweenRegionsData = {
      id: priceId,
      price: price,
      driverPrice: driverPrice,
      priority: priority,
    };

    await updatePriceBetweenRegions(updatePriceBetweenRegionsData);
    await fetchData();
    setLoading(false);
    setOpenModal(!openModal);
  };

  const handleClick = (price: any) => {
    form.setFieldValue("price", price.price);
    form.setFieldValue("driverPrice", price.driverPrice);
    form.setFieldValue("priceId", price.id);
    form.setFieldValue("priority", price.priority);
    setOpenModal(true);
  };

  const onSearch = async (value: string) => {
    setIgnoreFetch(false);
    setSearch(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row">
          <Col className="input-container-table">
            <Button
              onClick={() =>
                navigate(WebRoutes.CreatePriceRoute, {
                  replace: true,
                })
              }
            >
              Nuevo Precio
            </Button>
          </Col>

          <Space>
            <Text strong style={{ marginLeft: 20 }}>
              Buscar
            </Text>
            <Search
              placeholder="Nombre de la region"
              allowClear
              onSearch={onSearch}
            />
          </Space>
        </Row>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={columns(navigate, handleClick)}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Row>
        <Modal
          open={openModal}
          title="Modificar precios"
          footer={null}
          onCancel={() => setOpenModal(!openModal)}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item label="Id" name="priceId">
              <InputNumber<number>
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                min={1}
                disabled
              />
            </Form.Item>

            <Form.Item label="Precio" name="price">
              <InputNumber<number>
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                min={1}
              />
            </Form.Item>

            <Form.Item label="Precio conductor" name="driverPrice">
              <InputNumber<number>
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                min={1}
              />
            </Form.Item>

            <Form.Item
              label="Prioridad"
              name="priority"
              rules={[
                { required: true, message: "Por favor ingresa una prioridad!" }
              ]}
            >
              <InputNumber<number> />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Space direction="horizontal">
                <Button onClick={() => setOpenModal(!openModal)}>
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Guardar
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </Layout>
  );
};

export default PriceList;
