import { Col, Row, Table, Tooltip, Checkbox } from "antd";
import ContainerLayout from "../../common/containerLayout";
import { useState, useEffect } from "react";
import { formattedAddress } from "./utils";
import { validateAddress } from "../../../services/googlemapApi";
import ModalValidate from "./modalValidate";
import { MultipleLoadAddressDTO } from "../../../shared/dtos/multipleLoadDTO";
import FormMultipleLoad from "./form";

import "./styles.css";

const { Column } = Table;

interface T {
  [key: string]: any;
}

const address: T = {};

const formatted = (data: Array<any>) => {
  return Promise.all(
    data.map(async (i) => {
      const passenger = { ...i };
      const validatedOrigin = address[i.origin]
        ? address[i.origin]
        : await validateAddress(i.origin);

      if (validatedOrigin) {
        const origin: MultipleLoadAddressDTO = formattedAddress(
          i.origin,
          validatedOrigin
        );
        passenger.origin = origin;
        if (!address[i.origin]) {
          address[i.origin] = validatedOrigin;
        }
      } else {
        passenger.origin = {
          name: i.origin,
        };
      }

      const validatedDestiny = address[i.destiny]
        ? address[i.destiny]
        : await validateAddress(i.destiny);

      if (validatedDestiny) {
        const destiny: MultipleLoadAddressDTO = formattedAddress(
          i.destiny,
          validatedDestiny
        );
        passenger.destiny = destiny;
        if (!address[i.destiny]) {
          address[i.destiny] = validatedDestiny;
        }
      } else {
        passenger.destiny = {
          name: i.destiny,
        };
      }
      return passenger;
    })
  );
};

const MultipleLoad = (): JSX.Element => {
  const [data, setData] = useState<Array<any>>([]);
  const [formattedData, setFormattedData] = useState<Array<any>>([]);
  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
  const [updatedPassengerAddress, setUpdatePassengerAddress] =
    useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (updatedPassengerAddress) {
      if (updatedPassengerAddress.origin?.address) {
        address[updatedPassengerAddress.origin?.name] =
          updatedPassengerAddress.origin;
      }
      if (updatedPassengerAddress.destiny?.address) {
        address[updatedPassengerAddress.destiny?.name] =
          updatedPassengerAddress.destiny;
      }
      const updateData = formattedData.map((i) => {
        if (i.id === updatedPassengerAddress.id) {
          return updatedPassengerAddress;
        }
        if (i.origin?.name === address[i.origin?.name]?.name) {
          const origin = address[i.origin?.name];
          const tmp = { ...i };
          tmp.origin = { ...tmp.origin, ...origin };
          return tmp;
        }
        if (i.destiny?.name === address[i.destiny?.name]?.name) {
          const destiny = address[i.destiny?.name];
          const tmp = { ...i };
          tmp.destiny = { ...tmp.destiny, ...destiny };
          return tmp;
        }
        return i;
      });
      setFormattedData(updateData);
      setUpdatePassengerAddress(undefined);
    }
  }, [updatedPassengerAddress, formattedData]);

  useEffect(() => {
    if (data.length > 0) {
      setLoading(true);
      formatted(data).then((resp) => {
        setFormattedData(resp);
        setLoading(false);
      });
    }
  }, [data]);

  const handleClickCheckbox = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((value) => value !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  return (
    <ContainerLayout title="Carga múltiple" showNotification={false}>
      <Row
        style={{
          padding: "20px 0px 0px 0px",
        }}
      >
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <>
              <FormMultipleLoad
                setData={setData}
                formattedData={formattedData}
                setFormattedData={setFormattedData}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            </>
          </div>
        </Col>
        <Col span={24}>
          <Table
            dataSource={formattedData}
            rowKey="id"
            scroll={{ y: "calc(100vh - 250px)" }}
            loading={loading}
            pagination={{ hideOnSinglePage: true }}
          >
            <Column
              title=""
              width={80}
              dataIndex="id"
              render={(id) => {
                const selected = selectedIds.includes(id);
                return (
                  <Row>
                    <Col span={12}>
                      <Checkbox
                        value={id}
                        checked={selected}
                        className="customcheckbox"
                        onChange={() => handleClickCheckbox(id)}
                      />
                    </Col>
                    <Col span={12}>
                      {selected && <label>{selectedIds.indexOf(id) + 1}</label>}
                    </Col>
                  </Row>
                );
              }}
            />
            <Column title="Nombre" width={150} dataIndex="firstName" />
            <Column title="Apellido" width={150} dataIndex="lastName" />
            <Column title="Contacto" width={150} dataIndex="contact" />
            <Column
              title="Origen"
              render={(item) => {
                if (item?.origin?.address) {
                  return (
                    <span style={{ fontWeight: "bold" }}>
                      <Tooltip title={item?.origin?.address}>
                        {item?.origin?.name}
                      </Tooltip>
                    </span>
                  );
                } else {
                  return (
                    <>
                      <Row>
                        <Col span={24}>
                          <ModalValidate
                            type="origin"
                            passengerData={item}
                            setUpdatePassengerAddress={
                              setUpdatePassengerAddress
                            }
                          />
                        </Col>
                        <Col span={24} style={{ color: "red" }}>
                          {item?.origin?.name}
                        </Col>
                      </Row>
                    </>
                  );
                }
              }}
            />
            <Column
              title="Destino"
              render={(item) => {
                if (item?.destiny?.address) {
                  return (
                    <span style={{ fontWeight: "bold" }}>
                      <Tooltip title={item?.destiny?.address}>
                        {item?.destiny?.name}
                      </Tooltip>
                    </span>
                  );
                } else {
                  return (
                    <>
                      <Row>
                        <Col span={24}>
                          <ModalValidate
                            type="destiny"
                            passengerData={item}
                            setUpdatePassengerAddress={
                              setUpdatePassengerAddress
                            }
                          />
                        </Col>
                        <Col span={24} style={{ color: "red" }}>
                          {item?.destiny?.name}
                        </Col>
                      </Row>
                    </>
                  );
                }
              }}
            />
            <Column title="L" width={73} dataIndex="monday" />
            <Column title="M" width={73} dataIndex="tuesday" />
            <Column title="M" width={73} dataIndex="wednesday" />
            <Column title="J" width={73} dataIndex="thursday" />
            <Column title="V" width={73} dataIndex="friday" />
            <Column title="S" width={73} dataIndex="saturday" />
            <Column title="D" width={73} dataIndex="sunday" />
          </Table>
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default MultipleLoad;
