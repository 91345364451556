import axios from "axios";
import dayjs from "dayjs";
import { CheckPoint } from "../shared/dtos/groupTripDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_GROUP_TRIPS_MOCROSERVICE_API;

export const noptificationTripGroup = async (data: any) => {
  const result = await axios.post(`${apiUrl}whatsapp/notification`, data, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const getNotificationTripApi = async () => {
  const result = await axios.get(`${apiUrl}whatsapp`, {
    params: { start: 0, limit: 1000 },
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const updateNotificationStatusById = async (
  id: string,
  status: string
) => {
  const result = await axios.put(
    `${apiUrl}whatsapp/updateStatusById`,
    { id, status },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const sendNotificationMessage = async (
  cellphone: string,
  message: string
) => {
  const result = await axios.post(
    `${apiUrl}whatsapp/sendMessage`,
    { cellphone, message },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

/**
 * Send notification to group trip passengers
 */

type Trip = string | number;
export const sendNotificationTripPassengeres = async (
  checkPoints: Array<CheckPoint>,
  trip: Trip,
  date: string
) => {
  const passengers = checkPoints
    .map((cp) => {
      return cp.passengers.map((p: any) => {
        return {
          passenger: p.name,
          cellphone: p.cellphone,
          address: cp.address?.address,
          hour: cp.checkIn,
          date: dayjs(date, "YYYY-MM-DD").format("DD-MM-YYYY"),
          trip,
          getOn: p.getOn,
        };
      });
    })
    .flat()
    .filter((p) => p.getOn);

  return await Promise.all(
    passengers.map(async (passenger) => {
      const tmp = { ...passenger };
      delete tmp.getOn;
      const message = await noptificationTripGroup(tmp);
      return message;
    })
  );
};
