import jwt_decode from "jwt-decode";
import AuthSchemaDTO from "../../shared/dtos/authSchemaDTO";

export const HandleLoginHelper = (token: string) => {
  if (token) {
    const { exp = 0 } = jwt_decode<Partial<AuthSchemaDTO>>(token);
    return exp ? exp * 1000 > new Date().getTime() : false;
  }
  return false;
};
