import { CountryDTO } from "../../../shared/dtos/countryDTO";
import countries from "./countries.json";

interface Indexed {
  [key: string]: CountryDTO;
}

export const countriesList: CountryDTO[] = countries;

export const indexedCountriesByName = countries.reduce(
  (result: Indexed, el: CountryDTO) => {
    const country = el.country;
    if (result[`${country}`]) return result;
    return { ...result, [`${country}`]: el };
  },
  {}
);
