import { Col, Row } from "antd";
import GroupTripsMap from "../groupTripsMap/groupTripsMap";
import AdminTrip from "./adminTrip";
import ContainerLayout from "../../common/containerLayout";

const TripsGroup = (): JSX.Element => {
  return (
    <ContainerLayout title="Viajes en grupo">
      <Row style={{ paddingTop: 31 }}>
        <Col xs={24} md={16} xl={15}>
          <AdminTrip />
        </Col>
        <Col xs={24} md={8} xl={9}>
          <GroupTripsMap />
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default TripsGroup;
