import { Button, Form, Input, notification, Select } from "antd";
import { useEffect, useState } from "react";
import {
  associateServiceToProvider,
  updateProviderService,
} from "../../../../services/providerService";
import { getServiceTypeList } from "../../../../services/serviceTypeApi";
import { DataType } from "../list";
import { ProviderServiceStatus } from "../../../../shared/constants/ProviderServiceStatus";
import {
  ProviderServiceStatusDescription,
  ServiceModelDescription,
  ServiceNumberDescription,
  ServiceTypeIdDescription,
} from "../../../../shared/constants/Resources";

interface ServiceType {
  id: number;
  name: string;
}

interface DriverServiceFormProps {
  driverId: number;
  onSuccess: () => void;
  providerService?: DataType | undefined;
}

const DriverServiceForm = ({
  driverId,
  onSuccess: onSucess,
  providerService,
}: DriverServiceFormProps) => {
  const [serviceTypes, setServiceTypes] = useState([]);
  const [status, setStatus] = useState([] as any);
  const [loadingServiceTypes, setLoadingServiceTypes] = useState(false);
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();

  const getServiceTypesData = async () => {
    setLoadingServiceTypes(true);
    const data = await getServiceTypeList();
    setServiceTypes(
      data.map((st: ServiceType) => ({ value: st.id, label: st.name }))
    );
    setStatus(ProviderServiceStatus);
    setLoadingServiceTypes(false);
  };

  useEffect(() => {
    getServiceTypesData();
  }, []);

  const onFinish = async (values: any) => {
    const { serviceTypeId, serviceNumber, serviceModel } = values;

    try {
      setSaving(true);
      if (providerService) {
        const { status } = values;
        await updateProviderService({
          id: providerService.id,
          providerId: driverId,
          serviceTypeId,
          serviceNumber,
          serviceModel,
          status,
        });
      } else {
        await associateServiceToProvider({
          providerId: driverId,
          serviceTypeId,
          serviceNumber,
          serviceModel,
        });
      }

      notification.success({
        message: `Servicio ${
          providerService ? "actualizado" : "creado"
        } correctamente!`,
        placement: "bottom",
      });

      form.resetFields();

      setSaving(false);

      onSucess();
    } catch (error) {
      notification.error({
        message: `Ha ocurrido un error al ${
          providerService ? "actualizar" : "asociar"
        } el servicio.`,
        placement: "bottom",
      });
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      fields={
        providerService
          ? [
              {
                name: [ServiceTypeIdDescription],
                value: providerService?.serviceTypeId,
              },
              {
                name: [ServiceNumberDescription],
                value: providerService?.serviceNumber,
              },
              {
                name: [ServiceModelDescription],
                value: providerService?.serviceModel,
              },
              {
                name: [ProviderServiceStatusDescription],
                value: providerService?.status,
              },
            ]
          : []
      }
    >
      <Form.Item
        label="Tipo de servicio"
        name={ServiceTypeIdDescription}
        rules={[
          {
            required: true,
            message: "Por favor seleccione un tipo de servicio!",
          },
        ]}
      >
        <Select
          disabled={providerService !== undefined}
          loading={loadingServiceTypes}
          options={serviceTypes}
        />
      </Form.Item>

      <Form.Item
        label="Número de servicio"
        name={ServiceNumberDescription}
        rules={[{ required: true, message: "Por favor ingresa un número!" }]}
      >
        <Input placeholder="Ej: CY98769" />
      </Form.Item>

      <Form.Item
        label="Modelo"
        name="serviceModel"
        rules={[{ required: true, message: "Por favor ingresa un modelo!" }]}
      >
        <Input placeholder="Ej: Audi A8" />
      </Form.Item>

      {providerService ? (
        <Form.Item
          label="Status del servicio"
          name="status"
          rules={[
            {
              required: true,
              message: "Por favor seleccione un status!",
            },
          ]}
        >
          <Select loading={loadingServiceTypes} options={status} />
        </Form.Item>
      ) : null}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loadingServiceTypes}
          loading={saving}
        >
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DriverServiceForm;
