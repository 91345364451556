import axios from "axios";
import { DirectionDTO } from "../shared/dtos/PassengerGroupDTO";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_GROUP_TRIPS_MOCROSERVICE_API;

export const getGroupTripsDirection = async ({ filter }: any) => {
  const result = await axios.get(`${apiUrl}direction`, {
    params: filter,
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  console.log("Obtener direcciones ... ", result);

  return result.data.data;
};

export const addGroupTripsDirection = async (direction: DirectionDTO) => {
  console.log("here");
  const result = await axios.post(`${apiUrl}direction`, direction, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const editGroupTripsDirection = async (direction: DirectionDTO) => {
  const result = await axios.put(
    `${apiUrl}direction/${direction.id}`,
    direction,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.data;
};

export const deleteGroupTripsDirection = async (id: string) => {
  const result = await axios.delete(`${apiUrl}direction/${id}`);
  return result.data.data;
};
