import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { getNotificationTripApi } from "../../services/notificationWhatsappApi";
import { message } from "antd";
import { groupByProperty } from "../../utils/helpers/groupByProperty";
import { getGroupTrips } from "../../services/groupTripsApi";

interface Initial {
  notifications: any;
  loadingNotifications: boolean;
  tripsInNotifications: any;
}

const initialState: Initial = {
  notifications: [],
  loadingNotifications: false,
  tripsInNotifications: {},
};

const notificationTripSlice = createSlice({
  name: "notificationTrip",
  initialState,
  reducers: {
    setNotification: (state, actions) => {
      state.notifications = actions.payload;
    },
    setLoadingNotifications: (state, actions) => {
      state.loadingNotifications = actions.payload;
    },
    setTripInNotifications: (state, actions) => {
      state.tripsInNotifications = actions.payload;
    },
  },
});

export const {
  setNotification,
  setLoadingNotifications,
  setTripInNotifications,
} = notificationTripSlice.actions;

export const getNotificationsTrip = (): AppThunk => {
  return async (dispatch) => {
    try {
      const notifications = await getNotificationTripApi();
      const groupByType = groupByProperty(notifications?.data, "type");
      dispatch(setNotification(groupByType));
      const response = await getGroupTrips({ limit: 1000 });
      const trips = response.filter((r: any) => r.id_mowi);
      const tripsGroupByIdMowi = groupByProperty(trips, "id_mowi");
      dispatch(setTripInNotifications(tripsGroupByIdMowi));
    } catch (error) {
      message.error(
        "Ha ocurrido un error al intentar obtener las notificaciones."
      );
    }
  };
};

export default notificationTripSlice.reducer;
