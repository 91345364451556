/* eslint-disable react-hooks/exhaustive-deps */
import {
  DrawingManager,
  GoogleMap,
  Polygon,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { getRandomInt } from "../../utils/helpers/numberUtils";
import DrawingMapProps from "../../propInterfaces/DrawingMapProps";
import { Input } from "antd";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";

const mapContainerStyle = {
  height: "90vh",
  width: "100%",
};

const colors = [
  "#b40056",
  "#3b185a",
  "#c02485",
  "#ffde14",
  "#e83278",
  "#009c9b",
  "#c6d76b",
  "#f39200",
  "#f8eb4c",
  "#546918",
  "#ff8379",
];

const EDITABLE_COLOR = "blue";

export const DrawingMap: React.FunctionComponent<DrawingMapProps> = ({
  zoom,
  center,
  onFinishDrawing,
  existingPolygonPaths,
  drawable,
  editablePolygonPath,
}: DrawingMapProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager | null>(null);
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);

  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);

  const fitBoundsForMultiplePolygons = (polygons: google.maps.Polygon[]) => {
    const bounds = new google.maps.LatLngBounds();
    for (const polygon of polygons) {
      const paths = polygon.getPaths();
      paths.forEach(function (path) {
        const ar = path.getArray();
        for (let i = 0, l = ar.length; i < l; i++) {
          bounds.extend(ar[i]);
        }
      });
    }
    map?.fitBounds(bounds);
  };

  const fitBounds = (path: google.maps.LatLngLiteral[]) => {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0, l = path.length; i < l; i++) {
      bounds.extend(path[i]);
    }

    map?.fitBounds(bounds);
  };

  useEffect(() => {
    const polygons = [];

    if (existingPolygonPaths && map) {
      for (const latLngs of existingPolygonPaths) {
        const color = colors[getRandomInt(colors.length)];

        const polygon = new google.maps.Polygon({
          paths: latLngs,
          strokeColor: "#000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
        });

        polygon.setMap(map);
        polygons.push(polygon);
      }

      if (drawable) {
        fitBoundsForMultiplePolygons(polygons);
      }
    }
  }, [existingPolygonPaths]);

  useEffect(() => {
    if (editablePolygonPath) {
      fitBounds(editablePolygonPath);
    }
  }, [editablePolygonPath]);

  const onLoadManager = (
    drawingManager: google.maps.drawing.DrawingManager
  ) => {
    drawingManager.setOptions({ drawingControl: drawable });
    setDrawingManager(drawingManager);
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    drawingManager?.setOptions({ drawingControl: false, drawingMode: null });
    onFinishDrawing(polygon);
  };

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const polygon = new google.maps.Polygon({
        paths: nextPath,
      });

      onPolygonComplete(polygon);
    }
  }, []);

  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const handleLoad = (ref: any) => setSearchBox(ref);

  const onPlacesChanged = () => {
    if (
      searchBox &&
      searchBox.getPlaces() !== undefined &&
      searchBox.getPlaces()![0]
    ) {
      map?.setCenter(
        searchBox
          .getPlaces()![0]
          .geometry?.location?.toJSON() as google.maps.LatLngLiteral
      );
      map?.setZoom(10);
    } else map?.setCenter(center);
  };

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <div style={{ height: "400px" }}>
      <GoogleMap
        id="drawing-manager-example"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        center={center}
        onLoad={(map: google.maps.Map) => setMap(map)}
      >
        {editablePolygonPath.length > 0 ? (
          <Polygon
            editable
            options={{
              strokeColor: EDITABLE_COLOR,
              fillColor: EDITABLE_COLOR,
            }}
            path={editablePolygonPath}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        ) : (
          <>
            <DrawingManager
              options={{
                drawingControlOptions: {
                  drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
                polygonOptions: {
                  editable: true,
                  fillColor: EDITABLE_COLOR,
                  strokeColor: EDITABLE_COLOR,
                },
              }}
              onLoad={onLoadManager}
              onPolygonComplete={onPolygonComplete}
            />
            <StandaloneSearchBox
              onLoad={handleLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <Input
                type="text"
                placeholder="Ingrese una direccion"
                prefix={<SearchOutlined />}
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `300px`,
                  height: `38px`,
                  padding: `0px 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: "absolute",
                  right: "65px",
                  top: "10px"
                }}
              />
            </StandaloneSearchBox>
          </>
        )}
      </GoogleMap>
    </div>
  );
};
