import axios from "axios";

const apiUrl = process.env.REACT_APP_TRACKING_TRIPS_URL;
const token = process.env.REACT_APP_TRACKING_TRIPS_TOKEN;

export const getTripTrackingInformation = async (bookingId: string) => {
  const result = await axios.get(
    `${apiUrl}/${bookingId}`,
    {
      headers: {
        'x-api-key': token,
      },
    }
  );
  return result.data;
};