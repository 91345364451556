import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ModelState from "../../shared/states/modelState";
import { getAdminWalletByAdminId } from "../../services/adminWalletApi";

const initialState: ModelState<Number> = {
  value: 0,
  status: "idle",
};

export const getAdminWalletAsync = createAsyncThunk(
  "adminwallet/",
  async (id: number) => {
    const response = await getAdminWalletByAdminId(id);
    return response.data.data.currentBalance;
  }
);

export const getAdminWalletSlice = createSlice({
  name: "getAdminWallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminWalletAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAdminWalletAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(getAdminWalletAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default getAdminWalletSlice.reducer;
