import {
  Alert,
  Button,
  Col,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { QRCodeCanvas } from "qrcode.react";
import ContainerLayout from "../common/containerLayout";
import mowi from "../generateQR/mowi.png";
import { ChangeEvent, useEffect, useState } from "react";
import { selectCurrentAdminId } from "../../utils/helpers/selectorHelper";
import LabelAndInput from "../common/labelAndInput";
import { createAdminReferralQr, getAdminReferralQrByAdminId } from "../../services/adminReferralQrApi";
import { AdminReferralQrDTO } from "../../shared/dtos/adminReferralQrDTO";

const qrUrlValue = process.env.REACT_APP_REFERRAL_QR_CODE_URL;

const defaultQR: AdminReferralQrDTO = {
  bgColor: "#FFFFFF",
  errorLevel: "M",
  fgColor: "#000000",
  imgQrSize: 32,
  qrSize: 256,
};

const GenerateReferralQR = () => {
  const [qr, setQr] = useState<AdminReferralQrDTO | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const onChangeErrorLevel = (value: string | null) => {
    if (value)
      setQr((prev) => {
        return {
          ...prev,
          errorLevel: value,
        };
      });
  };

  const onChangeSize = (size: number | null) => {
    if (size) {
      const imgQrSize = (15 * size) / 100;
      setQr((prev) => {
        return {
          ...prev,
          qrSize: size,
          imgQrSize,
        };
      });
    }
  };

  const onChangeColor = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    const color = e.target.value;
    if (color && name)
      setQr((prev) => {
        return {
          ...prev,
          [name]: color,
        };
      });
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.querySelector("#qr-code") as HTMLCanvasElement;
    if (!canvas) throw new Error("<canvas> not found in the DOM");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    const getQr = async () => {
      setLoading(true);
      const response: AdminReferralQrDTO = await getAdminReferralQrByAdminId();
      if (response?.id) {
        setQr(response);
      } else {
        setQr(defaultQR);
      }
      setLoading(false);
    };

    getQr();
  }, []);

  const save = async () => {
    try {
      setLoading(true);

      const response = await createAdminReferralQr({
        ...qr,
        adminId: selectCurrentAdminId(),
      });

      setQr(response?.data);
      message.success("QR generado satisfactoriamente.");
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 409)
        message.error("Ya existe un QR para este cliente.");
      setLoading(false);
    }
  };

  return (
    <ContainerLayout title="Generar código QR de referidos" showNotification={false}>
      <Spin spinning={!qr && loading}>
        {!qr && loading ? (
          <Row style={{ minHeight: 200 }}></Row>
        ) : (
          <Row style={{ minHeight: 200 }}>
            {!qr?.adminId && (
              <Col flex="300px">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <LabelAndInput label="Tamaño">
                    <InputNumber
                      defaultValue={qr?.qrSize}
                      onChange={onChangeSize}
                      size="middle"
                      style={{ width: 250 }}
                      addonBefore="px"
                    />
                  </LabelAndInput>

                  <LabelAndInput label="Nivel">
                    <Select
                      defaultValue={qr?.errorLevel}
                      style={{ width: 250 }}
                      size="middle"
                      onChange={onChangeErrorLevel}
                      options={[
                        {
                          value: "L",
                          label: "L",
                        },
                        {
                          value: "M",
                          label: "M",
                        },
                        {
                          value: "Q",
                          label: "Q",
                        },
                        {
                          value: "H",
                          label: "H",
                        },
                      ]}
                    />
                  </LabelAndInput>

                  <LabelAndInput label="Color">
                    <input
                      type="color"
                      defaultValue={qr?.fgColor}
                      style={{ width: 250 }}
                      onChange={(e) => onChangeColor(e, "fgColor")}
                    />
                  </LabelAndInput>

                  <Button
                    type="primary"
                    onClick={save}
                    loading={loading}
                    style={{ width: 250 }}
                  >
                    Generar QR
                  </Button>
                </Space>
              </Col>
            )}
            <Col
              flex="auto"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <QRCodeCanvas
                  id="qr-code"
                  value={`${qrUrlValue}${qr?.code}`}
                  size={qr?.qrSize}
                  bgColor={qr?.bgColor || defaultQR.bgColor}
                  fgColor={qr?.fgColor || defaultQR.fgColor}
                  level={qr?.errorLevel}
                  includeMargin={false}
                  imageSettings={{
                    src: mowi,
                    x: undefined,
                    y: undefined,
                    height: qr?.imgQrSize || 32,
                    width: qr?.imgQrSize || 32,
                    excavate: true,
                  }}
                />
                {!qr?.adminId && (
                  <Alert
                    message={
                      <div style={{ textAlign: "center" }}>
                        Vista previa del QR
                        <br />
                        <b>No válido para ser usado!</b>
                      </div>
                    }
                    type="warning"
                  />
                )}
                {qr?.adminId && (
                  <Button
                    type="primary"
                    style={{ width: 200 }}
                    onClick={downloadQRCode}
                  >
                    Descargar
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Spin>
    </ContainerLayout>
  );
};

export default GenerateReferralQR;
