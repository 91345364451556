import { createSlice } from "@reduxjs/toolkit";
import DepartmentDTO from "../../shared/dtos/departmentDTO";
import { AppThunk } from "../../app/store";
import {
  FiltersDepartment,
  createDepartmentApi,
  deleteDepartmentApi,
  editDepartmentApi,
  getDepartmentsApi,
} from "../../services/departmentApi";
import { message } from "antd";

interface DepartmentValues {
  departments: DepartmentDTO[];
  total: number;
}

interface InitialState {
  values: DepartmentValues;
  loading: boolean;
  filtersDepartments: FiltersDepartment;
}

const initialState: InitialState = {
  values: { departments: [], total: 0 },
  loading: false,
  filtersDepartments: {
    searchText: undefined,
    page: 1,
    take: 10,
  },
};

const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    setDepartments: (state, actions) => {
      state.values = actions.payload;
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
    setFiltersDepartments: (state, actions) => {
      state.filtersDepartments = actions.payload;
    },
  },
});

export const { setDepartments, setLoading, setFiltersDepartments } =
  departmentsSlice.actions;

export const getDepartments = (filters?: FiltersDepartment): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const response = await getDepartmentsApi(filters);
    const list = response?.data?.map((i: any) => {
      return {
        id: i.id,
        name: i.name,
      };
    });
    const formattedData = { departments: list, total: response.total };
    dispatch(setDepartments(formattedData));
    dispatch(setLoading(false));
  };
};

export const addDepartment = (
  name: string,
  callBack?: (flag: boolean) => void
): AppThunk => {
  return async (dispatch, getState) => {
    const filters = getState().departments.filtersDepartments;
    try {
      if (callBack) callBack(true);
      await createDepartmentApi(name);
      if (callBack) callBack(false);
      message.success("El departamento se ha creado satisfactoriamente.");
      dispatch(getDepartments(filters));
    } catch (error) {
      message.error("Ha ocurrido un error al intentar crear el departamento.");
    }
  };
};

export const editDepartment = (
  id: string,
  name: string,
  callBack?: (flag: boolean) => void
): AppThunk => {
  return async (dispatch, getState) => {
    const filters = getState().departments.filtersDepartments;
    try {
      if (callBack) callBack(true);
      await editDepartmentApi(id, name);
      if (callBack) callBack(false);
      message.success("El departamento se ha editado satisfactoriamente.");
      dispatch(getDepartments(filters));
    } catch (error) {
      message.error("Ha ocurrido un error al intentar editar el departamento.");
    }
  };
};

export const deleteDepartment = (id: string): AppThunk => {
  return async (dispatch, getState) => {
    const filters = getState().departments.filtersDepartments;
    try {
      await deleteDepartmentApi(id);
      dispatch(getDepartments(filters));
    } catch (error) {
      message.error(
        "Ha ocurrido un error al intentar eliminar el departamento."
      );
    }
  };
};

export default departmentsSlice.reducer;
