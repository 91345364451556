import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PutAdminProps from "../../propInterfaces/putAdminProps";
import { putAdmin } from "../../services/adminApi";

export const initialState = {
  value: {},
  status: "idle",
};

export const putAdminAsync = createAsyncThunk(
  "admins/",
  async ({ id, newAdminDTO }: PutAdminProps) => {
    const response = await putAdmin({ id, newAdminDTO });

    return response;
  }
);

export const putAdminSlice = createSlice({
  name: "putadmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putAdminAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(putAdminAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(putAdminAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default putAdminSlice.reducer;
