export enum WebRoutes {
  TripListRoute = "/trip/list",
  PendingTripListRoute = "/trip/pending",
  TripCreateRoute = "/trip/create",
  TripTableRoute = "/trip/table",
  TripHistoricalDataRoute = "/trip/historical/table",
  TripEditRoute = "/trip/edit",
  AdminTableRoute = "/admin/table",
  CreateAdminRoute = "/admin/create",
  UpdateAdminRoute = "/admin/update",
  UserTableRoute = "/user/table",
  CreateUserRoute = "/user/create",
  UpdateUserRoute = "/user/update",
  LoginRoute = "/login",
  AssignProviderRoute = "/assing/provider",
  MapRoute = "/maps",
  BannerRoute = "/banners",
  CreateBannerRoute = "/banners/create",
  DriverRoute = "/drivers",
  CreateDriverRoute = "/drivers/create",
  EditDriverRoute = "/drivers/edit/:driverId",
  DriverServicesRoute = "/drivers/services/:driverId",
  WildCardRoute = "/*",
  GroupTripRequest = "/group/request",
  GroupTripPasagers = "/group/passegers",
  GroupTripTrips = "/group/trips",
  DispatcherGroupTrip = "/group/trips/add",
  AdminDocumentListRoute = "/admin/document/list",
  ProviderDocumentListRoute = "/provider/document/list",
  GroupTripsClients = "/group/trips/clients",
  MultipleLoad = "/trip/multiple/",
  IndexAdminPanel = "/admin/panel",
  AdminPanelTripDetails = "/admin/panel/trip/details",
  Departments = "/admin/departments",
  PrivacyPolicies = "/admin/privacy/policies",
  GenerateQR = "/admin/generateqr",
  GenerateReferralQR = "/admin/generatereferralqr",
  RegionsRoute = "/regions",
  CreateRegionRoute = "/regions/create",
  EditRegionRoute = "/regions/edit/:regionId",
  AdminConfiguration = "/admin/configuration",
  PricesRoute = "/prices",
  CreatePriceRoute = "/prices/create",
  EmptyTrips = "/trips/empty",
  CurrencyRoute = "/currencies",
  ServiceTypeRoute = "/servicetypes",
  CreateServiceTypeRoute = "/servicetypes/create",
  EditServiceTypeRoute = "/servicetypes/edit/:serviceTypeId",
  NotifyPassenger = "/notify/passenger/",
  ModifyUserPrivilegeRoute = "/user/privilege/:userId",
  UpdatePasswordRoute = "/update/password",
}
