import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminLoginDTO from "../../shared/dtos/adminLoginDTO";
import jwt_decode from "jwt-decode";

export const initialState = {
  value: {} as AdminLoginDTO,
  status: "idle",
};

export const currentAdminAsync = createAsyncThunk(
  "current/admin/",
  async (token: string) => {
    const verifiedToken: AdminLoginDTO = jwt_decode(token);
    return verifiedToken;
  }
);

export const currentAdminSlice = createSlice({
  name: "currentAdmin",
  initialState,
  reducers: {
    removeCurrentAdminValue: (state) => {
      state.value = {} as AdminLoginDTO;
    },
    updateAdminPartnerFeeValue: (state, action) => {
      state.value.partnerFee = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(currentAdminAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(currentAdminAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(currentAdminAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { removeCurrentAdminValue, updateAdminPartnerFeeValue } =
  currentAdminSlice.actions;

export default currentAdminSlice.reducer;
