import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AssingProviderProps from "../../propInterfaces/assignProviderProps";
import { assignProviderToUserRequest } from "../../services/dispatcherApi";

const initialState = {
  value: 0,
  status: "idle",
};

export const assignProviderToUserRequestAsync = createAsyncThunk(
  "userRequest/assignProvider",
  async ({ userRequestId, providerId }: AssingProviderProps) => {
    const response = await assignProviderToUserRequest({
      userRequestId,
      providerId,
    });
    return response;
  }
);

export const assignProviderToUserRequestSlice = createSlice({
  name: "pendingUserRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assignProviderToUserRequestAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(assignProviderToUserRequestAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(assignProviderToUserRequestAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default assignProviderToUserRequestSlice.reducer;
