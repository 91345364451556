import axios from "axios";
import DefaultPaginationProps from "../propInterfaces/defaultPaginationProps";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const getProviderList = async () => {
  const result = await axios.get(`${apiUrl}providers`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const getAvailableProviderList = async (
  userRequestId: number | null | undefined
) => {
  const result = await axios.get(`${apiUrl}providers/${userRequestId}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result.data;
};

export const getProviderPaginatedDocuments = async ({
  page,
  take,
  searchText,
  startDate,
  endDate,
}: DefaultPaginationProps) => {
  const result = await axios.post(
    `${apiUrl}admin/trip/report/provider/document?page=${page}&take=${take}`,
    {
      search: searchText,
      startDate,
      endDate,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const getProviderEmails = async ({
  startDate,
  endDate,
}: DefaultPaginationProps) => {
  const result = await axios.post(
    `${apiUrl}providers/emails/search/bydate`,
    {
      startDate: startDate,
      endDate: endDate,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const getProviderExcel = async (
  search: string,
  startDate: Date,
  endDate: Date
) => {
  const result = await axios.post(
    `${apiUrl}user-request/report/statement/excel/provider`,
    {
      search,
      startDate,
      endDate,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};
