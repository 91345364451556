import { Button, Card, Col, Row, Space, Tag, message } from "antd";
import EmptyTripDTO from "../../../shared/dtos/emptyTripDto";
import {
  Calendar,
  CarIcon,
  DestinationIcon,
  HashtagIcon,
  OriginIcon,
  ProviderIcon,
} from "../../../shared/constants/Images";
import { MinusSquareOutlined } from "@ant-design/icons";
import { useState } from "react";
import { deleteEmptyTripApi } from "../../../services/emptyTripApi";
import dayjs from "dayjs";

interface Props {
  emptyTrip: EmptyTripDTO | undefined;
  selected?: boolean;
  onSelected?: (e?: EmptyTripDTO) => void;
  refetch?: () => void;
}

const EmptyTripCard = ({ emptyTrip, selected, onSelected, refetch }: Props) => {
  const [deleting, setDeleting] = useState(false);

  const deleteEmptyTrip = async (e: any) => {
    e.stopPropagation();
    if (emptyTrip?.id) {
      setDeleting(true);
      try {
        await deleteEmptyTripApi(emptyTrip?.id);
        if (refetch) refetch();
        message.success("Viaje vacío eliminado satisfactoriamente.");
      } catch (error) {
        message.error(
          "Ha ocurrido un error al intentar eliminar el viaje vacío."
        );
      }
      setDeleting(false);
    }
  };

  return (
    <div
      style={{
        margin: "5px 8px 12px 8px",
        boxShadow: selected ? "rgba(0, 0, 0, 0.80) 0px 2px 5px" : "",
      }}
    >
      <Card
        bodyStyle={{
          padding: 0,
          background: selected ? "#e5e5e5" : "",
        }}
        hoverable
        onClick={() => {
          if (onSelected) onSelected(emptyTrip);
        }}
      >
        <Row gutter={[0, 16]} style={{ padding: "20px 18px" }}>
          <Col
            span={24}
            className="info-trip-card-container custom-text-card-trip"
          >
            <Space className="booking-id">
              <img src={HashtagIcon} alt="bookingId" />
              {emptyTrip?.bookingId}
            </Space>
          </Col>
          <Col span={24} className="info-trip-card-container">
            <div className="custom-text-card-trip" style={{ width: "100%" }}>
              <Space>
                <img src={OriginIcon} alt="origen" />
                <>{emptyTrip?.originAddress}</>
              </Space>
            </div>
            <div className="custom-text-card-trip" style={{ width: "100%" }}>
              <Space>
                <img src={DestinationIcon} alt="destino" />
                <>{emptyTrip?.destinationAddress}</>
              </Space>
            </div>
          </Col>
          <Col span={24}>
            <div className="custom-text-card-trip">
              <Space>
                <img src={CarIcon} alt="pago" />
                <>{emptyTrip?.userRequest?.serviceType?.name}</>
              </Space>
            </div>
          </Col>
          <Col span={24} className="info-trip-card-container">
            <div className="custom-text-card-trip">
              <Space>
                <img src={ProviderIcon} alt="conductor" />
                <>
                  {emptyTrip?.provider
                    ? `${emptyTrip?.provider?.firstName} ${emptyTrip?.provider?.lastName}`
                    : emptyTrip?.userRequest?.provider
                    ? `${emptyTrip?.userRequest?.provider?.firstName} ${emptyTrip?.userRequest?.provider?.lastName}`
                    : "No hay un conductor asignado"}
                </>
              </Space>
            </div>
            <div className="custom-text-card-trip">
              <Space>
                <img src={Calendar} alt="car" />
                {emptyTrip?.scheduleAt
                  ? dayjs(emptyTrip.scheduleAt).format("YYYY-MM-DD HH:mm:ss")
                  : emptyTrip?.estimatedDepartureTime
                  ? dayjs(emptyTrip.estimatedDepartureTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : ""}
              </Space>
            </div>
          </Col>
          {emptyTrip?.userRequest?.country && emptyTrip?.userRequest?.country.name !== 'Uruguay' && (
            <Col>
              <div className="custom-text-card-trip">
                <Space>
                  <Tag color="magenta">Internacional</Tag>
                  <>País: {emptyTrip?.userRequest?.country?.name}</>
                </Space>
              </div>
            </Col>
          )}
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              size="small"
              icon={<MinusSquareOutlined />}
              type="primary"
              danger
              onClick={deleteEmptyTrip}
              disabled={deleting}
              loading={deleting}
            >
              Viaje vacio
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EmptyTripCard;
