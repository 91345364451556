import { SuperAdminTypeId } from "../../shared/constants/Resources";
import { selectCurrentAdminType } from "../../utils/helpers/selectorHelper";
import ContainerLayout from "../common/containerLayout";
import Editor from "./editor";
import { useState, useEffect } from "react";

const PrivacyPolicies = (): JSX.Element => {
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selectCurrentAdminType() === SuperAdminTypeId) {
      setEditable(true);
    }
  }, []);

  return (
    <ContainerLayout title="Políticas de privacidad" showNotification={false}>
      <Editor allowEdit={editable} />
    </ContainerLayout>
  );
};

export default PrivacyPolicies;
