export const parseDataFromXMLSNotifyPassenger = (data: Array<any>): Array<any> => {
    return data.map((i) => {
      return {
        id: i["__rowNum__"],
        name: i["Nombre"],
        phoneNumber: i["Telefono"],
        date: i["Fecha"],
        hour: i["Hora"],
        bookingId: i["BookingId"]
      };
    });
  }
  