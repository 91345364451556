import { useState } from "react";
import { Button, Col, Form, Row, Select, Table } from "antd";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import Upload from "antd/lib/upload/Upload";
import ContainerLayout from "../../common/containerLayout";
import { parseDataFromXMLSNotifyPassenger } from "./utils";
import Column from "antd/lib/table/Column";
import { CheckCircleOutlined } from "@ant-design/icons";
import { NotificationType } from "./notificationType";
import { sendWhatsappToPassenger } from "../../../services/adminApi";
import { readUploadXLSXFile } from "../../groupTrip/common/utils";

const NotifyPassengerExcel = () => {
  const [data, setData] = useState<Array<any>>([]);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const readUploadFile = (e: any) => {
    readUploadXLSXFile(
      e,
      (data) => {
        const list = parseDataFromXMLSNotifyPassenger(data);
        setData(list);
      },
      false
    );
  };

  const normFile = (e: any) => {
    setLoading(true);
    if (e?.fileList && e?.fileList.length > 0) {
      const tmp = { target: { files: [e?.file] } };
      setFileList(e?.file ? [e.file] : []);
      readUploadFile(tmp);
    } else {
      setFileList([]);
    }
    if (Array.isArray(e)) {
      return e;
    }
    setLoading(false);
    return e?.fileList;
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const { notificationTypeId } = values;
    if (!data || !notificationTypeId) 
      return;
    
    await sendWhatsappToPassenger(data, notificationTypeId);
    setLoading(false);
    setData([]);
    setFileList([]);
  };

  const handleRemovedFile = (e: any) => {
    if (e.fileList.length === 0) {
      setData([]);
    }
  }

  return (
    <ContainerLayout title="Notificar Pasajeros via Whatsapp" showNotification={false}>
      <Row
        style={{
          padding: "20px 0px 0px 0px",
        }}
      >
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <Form
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="inline"
              disabled={loading}
            >
              <Form.Item
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                className="custom-upload-xlsx-file"
              >
                <Upload
                  listType="picture"
                  beforeUpload={() => false}
                  maxCount={1}
                  onChange={handleRemovedFile}
                >
                  {fileList.length === 0 && (
                    <Button icon={<UploadOutlined />}>
                      Cargar archivo excel
                    </Button>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item
                name="notificationTypeId"
                label="Tipo de Notificacion"
                tooltip="
                  Que mensaje desea que se le envie al pasajero?
                  
                  NOTA:
                  Si enviaras notificacion con booking id asegurate
                  que todos los usuarios tienen booking id de otra
                  forma no le llegara la notificacion al usuario sin
                  booking id. (Asi mismo como otros campos que son
                  importantes como Nombre, Telefono, Fecha y Hora)
                "
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione un tipo de notificacion!",
                  },
                ]}
              >
                <Select
                  placeholder="Seleccione un tipo de notificacion"
                  options={NotificationType}
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              disabled={data?.length === 0}
              loading={loading}
              icon={<CheckCircleOutlined />}
            >
              Enviar notificacion
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Table
            dataSource={data}
            rowKey="id"
            scroll={{ y: "calc(100vh - 250px)" }}
            loading={loading}
            pagination={{ hideOnSinglePage: true }}
          >
            <Column
              title=""
              width={80}
              dataIndex="id"
            />
            <Column title="Nombre" width={150} dataIndex="name" />
            <Column title="Telefono" width={150} dataIndex="phoneNumber" />
            <Column title="Fecha" width={150} dataIndex="date" />
            <Column title="Hora" width={150} dataIndex="hour" />
            <Column title="Booking Id" width={150} dataIndex="bookingId" />
          </Table>
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default NotifyPassengerExcel;
