import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import ServiceTypeDTO from "../shared/dtos/serviceTypeDTO";

const apiUrl = process.env.REACT_APP_API_URL;

interface PaginatedProps {
  page?: number;
  take?: number;
  search?: string;
}

export const getServiceTypeList = async () => {
  const result = await axios.get(`${apiUrl}/service-types`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const getServiceTypePaginatedList = async ({
  page = 1,
  take = 10,
  search = ""
}: PaginatedProps) => {
  const result = await axios.get(`${apiUrl}/service-types?page=${page}&take=${take}${search ? `&search=${search}` : ""}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const deleteServiceType = async (id: number) => {
  const result = await axios.delete(`${apiUrl}/service-types/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const getServiceType = async (id: number) => {
  const result = await axios.get(`${apiUrl}/service-types/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data[0];
};

export const createServiceType = async (serviceTypeDTO: ServiceTypeDTO) => {
  const result = await axios.post(`${apiUrl}/service-types/`, {
    name: serviceTypeDTO.name,
    calculator: serviceTypeDTO.calculator,
    capacity: serviceTypeDTO.capacity,
    distance: serviceTypeDTO.distance,
    fixed: serviceTypeDTO.fixed,
    minute: serviceTypeDTO.minute,
    price: serviceTypeDTO.price,
    image: serviceTypeDTO.image,
    serviceClass: serviceTypeDTO.serviceClass
  }, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data[0];
};

export const patchServiceType = async (serviceTypeDTO: ServiceTypeDTO, serviceTypeId: number) => {
  const result = await axios.patch(`${apiUrl}service-types/${serviceTypeId}`, {
    name: serviceTypeDTO.name,
    calculator: serviceTypeDTO.calculator,
    capacity: serviceTypeDTO.capacity,
    distance: serviceTypeDTO.distance,
    fixed: serviceTypeDTO.fixed,
    minute: serviceTypeDTO.minute,
    price: serviceTypeDTO.price,
    image: serviceTypeDTO.image
  }, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data[0];
};

export const patchServiceTypeActiveForAllAdmins = async (serviceTypeId: number, isActiveForAllAdmins: boolean) => {
  const result = await axios.patch(`${apiUrl}service-types/${serviceTypeId}/isactiveforadmins`, {
    isActiveForAllAdmins
  }, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data[0];
};