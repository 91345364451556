import { useRef, useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { Button, Col, Row, Space, message } from "antd";
import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  getPrivacyPoliciesApi,
  updatePrivacyPoliciesApi,
} from "../../services/privacyPoliciesApi";

interface PrivacyPolicies {
  country: string | null;
  countryId: string | null;
  id: string | number;
  key: string;
  value: string;
}

interface Props {
  allowEdit?: boolean;
}

const Editor = ({ allowEdit = true }: Props) => {
  const [loading, setLoading] = useState(false);
  const [privacyPolicies, setPrivacyPolicies] = useState<
    PrivacyPolicies | undefined
  >(undefined);
  const [disabledEdit, setDisabledEdit] = useState(true);
  const editor = useRef<SunEditorCore>();

  useEffect(() => {
    const getPrivacyPolicies = async () => {
      const response = await getPrivacyPoliciesApi();
      setPrivacyPolicies(response);
    };

    getPrivacyPolicies();
  }, []);

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const handleOnSave = async () => {
    const content = editor.current?.getContents(false);
    const settings = {
      id: privacyPolicies?.id,
      key: privacyPolicies?.key,
      value: content,
      countryId: privacyPolicies?.countryId,
    };

    try {
      setLoading(true);
      await updatePrivacyPoliciesApi(settings);
      message.success(
        "Las políticas de privacidad han sido actualizadas satisfactoriamente."
      );
      setLoading(false);
      setDisabledEdit(true);
    } catch (error) {
      setLoading(false);
      message.error(
        "Ha ocurrido un error al intentar actualizar las políticas de privacidad."
      );
    }
  };

  const handleOnCancel = () => {
    setDisabledEdit(true);
    editor.current?.setContents(privacyPolicies?.value || "");
  };

  return (
    <Row gutter={[0, 8]}>
      {allowEdit && (
        <Col span={24}>
          <Button
            type="text"
            icon={<EditOutlined />}
            disabled={loading}
            onClick={() => {
              setDisabledEdit(false);
            }}
          >
            Editar
          </Button>
        </Col>
      )}
      <Col span={24}>
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          lang="es"
          name="mowi-editor-privacy-policies"
          setContents={privacyPolicies?.value || ""}
          width="100%"
          height="100%"
          disable={disabledEdit || loading}
          hideToolbar={disabledEdit}
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
              ],
              ["removeFormat"],
              ["horizontalRule", "list", "lineHeight"],
              ["font", "align", "fontSize", "formatBlock"],
              ["link"],
              ["fontColor", "hiliteColor", "textStyle"],
              ["image"],
              ["preview", "print"],
            ],
          }}
        />
      </Col>
      {!disabledEdit && (
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            <Button
              onClick={handleOnCancel}
              icon={<CloseCircleOutlined />}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleOnSave}
              icon={<SaveOutlined />}
              disabled={loading}
              loading={loading}
            >
              Guardar
            </Button>
          </Space>
        </Col>
      )}
    </Row>
  );
};
export default Editor;
