import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdminsForSelect } from "../../services/adminApi";
import AdminDTO from "../../shared/dtos/adminDTO";

export const initialState = {
  value: new Array<AdminDTO>(),
  loading: false,
};

export const adminsForSelectAsync = createAsyncThunk(
  "adminsForSelect/",
  async () => {
    return await getAdminsForSelect();
  }
);

export const adminsForSelectSlice = createSlice({
  name: "adminsForSelect",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adminsForSelectAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(adminsForSelectAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(adminsForSelectAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default adminsForSelectSlice.reducer;
