import { store } from "../../app/store";

export const selectCurrentAdminTokens = () => {
  const state = store.getState();
  return state.auth.value;
};

export const selectCurrentAdminToken = () => {
  const state = store.getState();
  return state.auth.value.token;
};

export const selectCurrentAdminType = () => {
  const state = store.getState();
  return state.currentAdmin.value.adminTypeId;
};

export const selectCurrentAdminId = () => {
  const state = store.getState();
  return state.currentAdmin.value.adminId;
};

export const selectCurrentAdminPartner = () => {
  const state = store.getState();
  return state.currentAdmin.value.partner;
};

export const selectCurrentAdminPartnerFee = () => {
  const state = store.getState();
  return state.currentAdmin.value.partnerFee;
};

export const selectCurrentAdminPriceTable = () => {
  const state = store.getState();
  return state.currentAdmin.value.priceTable;
};

export const selectSelectedCountryId = () => {
  const state = store.getState();
  return state.userRequestList.selectedCountryId;
};

export const selectPasswordUpdatedAt = () => {
  const state = store.getState();
  return state.currentAdmin.value.passwordUpdatedAt;
};
