import React, { useMemo, useCallback, useState, useEffect } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { setCountry } from "../../features/map/mapSlice";
import MapDTO from "../../shared/dtos/mapDTO";
import providerDTO from "../../shared/dtos/providerDTO";
import { RootState } from "../../app/store";
import { ProviderMarkers } from "./ProviderMarkers";
import { COUNTRIES } from "../../utils/constants/countries";
import { WayPoints } from "./Waypoints";
import CheckPoints from "./CheckPoints";

function MockMap() {
  const { destinations, show } = useSelector((state: RootState) => state?.map);

  const listMarker = useSelector((state: RootState) => state?.map?.list);

  const asigDriver = (selected: providerDTO) => {
    alert(`Hola, ${selected.name}`);
    //Dispach asignarDriver(select)
  };

  return (
    <>
      <DispatcherMap
        destinations={destinations}
        providers={listMarker}
        showProviders={show}
        assignProvider={asigDriver}
      />
      {/* <button onClick={() => dispatch(showProviders(!show))}>
        {!show ? "Mostrar proveedores" : "Ocultar proveedores"}
      </button> */}
    </>
  );
}

interface MapProps {
  destinations?: MapDTO | null;
  providers?: Array<providerDTO>;
  showProviders?: boolean | false;
  assignProvider?: (provider: providerDTO) => void;
  setProvider?: (provider: providerDTO | null) => void;
  selectedProvider?: providerDTO | null;
  activeProvider?: providerDTO | null;
  shouldCalculateDistance?: boolean | false;
  darkMode?: boolean;
}

const darkStyles = {
  styles: [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#242f3e" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ],
};

export const DispatcherMap: React.FunctionComponent<MapProps> = (props) => {
  const {
    destinations,
    providers,
    showProviders,
    assignProvider,
    selectedProvider,
    setProvider,
    activeProvider,
    shouldCalculateDistance,
    darkMode,
  } = props;
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(setCountry(COUNTRIES[0]));
  }, [dispatch]);

  const { lat, lng } = useSelector(
    (state: RootState) => state?.map.country
  );

  const center = useMemo(() => {
    return { lat: -34.88582, lng: -56.17122 };
  }, []);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);

      map.setCenter(center);
      map.setZoom(12);
    },
    [center]
  );

  const wayPointsDirections = useMemo(() => {
    return {
      pickUp: destinations?.pickUp,
      dropOff: destinations?.dropOff,
    };
  }, [destinations]);

  return (
    <>
      <div style={{ height: "400px" }}>
        <GoogleMap
          mapContainerStyle={{
            margin: "0 10px 0 10px",
            height: "80vh",
            width: "100%",
          }}
          onLoad={onLoad}
          options={darkMode ? darkStyles : { styles: [] }}
          center={{lat, lng}}
        >
          {destinations && destinations.wayPoints ? (
            <CheckPoints destinations={destinations} />
          ) : (
            destinations &&
            destinations.pickUp && (
              <WayPoints
                directions={wayPointsDirections}
                shouldCalculateDistance={shouldCalculateDistance}
              />
            )
          )}

          {showProviders && providers && (
            <ProviderMarkers
              providers={providers}
              assignProvider={assignProvider}
              selectedProvider={selectedProvider}
              setProvider={setProvider}
              map={map}
            />
          )}
          {activeProvider &&
            activeProvider.latitude &&
            activeProvider.longitude && (
              <ProviderMarkers
                providers={[
                  {
                    ...activeProvider,
                    name: `${activeProvider.firstName} ${activeProvider.lastName}`,
                    phone: `${activeProvider.mobile}`,
                    coordinates: {
                      lat: activeProvider.latitude,
                      lng: activeProvider.longitude,
                    },
                  },
                ]}
                map={map}
              />
            )}
        </GoogleMap>
      </div>
    </>
  );
};

export default MockMap;
