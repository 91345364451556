export enum Routes {
  Report,
  UserReport,
  UserRequestHistory,
  PendingUserRequestHistory,
  Dispatcher,
  Map,
  SignOut,
  GroupTripRequest,
  GroupTripPasagers,
  GroupTripTrips,
  Banner,
  Driver,
  GroupTripsClients,
  MultipleLoad,
  IndexAdminPanel,
  Departments,
  PaymentHistory,
  PrivacyPolicies,
  GenerateQR,
  Regions,
  AdminConfiguration,
  RegionPrices,
  EmptyTrips,
  Currencies,
  ServiceTypes,
  NotifyPassengers,
  GenerateReferralQR
}
