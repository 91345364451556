export const ProviderServiceStatus = [
  {
    value: "offline",
    label: "Desconectado",
  },
  {
    value: "active",
    label: "Conectado",
  },
  {
    value: "riding",
    label: "En viaje",
  },
];
