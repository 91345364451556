import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Form, Modal, Space } from "antd";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setSelected,
  setOpenModal,
} from "../../../features/groupTrip/groupTripsClients/grpupTripsClientsSlice";
import FormClient from "./formClient";

interface Props {
  addingClient?: boolean;
}

const AddClientModal = ({ addingClient = true }: Props) => {
  const [loading, setLoading] = useState(false);
  const open = useAppSelector((state) => state.groupTripsClients.openModal);
  const selected = useAppSelector((state) => state.groupTripsClients.selected);

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    dispatch(setOpenModal(true));
  };

  const handleOnCancel = () => {
    dispatch(setOpenModal(false));
  };

  const onOk = () => {
    form.submit();
  };

  const afterClose = () => {
    dispatch(setSelected(undefined));
    form.resetFields();
  };

  return (
    <>
      <Button type={"primary"} onClick={handleOnClick}>
        + Cliente
      </Button>

      <Modal
        title={
          selected
            ? "Editar cliente/empresa."
            : "Adicionar nuevo cliente/empresa."
        }
        open={open}
        onCancel={handleOnCancel}
        okText={
          <Space>
            {loading ? <LoadingOutlined /> : <CheckCircleOutlined />}Aceptar
          </Space>
        }
        cancelText={
          <Space>
            <CloseCircleOutlined />
            Cancelar
          </Space>
        }
        onOk={onOk}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        afterClose={afterClose}
        destroyOnClose
      >
        <FormClient form={form} setLoading={setLoading} selected={selected} />
      </Modal>
    </>
  );
};

export default AddClientModal;
