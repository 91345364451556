import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { WebRoutes } from "./shared/constants/WebRoutes";

/* Utils */
import AdminAuth from "./utils/helpers/adminAuth";
import SuperAdminAuth from "./utils/helpers/superAdminAuth";
import LoggedInHelper from "./utils/helpers/loggedInHelper";

/* Components */
import Login from "./components/login";
import DispatcherGroupTrip from "./components/groupTrip/dispatcherGroupTrip";
import ProviderReportDocumentList from "./components/provider/reportDocumentList";
import GroupTripsClients from "./components/groupTrip/groupTripsClients/groupTripsClients";
import MultipleLoad from "./components/userRequest/multipleLoad";
import AdminPanelTripDetails from "./components/adminPanel/adminPanelTripDetails";
import Departments from "./components/department/departments";
import PrivacyPolicies from "./components/privacyPolicies/privacyPolicies";
import PendingUserRequestList from "./components/userRequest/pendingTable";
import GenerateQR from "./components/generateQR";
import RegionList from "./components/regions/list";
import RegionForm from "./components/regions/form";
import PriceList from "./components/regionsPrices/list";
import PriceForm from "./components/regionsPrices/form";
import EmptyTrips from "./components/emptyTrip/list";
import CurrencyList from "./components/currency";
import NotifyPassengerExcel from "./components/passenger/notifyExcel";
import EditUserPrivilege from "./components/user/privilege";
import UserRequestTable from "./components/userRequest/table";
import CreateUserRequest from "./components/userRequest/create";
import UserRequestList from "./components/userRequest/list";
import AdminConfiguration from "./components/admin/configuration";
import AdminList from "./components/admin/list";
import AdminForm from "./components/admin/form";
import AdminReportDocumentList from "./components/admin/reportDocumentList";
import ServiceTypeList from "./components/serviceType/list";
import ServiceTypeForm from "./components/serviceType/form";
import AdminPanel from "./components/adminPanel";
import DriverServiceList from "./components/provider/services/list";
import PanelLayout from "./components/panelLayout";
import GroupTrip from "./components/groupTrip/requestGroup/groupTrip";
import PassegersGroup from "./components/groupTrip/passengerGroup";
import TripsGroup from "./components/groupTrip/tripGroup";
import AssingProvider from "./components/admin/assignProvider";
import UserRequestEdit from "./components/userRequest/editTotal";
import UserList from "./components/user/list";
import UserForm from "./components/user/form";
import BannerList from "./components/banner/list";
import BannerForm from "./components/banner/form";
import DriverList from "./components/provider/list";
import DriverForm from "./components/provider/form";

import "antd/dist/antd.min.css";
import "./index.css";
import ResetPasswordForm from "./components/resetPassword";
import VerifyAdminPasswordUpdated from "./utils/helpers/verifyAdminPasswordUpdated";
import GenerateReferralQR from "./components/generateReferralQR";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoggedInHelper />}>
          <Route path={WebRoutes.LoginRoute} element={<Login />}></Route>
          <Route
            path={WebRoutes.WildCardRoute}
            element={<Navigate to={WebRoutes.LoginRoute} />}
          ></Route>
        </Route>

        <Route element={<AdminAuth />}>
          <Route
            path={WebRoutes.UpdatePasswordRoute}
            element={<ResetPasswordForm />}
          />
          <Route element={<VerifyAdminPasswordUpdated />}>
            <Route element={<PanelLayout />}>
              <Route
                path={WebRoutes.TripTableRoute}
                element={<UserRequestTable />}
              />
              <Route
                path={WebRoutes.AdminConfiguration}
                element={<AdminConfiguration />}
              />
              <Route
                path={WebRoutes.UserTableRoute}
                element={<UserList />}
              ></Route>
              <Route path={WebRoutes.CreateUserRoute} element={<UserForm />} />
              <Route path={WebRoutes.UpdateUserRoute} element={<UserForm />} />
              <Route
                path={WebRoutes.ModifyUserPrivilegeRoute}
                element={<EditUserPrivilege />}
              />
              <Route
                path={WebRoutes.IndexAdminPanel}
                element={<AdminPanel />}
              />
              <Route
                path={WebRoutes.AdminPanelTripDetails}
                element={<AdminPanelTripDetails />}
              />
              <Route path={WebRoutes.Departments} element={<Departments />} />
              <Route
                path={WebRoutes.PrivacyPolicies}
                element={<PrivacyPolicies />}
              />
              <Route path={WebRoutes.GenerateQR} element={<GenerateQR />} />
              <Route path={WebRoutes.GenerateReferralQR} element={<GenerateReferralQR />} />
              <Route
                path={WebRoutes.CurrencyRoute}
                element={<CurrencyList />}
              />
            </Route>
          </Route>
        </Route>

        <Route element={<SuperAdminAuth />}>
          <Route
            path={WebRoutes.UpdatePasswordRoute}
            element={<ResetPasswordForm />}
          />
          <Route element={<VerifyAdminPasswordUpdated />}>
            <Route element={<PanelLayout />}>
              <Route
                path={WebRoutes.AdminTableRoute}
                element={<AdminList />}
              ></Route>
              <Route
                path={WebRoutes.TripTableRoute}
                element={<UserRequestTable />}
              />
              <Route
                path={WebRoutes.UserTableRoute}
                element={<UserList />}
              ></Route>
              <Route
                path={WebRoutes.TripListRoute}
                element={<UserRequestList />}
              ></Route>
              <Route
                path={WebRoutes.PendingTripListRoute}
                element={<PendingUserRequestList />}
              ></Route>
              <Route
                path={WebRoutes.TripCreateRoute}
                element={<CreateUserRequest />}
              />
              <Route
                path={WebRoutes.AssignProviderRoute}
                element={<AssingProvider />}
              />
              <Route
                path={WebRoutes.GroupTripRequest}
                element={<GroupTrip />}
              />
              <Route
                path={WebRoutes.GroupTripPasagers}
                element={<PassegersGroup />}
              />
              <Route path={WebRoutes.GroupTripTrips} element={<TripsGroup />} />
              <Route
                path={WebRoutes.GroupTripsClients}
                element={<GroupTripsClients />}
              />
              <Route
                path={WebRoutes.DispatcherGroupTrip}
                element={<DispatcherGroupTrip />}
              />
              <Route
                path={WebRoutes.MapRoute}
                element={<CreateUserRequest />}
              />
              <Route
                path={WebRoutes.CreateAdminRoute}
                element={<AdminForm />}
              />
              <Route
                path={WebRoutes.UpdateAdminRoute}
                element={<AdminForm />}
              />
              <Route path={WebRoutes.CreateUserRoute} element={<UserForm />} />
              <Route path={WebRoutes.UpdateUserRoute} element={<UserForm />} />
              <Route
                path={WebRoutes.ModifyUserPrivilegeRoute}
                element={<EditUserPrivilege />}
              />
              <Route
                path={WebRoutes.TripEditRoute}
                element={<UserRequestEdit />}
              />
              <Route
                path={WebRoutes.BannerRoute}
                element={<BannerList />}
              ></Route>
              <Route
                path={WebRoutes.CreateBannerRoute}
                element={<BannerForm />}
              ></Route>
              <Route
                path={WebRoutes.DriverRoute}
                element={<DriverList />}
              ></Route>
              <Route
                path={WebRoutes.CreateDriverRoute}
                element={<DriverForm />}
              ></Route>
              <Route
                path={WebRoutes.AdminDocumentListRoute}
                element={<AdminReportDocumentList />}
              ></Route>
              <Route
                path={WebRoutes.ProviderDocumentListRoute}
                element={<ProviderReportDocumentList />}
              ></Route>
              <Route
                path={WebRoutes.DriverServicesRoute}
                element={<DriverServiceList />}
              ></Route>
              <Route
                path={WebRoutes.EditDriverRoute}
                element={<DriverForm />}
              ></Route>
              <Route
                path={WebRoutes.MultipleLoad}
                element={<MultipleLoad />}
              ></Route>
              <Route
                path={WebRoutes.NotifyPassenger}
                element={<NotifyPassengerExcel />}
              ></Route>
              <Route
                path={WebRoutes.RegionsRoute}
                element={<RegionList />}
              ></Route>
              <Route
                path={WebRoutes.CreateRegionRoute}
                element={<RegionForm />}
              ></Route>
              <Route
                path={WebRoutes.EditRegionRoute}
                element={<RegionForm />}
              ></Route>
              <Route
                path={WebRoutes.PricesRoute}
                element={<PriceList />}
              ></Route>
              <Route
                path={WebRoutes.CreatePriceRoute}
                element={<PriceForm />}
              ></Route>
              <Route path={WebRoutes.EmptyTrips} element={<EmptyTrips />} />
              <Route
                path={WebRoutes.CurrencyRoute}
                element={<CurrencyList />}
              />
              <Route
                path={WebRoutes.ServiceTypeRoute}
                element={<ServiceTypeList />}
              />
              <Route
                path={WebRoutes.CreateServiceTypeRoute}
                element={<ServiceTypeForm />}
              />
              <Route
                path={WebRoutes.EditServiceTypeRoute}
                element={<ServiceTypeForm />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
