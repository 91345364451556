import { Button, Col, Input, Popconfirm, Row, Space, Table, Tag } from "antd";
import ContainerLayout from "../common/containerLayout";
import "./departments.css";
import { DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  deleteDepartment,
  getDepartments,
  setFiltersDepartments,
} from "../../features/departments/departmentsSlice";
import DepartmentModal from "./departmentModal";

const { Search } = Input;
const { Column } = Table;

const Departments = (): JSX.Element => {
  const departments = useAppSelector((state) => state.departments.values);
  const filters = useAppSelector(
    (state) => state.departments.filtersDepartments
  );
  const loading = useAppSelector((state) => state.departments.loading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDepartments({ ...filters }));
  }, [dispatch, filters]);

  const onSearch = (value: string) => {
    const newFilters = { ...filters, page: 1, searchText: value };
    dispatch(setFiltersDepartments(newFilters));
  };

  const handleOnClickDelete = (id: string) => {
    dispatch(deleteDepartment(id));
  };

  const onChangePagination = (page: number, pageSize: number) => {
    const newFilters = { ...filters, page, take: pageSize };
    dispatch(setFiltersDepartments(newFilters));
  };

  const showTotal = (total: number) => `Total  ${total} departamentos`;

  return (
    <ContainerLayout title="Departamentos" showNotification={false}>
      <Row gutter={[0, 24]}>
        <Col span={24} className="custom-add-search-column-departments">
          <DepartmentModal />
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ maxWidth: 400 }}
            allowClear
          />
        </Col>
        <Col span={24}>
          <Table
            size="middle"
            dataSource={departments?.departments}
            rowKey="id"
            loading={loading}
            pagination={{
              total: departments?.total,
              current: filters?.page,
              onChange: onChangePagination,
              hideOnSinglePage: true,
              showSizeChanger: false,
              size: "default",
              showTotal,
            }}
          >
            <Column title="ID" dataIndex="id" />
            <Column title="Nombre" dataIndex="name" />
            <Column
              title=""
              width={200}
              render={(record) => (
                <>
                  <Space>
                    <DepartmentModal
                      create={false}
                      getValuesToEdit={() => ({
                        id: record?.id,
                        name: record?.name,
                      })}
                    />
                    <Popconfirm
                      placement="topLeft"
                      title={
                        <>
                          Está seguro(a) que desea eliminar el departamento?{" "}
                          <Tag color="red">{record?.name}</Tag>
                        </>
                      }
                      onConfirm={() => handleOnClickDelete(record?.id)}
                      okText="Aceptar"
                      cancelText="Cancelar"
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        type="primary"
                        style={{
                          background: "#f44236",
                          borderColor: "#f44236",
                          borderRadius: 20,
                        }}
                      >
                        Eliminar
                      </Button>
                    </Popconfirm>
                  </Space>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default Departments;
