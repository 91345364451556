import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserRequestProps from "../../propInterfaces/UserRequestProps";
import { getUserRequestById } from "../../services/userRequestApi";
import UserRequestDTO from "../../shared/dtos/userRequestDTO";
import { UserRequestEditDTO } from "../../shared/dtos/userRequestEditDTO";

const initialState = {
  value: {} as UserRequestEditDTO,
  status: "idle",
};

export const userRequestByIdAsync = createAsyncThunk(
  "userrequest/id",
  async ({ userRequestId }: UserRequestProps) => {
    const response = await getUserRequestById({ userRequestId });
    const userRequestDTO: UserRequestDTO = response;

    const userRequest: UserRequestEditDTO = {
      id: userRequestDTO.id,
      customerName: `${userRequestDTO.user?.firstName ?? ""} ${
        userRequestDTO.user?.lastName ?? ""
      }`,
      providerName: `${userRequestDTO.provider?.firstName ?? ""} ${
        userRequestDTO.provider?.lastName ?? ""
      }`,
      distance: userRequestDTO.payment?.distance,
      startedAt: userRequestDTO.startedAt,
      finishedAt: userRequestDTO.finishedAt,
      originAddress: userRequestDTO.originAddress,
      destinationAddress: userRequestDTO.destinationAddress,
      total: userRequestDTO.payment?.total,
      status: userRequestDTO.status,
      originLatitude: userRequestDTO.originLatitude,
      originLongitude: userRequestDTO.originLongitude,
      destinationLatitude: userRequestDTO.destinationLatitude,
      destinationLongitude: userRequestDTO.destinationLongitude,
      qrFee: userRequestDTO.qrFee,
    };

    return userRequest;
  }
);

export const userRequestByIdSlice = createSlice({
  name: "userRequestById",
  initialState,
  reducers: {
    updateUserRequestValue: (state, action) => {
      state.value = {} as UserRequestEditDTO;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userRequestByIdAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(userRequestByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(userRequestByIdAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default userRequestByIdSlice.reducer;
