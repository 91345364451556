import { Navigate, Outlet } from "react-router-dom";
import { WebRoutes } from "../../shared/constants/WebRoutes";
import { HandleLoginHelper } from "./verifyLoginHelper";
import {
  selectCurrentAdminToken,
  selectCurrentAdminType,
} from "./selectorHelper";
import {
  OperatorTypeId,
  SuperAdminTypeId,
} from "../../shared/constants/Resources";

const SuperAdminAuth = () => {
  const tokenFromHelper: string = selectCurrentAdminToken();
  const adminTypeId: number = selectCurrentAdminType();
  let isValidLogin = false;

  if (tokenFromHelper) isValidLogin = HandleLoginHelper(tokenFromHelper);

  return (isValidLogin && adminTypeId === SuperAdminTypeId) ||
    adminTypeId === OperatorTypeId ? (
    <Outlet />
  ) : (
    <Navigate to={WebRoutes.TripTableRoute} replace />
  );
};

export default SuperAdminAuth;
