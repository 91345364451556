import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { getPaginatedProvidersAsync } from "../../../features/driver/driverSlice";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { DataType, driverColumns } from "./columns";
import { getProvidersExcel } from "../../../services/adminProviderApi";

import "../../../shared/styles/list.scss";

const { Text } = Typography;
const { Search } = Input;

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters: Record<string, FilterValue | null>;
}

const DriverList = () => {
  const title = "Administración de Conductores";

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [search, setSearch] = useState("");
  const [ignoreFetch, setIgnoreFetch] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  const { data, loading } = useSelector((state: RootState) => state.drivers);

  const [tableParams, setTableParams] = useState<TableParams>({
    filters: {
      active: [true],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = (filters?: string) => {
    dispatch(
      getPaginatedProvidersAsync({
        page: tableParams.pagination?.current || 1,
        take: tableParams.pagination?.pageSize || 10,
        filters,
      })
    ).then(({ payload }: any) => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: payload.total,
        },
      });
    });

    setIgnoreFetch(true);
  };

  useEffect(() => {
    if (!ignoreFetch) {
      let filters;

      if (search) {
        filters = JSON.stringify({
          first_name: search,
          last_name: search,
        });
      }

      fetchData(filters);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams), search]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    setIgnoreFetch(false);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const onSearch = (value: string) => {
    setIgnoreFetch(false);
    setSearch(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const handleExcelExport = async () => {
    setIsLoadingExcel(true);
    const result = await getProvidersExcel();
    setIsLoadingExcel(false);
    const link = document.createElement("a");
    link.href = result;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row">
          <Col span={10}>
            <Button
              loading={isLoadingExcel}
              onClick={() => handleExcelExport()}
            >
              Descargar lista
            </Button>
          </Col>
          <Space>
            <Button
              onClick={() =>
                navigate(WebRoutes.ProviderDocumentListRoute, {
                  replace: true,
                })
              }
            >
              Lista de liquidaciones
            </Button>
            <Button
              type="primary"
              onClick={() =>
                navigate(WebRoutes.CreateDriverRoute, {
                  replace: true,
                })
              }
            >
              + Nuevo conductor
            </Button>

            <Text strong style={{ marginLeft: 20 }}>
              Buscar
            </Text>
            <Search
              placeholder="Nombre del conductor"
              allowClear
              onSearch={onSearch}
            />
          </Space>
        </Row>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={driverColumns(navigate, tableParams, search)}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Row>
      </Content>
    </Layout>
  );
};

export default DriverList;
