import AdminDTO from "./adminDTO";
import UserDTO from "./userDTO";

export default class UserPermissionDTO {
  id?: number;
  userId?: number;
  user?: UserDTO;
  adminId?: number;
  admin?: AdminDTO;
  permissionType?: string;
  permissions?: string;
  createdAt?: Date;
  updatedAt?: Date;
}