/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import moment, { Moment } from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import {
  createOrUpadateDriverAsync,
  getProviderDataByIdAsync,
} from "../../../features/driver/driverSlice";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import ProviderAdminDTO from "../../../shared/dtos/providerAdminDTO";
import { capitalizeFirstLetter } from "../../../utils/helpers/capitalizeString";
import { countriesList, indexedCountriesByName } from "./utilCountry";

import "../../../shared/styles/form.scss";
import { PasswordRegexExpression, PasswordRequirementText } from "../../../shared/constants/Resources";

const { Text } = Typography;

export const DriverForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { saving, loadingDriverInfo, currentProvider, error, isSuccess } =
    useSelector((state: RootState) => state.drivers);

  const [form] = Form.useForm();

  let { driverId } = useParams();

  useEffect(() => {
    if (driverId) {
      dispatch(getProviderDataByIdAsync(+driverId));
    }
  }, []);

  useEffect(() => {
    if (currentProvider) {
      const { dni, firstName, lastName, email, mobile, profile, bank } =
        currentProvider;
      let phone = mobile;
      const phoneCode = indexedCountriesByName[profile?.country]?.phoneCode;
      if (phoneCode) {
        const reg = new RegExp("^" + phoneCode.replace("+", "") + "");
        phone = phone?.replace(reg, "");
      }

      form.setFieldsValue({
        firstName,
        lastName,
        email,
        phone: Number(phone),
        dni,
        country: profile?.country,
        city: profile?.city,
        accountHolder: bank?.ownerName,
        bank: bank?.bank,
        accountType: bank?.accountType,
        accountNumber: bank?.number,
        address: bank?.address,
        birthdate: bank?.dateBirth
          ? bank?.dateBirth === "0000-00-00"
            ? null
            : moment(bank.dateBirth, "YYYY-MM-DD")
          : null,
        password: "",
      });
    }
  }, [currentProvider, form]);

  const onFinish = async (values: any) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      accountHolder,
      birthdate,
      country,
    } = values;
    let mobile = phone;
    const phoneCode = indexedCountriesByName[country]?.phoneCode;
    if (phoneCode) {
      const code = phoneCode.replace("+", "");
      mobile = `${code}${phone}`;
    }

    const data: ProviderAdminDTO = {
      ...values,
      id: driverId ? +driverId : undefined,
      firstName: capitalizeFirstLetter(firstName),
      lastName: capitalizeFirstLetter(lastName),
      email: (email as string).toLowerCase().trim(),
      mobile,
      accountHolder: capitalizeFirstLetter(accountHolder),
      birthdate: birthdate ? (birthdate as Moment).format("YYYY-MM-DD") : null,
    };

    await dispatch(createOrUpadateDriverAsync(data));
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: `Conductor ${
          driverId ? "actualizado" : "creado"
        } correctamente!`,
        placement: "bottom",
      });

      navigate(WebRoutes.DriverRoute, {
        replace: true,
      });
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        placement: "bottom",
      });
    }
  }, [error]);

  return (
    <>
      <Header>
        {!!!driverId ? "Crear nuevo conductor" : "Editar conductor"}
      </Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row
            style={{ textAlign: "center" }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={8}>
              <Text strong>Datos básicos</Text>

              <Form.Item
                label="DNI"
                name="dni"
                rules={[
                  { required: true, message: "Por favor ingresa un DNI!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Nombres"
                name="firstName"
                rules={[
                  { required: true, message: "Por favor ingresa un nombre!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Apellidos"
                name="lastName"
                rules={[
                  { required: true, message: "Por favor ingresa un apellido!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="País"
                name="country"
                rules={[
                  { required: true, message: "Por favor selecciona un País!" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seleccione el país."
                  onChange={(value: string) =>
                    form.setFieldValue("country", value)
                  }
                  filterOption={(input, option) =>
                    (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={countriesList.map((country) => ({
                    value: country.country,
                    label: (
                      <Space>
                        {country.country}
                        <img
                          src={`/images/flag/${country.code.toLowerCase()}.webp`}
                          alt="flag"
                        />
                      </Space>
                    ),
                  }))}
                />
              </Form.Item>

              <Form.Item
                label="Ciudad"
                name="city"
                rules={[
                  { required: true, message: "Por favor ingresa una ciudad!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Text strong>Cuenta de perfil</Text>
              <Form.Item
                label="Correo electrónico"
                name="email"
                rules={[
                  { required: true, message: "Por favor ingresa un correo!" },
                  { type: "email", message: "Ingrese un correo válido!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: !!!driverId,
                    message: "Por favor ingresa una clave!",
                  },
                  {
                      pattern: PasswordRegexExpression,
                      message: PasswordRequirementText
                  }
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirmar contraseña"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: !!!driverId,
                    message: "Por favor confirma la clave!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Las contraseñas no coinciden!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.country !== curValues.country
                }
              >
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      label="Teléfono"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa un teléfono!",
                        },
                        { type: "integer" },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        prefix={
                          indexedCountriesByName[getFieldValue("country")]
                            ?.phoneCode || ""
                        }
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Text strong>Cuenta bancaria</Text>

              <Form.Item
                label="Nombre del titular de la cuenta"
                name="accountHolder"
                rules={[
                  { required: true, message: "Por favor ingresa un nombre!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Nombre del banco"
                name="bank"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa el nombre del banco!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Tipo de cuenta"
                name="accountType"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa un tipo de cuenta",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Número de la cuenta bancaria"
                name="accountNumber"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa un número de cuenta!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Dirección"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa una dirección!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Fecha de nacimiento"
                name="birthdate"
                rules={[
                  { required: true, message: "Por favor ingresa una fecha!" },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  placeholder=""
                />
              </Form.Item>
            </Col>
          </Row>

          <Space direction="horizontal">
            <Button
              type="primary"
              htmlType="submit"
              disabled={loadingDriverInfo}
              loading={saving}
            >
              Guardar
            </Button>
            <Button
              onClick={() =>
                navigate(WebRoutes.DriverRoute, {
                  replace: true,
                })
              }
            >
              Cancelar
            </Button>
          </Space>
        </Form>
      </Content>
    </>
  );
};

export default DriverForm;
