import { Button, Checkbox, Form, message, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PassengerGroupDTO } from "../../../shared/dtos/PassengerGroupDTO";
import { useAppSelector } from "../../../app/hooks";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { generateTrips } from "../../../services/groupTripRequestApi";
dayjs.extend(weekday);
interface propsModal {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ModalGenerateTrips: React.FC<propsModal> = ({ open, setOpen }) => {
  const [form] = Form.useForm();
  const [validate, setValidate] = useState<boolean>(false);
  const [ready, setReady] = useState(false);
  const [generating, setGenerating] = useState(false);

  const passengers: PassengerGroupDTO[] = useAppSelector(
    (state) => state.passeger.value
  );
  const request = useAppSelector((state) => state.groupTripsRequest.selected);
  const navigate = useNavigate();

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    open && console.log("Verificar si hay destinos sin validar ... ");
    const aux = passengers.find(
      (p) => !p.destiny?.googlemaps || !p.origin?.googlemaps
    );
    console.log("Por validar ...", aux);
    if (aux?.id) {
      setValidate(true);
      setReady(false);
    } else {
      setValidate(false);
      setReady(true);
    }
    return () => {
      console.log("Destruir ... ");
      form.resetFields();
      setValidate(false);
      setReady(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, passengers]);

  const onFinish = async (values: any) => {
    setGenerating(true);
    console.log("Valores :", values);
    const nextDate = dayjs()
      .add(parseInt(values.date), "day")
      .format("YYYY-MM-DD");
    console.log("nueva fecha ...", nextDate);
    console.log("Request ... ", request?.id);

    const response = await generateTrips(request?.id || "0", nextDate);
    console.log("Respuesta del generar viajes ... ", response);
    setGenerating(false);
    if (response.status === "success") {
      navigate("/group/trips", {
        state: { request: request?.id },
        replace: true,
      });
    } else {
      message.error("Error al generar los viajes");
    }
  };
  const onSkip = (value: any) => {
    setReady(value.target.checked);
  };

  return (
    <>
      <Modal
        title="Generar Viajes"
        open={open}
        key="modal_generate_trips"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            key="submit"
            onClick={handleOk}
            disabled={!ready}
          >
            Aceptar
          </Button>,
          <Button type="primary" key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
      >
        <Spin tip="Generando viajes ..." spinning={generating}>
          <Form
            form={form}
            key="generate_trips"
            name="register"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ date: "7", skip: false }}
          >
            <Form.Item name="date" label="Período">
              <Select>
                <Select.Option key={1} value="7">
                  Semana
                </Select.Option>
                <Select.Option key={2} value="15">
                  Quincena
                </Select.Option>
                <Select.Option key={3} value="30">
                  Mes
                </Select.Option>
              </Select>
            </Form.Item>
            {validate && (
              <>
                <h3 style={{ color: "red" }}>
                  Existen pasajeros con direcciones sin validar.¿Desea excluir
                  estos pasajeros de la planificación?
                </h3>
                <Form.Item
                  name="skip"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox onChange={onSkip}>Deseo continuar</Checkbox>
                </Form.Item>
              </>
            )}
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ModalGenerateTrips;
