import { createSlice } from "@reduxjs/toolkit";
import { DirectionDTO } from "../../../shared/dtos/PassengerGroupDTO";
import { ListState } from "../../../shared/states/listState";
import {
  getGroupTripsDirection,
  addGroupTripsDirection,
  editGroupTripsDirection,
  deleteGroupTripsDirection,
} from "../../../services/groupTripDestinationApi";
import { AppThunk } from "../../../app/store";
import { message } from "antd";

interface Direction extends ListState<DirectionDTO> {
  selected: DirectionDTO | undefined;
  loading: boolean;
}

const initialState: Direction = {
  value: new Array<DirectionDTO>(),
  status: "idle",
  selected: undefined,
  loading: false,
};

const groupTripDirectionSlide = createSlice({
  name: "groupTripsDirection",
  initialState,
  reducers: {
    set: (state, actions) => {
      state.value = actions.payload;
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
    editDirection: (state, actions) => {
      state.value = state.value.map((r) => {
        if (r.id === actions.payload.id) {
          return actions.payload;
        }
        return r;
      });
    },
    deleteDirection: (state, actions) => {
      state.value = state.value.filter((i) => i.id !== actions.payload);
    },
    setSelectedDirection: (state, actions) => {
      state.selected = state.value.find((r) => r.id === actions.payload);
    },
  },
});

export const {
  set,
  setLoading,
  editDirection,
  deleteDirection,
  setSelectedDirection,
} = groupTripDirectionSlide.actions;

export const groupTripsDirectionList = (client?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await getGroupTripsDirection({ clientId: client });
      dispatch(set(response));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const addGroupTripsDirectionAsync = (
  direction: DirectionDTO
): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await addGroupTripsDirection(direction);
      message.success(response.message);
      dispatch(groupTripsDirectionList());
    } catch (error) {
      console.log(error);
    }
  };
};

export const editGroupTripsDirectionAsync = (
  direction: DirectionDTO
): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await editGroupTripsDirection(direction);
      dispatch(groupTripsDirectionList());
      message.success(response.message);
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteGroupTripsDirectionAsync = (id: string): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await deleteGroupTripsDirection(id);
      message.success(response.message);
      dispatch(groupTripsDirectionList());
    } catch (error) {
      console.log(error);
    }
  };
};

export default groupTripDirectionSlide.reducer;
