import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { getAvailableProviderList } from "../../../services/providerApi";
import { assignProviderToUserRequest } from "../../../services/dispatcherApi";
import { editGroupTrips } from "../../../services/groupTripsApi";
import { groupTripsList } from "./groupTripListSlide";
import { message } from "antd";
import { sendNotificationTripPassengeres } from "../../../services/notificationWhatsappApi";
import { CheckPoint, GroupTripDTO } from "../../../shared/dtos/groupTripDTO";

interface InitialProps {
  loading: boolean;
  list: Array<any>;
  selectedDriver: any;
}

const initialState: InitialProps = {
  loading: false,
  list: [],
  selectedDriver: undefined,
};

const driverAssignModalSlice = createSlice({
  name: "driverAssignModal",
  initialState,
  reducers: {
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
    setList: (state, actions) => {
      state.list = actions.payload;
    },
    setSelectedDriver: (state, actions) => {
      state.selectedDriver = actions.payload;
    },
  },
});

export const { setLoading, setList, setSelectedDriver } =
  driverAssignModalSlice.actions;

export const getDrivers = (
  userRequestId: number | undefined | null,
  driverId: number | string | null | undefined,
  latitude: number | undefined | null,
  longitude: number | undefined | null,
  distance: number | undefined | null,
  service: number | undefined | null
): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const response = await getAvailableProviderList(userRequestId);
    dispatch(setList(response));
    dispatch(setLoading(false));
    if (driverId) {
      const driver = response.find((d: any) => d.id === driverId);
      if (driver) {
        dispatch(setSelectedDriver(driver));
      }
    }
  };
};

export const assignDriver = (
  userRequestId: number | undefined | null,
  providerId: number | undefined | null,
  closeModal: () => void,
  ejecutingAction: (flag: boolean) => void
): AppThunk => {
  return async (dispatch, getState) => {
    const { originalData } = getState().groupTrip;
    const { selectedDriver } = getState().driverAssignModal;
    const selectedRequest = getState().groupTripsRequest.selected;
    ejecutingAction(true);
    try {
      const response = await assignProviderToUserRequest({
        userRequestId,
        providerId,
      });
      if (response.id) {
        const tmpTrip = originalData.find(
          (t) => t.id_mowi?.toString() === response.id.toString()
        );
        const trip = { ...tmpTrip };
        trip.driver = { ...selectedDriver };
        await editGroupTrips(trip);
        dispatch(groupTripsList(selectedRequest?.id));
        message.success("El driver se ha asignado satisfactoriamente.");
        closeModal();
        ejecutingAction(false);
        await sendNotificationMessage(
          trip.check_points,
          trip.id_mowi,
          trip.schedule_at
        );
      }
    } catch (error) {
      console.log("error", error);
      message.error("Ha ocurrido un error al intentar adicionar el driver");
      ejecutingAction(false);
    }
  };
};

export const assignDriverDispatcher = (
  userRequestId: number | undefined | null,
  providerId: number | undefined | null,
  closeModal: () => void,
  ejecutingAction: (flag?: boolean) => void,
  groupTrip: GroupTripDTO | undefined
) => {
  return async () => {
    try {
      const response = await assignProviderToUserRequest({
        userRequestId,
        providerId,
      });
      if (response.id) {
        closeModal();
        ejecutingAction();
        message.success("El conductor se ha adicionado satisfactoriamente.");

        if (groupTrip) {
          await sendNotificationMessage(
            groupTrip?.checkPoints,
            response.id,
            groupTrip?.scheduleAt
          );
        }
      }
    } catch (error) {
      message.error("Ha ocurrido un error al intentar asignar el conductor.");
    }
  };
};

const sendNotificationMessage = async (
  checkPoints: Array<CheckPoint>,
  tripId: string | number,
  date: string
) => {
  await sendNotificationTripPassengeres(checkPoints, tripId, date)
    .then((resp: any) => {
      let ok = 0;
      resp.forEach((element: any) => {
        if (element.status === "success") {
          ok += 1;
        }
      });
      if (ok === resp.length) {
        message.success("Se ha notificado a los pasajeros del viaje");
      } else {
        message.warning(
          `${ok} notificaciones fueron enviadas satisfactoriamente.`
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export default driverAssignModalSlice.reducer;
