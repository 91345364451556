import { notification } from "antd";
import axios from "axios";
import DefaultPaginationProps from "../propInterfaces/defaultPaginationProps";
import AutoCompleteDTO from "../shared/dtos/autoCompleteDTO";
import UserProfileDTO from "../shared/dtos/userProfileDTO";
import PATHS from "./paths";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

export const AutoCompleteUser = async (query: AutoCompleteDTO) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios.get(
    `${apiURL}${PATHS.AUTOCOMPLETE_USER}?firstName=${query.firstName}&lastName=${query.lastName}&mobile=${query.mobile}&email=${query.email}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const GetUserList = async ({
  page,
  take,
  searchText,
}: DefaultPaginationProps) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios.post(
    `${apiURL}${PATHS.GET_USERS}search/?page=${page}&take=${take}`,
    {
      search: searchText ?? "",
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const DeleteUser = async (userId: number) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios
    .delete(`${apiURL}${PATHS.GET_ADMIN_USERS}${userId}`, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then((response: any) => {
      notification.success({
        message: `El usuario #${userId}`,
        description: `se ha eliminado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });

  return result.data;
};

export const UpdateUserPassword = async (userId: number, password: string) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios
    .patch(
      `${apiURL}${PATHS.GET_USERS}${userId}/password`,
      { password: password },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then((response: any) => {
      notification.success({
        message: `La contraseña del usuario #${userId}`,
        description: `se ha actualizado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });

  return result.data;
};

export const updateUser = async (userId: number, user: UserProfileDTO) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios
    .patch(
      `${apiURL}${PATHS.GET_ADMIN_USERS}${userId}/profile`,
      { user },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then((response: any) => {
      notification.success({
        message: `Los datos del usuario #${userId}`,
        description: `se han actualizado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });

  return result.data;
};

export const postUserAsync = async (user: UserProfileDTO) => {
  const apiURL = process.env.REACT_APP_API_URL;

  const result = await axios
    .post(
      `${apiURL}${PATHS.GET_ADMIN_USERS}`,
      {
        firstName: user.firstName,
        lastName: user.lastName,
        mobile: user.mobile,
        email: user.email,
        password: user.password,
        loginBy: user.loginBy,
        deviceType: user.deviceType,
        paymentMode: user.paymentMode,
        departmentId: user.departmentId,
      },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then((response: any) => {
      notification.success({
        message: `Usuario se ha creado exitosamente`,
        description: ``,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    });

  return result.data;
};

export const updateUserManager = async (
  id: number | null | undefined,
  userType: boolean
) => {
  const apiURL = process.env.REACT_APP_API_URL;

  return await axios.patch(
    `${apiURL}admin/users/${id}/usertype`,
    { userType },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const updateUserOfferExtraTrip = async (
  id: number | null | undefined,
  offerExtraTrip: boolean
) => {
  const apiURL = process.env.REACT_APP_API_URL;

  return await axios.patch(
    `${apiURL}admin/users/${id}/offerExtraTrip`,
    { offerExtraTrip },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};
