import axios from "axios";
import UserRequestProps from "../propInterfaces/UserRequestProps";
import userRequestExcelProps from "../propInterfaces/userRequestExcelProps";
import UserRequestByDateListProps from "../propInterfaces/userRequestListExportProps";
import PendingPayEmailProps from "../propInterfaces/pendingPayEmailProps";
import UserRequestDTO from "../shared/dtos/userRequestDTO";
import UserRequestByDateRequestDTO from "../shared/dtos/userRequestListExportRequestDTO";
import { notification } from "antd";
import { TRIP_STATUS } from "../shared/constants/TripStatus";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import ApiResponseDTO from "../shared/dtos/apiResponseDTO";

const apiUrl = process.env.REACT_APP_API_URL;

export const getUserRequests = async (countryId: number | undefined, adminId: number | undefined) => {
  const result = await axios.post(`${apiUrl}user-request/actives`,
    {
      countryId: countryId === 0 ? null : countryId,
      adminId: adminId === 0 ? null : adminId
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    });
  return result;
};

export const postUserRequestsAsDispatcher = async (
  userRequest: UserRequestDTO
) => {
  const result = await axios
    .post(`${apiUrl}trip/dispatch`, userRequest, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then((response: any) => {
      notification.success({
        message: `El viaje`,
        description: `se ha creado exitosamente`,
        placement: "bottom",
      });
      return response;
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 409
            ? "Este usuario ya tiene un viaje agendado"
            : error.response.data.message,
        placement: "bottom",
      });
    });
  return result.data.data;
};

export const removeProviderFromUserRequest = async ({
  userRequestId,
}: UserRequestProps) => {
  const result = await axios.post(
    `${apiUrl}trip/remove/${userRequestId}/driver`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.data;
};

export const getUserRequestsStatementBetweenTwoDates = async ({
  startDate,
  endDate,
  page,
  take,
  bookingId,
  searchText,
  shouldSendCompany,
  adminId,
  status
}: UserRequestByDateListProps) => {
  const userRequestListExportRequestDTO: UserRequestByDateRequestDTO = {
    startDate,
    endDate,
    bookingId,
    searchText,
    adminId,
    shouldSendCompany,
    status
  };

  const result = await axios.post(
    `${apiUrl}user-request/report/statement/list?page=${page}&take=${take}`,
    userRequestListExportRequestDTO,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const getPendingCalculationUserRequest = async ({
  page,
  take,
  searchText,
}: UserRequestByDateListProps) => {
  const result = await axios.post(
    `${apiUrl}user-request/pending/calculation?page=${page}&take=${take}`,
    { searchText },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const getUserRequestsExcel = async ({
  startDate,
  endDate,
  shouldSendCompany,
  shouldLoadCache,
  adminId,
}: userRequestExcelProps) => {
  const userRequestListExportRequestDTO: UserRequestByDateRequestDTO = {
    startDate: startDate,
    endDate: endDate,
    shouldSendCompany: shouldSendCompany,
    shouldLoadCache: shouldLoadCache,
    adminId,
  };

  const result = await axios.post(
    `${apiUrl}user-request/report/statement/excel`,
    userRequestListExportRequestDTO,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.file;
};

export const deleteUserRequest = async ({
  userRequestId,
}: UserRequestProps) => {
  const result = await axios
    .delete(`${apiUrl}trip/${userRequestId}`, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then(() => {
      notification.success({
        message: `El viaje ${userRequestId}`,
        description: `se ha eliminado exitosamente`,
        placement: "bottom",
      });
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          "El viaje no se puede cancelar por el estado en el que se encuentra",
        placement: "bottom",
      });
    });
  return result;
};

export const sendPaymentEmail = async ({
  userRequestId,
  totalInPesos,
  totalInDollars,
  sendLocalCurrency,
}: PendingPayEmailProps) => {
  const result = await axios
    .patch(
      `${apiUrl}admin/trip/payment/mail/${userRequestId}`,
      { totalInPesos, totalInDollars, sendLocalCurrency },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then(() => {
      notification.success({
        message: `Viaje ${userRequestId}`,
        description: `se ha enviado la factura de manera correcta`,
        placement: "bottom",
      });
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          "Ha habido un error al enviar la factura, intentelo mas tarde o contacte a el departamento de sistemas",
        placement: "bottom",
      });
    });
  return result;
};

export const skipPendingUserRequest = async (userRequestId: number) => {
  const result = await axios
    .patch(
      `${apiUrl}admin/trip/payment/skip/${userRequestId}`,
      {},
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then(() => {
      notification.success({
        message: `Viaje ${userRequestId}`,
        description: `se ha liberado la factura de manera correcta`,
        placement: "bottom",
      });
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          "Ha habido un error al liberar la factura, intentelo mas tarde o contacte a el departamento de sistemas",
        placement: "bottom",
      });
    });
  return result;
};

export const skipAllPendingUserRequests = async (
  userRequestIds: Array<number>
) => {
  const result = await axios
    .patch(
      `${apiUrl}admin/trip/payment/skip/all`,
      { userRequestIds },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then(() => {
      notification.success({
        message: `Todos los viajes`,
        description: `han liberado las facturas de manera correcta`,
        placement: "bottom",
      });
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          "Ha habido un error al liberar las facturas, intentelo mas tarde o contacte a el departamento de sistemas",
        placement: "bottom",
      });
    });
  return result;
};

export const completeUserRequest = async ({
  userRequestId,
}: UserRequestProps) => {
  const result = await axios
    .patch(
      `${apiUrl}trip/status/${userRequestId}`,
      { status: TRIP_STATUS.Completed },
      {
        headers: {
          auth: selectCurrentAdminToken(),
        },
      }
    )
    .then(() => {
      notification.success({
        message: `El viaje ${userRequestId}`,
        description: `se ha completado exitosamente`,
        placement: "bottom",
      });
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          "El viaje no se puede completado por el estado en el que se encuentra",
        placement: "bottom",
      });
    });
  return result;
};

export const cancelUserRequest = async ({
  userRequestId,
}: UserRequestProps) => {
  const result = await axios
    .get(`${apiUrl}admin/trip/cancel/${userRequestId}`, {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    })
    .then(() => {
      notification.success({
        message: `El viaje ${userRequestId}`,
        description: `se ha cancelado exitosamente`,
        placement: "bottom",
      });
    })
    .catch((error: any) => {
      notification.error({
        message: error.response.data.status,
        description:
          "El viaje no se puede cancelar por el estado en el que se encuentra",
        placement: "bottom",
      });
    });
  return result;
};

export const getUserRequestById = async ({
  userRequestId,
}: UserRequestProps) => {
  const result = await axios.get(`${apiUrl}admin/trip/${userRequestId}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const updateQRFeeUserRequest = async ({
  userRequestId,
  qrFee,
}: UserRequestProps) => {
  const result = await axios.patch(
    `${apiUrl}admin/trip/qrFee/${userRequestId}`,
    { qrFee },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const updateUserRequestPayment = async ({
  userRequestId,
  total,
}: UserRequestProps) => {
  const result = await axios.patch(
    `${apiUrl}admin/trip/payment/${userRequestId}`,
    { total },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const updateSheduleDate = async (
  userRequestId: number,
  scheduleDate: string
) => {
  return await axios.post(
    `${apiUrl}user-request/scheduledate/${userRequestId}`,
    { scheduleDate },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const updateDescription = async (
  userRequestId: number,
  description: string
) => {
  return await axios.post(
    `${apiUrl}user-request/description/${userRequestId}`,
    { description },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};

export const getUserRequestTripMap = async ({ id }: { id: string }) => {
  const result = await axios.get(`${apiUrl}user-request/${id}/trip-map`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const getAdminComission = async (requestLastPeriod: boolean = false): Promise<ApiResponseDTO<any>> => {
  const result = await axios.get(`${apiUrl}user-request/admin/comission?requestLastPeriod=${requestLastPeriod}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const createTripBundle = async (
  userRequests: UserRequestDTO[]
) => {
  return await axios.post(
    `${apiUrl}trip/bundle`,
    { userRequests },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};