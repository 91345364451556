import { Button, Drawer, Form, Input } from "antd";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { useCallback } from "react";
import {
  sendMessage,
  setConversationOpen,
} from "../../../features/notifications/conversationSlice";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";
import ConversationArea from "./conversationArea";

const ConversationPanel = (): JSX.Element => {
  const conversationOpen = useAppSelector(
    (state) => state.conversation.conversationOpen
  );
  const currentConversation = useAppSelector(
    (state) => state.conversation.currentConversation
  );
  const sendingMessage = useAppSelector(
    (state) => state.conversation.sendingMessage
  );

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(setConversationOpen(false));
  }, [dispatch]);

  const clientNumber =
    currentConversation[0]?.from?.type === "client"
      ? currentConversation[0]?.from?.number
      : currentConversation[0]?.to?.number;

  const onFinish = (values: any) => {
    const { message } = values;
    if (message) {
      dispatch(sendMessage(clientNumber, message, form.resetFields));
    }
  };

  return (
    <Drawer
      title={clientNumber || ""}
      placement="right"
      width="100%"
      onClose={onClose}
      open={conversationOpen}
      getContainer=".site-drawer-render-in-current-drawer-wrapper"
      style={{
        position: "absolute",
        transform: "translateX(0px) !important",
      }}
      headerStyle={{ backgroundColor: "#f0f2f5" }}
      bodyStyle={{
        padding: 0,
        background: "#efeae2",
      }}
      destroyOnClose={true}
      closeIcon={<ArrowLeftOutlined />}
      footerStyle={{ backgroundColor: "#f0f2f5" }}
      footer={
        <Form
          layout="inline"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item name="message" style={{ width: "90%" }}>
            <Input
              width="100%"
              style={{ borderRadius: 10 }}
              disabled={sendingMessage}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              htmlType="submit"
              style={{ padding: 0, height: 25 }}
            >
              {sendingMessage ? (
                <LoadingOutlined />
              ) : (
                <SendOutlined style={{ fontSize: 22, color: "#000" }} />
              )}
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <ConversationArea />
    </Drawer>
  );
};

export default ConversationPanel;
