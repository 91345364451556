import PassegerList from "./PassengerList";
import LoadTemplate from "./LoadTemplate";
import { Col, Row } from "antd";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { addGroupTripsPassegerAsync } from "../../../features/groupTrip/passeger/groupTripPassegerSlide";
import ModalPassenger from "../common/formPassengers/modalPassenger";
import SelectRequest from "../common/selectRequest";

const Pasajeros = () => {
  const selected = useAppSelector((state) => state.groupTripsRequest.selected);

  const dispatch = useAppDispatch();

  return (
    <>
      <Row gutter={8}>
        {selected && (
          <Col>
            <ModalPassenger
              addPassengerAction={({ passengerData, requestId, showModal }) => {
                dispatch(
                  addGroupTripsPassegerAsync(
                    passengerData,
                    requestId || "0",
                    () => showModal && showModal(false)
                  )
                );
              }}
            />
          </Col>
        )}
        {selected && (
          <Col>
            <LoadTemplate />
          </Col>
        )}
        <SelectRequest />
      </Row>
      <PassegerList />
    </>
  );
};

export default Pasajeros;
