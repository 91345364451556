import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import { Planning } from "../../../shared/dtos/groupTripRequestDTO";
import { useAppDispatch } from "../../../app/hooks";
import {
  deleteGroupTripsRequestAsync,
  setSelectedRequest,
} from "../../../features/groupTrip/groupTripRequest/groupTripRequestSlide";
import PopUpNewGroup from "./popUpNewGroup";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

const { Column } = Table;

interface Schedule {
  id: string;
  name?: string;
  totalGroups?: number;
  totalPassengers?: number;
  planning: Planning[];
  repeat: boolean;
  endDate: string;
  startDate: string;
}

const GroupSchedule = ({
  id,
  name,
  totalGroups,
  planning,
  totalPassengers,
  repeat,
  endDate,
  startDate,
}: Schedule): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteReq = (idRequest: string) => {
    dispatch(deleteGroupTripsRequestAsync(idRequest));
  };

  const handleConfig = (idRequest: string) => {
    dispatch(setSelectedRequest(idRequest));
  };

  const handleEdit = (idRequest: string) => {
    navigate("/group/passegers", {
      state: { request: idRequest },
      replace: true,
    });
  };

  return (
    <Card
      style={{ width: "100%", background: "transparent" }}
      title={name ? `Nombre: ${name}` : ""}
      extra={
        name ? (
          <Space>
            {/* <Button type="link">Deshabilitar</Button> */}
            <Tooltip
              title="Editar pasajeros en este grupo."
              placement="topRight"
            >
              <Button type="link" onClick={() => handleEdit(id)}>
                <TeamOutlined />
                Editar
              </Button>
            </Tooltip>
            <PopUpNewGroup
              text="Configurar"
              btnType="link"
              onClick={() => handleConfig(id)}
              modalTitle="Configurar planificacion"
              icon={<SettingOutlined />}
              titleTooltip="Configurar viaje en grupo."
            />
            <Popconfirm
              title="Está seguro(a) que desea eliminar este viaje en grupo?"
              onConfirm={() => deleteReq(id)}
              placement="topRight"
              okText="Si"
              cancelText="No"
            >
              <Button
                type="link"
                style={{ color: "red" }}
                icon={<DeleteOutlined />}
              >
                Eliminar
              </Button>
            </Popconfirm>
          </Space>
        ) : null
      }
      headStyle={{
        background: "#F3F6F9",
      }}
      bodyStyle={{
        backgroundColor: name ? "#F3F6F9" : "transparent",
        padding: "0px 0px 10px 0px",
      }}
    >
      <Row
        style={{
          background: "transparent",
          width: "100%",
          minWidth: "auto",
          maxWidth: 1200,
        }}
      >
        <Col
          span={24}
          style={{ width: "100%", paddingLeft: name ? 71 : 0, paddingTop: 0 }}
        >
          <Row style={{ width: "100%" }}>
            <Col span={24} className="custome-table-group-trip-days">
              <Table
                dataSource={planning}
                rowKey={() => uuidv4()}
                size="small"
                pagination={{ hideOnSinglePage: true }}
              >
                <Column title="Días" dataIndex="day" key="day" width={140} />
                <Column title="Entrada" dataIndex="entryTime" key="entryTime" />
                <Column
                  title="Salida"
                  dataIndex="departureTime"
                  key="departureTime"
                />
                <Column
                  title="Fecha de inicio"
                  key="startDate"
                  width={160}
                  render={(text, record, index) => {
                    const firstPosition = index === 0;
                    const date = startDate
                      ? dayjs(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                      : "indefinido";
                    return firstPosition ? date : "";
                  }}
                />
                <Column
                  title="¿Se Repite?"
                  key="departureTime"
                  render={(text, record, index) => {
                    const firstPosition = index === 0;
                    const yesNo = repeat ? "Si" : "No";
                    return firstPosition ? yesNo : "";
                  }}
                />
                <Column
                  title="Fecha de finalización"
                  key="endDate"
                  render={(text, record, index) => {
                    const firstPosition = index === 0;
                    const date = endDate
                      ? dayjs(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                      : "indefinido";
                    return firstPosition ? date : "";
                  }}
                />

                <Column
                  title="Pasajeros"
                  key="totalPassengers"
                  render={(text, record, index) => {
                    const firstPosition = index === 0;
                    return firstPosition ? totalPassengers : "";
                  }}
                />
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default GroupSchedule;
