import { Col, Progress, Row, Spin } from "antd";
import ContainerLayout from "../../common/containerLayout";
import GroupTripsMap from "../groupTripsMap/groupTripsMap";
import FormAddGroupTrip from "./formAddGroupTrip";
import { validateDTO } from "../../../shared/dtos/PassengerGroupDTO";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { useEffect } from "react";
import { setDirectionResponse } from "../../../features/groupTrip/groupTripsMap/groupTripsMapSlide";

const DispatcherGroupTrip = (): JSX.Element => {
  const validate: validateDTO = useAppSelector(
    (state) => state.passeger.validating
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setDirectionResponse(null));
    };
  }, [dispatch]);

  return (
    <ContainerLayout title="Crear viaje en grupo">
      <Spin
        tip={`${validate.completed}/${validate.total}`}
        spinning={validate.active}
      >
        {validate.active && (
          <Progress percent={(validate.completed * 100) / validate.total} />
        )}

        {/* <h1 style={{ textAlign: "center" }}>Pasajeros viajes en grupos</h1> */}
        <div style={{ paddingTop: 20 }}>
          <Row>
            <Col
              span={10}
              style={{
                overflowY: "scroll",
                maxHeight: "calc(100vh - 80px)",
                paddingRight: 8,
              }}
            >
              <FormAddGroupTrip />
            </Col>
            <Col span={14}>
              <GroupTripsMap />
            </Col>
          </Row>
        </div>
      </Spin>
    </ContainerLayout>
  );
};

export default DispatcherGroupTrip;
