import {
  Button,
  Layout,
  Modal,
  Row,
  Form,
  Input,
  Checkbox,
  Col,
  Table,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import {
  pendingCalculationUserRequestAsync,
  removeFromPendingListAsync,
  skipFromPendingListAsync,
} from "../../../features/userRequest/pendingCalculationUserRequestSlice";
import UserRequestByDateDTO from "../../../shared/dtos/userRequestExportDTO";
import {
  skipAllPendingUserRequests,
  updateQRFeeUserRequest,
} from "../../../services/userRequestApi";
import UserRequestColumns from "./columns";

import "./styles.scss";

export const UserRequestPendingTable = () => {
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setIsConfirmLoading] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const title: string = "User Request List";
  const userRequestByDateValue: UserRequestByDateDTO[] = useSelector(
    (state: RootState) =>
      state.pendingCalculationUserRequest.value.userRequestByDateDto
  );
  const userRequestByDateTotal: number = useSelector(
    (state: RootState) => state.pendingCalculationUserRequest.value.total
  );

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      pendingCalculationUserRequestAsync({
        page: activePage,
        take: 10,
      })
    ).then(() => setIsLoading(false));
  }, [dispatch, activePage, reload]);

  const onPageChange = async (page: number) => {
    setIsLoading(true);
    setActivePage(page);
  };

  const calculateRequest = () => {
    const { totalInPesos, sendLocalCurrency, totalInDollars } =
      form.getFieldsValue();

    if (selectedId) {
      dispatch(
        removeFromPendingListAsync({
          userRequestId: selectedId,
          totalInDollars,
          totalInPesos,
          sendLocalCurrency,
        })
      ).then(() => {
        setIsLoading(true);
        setReload(!reload);
        setOpenModal(false);
        setSelectedId(undefined);
      });
    } else {
      setSelectedId(undefined);
      setOpenModal(false);
    }
  };

  const skipRequest = (id?: number | null) => {
    if (id) {
      dispatch(skipFromPendingListAsync(id)).then(() => {
        setIsLoading(true);
        setReload(!reload);
      });
    }
  };

  const items = (id: number, qrFee: number) => {
    return [
      {
        key: "1",
        label: (
          <span
            onClick={() => {
              setSelectedId(id);
              setOpenModal(true);
            }}
          >
            Cobrar
          </span>
        ),
      },
      {
        key: "2",
        label: (
          <span
            onClick={() => {
              setSelectedId(id);
              setIsQRModalOpen(true);
              form.setFieldValue("qrFee", qrFee);
            }}
          >
            Editar Comision
          </span>
        ),
      },
    ];
  };

  const handleOk = async () => {
    setIsConfirmLoading(true);
    const userRequestIds = new Array<number>();

    for (const userRequest of userRequestByDateValue) {
      userRequestIds.push(userRequest.id ?? 0);
    }

    await skipAllPendingUserRequests(userRequestIds).then(() => {
      setIsLoading(true);
      setReload(!reload);
    });

    setIsModalOpen(false);
    setIsConfirmLoading(false);
  };

  const handleQRFeeOk = async () => {
    const { qrFee } =
      form.getFieldsValue();

    setIsConfirmLoading(true);
    await updateQRFeeUserRequest({
      userRequestId: selectedId ?? 0,
      qrFee: +qrFee,
    }).then(() => {
      setIsLoading(true);
      setReload(!reload);
      setIsQRModalOpen(false);
      setIsConfirmLoading(false);
    });
  };

  const handleClick = () => {
    dispatch(
      pendingCalculationUserRequestAsync({
        page: activePage,
        take: 10,
        searchText: search,
      })
    ).then(() => setIsLoading(false));
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="list-container">
          <Row className="upper-row">
            <Form className="form-filter" onFinish={handleClick}>
              <Col span={3} className="input-container-table">
                <Input
                  type="text"
                  value={search}
                  placeholder="Buscar por..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
              <Col span={18} className="input-container-table">
                <Button htmlType="submit" disabled={isLoading === true}>
                  Filtrar
                </Button>
              </Col>
              <Col className="input-container-table">
                <Button
                  onClick={() => setIsModalOpen(!isModalOpen)}
                  disabled={isLoading === true}
                >
                  Omitir todos
                </Button>
              </Col>
            </Form>
          </Row>
          <Col span={24} style={{ width: "100px" }}>
            <Table
              loading={isLoading}
              size="small"
              rowKey="id"
              locale={{ emptyText: "No hay datos" }}
              dataSource={userRequestByDateValue}
              columns={UserRequestColumns({ items, skipRequest })}
              pagination={{
                total: userRequestByDateTotal,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: onPageChange,
                showTotal: (total) => `Total ${total} viajes`,
                size: "default",
              }}
              scroll={{ x: 500, y: "calc(100vh - 315px)" }}
            />
          </Col>
        </Row>
      </Content>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => setIsModalOpen(false)}
        confirmLoading={confirmLoading}
        okText={confirmLoading ? "Omitiendo" : "Ok"}
      >
        <b>Desea omitir los viajes listados?</b>
      </Modal>
      <Modal
        title="Comision en porcentaje"
        open={isQRModalOpen}
        onOk={handleQRFeeOk}
        onCancel={() => setIsQRModalOpen(false)}
        confirmLoading={confirmLoading}
        okText={confirmLoading ? "Editando" : "Editar Comision"}
      >
        <Form
          layout="vertical"
          name="trip"
          scrollToFirstError
          form={form}
        >
          <Row>
            <Col>
              <Form.Item
                name="qrFee"
                label="Comision en porcentaje"
                tooltip="Comision en porcentaje"
                rules={[
                  {
                    required: true,
                    message: "Ingresar comision en porcentaje",
                  },
                ]}
              >
                <Input type="number" placeholder="100" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Cotizar viaje"
        centered
        open={openModal}
        onOk={calculateRequest}
        onCancel={() => setOpenModal(false)}
        width={1000}
      >
        <Form
          layout="vertical"
          name="trip"
          scrollToFirstError
          form={form}
          fields={[
            {
              name: "totalInPesos",
              value: 0,
            },
            {
              name: "sendLocalCurrency",
              value: false,
            },
            {
              name: "totalInDollars",
              value: 0,
            },
          ]}
        >
          <Row>
            <Form.Item
              name="totalInPesos"
              label="Total del viaje en Pesos Uruguayos"
              tooltip="Total del viaje en Pesos Uruguayos"
              rules={[{ required: true, message: "Ingresar total" }]}
            >
              <Input type="number" />
            </Form.Item>
            <Col>
              <Form.Item
                name="sendLocalCurrency"
                tooltip="Enviar cierre de viaje en pesos"
                className="checkbox-form"
                valuePropName="checked"
              >
                <Checkbox>Cierre en pesos</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              name="totalInDollars"
              label="Total del viaje en Dolares"
              tooltip="Total del viaje en dolares"
              rules={[{ required: true, message: "Ingresar total" }]}
            >
              <Input type="number" />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </Layout>
  );
};

export default UserRequestPendingTable;
