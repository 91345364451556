import { Button, Col, Input, Row } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { DispatcherMap } from "../../map/Map";
import getDestinationsByUserRequestHelper from "../../../utils/helpers/getDestinationsByUserRequestHelper";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useEffect, useState } from "react";
import { userRequestByIdAsync } from "../../../features/userRequest/userRequestByIdSlice";
import { UserRequestEditDTO } from "../../../shared/dtos/userRequestEditDTO";
import { LoadingOutlined } from "@ant-design/icons";
import { StatusTranslations } from "../../../shared/constants/Status";
import moment from "moment";
import {
  updateQRFeeUserRequest,
  updateUserRequestPayment,
} from "../../../services/userRequestApi";

const UserRequestEdit: React.FunctionComponent = () => {
  const { state } = useLocation();
  const [userRequest, setUserRequest] = useState<UserRequestEditDTO | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [totalInput, setTotalInput] = useState(0);
  const [isTotalInput, setIsTotalInput] = useState(false);
  const [qrFeeInput, setQRFeeInput] = useState(0);
  const [isQRFeeInput, setIsQRFeeInput] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const userRequestById = useSelector(
    (state: RootState) => state.userRequestById.value
  );
  const userRequestByIdStatus = useSelector(
    (state: RootState) => state.userRequestById.status
  );

  useEffect(() => {
    const getUserRequest = async () => {
      if (state && state?.id > 0 && userRequest === null) {
        setIsLoading(true);
        const userRequestId = state.id;
        state.id = 0;
        await dispatch(userRequestByIdAsync({ userRequestId }));
      }
    };
    const assignUserRequest = () => {
      if (userRequest === null) {
        setUserRequest(userRequestById as UserRequestEditDTO);
      } else if (userRequest?.id !== userRequestById?.id) {
        setUserRequest(userRequestById as UserRequestEditDTO);
      }
      if (userRequest?.id === userRequestById?.id) {
        setIsLoading(false);
      }
    };
    getUserRequest();
    assignUserRequest();
  }, [dispatch, userRequestByIdStatus, state, userRequestById, userRequest]);

  const handleTotalInput = (total: number) => {
    if (!total) return null;

    setIsTotalInput(true);
    setTotalInput(total);
  };

  const handleQRFeeInput = (qrFee: number) => {
    if (!qrFee) return null;

    setIsQRFeeInput(true);
    setQRFeeInput(qrFee);
  };

  const handleTotalInputEdit = async (total: number) => {
    if (!total) return null;
    if (!userRequest) return null;

    await updateUserRequestPayment({
      userRequestId: userRequest.id,
      total,
    });
    setIsTotalInput(false);
    setTotalInput(total);
    await dispatch(userRequestByIdAsync({ userRequestId: userRequest.id }));
  };

  const handleQRFeeInputEdit = async (qrFee: number) => {
    if (!qrFee) return null;
    if (!userRequest) return null;

    await updateQRFeeUserRequest({
      userRequestId: userRequest.id,
      qrFee,
    });
    setIsQRFeeInput(false);
    setQRFeeInput(qrFee);
    await dispatch(userRequestByIdAsync({ userRequestId: userRequest.id }));
  };

  return (
    <>
      <Header className="site-layout-sub-header-background">
        Detalles del viaje
      </Header>
      <Content
        style={{
          margin: "16px",
        }}
      >
        <Row>
          {isLoading === false && userRequest !== null ? (
            <>
              <Col span={18} push={6}>
                {
                  <DispatcherMap
                    destinations={getDestinationsByUserRequestHelper(
                      userRequest
                    )}
                  />
                }
              </Col>
              <Col
                span={6}
                pull={18}
                className="card-container"
                id="user-request-by-id"
              >
                <Row>
                  <span>
                    <b>Usuario:</b> {userRequest.customerName}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Conductor:</b> {userRequest.providerName}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Distancia:</b> {userRequest.distance}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Hora de inicio:</b>{" "}
                    {moment(userRequest.startedAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Hora final:</b>{" "}
                    {moment(userRequest.finishedAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Origen:</b> {userRequest.originAddress}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Destino:</b> {userRequest.destinationAddress}
                  </span>
                </Row>
                <Row>
                  {isTotalInput === false ? (
                    <span>
                      <b>Total:</b>{" "}
                      {totalInput > 0 ? totalInput : userRequest.total}{" "}
                      <Button
                        onClick={() =>
                          handleTotalInput(
                            totalInput > 0
                              ? totalInput
                              : (userRequest.total as number)
                          )
                        }
                      >
                        Editar
                      </Button>
                    </span>
                  ) : (
                    <Row justify="space-between">
                      <Col>
                        <Input
                          value={totalInput}
                          type="number"
                          onChange={(e) =>
                            setTotalInput(Number(e.target.value))
                          }
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={() =>
                            handleTotalInputEdit(totalInput as number)
                          }
                        >
                          Guardar
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button onClick={() => setIsTotalInput(false)}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Row>
                <Row>
                  {isQRFeeInput === false ? (
                    <span>
                      <b>Porcentaje de Comision QR:</b>{" "}
                      {qrFeeInput > 0 ? qrFeeInput : userRequest.qrFee}{" "}
                      <Button
                        onClick={() =>
                          handleQRFeeInput(
                            qrFeeInput > 0
                              ? qrFeeInput
                              : (userRequest.qrFee as number)
                          )
                        }
                      >
                        Editar
                      </Button>
                    </span>
                  ) : (
                    <Row justify="space-between">
                      <Col>
                        <Input
                          value={qrFeeInput}
                          type="number"
                          onChange={(e) =>
                            setQRFeeInput(Number(e.target.value))
                          }
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={() =>
                            handleQRFeeInputEdit(qrFeeInput as number)
                          }
                        >
                          Guardar
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button onClick={() => setIsQRFeeInput(false)}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Row>
                <Row>
                  <span>
                    <b>Estatus:</b>{" "}
                    {
                      StatusTranslations.find(
                        (x) => x.name === userRequest.status
                      )?.translation
                    }
                  </span>
                </Row>
              </Col>
            </>
          ) : (
            <LoadingOutlined />
          )}
        </Row>
      </Content>
    </>
  );
};

export default UserRequestEdit;
