import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export interface FiltersDepartment {
  searchText?: string | undefined;
  page?: number;
  take?: number;
}

export const getDepartmentsApi = async (filters?: FiltersDepartment) => {
  const { page, take, searchText } = filters || {};
  const result = await axios.post(
    `${apiUrl}department/admin/search?page=${page}&take=${take}`,
    { searchText },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const createDepartmentApi = async (name: string) => {
  const result = await axios.post(
    `${apiUrl}department/admin`,
    {
      name,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const editDepartmentApi = async (id: string | number, name: string) => {
  const result = await axios.patch(
    `${apiUrl}department/${id}`,
    { name },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const deleteDepartmentApi = async (id: string) => {
  const result = await axios.delete(`${apiUrl}department/admin/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const getDepartmentsByAdminApi = async () => {
  const result = await axios.get(`${apiUrl}department/admin/token`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};
