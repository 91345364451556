import { Navigate, Outlet } from "react-router-dom";
import { selectCurrentAdminTokens } from "./selectorHelper";
import { HandleLoginHelper } from "./verifyLoginHelper";
import TokensDTO from "../../shared/dtos/tokensDTO";

const AdminAuth = () => {
  const tokensFromHelper: TokensDTO = selectCurrentAdminTokens();
  let isValidLogin = false;

  if (tokensFromHelper.token)
    isValidLogin = HandleLoginHelper(tokensFromHelper.token);

  return isValidLogin ? <Outlet /> : <Navigate replace to="/login" />;
};

export default AdminAuth;
