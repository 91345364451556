import React from "react";
import { Modal } from "antd";
import { useAppDispatch } from "../../../app/hooks";
import { setSelectedRequest } from "../../../features/groupTrip/groupTripRequest/groupTripRequestSlide";
import FormNewGroupRequest from "./formNewGroupRequest";

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
}

const ModalNewGroupRequest: React.FC<ModalProps> = ({
  open,
  setOpen,
  title,
}) => {
  const dispatch = useAppDispatch();
  const handleCancel = () => {
    setOpen(false);
    dispatch(setSelectedRequest({}));
  };

  return (
    <>
      <Modal
        title={title}
        open={open}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
      >
        <FormNewGroupRequest onCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default ModalNewGroupRequest;
