import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import {
  setFiltersUserRequestByDateDTO,
  userRequestByDateListAsync,
} from "../../../features/userRequest/userRequestByDateListSlice";
import {
  cancelUserRequest,
  completeUserRequest,
  deleteUserRequest,
  getUserRequestsExcel,
} from "../../../services/userRequestApi";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  AdminTypeId,
  ExportToExcelDescription,
  NoFinishDate,
  NotPaidDescription,
  PaidDescription,
  SuperAdminTypeId,
} from "../../../shared/constants/Resources";
import { useNavigate } from "react-router-dom";
import {
  selectCurrentAdminId,
  selectCurrentAdminType,
} from "../../../utils/helpers/selectorHelper";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { IndexStatusTranslations } from "../../../shared/constants/Status";
import UserRequestByDateDTO from "../../../shared/dtos/userRequestExportDTO";
import ContainerLayout from "../../common/containerLayout";
import { useAppSelector } from "../../../app/hooks";
import dayjs from "dayjs";
import debounce from "lodash.debounce";
import { removeFromPendingListAsync } from "../../../features/userRequest/pendingCalculationUserRequestSlice";
import { TRIP_STATUS } from "../../../shared/constants/TripStatus";

const { Column } = Table;

export const UserRequestTable = () => {
  const navigate = useNavigate();
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pesos, setPesos] = useState("");
  const [dollar, setDollar] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [sendLocalCurrency, setSendLocalCurrency] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const title: string = "Viajes por fecha";
  const userRequestByDateValue: UserRequestByDateDTO[] = useAppSelector(
    (state) => state.userRequestByDateList.value.userRequestByDateDto
  );
  const userRequestByDateTotal = useAppSelector(
    (state) => state.userRequestByDateList.value.total
  );
  const loading = useAppSelector(
    (state) => state.userRequestByDateList.loading
  );
  const filters = useAppSelector(
    (state) => state.userRequestByDateList.filtersUserRequestByDateDTO
  );

  useEffect(() => {
    dispatch(userRequestByDateListAsync({ ...filters }));
  }, [dispatch, filters]);

  const onPageChange = async (page: number, pageSize: number) => {
    const newFilters = { ...filters, page, take: pageSize };
    dispatch(setFiltersUserRequestByDateDTO(newFilters));
  };

  const handleExcelExport = async () => {
    setIsLoadingExcel(true);
    const result = await getUserRequestsExcel({
      startDate: dayjs(filters.startDate).toDate(),
      endDate: dayjs(filters.endDate).toDate(),
      shouldSendCompany: filters.shouldSendCompany || false,
      adminId:
        selectCurrentAdminType() === AdminTypeId
          ? selectCurrentAdminId()
          : null,
    });
    setIsLoadingExcel(false);
    const link = document.createElement("a");
    link.href = result;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const showDeleteConfirm = async (id: number) => {
    Modal.confirm({
      title: "Estas seguro de borrar este viaje?",
      icon: <ExclamationCircleFilled />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await deleteUserRequest({ userRequestId: id }).finally(() => {
          Modal.destroyAll();
        });
        const newFilters = {
          ...filters,
          page: 1,
        };
        dispatch(setFiltersUserRequestByDateDTO(newFilters));
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const showCompleteConfirm = async (id: number) => {
    Modal.confirm({
      title: "Estas seguro de completar este viaje?",
      icon: <ExclamationCircleFilled />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await completeUserRequest({ userRequestId: id }).finally(() => {
          Modal.destroyAll();
        });
        const newFilters = {
          ...filters,
          page: 1,
        };
        dispatch(setFiltersUserRequestByDateDTO(newFilters));
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const calculateRequest = (id: number) => {
    const totalInDollars = parseInt(dollar);
    const totalInPesos = parseInt(pesos);
    if (isNaN(totalInDollars) || isNaN(totalInPesos)) {
      alert("Dolares y Pesos deben ser numeros");
    }
    if (id) {
      dispatch(
        removeFromPendingListAsync({
          userRequestId: id,
          totalInDollars,
          totalInPesos,
          sendLocalCurrency,
        })
      ).then(() => {
        setIsLoading(true);
        setOpenModal(false);
      });
    } else {
      setOpenModal(false);
    }
  };

  const showCancelConfirm = async (id: number) => {
    Modal.confirm({
      title: "Estas seguro de cancelar este viaje?",
      icon: <ExclamationCircleFilled />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await cancelUserRequest({ userRequestId: id }).finally(() =>
          Modal.destroyAll()
        );
        const newFilters = {
          ...filters,
          page: 1,
        };
        dispatch(setFiltersUserRequestByDateDTO(newFilters));
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const onFilter = (value: any, filter: any) => {
    const status = filter.status[0];
    const newFilters = {
      ...filters,
      page: value.current,
      status: status === "" ? null : status,
    };
    setCurrentStatus(status);

    dispatch(setFiltersUserRequestByDateDTO(newFilters));
  };

  const items = (id: number) => {
    return [
      {
        key: "1",
        label: (
          <span
            onClick={() =>
              navigate(WebRoutes.TripEditRoute, {
                replace: true,
                state: {
                  id: id,
                },
              })
            }
          >
            Editar
          </span>
        ),
      },
      {
        key: "2",
        label: (
          <>
            <span onClick={() => setOpenModal(true)}>Reenviar cierre</span>
            <Modal
              title="Cotizar viaje"
              centered
              open={openModal}
              onOk={() => calculateRequest(id)}
              onCancel={() => setOpenModal(false)}
              width={1000}
            >
              <Form layout="vertical" name="trip" scrollToFirstError>
                <Row>
                  <Form.Item
                    name="totalInPesos"
                    label="Total del viaje en Pesos Uruguayos"
                    tooltip="Total del viaje en Pesos Uruguayos"
                  >
                    <Input
                      placeholder="100"
                      value={pesos}
                      onChange={(e) => {
                        setPesos(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Col>
                    <Form.Item
                      name="sendLocalCurrency"
                      label="Cierre en pesos"
                      tooltip="Enviar cierre de viaje en pesos"
                      className="checkbox-form"
                    >
                      <Checkbox
                        value={sendLocalCurrency}
                        onChange={(e) => setSendLocalCurrency(e.target.checked)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item
                    name="totalInDollars"
                    label="Total del viaje en Dolares"
                    tooltip="Total del viaje en dolares"
                  >
                    <Input
                      placeholder="100"
                      value={dollar}
                      onChange={(e) => {
                        setDollar(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Row>
              </Form>
            </Modal>
          </>
        ),
      },
      {
        key: "3",
        label: <span onClick={() => showCompleteConfirm(id)}>Completar</span>,
      },
      {
        key: "4",
        label: <span onClick={() => showCancelConfirm(id)}>Cancelar</span>,
      },
      {
        key: "5",
        label: <span onClick={() => showDeleteConfirm(id)}>Borrar</span>,
      },
    ];
  };

  const onFieldsChange = debounce((changedFields: any) => {
    const field = changedFields[0]?.name[0];
    const value = changedFields[0]?.value;
    if (field) {
      const newFilters = {
        ...filters,
        page: 1,
        [field]:
          field === "startDate" || field === "endDate"
            ? dayjs(value).toDate().toString()
            : value,
      };
      dispatch(setFiltersUserRequestByDateDTO(newFilters));
    }
  }, 500);

  return (
    <ContainerLayout title={title} showNotification={false}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Form
            layout="inline"
            initialValues={{
              shouldSendCompany: filters?.shouldSendCompany,
              startDate: dayjs(filters?.startDate),
              endDate: dayjs(filters?.endDate),
            }}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item>
              <Button
                onClick={() => handleExcelExport()}
                loading={isLoadingExcel}
              >
                {ExportToExcelDescription}
              </Button>
            </Form.Item>
            <Form.Item name="startDate">
              <DatePicker allowClear={false} />
            </Form.Item>
            <Form.Item name="endDate">
              <DatePicker allowClear={false} />
            </Form.Item>
            {selectCurrentAdminType() === AdminTypeId ? null : (
              <Form.Item name="shouldSendCompany">
                <Select
                  options={[
                    {
                      value: true,
                      label: "Filtrar por compañia",
                    },
                    {
                      value: false,
                      label: "Filtrar por conductor",
                    },
                  ]}
                />
              </Form.Item>
            )}
            <Form.Item name="bookingId">
              <Input
                type="text"
                placeholder="Buscar por booking Id"
                allowClear
              />
            </Form.Item>

            <Form.Item name="searchText">
              <Input type="text" placeholder="Buscar por nombre" allowClear />
            </Form.Item>
          </Form>
        </Col>

        <Col span={24} style={{ width: "100px" }}>
          <Table
            loading={loading}
            size="small"
            rowKey="id"
            locale={{ emptyText: "No hay datos" }}
            dataSource={userRequestByDateValue}
            pagination={{
              total: userRequestByDateTotal,
              hideOnSinglePage: true,
              showSizeChanger: false,
              onChange: onPageChange,
              showTotal: (total) => `Total ${total} viajes`,
              size: "default",
            }}
            scroll={{ x: 600, y: "calc(100vh - 305px)" }}
            onChange={onFilter}
          >
            <Column
              title="Booking Id"
              dataIndex="bookingId"
              width={100}
              fixed="left"
            />
            <Column
              title="Inicio"
              dataIndex="startedAt"
              width={100}
              render={(start) =>
                start ? dayjs(start).format("DD-MM-YYYY HH:mm") : ""
              }
            />

            <Column
              title="Fin"
              dataIndex="finishedAt"
              width={100}
              render={(item) => {
                return item ?? NoFinishDate;
              }}
            />
            <Column title="Conductor" width={150} dataIndex="providerName" />
            <Column title="Descripción" width={200} dataIndex="description" />
            <Column title="Empresa" width={150} dataIndex="company" />
            <Column title="Departmento" width={150} dataIndex="department" />
            <Column title="Cliente" width={150} dataIndex="customerName" />
            <Column title="Pasajero" width={150} dataIndex="passengerName" />
            <Column title="Origen" width={250} dataIndex="originAddress" />
            <Column
              title="Destino"
              width={250}
              dataIndex="destinationAddress"
            />
            <Column
              title="Estado"
              width={100}
              dataIndex="status"
              render={(status) =>
                IndexStatusTranslations[status]?.translation ?? status
              }
              filters={[
                {
                  value: "",
                  text: "Ninguno",
                },
                {
                  value: "ACCEPTED",
                  text: "Aceptado",
                },
                {
                  value: "SCHEDULED",
                  text: "Buscando",
                },
                {
                  value: "CANCELLED",
                  text: "Cancelado",
                },
                {
                  value: "STARTED",
                  text: "Iniciado",
                },
                {
                  value: "COMPLETED",
                  text: "Completado",
                },
                {
                  value: "ARRIVED",
                  text: "Conductor llego",
                },
                {
                  value: "CREATING",
                  text: "Creando",
                },
                {
                  value: "PICKEDUP",
                  text: "Abordado",
                },
                {
                  value: "DROPPED",
                  text: "En destino",
                },
                {
                  value: "FINISHED",
                  text: "Finalizado",
                },
                {
                  value: "TRAVELING",
                  text: "En viaje",
                },
              ]}
              filterMultiple={false}
              filteredValue={[currentStatus]}
            />
            <Column
              title="Pagado"
              dataIndex="paid"
              width={150}
              render={(paid) =>
                paid === 1 ? PaidDescription : NotPaidDescription
              }
            />
            <Column
              title={`${filters?.shouldSendCompany ? "Total" : "Comisión"}`}
              width={150}
              dataIndex={`${
                filters?.shouldSendCompany ? "total" : "commision"
              }`}
            />
            <Column title="Minutos" dataIndex="minutes" width={150} />
            <Column title="Distancia" dataIndex="distance" width={150} />
            {selectCurrentAdminType() === SuperAdminTypeId && (
              <>
                <Column
                  fixed="right"
                  width={105}
                  render={(record) => (
                    <Dropdown
                      menu={{
                        items: items(record.id ?? 0),
                      }}
                      placement="bottom"
                      arrow
                    >
                      <Button>Acciones</Button>
                    </Dropdown>
                  )}
                />
                <Column
                  fixed="right"
                  width={200}
                  render={(record) => {
                    return record.status === TRIP_STATUS.Completed && (
                      <Button
                        size="small"
                        onClick={() => {
                          navigate(WebRoutes.AdminPanelTripDetails, {
                            state: { ...record, navigationType: "SuperAdmin" },
                            replace: true,
                          });
                        }}
                      >
                        Ver detalles del viaje
                      </Button>
                    );
                  }}
                />
              </>
            )}
            {selectCurrentAdminType() === AdminTypeId && (
              <>
                <Column
                  title="Total Local"
                  dataIndex="totalLocal"
                  width={150}
                />
                <Column title="Moneda" dataIndex="currency" width={150} />
              </>
            )}
          </Table>
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default UserRequestTable;
