import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  createProvider,
  getPaginatedProviders,
  getProviderById,
  updateProvider,
} from "../../services/adminProviderApi";
import ProviderAdminDTO from "../../shared/dtos/providerAdminDTO";
import { ValidationErrors } from "../ValidationErrors";

interface PaginatedProviderProps {
  page: number;
  take: number;
  filters?: string;
}

//TODO: Mapear correctamente este state
const initialState: any = {
  data: [],
  loading: false,
  error: "",
  isSuccess: false,
  saving: false,
  loadingDriverInfo: false,
  currentProvider: undefined,
};

export const getPaginatedProvidersAsync = createAsyncThunk(
  "drivers/paginated",
  async (
    { page, take, filters }: PaginatedProviderProps,
    { rejectWithValue }
  ) => {
    try {
      return await getPaginatedProviders({ page, take, filters });
    } catch (err: any) {
      let error: AxiosError<ValidationErrors> = err; // cast the error for access

      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const createOrUpadateDriverAsync = createAsyncThunk(
  "drivers/create",
  async (providerAdminDTO: ProviderAdminDTO, { rejectWithValue }) => {
    try {
      if (providerAdminDTO.id) {
        return await updateProvider(providerAdminDTO.id, providerAdminDTO);
      }

      return await createProvider(providerAdminDTO);
    } catch (err: any) {
      let error: AxiosError<ValidationErrors> = err; // cast the error for access
      console.log("error", error);
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const getProviderDataByIdAsync = createAsyncThunk(
  "drivers/byId",
  async (providerId: number, { rejectWithValue }) => {
    try {
      return await getProviderById(providerId);
    } catch (err: any) {
      let error: AxiosError<ValidationErrors> = err; // cast the error for access

      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedProvidersAsync.pending, (state, action) => {
        state.loading = true;
        state.isSuccess = false;
        state.currentProvider = undefined;
      })
      .addCase(getPaginatedProvidersAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
      })
      .addCase(getPaginatedProvidersAsync.rejected, (state, { error }) => {
        state.loading = false;
        state.data = [];
        state.error = error.message || "";
      })
      .addCase(createOrUpadateDriverAsync.pending, (state, action) => {
        state.saving = true;
      })
      .addCase(createOrUpadateDriverAsync.fulfilled, (state, { payload }) => {
        state.saving = false;
        state.isSuccess = true;
        state.currentProvider = undefined;
      })
      .addCase(createOrUpadateDriverAsync.rejected, (state, { payload }) => {
        state.saving = false;
        state.isSuccess = false;
        state.error = (payload as any)?.message || "";
      })
      .addCase(getProviderDataByIdAsync.pending, (state, action) => {
        state.loadingDriverInfo = true;
        state.currentProvider = undefined;
      })
      .addCase(getProviderDataByIdAsync.fulfilled, (state, { payload }) => {
        state.loadingDriverInfo = false;
        state.currentProvider = payload;
      })
      .addCase(getProviderDataByIdAsync.rejected, (state, { payload }) => {
        state.loadingDriverInfo = false;
        state.error = (payload as any).message || "";
        state.currentProvider = undefined;
      });
  },
});

export default adminSlice.reducer;
