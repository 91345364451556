import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import ServiceTypeDTO from "../../../shared/dtos/serviceTypeDTO";
import { serviceTypeListAsync } from "../../../features/serviceType/serviceTypeListSlice";

export const useServicesTypes = () => {
  const [servicesTypes, setServicesTypes] = useState<ServiceTypeDTO[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(serviceTypeListAsync()).then((res: any) => {
      setServicesTypes(res?.payload);
    });
  }, [dispatch]);

  return servicesTypes;
};
