import { Alert, Button, Form, Input, Layout, Row } from "antd";
import { useState } from "react";
import "./styles.scss";
import { WebRoutes } from "../../shared/constants/WebRoutes";
import { useNavigate } from "react-router-dom";
import { adminPasswordReset } from "../../services/authApi";
import { AppDispatch } from "../../app/store";
import { useDispatch } from "react-redux";
import { removeAuth } from "../../features/auth/authSlice";
import { removeCurrentAdminValue } from "../../features/admin/currentAdminSlice";
import { Typography } from "antd";
import {
  PasswordRegexExpression,
  PasswordRequirementText,
} from "../../shared/constants/Resources";

const { Title } = Typography;

const ResetPasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch: any = useDispatch<AppDispatch>();

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const { password } = values;
    setIsLoading(true);
    await adminPasswordReset(password)
      .then(async (res: any) => {
        if (res) {
          await dispatch(removeAuth());
          await dispatch(removeCurrentAdminValue());
          navigate(WebRoutes.LoginRoute, { replace: true });
        }
      });
      setIsLoading(false)
  };

  return (
    <Layout className="reset-password-container">
      <Row>
        <Title>Actualizar contraseña</Title>
      </Row>
      <Row justify="center">
        <Alert
          message="Favor de crear una nueva contraseña"
          description="Por temas de seguridad, la contraseña debe actualizarse y debe
          contener minimo 7 caracteres, una letra, un numero y un caracter
          especial !+-.@#$%^&"
          type="warning"
          showIcon
        />
      </Row>
      <Row className="form-container">
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[
              {
                required: true,
                message: "Por favor ingresa una clave!",
              },
              {
                pattern: PasswordRegexExpression,
                message: PasswordRequirementText,
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirmar contraseña"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Por favor ingresa una clave!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Las contraseñas no coinciden!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="button-container"
            loading={isLoading}
          >
            Actualizar contraseña
          </Button>
        </Form>
      </Row>
    </Layout>
  );
};

export default ResetPasswordForm;
