import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import { AdminQrDTO } from "../shared/dtos/adminQrDTO";
import PatchAdminQrDTO from "../shared/dtos/PatchAdminQrDTO";
const apiUrl = process.env.REACT_APP_API_URL;

export const getAdminQrByAdminId = async (adminId: number) => {
  const result = await axios.get(`${apiUrl}adminqr/${adminId}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const getAdminQrByCode = async (code: string) => {
  const result = await axios.get(`${apiUrl}adminqr/code/${code}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const createAdminQr = async (adminQr: AdminQrDTO) => {
  const result = await axios.post(`${apiUrl}adminqr`, adminQr, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result;
};

export const updateAdminQr = async (patchAdminQrDTO: PatchAdminQrDTO) => {
  const result = await axios.patch(`${apiUrl}adminqr`, patchAdminQrDTO, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result;
};

export const deleteAdminQr = async (id: number) => {
  const result = await axios.delete(`${apiUrl}adminqr/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};
