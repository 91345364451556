import { Space, Typography } from "antd";
import { CSSProperties } from "react";

const { Text } = Typography;

interface Prop {
  label: string;
  children: JSX.Element;
  style?: CSSProperties;
  boldText?: string;
}

const LabelAndInput = ({ label, children, style, boldText }: Prop) => {
  return (
    <Space direction="vertical" size={1} style={style}>
      <Text>{label}</Text>
      {boldText != null ? <b>{boldText}</b> : null}
      {children}
    </Space>
  );
};

export default LabelAndInput;
