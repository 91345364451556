import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { getPaginatedRegions } from "../../../services/regionsApi";
import { columns, DataType } from "./columns";

import "../../../shared/styles/list.scss";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

const { Text } = Typography;
const { Search } = Input;

const RegionList = () => {
  const title = "Administración de Regiones";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [ignoreFetch, setIgnoreFetch] = useState(false);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = async () => {
    setLoading(true);

    const { data, total } = await getPaginatedRegions({
      page: tableParams.pagination?.current || 1,
      take: tableParams.pagination?.pageSize || 10,
      name: search,
    });

    setData(data);
    setLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
      },
    });
    setIgnoreFetch(true);
  };

  useEffect(() => {
    if (!ignoreFetch) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams), search]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    setIgnoreFetch(false);
  };

  const onSearch = async (value: string) => {
    setIgnoreFetch(false);
    setSearch(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row">
          <Col className="input-container-table">
            <Button
              onClick={() =>
                navigate(WebRoutes.CreateRegionRoute, {
                  replace: true,
                })
              }
            >
              Crear nueva región
            </Button>
          </Col>

          <Space>
            <Text strong style={{ marginLeft: 20 }}>
              Buscar
            </Text>
            <Search
              placeholder="Nombre de la region"
              allowClear
              onSearch={onSearch}
            />
          </Space>
        </Row>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={columns(navigate)}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Row>
      </Content>
    </Layout>
  );
};

export default RegionList;
