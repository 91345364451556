import { Button, Modal, Tag } from "antd";
import FormDirection from "../../groupTrip/directionGroup/FormDirection";
import { useState } from "react";
import { FolderAddOutlined } from "@ant-design/icons";

interface Props {
  type: string;
  setUpdatePassengerAddress: (passenger: any) => void;
  passengerData: any;
}

const style = { fontSize: 14, fontWeight: "bold" };

const ModalValidate = ({
  type,
  setUpdatePassengerAddress,
  passengerData,
}: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState<any>(undefined);

  const handleCancel = () => {
    setShow(false);
  };

  const handleOk = () => {
    if (updatedAddress) {
      const newPassengerData = {
        ...passengerData,
      };
      if (type === "origin") {
        const updateOrigin = {
          ...passengerData?.origin,
          address: updatedAddress?.address,
          coordinates: {
            lat: updatedAddress.latitude,
            lng: updatedAddress.longitude,
          },
          googlemaps: updatedAddress.id,
        };
        newPassengerData.origin = {
          ...updateOrigin,
        };
      } else {
        const updateDestiny = {
          ...passengerData?.destiny,
          address: updatedAddress?.address,
          coordinates: {
            lat: updatedAddress.latitude,
            lng: updatedAddress.longitude,
          },
          googlemaps: updatedAddress.id,
        };
        newPassengerData.destiny = {
          ...updateDestiny,
        };
      }

      setUpdatePassengerAddress(newPassengerData);
    }
  };

  return (
    <>
      <Button type="link" onClick={() => setShow(true)}>
        <FolderAddOutlined
          style={{ marginLeft: "40%", color: "red", fontSize: 25 }}
        />
      </Button>
      <Modal
        title={
          <>
            Validar dirección del pasajero :
            <Tag style={style}>
              {`${passengerData?.firstName || ""} ${
                passengerData?.lastName || ""
              }`}
            </Tag>
          </>
        }
        open={show}
        onCancel={handleCancel}
        destroyOnClose
        footer={[
          <Button type="primary" key="submitModalPasseger" onClick={handleOk}>
            Aceptar
          </Button>,
          <Button
            type="primary"
            key="cancelModalPasseger"
            onClick={handleCancel}
          >
            Cancelar
          </Button>,
        ]}
      >
        <h3>
          {type === "origin" ? (
            <>
              Origen : <Tag style={style}>{passengerData?.origin?.name}</Tag>
            </>
          ) : (
            <>
              Destino : <Tag style={style}>{passengerData?.destiny?.name}</Tag>
            </>
          )}
        </h3>

        <FormDirection setDir={setUpdatedAddress} />
      </Modal>
    </>
  );
};

export default ModalValidate;
