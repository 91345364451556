import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const createClientApi = async (data: any) => {
  const result = await axios.post(`${apiUrl}admin/users`, data, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const editClientApi = async (data: any, id: string | number) => {
  const result = await axios.patch(`${apiUrl}admin/users/${id}`, data, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const deleteClientApi = async (id: string | number) => {
  const result = await axios.delete(`${apiUrl}admin/users/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};
