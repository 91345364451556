import { Button, Input, Layout, Row } from "antd";
import { useState } from "react";
import "./styles.scss";
import { AppDispatch } from "../../app/store";
import { authAsync } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { MowiLogo } from "../../shared/constants/Images";
import { WebRoutes } from "../../shared/constants/WebRoutes";
import { useNavigate } from "react-router-dom";
import {
  selectCurrentAdminType,
} from "../../utils/helpers/selectorHelper";
import {
  AdminTypeId,
  OperatorTypeId,
  SuperAdminTypeId,
} from "../../shared/constants/Resources";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setIsLoading(true);
    await dispatch(authAsync({ email, password })).then((res: any) => {
      const adminTypeId: number = selectCurrentAdminType();

      if (adminTypeId === SuperAdminTypeId || adminTypeId === OperatorTypeId) {
        return navigate(WebRoutes.TripListRoute, {
          replace: true,
        });
      }

      if (adminTypeId === AdminTypeId) {
        return navigate(WebRoutes.IndexAdminPanel, {
          replace: true,
        });
      }
    });
    setIsLoading(false);
  };

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <Layout className="login-container">
      <Row>
        <img src={MowiLogo} alt="mowi logo" />
      </Row>
      <Row className="form-container">
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="correo"
          className="input-container"
          onKeyDown={handleKeypress}
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="contraseña"
          onKeyDown={handleKeypress}
        />
        <Button
          type="default"
          className="button-container"
          onClick={handleLogin}
          loading={isLoading}
        >
          Login
        </Button>
      </Row>
    </Layout>
  );
};

export default Login;
