import { Button, Col, Row } from "antd";
import Pasajeros from "./Passenger";
import { validateDTO } from "../../../shared/dtos/PassengerGroupDTO";
import { useAppSelector } from "../../../app/hooks";
import GroupTripsMap from "../groupTripsMap/groupTripsMap";
import { useState } from "react";
import ModalGenerateTrips from "./ModalGenerateTrips";

const GridViajes = () => {
  const [open, setOpen] = useState<boolean>(false);
  const validate: validateDTO = useAppSelector(
    (state) => state.passeger.validating
  );
  const selected = useAppSelector((state) => state.groupTripsRequest.selected);

  return (
    <>
      <Row style={{ height: "100%" }}>
        <Col span={16}>
          <Pasajeros />
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
            }}
            disabled={selected ? false : true}
          >
            Generar viajes
          </Button>
          {!validate.active && <GroupTripsMap type="passeger" />}
        </Col>
      </Row>
      <ModalGenerateTrips open={open} setOpen={setOpen} />
    </>
  );
};

export default GridViajes;
