import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Popconfirm,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import {
  adminListAsync,
  updateAdminListValue,
} from "../../../features/admin/adminListSlice";
import {
  deleteAdmin,
  getAdminsExcel,
  impersonateAdminLogin,
  patchAdminPassword,
} from "../../../services/adminApi";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import AdminDTO from "../../../shared/dtos/adminDTO";
import AdminWalletDTO from "../../../shared/dtos/adminWalletDTO";
import TextArea from "antd/lib/input/TextArea";
import {
  AdminWalletConcept,
  AdminWalletDeposit,
  PasswordRegexExpression,
  PasswordRequirementText,
} from "../../../shared/constants/Resources";
import { createAdminWallet } from "../../../services/adminWalletApi";
import { FilterValue, SorterResult } from "antd/lib/table/interface";

import "./styles.scss";
import AdminSwitch from "./utils/adminSwitch";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters: Record<string, FilterValue | null>;
}
const { Search } = Input;
const { Column } = Table;

const AdminList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [amount, setAmount] = useState(0);
  const [concept, setConcept] = useState(AdminWalletConcept);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [ignoreFetch, setIgnoreFetch] = useState(false);
  const [data, setData] = useState(new Array<AdminDTO>());
  const title: string = "Administradores";
  const { loading } = useSelector((state: RootState) => state.adminList);

  const [form] = Form.useForm();

  const [tableParams, setTableParams] = useState<TableParams>({
    filters: {
      active: [true],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    dispatch(updateAdminListValue());
  }, [dispatch]);

  const fetchData = (filters?: string) => {
    dispatch(
      adminListAsync({
        page: tableParams.pagination?.current || 1,
        take: tableParams.pagination?.pageSize || 10,
        searchText,
      })
    ).then(({ payload }: any) => {
      setData(payload.adminDtos);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: payload.total,
        },
      });
    });

    setIgnoreFetch(true);
  };

  useEffect(() => {
    if (!ignoreFetch) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreFetch, JSON.stringify(tableParams), searchText]);

  const handleAdminType = (adminTypeId: number) => {
    switch (adminTypeId) {
      case 1:
        return "Super Admin";
      case 2:
        return "Admin";
      case 3:
        return "Operador";
      default:
        return "Admin";
    }
  };

  const handleIsActive = (active: any) => {
    switch (active) {
      case true:
        return "Si";
      case false:
        return "No";
      default:
        return "No";
    }
  };

  const handleUpdatePassword = async (values: any) => {
    const { password } = values;
    if (selectedAdminId && password) {
      await patchAdminPassword(selectedAdminId, password);
      setShowPasswordInput(false);
      setSelectedAdminId(0);
    }
  };

  const handleOk = async (adminId: number) => {
    setConfirmLoading(true);
    const adminWallet: AdminWalletDTO = {
      adminId: adminId,
      amount: amount,
      concept: concept,
      transactionType: AdminWalletDeposit,
    };
    await createAdminWallet(adminId, adminWallet);
    setConfirmLoading(false);
    setAmount(0);
    setConcept(AdminWalletConcept);
    setIsModalOpen(!isModalOpen);
  };

  const handleExcelExport = async () => {
    setIsLoadingExcel(true);
    const result = await getAdminsExcel();
    setIsLoadingExcel(false);
    const link = document.createElement("a");
    link.href = result;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AdminDTO> | SorterResult<AdminDTO>[]
  ) => {
    setIgnoreFetch(false);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const onSearch = (value: string) => {
    setIgnoreFetch(false);
    setSearchText(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row">
          <Col className="input-container-table">
            <Button
              onClick={() =>
                navigate(WebRoutes.CreateAdminRoute, {
                  replace: true,
                })
              }
            >
              Agregar administrador
            </Button>
          </Col>
          <Col className="input-container-table">
            <Search
              placeholder="Buscar por nombre"
              allowClear
              onSearch={onSearch}
            />
          </Col>
          <Col className="input-container-table"></Col>
          <Col span={12} className="input-container-table">
            <Button
              onClick={() =>
                navigate(WebRoutes.AdminDocumentListRoute, {
                  replace: true,
                })
              }
            >
              Lista de liquidaciones
            </Button>
          </Col>
          <Col>
            <Button
              loading={isLoadingExcel}
              onClick={() => handleExcelExport()}
            >
              Descargar lista
            </Button>
          </Col>
        </Row>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            rowKey={(record: AdminDTO) => Number(record.id)}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          >
            <Column title="Id" dataIndex="id" width={100} />
            <Column title="Nombre" width={100} dataIndex="name" />
            <Column title="Correo electronico" dataIndex="email" width={100} />
            <Column title="Movil" dataIndex="mobile" width={100} />
            <Column
              title="Activo"
              width={100}
              render={(record: AdminDTO) => {
                return handleIsActive(record.active);
              }}
            />
            <Column
              title="Tipo de Admin"
              width={100}
              render={(record: AdminDTO) => {
                return handleAdminType(record.active);
              }}
            />
            <Column
              title="Avanzado"
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <AdminSwitch
                    adminId={record.id}
                    switchValue={record.advanced}
                    setIgnoreFetch={setIgnoreFetch}
                    swithType="advanced"
                  />
                );
              }}
            />
            <Column
              title="Partner"
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <AdminSwitch
                    adminId={record.id}
                    switchValue={record.partner}
                    setIgnoreFetch={setIgnoreFetch}
                    swithType="partner"
                  />
                );
              }}
            />
            <Column
              title="Wallet"
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <AdminSwitch
                    adminId={record.id}
                    switchValue={record.wallet}
                    setIgnoreFetch={setIgnoreFetch}
                    swithType="wallet"
                  />
                );
              }}
            />
            <Column
              title="Coordenadas"
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <AdminSwitch
                    adminId={record.id}
                    switchValue={record.wallet}
                    setIgnoreFetch={setIgnoreFetch}
                    swithType="coordinates"
                  />
                );
              }}
            />
            <Column
              title="Enviar whatsapp"
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <AdminSwitch
                    adminId={record.id}
                    switchValue={record.sendWhatsappMessage}
                    setIgnoreFetch={setIgnoreFetch}
                    swithType="sendWhatsappMessage"
                  />
                );
              }}
            />
            <Column
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <>
                    <Button
                      disabled={!record.wallet}
                      onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                      Depositar fondos
                    </Button>
                    <Modal
                      title="Depositar fondos"
                      open={isModalOpen}
                      onOk={() => handleOk(record.id)}
                      onCancel={() => setIsModalOpen(false)}
                      confirmLoading={confirmLoading}
                      okText={confirmLoading ? "Procesando" : "Depositar"}
                    >
                      <Row style={{ marginBottom: "20px" }}>
                        <label>Cantidad a depositar:</label>
                        <Input
                          value={amount}
                          type="number"
                          onChange={(e) => setAmount(+e.target.value)}
                          placeholder="Ingresa un numero"
                        />
                      </Row>
                      <Row>
                        <label>Concepto de pago:</label>
                        <TextArea
                          value={concept}
                          onChange={(e) => setConcept(e.target.value)}
                          placeholder="Ingresar concepto"
                        />
                      </Row>
                    </Modal>
                  </>
                );
              }}
            />
            <Column
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <Button
                    size="small"
                    onClick={() => {
                      navigate(WebRoutes.UpdateAdminRoute, {
                        replace: true,
                        state: {
                          adminDTO: record,
                        },
                      });
                    }}
                  >
                    Actualizar
                  </Button>
                );
              }}
            />
            <Column
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <Popconfirm
                    title="Al ingresar a otro usuario, si requiere ingresar a su admin, tiene que iniciar sesion de nuevo"
                    onConfirm={async () => {
                      await impersonateAdminLogin(record.id);
                      navigate("/", { replace: true });
                    }}
                    okText="Si"
                    cancelText="No"
                    placement="bottom"
                  >
                    <Button size="small">Administrador</Button>
                  </Popconfirm>
                );
              }}
            />
            <Column
              width={100}
              render={(record: AdminDTO) => {
                return showPasswordInput && record.id === selectedAdminId ? (
                  <Row justify="space-evenly">
                    <Form
                      form={form}
                      name="basic"
                      onFinish={handleUpdatePassword}
                      layout="vertical"
                      fields={[
                        {
                          name: "password",
                          value: "",
                        },
                      ]}
                    >
                      <Col>
                        <Form.Item
                          label="Contraseña"
                          name="password"
                          rules={[
                            {
                              pattern: PasswordRegexExpression,
                              message: PasswordRequirementText,
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button size="small" htmlType="submit">
                          Actualizar
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          size="small"
                          onClick={() => {
                            setShowPasswordInput(false);
                            setSelectedAdminId(0);
                          }}
                        >
                          Cancelar
                        </Button>
                      </Col>
                    </Form>
                  </Row>
                ) : (
                  <Button
                    size="small"
                    onClick={() => {
                      setSelectedAdminId(record.id);
                      setShowPasswordInput(true);
                    }}
                  >
                    Actualizar contraseña
                  </Button>
                );
              }}
            />
            <Column
              width={100}
              render={(record: AdminDTO) => {
                return (
                  <Popconfirm
                    title="Estas seguro de eliminar este administrador?"
                    onConfirm={async () => {
                      await deleteAdmin(record.id);
                      setIgnoreFetch(false);
                    }}
                    okText="Si"
                    cancelText="No"
                    placement="bottom"
                  >
                    <Button size="small">Borrar</Button>
                  </Popconfirm>
                );
              }}
            />
          </Table>
        </Row>
      </Content>
    </Layout>
  );
};

export default AdminList;
