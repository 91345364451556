import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DepartmentDTO from "../../shared/dtos/departmentDTO";
import { getDepartmentsByAdminApi } from "../../services/departmentApi";

interface InitialState {
  value: Array<DepartmentDTO>;
  loading: boolean;
}

const initialState: InitialState = {
  value: [] as Array<DepartmentDTO>,
  loading: false,
};

export const departmentsByAdminAsync = createAsyncThunk(
  "departmentsByAdmin/",
  async () => {
    const response = await getDepartmentsByAdminApi();
    return response;
  }
);

const departmentsByAdminSlice = createSlice({
  name: "departmentsByAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(departmentsByAdminAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(departmentsByAdminAsync.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loading = false;
      })
      .addCase(departmentsByAdminAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default departmentsByAdminSlice.reducer;
