export const NotificationType = [
    {
        label: "Notificacion con Nombre, Fecha y Hora",
        value: 1
    },
    {
        label: "Notificacion con Nombre, Fecha, Hora y Booking Id",
        value: 2
    },
    {
        label: "Notificacion con Nombre, Informacion del Conductor, Hora y Ubicaciones",
        value: 3
    }
]
