import axios from "axios";
import AssingProviderProps from "../propInterfaces/assignProviderProps";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import UserRequestDTO from "../shared/dtos/userRequestDTO";
import { notification } from "antd";

const apiUrl = process.env.REACT_APP_API_URL;

export const assignProviderToUserRequest = async ({
  userRequestId,
  providerId,
}: AssingProviderProps) => {
  const result = await axios.get(
    `${apiUrl}admin/trip/assign/${userRequestId}/${providerId}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  )
  .then((response: any) => {
    notification.success({
      message: `El conductor`,
      description: `se ha asignado exitosamente`,
      placement: "bottom",
    });
    return response;
  })
  .catch((error: any) => {
    console.log(error);
    notification.error({
      message: error.response.data.status,
      description:
      error.response.data.message ?? "Error en el servidor",
      placement: "bottom",
    });
  });;
  return result.data;
};

interface AssingProviderForPackageProps {
  userRequests: UserRequestDTO[];
  providerId: number;
}

export const assignProviderToUserRequests = async ({
  userRequests,
  providerId,
}: AssingProviderForPackageProps) => {
  const result = await axios.post(
    `${apiUrl}admin/trip/assign-multiple/${providerId}`,
    {
      userRequests,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};
