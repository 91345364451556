import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ModelState from "../../shared/states/modelState";
import { getAdminComission } from "../../services/userRequestApi";
import AdminComissionDTO from "../../shared/dtos/adminComissionDTO";

const initialState: ModelState<AdminComissionDTO> = {
  value: {
    totalEarnings: 0,
    dateRange: ""
  },
  status: "idle",
};

export const getAdminComissionAsync = createAsyncThunk(
  "admincomission/",
  async (showLastPeriod: boolean) => {
    const response = await getAdminComission(showLastPeriod);
    return response.data;
  }
);

export const getAdminComissionSlice = createSlice({
  name: "getAdminComission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminComissionAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAdminComissionAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(getAdminComissionAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default getAdminComissionSlice.reducer;
