import { MultipleLoadAddressDTO } from "../../../shared/dtos/multipleLoadDTO";

export const parseDataFromXMLSMultipleLoad = (data: Array<any>): Array<any> => {
  return data.map((i) => {
      return {
        id: i["__rowNum__"],
        firstName: i["__EMPTY"],
        lastName: i["__EMPTY_1"],
        contact: i["__EMPTY_2"],
        origin: i["__EMPTY_3"],
        destiny: i["__EMPTY_4"],
        monday: i["__EMPTY_5"],
        tuesday: i["__EMPTY_6"],
        wednesday: i["__EMPTY_7"],
        thursday: i["__EMPTY_8"],
        friday: i["__EMPTY_9"],
        saturday: i["__EMPTY_10"],
        sunday: i["__EMPTY_11"],
      };
  });
};

export const formattedAddress = (
  defaultName: string,
  validatedAddress: any
): MultipleLoadAddressDTO => {
  return {
    googlemaps: validatedAddress.place_id,
    address: validatedAddress.formatted_address,
    name: defaultName,
    coordinates: {
      lat: validatedAddress?.geometry?.location?.lat,
      lng: validatedAddress?.geometry?.location?.lng,
    },
  };
};
