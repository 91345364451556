import { ExclamationCircleFilled, PlusSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Row,
  notification,
  Space,
  Select,
  Modal,
  Divider,
  Typography,
} from "antd";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import { updateUserRequestValue } from "../../../../features/userRequest/pendingUserRequestSlice";
import { removeProviderFromUserRequestAsync } from "../../../../features/userRequest/removeProviderFromUserRequestSlice";
import {
  removeProviderFromLocalUserRequestValue,
  setSelectedAdminId,
  setSelectedCountryId,
  userRequestListAsync,
} from "../../../../features/userRequest/userRequestListSlice";
import { TRIP_STATUS } from "../../../../shared/constants/TripStatus";
import { WebRoutes } from "../../../../shared/constants/WebRoutes";
import UserRequestDTO from "../../../../shared/dtos/userRequestDTO";
import { useState, useEffect } from "react";
import { getServiceTypeList } from "../../../../services/serviceTypeApi";
import ServiceTypeDTO from "../../../../shared/dtos/serviceTypeDTO";
import { setSelectedServiceTypeId } from "../../../../features/serviceType/serviceTypeListSlice";
import { useAppSelector } from "../../../../app/hooks";
import {
  cancelUserRequest,
  createTripBundle,
  deleteUserRequest,
} from "../../../../services/userRequestApi";
import { COUNTRIES } from "../../../../utils/constants/countries";
import { GoogleMapsCountryDTO } from "../../../../shared/dtos/googleMapsCountryDTO";
import { selectSelectedCountryId } from "../../../../utils/helpers/selectorHelper";
import { adminsForSelectAsync } from "../../../../features/admin/adminsForSelectSlice";

const { Text } = Typography;

interface FilterProps {
  isProviderAssinged: boolean;
  isLoading: boolean;
  selectedUserRequest: UserRequestDTO[] | null;
  setIsProviderAssigned: any;
  setSelectedUserRequest: (selected: UserRequestDTO[]) => void;
  selectedUserRequestForPackage: UserRequestDTO[];
  setRefreshData: any;
  setCountryId: any;
  setAdminId: any;
}

export const Filter = ({
  isProviderAssinged,
  isLoading,
  selectedUserRequest,
  setIsProviderAssigned,
  setSelectedUserRequest,
  selectedUserRequestForPackage,
  setRefreshData,
  setCountryId,
  setAdminId,
}: FilterProps) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const cancellableStatus = [
    TRIP_STATUS.Searching,
    TRIP_STATUS.Accepted,
    TRIP_STATUS.Arrived,
    TRIP_STATUS.Started,
    TRIP_STATUS.Created,
    TRIP_STATUS.Scheduled,
  ];

  const [servicesTypeList, setServicesTypesList] = useState([]);
  const selectedServiceTypeId = useAppSelector(
    (state: RootState) => state.serviceTypeList.selectedServiceTypeId
  );
  const adminsForSelect = useAppSelector(
    (state: RootState) => state.adminsForSelect.value
  );
  const selectedAdminId: number | undefined = useAppSelector(
    (state: RootState) => state.userRequestList.selectedAdminId
  );

  const currentUserRequest = (): UserRequestDTO | undefined => {
    return selectedUserRequest && selectedUserRequest.length === 1
      ? selectedUserRequest[0]
      : undefined;
  };

  useEffect(() => {
    const getServicesTypes = async () => {
      try {
        let list = await getServiceTypeList();
        list = [{ id: 0, name: "Mostrar conductores disponibles" }, ...list];
        setServicesTypesList(list);
        dispatch(setSelectedServiceTypeId(list[0]?.id));
      } catch (error) {
        console.log(error);
      }
    };
    getServicesTypes();

    return () => {
      dispatch(setSelectedServiceTypeId(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(adminsForSelectAsync());
  }, [dispatch]);

  const handleOnChange = (id: number) => {
    dispatch(setSelectedServiceTypeId(id));
  };

  const showCancelConfirm = async (id?: number | null) => {
    if (!id) {
      return;
    }

    Modal.confirm({
      title: "Estas seguro de cancelar esta solicitud de viaje?",
      icon: <ExclamationCircleFilled />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await cancelUserRequest({ userRequestId: id }).finally(() =>
          Modal.destroyAll()
        );
        dispatch(userRequestListAsync({}));
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const showDeleteConfirm = async (id?: number | null) => {
    Modal.confirm({
      title: "Estas seguro de eliminar esta solicitud de viaje?",
      icon: <ExclamationCircleFilled />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await deleteUserRequest({ userRequestId: id }).finally(() => {
          Modal.destroyAll();
        });
        dispatch(userRequestListAsync({}));
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const assignRequestsToDriver = () => {
    if (selectedUserRequestForPackage.length < 2) {
      notification.warning({
        message:
          "Debe seleccionar al menos dos viajes para crear un paquete de viajes",
        placement: "bottom",
      });
      return;
    }

    dispatch(updateUserRequestValue(selectedUserRequestForPackage));
    navigate(WebRoutes.AssignProviderRoute, {
      state: { package: true },
      replace: true,
    });
  };

  const handleCreateTripBundle = async () => {
    if (selectedUserRequestForPackage.length < 2) {
      notification.warning({
        message:
          "Debe seleccionar al menos dos viajes para crear un paquete de viajes",
        placement: "bottom",
      });
      return;
    }

    await createTripBundle(selectedUserRequestForPackage).then((x) => {
      notification.success({
        message: "Se ha creado exitosamente el paquete de viajes",
        placement: "bottom",
      });
    });
  };

  const handleOnCountryChange = (id: number) => {
    dispatch(setSelectedCountryId(id));
    setCountryId(id);
    setRefreshData(true);
  };

  const handleOnAdminChange = (id: number) => {
    dispatch(setSelectedAdminId(id));
    setAdminId(id);
    setRefreshData(true);
  };

  return (
    <Row style={{ marginBottom: "10px" }}>
      <Space>
        {servicesTypeList?.length > 0 && (
          <Select
            size="small"
            style={{ width: 210 }}
            onChange={handleOnChange}
            defaultValue={selectedServiceTypeId}
          >
            {servicesTypeList.map((st: ServiceTypeDTO) => (
              <Select.Option key={st.id} value={st.id}>
                {st?.name}
              </Select.Option>
            ))}
          </Select>
        )}
        <Select
          size="small"
          style={{ width: 210 }}
          onChange={handleOnCountryChange}
          defaultValue={selectSelectedCountryId()}
        >
          <Select.Option key={0} value={0}>
            Todos los paises
          </Select.Option>
          {COUNTRIES.map((googleMapsCountryDTO: GoogleMapsCountryDTO) => (
            <Select.Option
              key={googleMapsCountryDTO.value}
              value={googleMapsCountryDTO.value}
            >
              {googleMapsCountryDTO?.label}
            </Select.Option>
          ))}
        </Select>
        {adminsForSelect?.length > 0 && (
          <Select
            size="small"
            style={{ width: 210 }}
            onChange={handleOnAdminChange}
            defaultValue={selectedAdminId}
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toString().toLowerCase())
            }
          >
            <Select.Option key={0} value={0}>
              Ninguno
            </Select.Option>
            {adminsForSelect.map((st: ServiceTypeDTO) => (
              <Select.Option key={st.id} value={st.id}>
                {st?.name}
              </Select.Option>
            ))}
          </Select>
        )}
        {selectedUserRequest &&
        selectedUserRequest[0] &&
        !cancellableStatus.some((c) => c === selectedUserRequest[0].status) ? (
          <>
            <Divider type="vertical" />
            <Text className="default-font">No se puede remover conductor en estado actual</Text>
          </>
        ) : !selectedUserRequest ||
          !selectedUserRequest[0] ? null : isLoading ? null : isProviderAssinged ? (
          <>
            <Divider type="vertical" />
            <Button
              size="small"
              onClick={async () => {
                if (selectedUserRequest[0].id) {
                  Promise.all(
                    selectedUserRequest.map(async (p) => {
                      return await dispatch(
                        removeProviderFromUserRequestAsync({
                          userRequestId: p.id,
                        })
                      ).then(() => {
                        notification.success({
                          message: "Se removio el conductor existosamente",
                          description: `Se removio el conductor del viaje ${p.bookingId}`,
                          placement: "bottom",
                        });
                        dispatch(
                          removeProviderFromLocalUserRequestValue({
                            userRequestId: p.id,
                          })
                        );
                      });
                    })
                  );

                  setIsProviderAssigned(false);
                  setSelectedUserRequest([]);
                  navigate(WebRoutes.TripListRoute, {
                    replace: true,
                  });
                }
              }}
            >
              Remover conductor
            </Button>
          </>
        ) : selectedUserRequestForPackage.length >= 2 ? (
          <>
            <Divider type="vertical" />
            <Button
              size="small"
              type="primary"
              onClick={assignRequestsToDriver}
            >
              Asignar conductor a viajes
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={handleCreateTripBundle}
            >
              Crear paquete de viajes
            </Button>
          </>
        ) : (
          <>
            <Divider type="vertical" />
            <Button
              size="small"
              onClick={() => {
                dispatch(updateUserRequestValue(selectedUserRequest));
                navigate(WebRoutes.AssignProviderRoute, { replace: true });
              }}
            >
              Asignar conductor
            </Button>
          </>
        )}
        {currentUserRequest()?.status === TRIP_STATUS.Scheduled && (
          <>
            <Divider type="vertical" />
            <Button
              size="small"
              danger
              onClick={() => showCancelConfirm(currentUserRequest()?.id)}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              size="small"
              danger
              onClick={() => showDeleteConfirm(currentUserRequest()?.id)}
            >
              Eliminar
            </Button>
          </>
        )}

        <Divider type="vertical" />

        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => navigate(WebRoutes.TripCreateRoute, { replace: true })}
        >
          Crear
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() =>
            navigate(WebRoutes.DispatcherGroupTrip, {
              state: { dispatcher: true },
              replace: true,
            })
          }
        >
          Viaje en grupo
        </Button>
      </Space>
    </Row>
  );
};

export default Filter;
