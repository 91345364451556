import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_GROUP_TRIPS_MOCROSERVICE_API;
const apiUrlMowi = process.env.REACT_APP_API_URL;

interface Filters {
  requestId?: string;
  start?: number;
  limit?: number;
}

export const getGroupTrips = async (data?: Filters) => {
  const result = await axios.get(`${apiUrl}trip`, {
    params: {
      request: data?.requestId,
      start: data?.start,
      limit: data?.limit,
    },
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const editGroupTrips = async (trip: any) => {
  const result = await axios.put(`${apiUrl}trip/${trip.id}`, trip, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const deleteGroupTrip = async (groupTripId: string) => {
  const result = await axios.delete(`${apiUrl}trip/${groupTripId}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data.data;
};

export const deleteMowiTrip = async (id: string) => {
  const result = await axios.patch(
    `${apiUrlMowi}admin/trip/status/${id}`,
    { status: "CANCELLED", distance: 0 },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const generateTrip = async (data: any) => {
  const result = await axios.post(`${apiUrlMowi}trip/dispatch`, data, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const addTrips = async (trips: Array<any>) => {
  const result = await axios.post(
    `${apiUrl}trip/multiple`,
    {
      trips,
    },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const getDriversGroupTrips = async (
  latitude: number | string | null | undefined,
  longitude: number | string | null | undefined,
  distance: number | string | null | undefined,
  serviceTypeId?: number | string | null | undefined
) => {
  const result = await axios.get(
    `${apiUrlMowi}providers/active?latitude=${latitude}&longitude=${longitude}&distance=${distance}${
      serviceTypeId ? `&serviceTypeId=${serviceTypeId}` : ""
    }`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};
