export const getBackgroundByStatus = (userRequestDtoStatus: string) => {
  let backgroundColor = "";

  switch (userRequestDtoStatus) {
    case "Finalizado":
      backgroundColor = "#20b9ae";
      break;
    case "Completado":
      backgroundColor = "#20b9ae";
      break;
    case "Agendado":
      backgroundColor = "#1547AA";
      break;
    case "Cancelado":
      backgroundColor = "red";
      break;
    case "En viaje":
      backgroundColor = "#15AA2C";
      break;
    case "Abordado":
      backgroundColor = "#15AA2C";
      break;
    case "En destino":
      backgroundColor = "#20b9ae";
      break;
    case "Conductor llego":
      backgroundColor = "#20b9ae";
      break;
    case "Aceptado":
      backgroundColor = "#1547AA";
      break;
    case "Iniciado":
      backgroundColor = "#1547AA";
      break;
    case "Buscando":
      backgroundColor = "orange";
      break;
    case "Creando":
      backgroundColor = "orange";
      break;
    default:
      backgroundColor = "";
      break;
  }
  return backgroundColor;
};
