import { createSlice } from "@reduxjs/toolkit";
import { getUserRequestsStatementBetweenTwoDates } from "../../services/userRequestApi";
import UserRequestByDateDTO from "../../shared/dtos/userRequestExportDTO";
import { AppThunk } from "../../app/store";
import { message } from "antd";
import {
  AdminTypeId,
  endDateValue,
  startDateValue,
} from "../../shared/constants/Resources";
import moment from "moment";
import {
  selectCurrentAdminId,
  selectCurrentAdminType,
} from "../../utils/helpers/selectorHelper";

interface Filters {
  startDate: string;
  endDate: string;
  page: number;
  take: number;
  searchText: string | undefined;
  bookingId: string | undefined;
  shouldSendCompany?: boolean;
  status: string | undefined;
}

const defaultFilters: Filters = {
  startDate: startDateValue,
  endDate: endDateValue,
  page: 1,
  take: 10,
  searchText: undefined,
  bookingId: undefined,
  shouldSendCompany: true,
  status: undefined,
};

const initialState = {
  value: {
    userRequestByDateDto: new Array<UserRequestByDateDTO>(),
    total: 0,
  },
  loading: false,
  filtersUserRequestByDateDTO: structuredClone(defaultFilters),
};

export const userRequestByDateListSlice = createSlice({
  name: "userRequestListExport",
  initialState,
  reducers: {
    updateUserRequestByDateValue: (state, actions) => {
      state.value = actions.payload;
    },
    setLoadingUserRequestByDateValue: (state, actions) => {
      state.loading = actions.payload;
    },
    setFiltersUserRequestByDateDTO: (state, actions) => {
      state.filtersUserRequestByDateDTO = actions.payload
        ? actions.payload
        : structuredClone(defaultFilters);
    },
  },
});

export const {
  updateUserRequestByDateValue,
  setLoadingUserRequestByDateValue,
  setFiltersUserRequestByDateDTO,
} = userRequestByDateListSlice.actions;

export const userRequestByDateListAsync = ({
  startDate,
  endDate,
  page,
  take = 10,
  bookingId,
  shouldSendCompany,
  searchText,
  status,
}: Filters): AppThunk => {
  return async (dispatch) => {
    const adminId =
      selectCurrentAdminType() === AdminTypeId ? selectCurrentAdminId() : null;
    try {
      dispatch(setLoadingUserRequestByDateValue(true));
      const response: any = await getUserRequestsStatementBetweenTwoDates({
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
        page,
        take,
        bookingId,
        searchText,
        shouldSendCompany,
        adminId,
        status,
      });

      const userRequestByDateListObject = {
        userRequestByDateDto: response.data,
        total: response.total,
        totalSpent: response.totalSpent,
      };
      dispatch(updateUserRequestByDateValue(userRequestByDateListObject));
      dispatch(setLoadingUserRequestByDateValue(false));
    } catch (error) {
      message.error("Ha ocurrido un error al intentar obtener los datos.");
      dispatch(setLoadingUserRequestByDateValue(false));
    }
  };
};

export default userRequestByDateListSlice.reducer;
