import {
  LogoutOutlined,
  AimOutlined,
  BarChartOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  UserOutlined,
  TeamOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  CarOutlined,
  SettingOutlined,
  PictureOutlined,
  WarningOutlined,
  IdcardOutlined,
  DiffOutlined,
  RadarChartOutlined,
  DollarOutlined,
  MoneyCollectFilled,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import { updateActiveProviderListValue } from "../../features/provider/activeProviderListSlice";
import { Routes } from "../../shared/constants/Routes";
import { WebRoutes } from "../../shared/constants/WebRoutes";
import type { MenuProps } from "antd";
import { removeAuth } from "../../features/auth/authSlice";
import {
  selectCurrentAdminPartner,
  selectCurrentAdminType,
} from "../../utils/helpers/selectorHelper";
import { removeCurrentAdminValue } from "../../features/admin/currentAdminSlice";
import {
  AdminTypeId,
  SuperAdminTypeId,
} from "../../shared/constants/Resources";
import GroupTitleMenu from "./groupTitleMenu";
import { ItemType } from "antd/lib/menu/hooks/useItems";

import "./styles.scss";
import NewDomainModal from "../../shared/components/modals/newDomainModal";

const oldDomain = process.env.REACT_APP_OLD_DOMAIN;

export const PanelLayout = () => {
  const dispatch: any = useDispatch<AppDispatch>();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const signOut = async () => {
    await dispatch(removeAuth());
    await dispatch(removeCurrentAdminValue());
    navigate(WebRoutes.LoginRoute, { replace: true });
  };

  useEffect(() => {
    if (!window.location.href.includes(WebRoutes.AssignProviderRoute)) {
      dispatch(updateActiveProviderListValue());
    }
  });

  const onClick = (e: any) => {
    switch (Number(e.key)) {
      case Routes.Dispatcher:
        return navigate(WebRoutes.TripListRoute, { replace: true });
      case Routes.Report:
        return navigate(WebRoutes.AdminTableRoute, { replace: true });
      case Routes.UserReport:
        return navigate(WebRoutes.UserTableRoute, { replace: true });
      case Routes.UserRequestHistory:
        return navigate(WebRoutes.TripTableRoute, { replace: true });
      case Routes.PendingUserRequestHistory:
        return navigate(WebRoutes.PendingTripListRoute, { replace: true });
      case Routes.GroupTripRequest:
        return navigate(WebRoutes.GroupTripRequest, { replace: true });
      case Routes.GroupTripPasagers:
        return navigate(WebRoutes.GroupTripPasagers, { replace: true });
      case Routes.GroupTripTrips:
        return navigate(WebRoutes.GroupTripTrips, { replace: true });
      case Routes.Banner:
        return navigate(WebRoutes.BannerRoute, { replace: true });
      case Routes.Driver:
        return navigate(WebRoutes.DriverRoute, { replace: true });
      case Routes.GroupTripsClients:
        return navigate(WebRoutes.GroupTripsClients, { replace: true });
      case Routes.MultipleLoad:
        return navigate(WebRoutes.MultipleLoad, { replace: true });
      case Routes.NotifyPassengers:
        return navigate(WebRoutes.NotifyPassenger, { replace: true });
      case Routes.IndexAdminPanel:
        return navigate(WebRoutes.IndexAdminPanel, { replace: true });
      case Routes.Departments:
        return navigate(WebRoutes.Departments, { replace: true });
      case Routes.PrivacyPolicies:
        return navigate(WebRoutes.PrivacyPolicies, { replace: true });
      case Routes.GenerateQR:
        return navigate(WebRoutes.GenerateQR, { replace: true });
      case Routes.GenerateReferralQR:
        return navigate(WebRoutes.GenerateReferralQR, { replace: true });
      case Routes.Regions:
        return navigate(WebRoutes.RegionsRoute, { replace: true });
      case Routes.AdminConfiguration:
        return navigate(WebRoutes.AdminConfiguration, { replace: true });
      case Routes.RegionPrices:
        return navigate(WebRoutes.PricesRoute, { replace: true });
      case Routes.EmptyTrips:
        return navigate(WebRoutes.EmptyTrips, { replace: true });
      case Routes.Currencies:
        return navigate(WebRoutes.CurrencyRoute, { replace: true });
      case Routes.ServiceTypes:
        return navigate(WebRoutes.ServiceTypeRoute, { replace: true });
      case Routes.SignOut:
        return signOut();
      default:
        return "";
    }
  };

  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const getMenuItems = (): ItemType[] => {
    if (selectCurrentAdminType() === SuperAdminTypeId) {
      return [
        {
          key: Routes.UserRequestHistory,
          icon: <BarChartOutlined />,
          label: "Viajes por fecha",
        },
        {
          key: Routes.PendingUserRequestHistory,
          icon: <BarChartOutlined />,
          label: "Cierres",
        },
        getItem("Viajes en Grupos", "sub1", <TeamOutlined />, [
          getItem("Clientes", Routes.GroupTripsClients, <SolutionOutlined />),
          getItem("Solicitudes", Routes.GroupTripRequest, <SolutionOutlined />),
          getItem(
            "Pasajeros",
            Routes.GroupTripPasagers,
            <UsergroupAddOutlined />
          ),
          getItem("Viajes", Routes.GroupTripTrips, <CarOutlined />),
        ]),
        {
          key: Routes.Dispatcher,
          icon: <AimOutlined />,
          label: "Despachador",
        },
        {
          key: Routes.MultipleLoad,
          icon: <DiffOutlined />,
          label: "Carga múltiple",
        },
        {
          key: Routes.NotifyPassengers,
          icon: <DiffOutlined />,
          label: "Notificar Pasajeros",
        },
        {
          label: "Administración",
          icon: <SettingOutlined />,
          key: "admin",
          children: [
            {
              key: Routes.Report,
              icon: <WarningOutlined />,
              label: "Administradores",
            },
            {
              key: Routes.UserReport,
              icon: <UserOutlined />,
              label: "Usuarios",
            },
            {
              key: Routes.Banner,
              icon: <PictureOutlined />,
              label: "Banners",
            },
            {
              key: Routes.Driver,
              icon: <IdcardOutlined />,
              label: "Conductores",
            },
            {
              key: Routes.Regions,
              icon: <RadarChartOutlined />,
              label: "Regiones",
            },
            {
              key: Routes.RegionPrices,
              icon: <DollarOutlined />,
              label: "Precios",
            },
            {
              key: Routes.EmptyTrips,
              icon: <IdcardOutlined />,
              label: "Viajes vacíos",
            },
            {
              key: Routes.Currencies,
              icon: <MoneyCollectFilled />,
              label: "Denominaciones",
            },
            {
              key: Routes.ServiceTypes,
              icon: <IdcardOutlined />,
              label: "Tipos de Servicio",
            },
          ],
        },
        {
          key: Routes.PrivacyPolicies,
          icon: <BarChartOutlined />,
          label: "Politica privacidad",
        },
        {
          key: Routes.SignOut,
          icon: <LogoutOutlined />,
          label: "Salir",
        },
      ];
    }

    if (selectCurrentAdminType() === AdminTypeId) {
      return [
        {
          type: "group", // Must have
          label: <GroupTitleMenu title="PANEL ADMIN" />,
          children: [
            {
              key: Routes.IndexAdminPanel,
              icon: <BarChartOutlined />,
              label: "Admin",
            },
          ],
        },
        {
          type: "group", // Must have
          label: <GroupTitleMenu title="USUARIOS" />,
          children: [
            {
              key: Routes.UserReport,
              icon: <UserOutlined />,
              label: "Usuarios",
            },
            {
              key: Routes.Departments,
              icon: <UserOutlined />,
              label: "Departamentos",
            },
            {
              key: Routes.Currencies,
              icon: <MoneyCollectFilled />,
              label: "Denominaciones",
            },
          ],
        },
        {
          type: "group", // Must have
          label: <GroupTitleMenu title="REPORTES" />,
          children: [
            {
              key: Routes.UserRequestHistory,
              icon: <BarChartOutlined />,
              label: "Viajes por fecha",
            },
          ],
        },
        {
          type: "group", // Must have
          label: <GroupTitleMenu title="otros" />,
          children: [
            {
              key: Routes.PrivacyPolicies,
              icon: <BarChartOutlined />,
              label: "Politica privacidad",
            },
            {
              key: Routes.GenerateQR,
              icon: <BarChartOutlined />,
              label: "Generar QR",
              style: {
                display: selectCurrentAdminPartner() ? "block" : "none",
              },
            },
            {
              key: Routes.GenerateReferralQR,
              icon: <UserOutlined />,
              label: "QR de Referidos",
            },
            {
              key: Routes.AdminConfiguration,
              icon: <BarChartOutlined />,
              label: "Configuracion de Admin",
              style: {
                display: selectCurrentAdminPartner() ? "block" : "none",
              },
            },
          ],
        },
        {
          key: Routes.SignOut,
          icon: <LogoutOutlined />,
          label: "Salir",
        },
      ];
    }

    // Tipo Operador
    return [
      {
        key: Routes.Dispatcher,
        icon: <AimOutlined />,
        label: "Despachador",
      },
      {
        key: Routes.UserRequestHistory,
        icon: <BarChartOutlined />,
        label: "Viajes por fecha",
      },
      {
        key: Routes.PendingUserRequestHistory,
        icon: <BarChartOutlined />,
        label: "Cierres",
      },
      {
        label: "Administración",
        icon: <SettingOutlined />,
        key: "admin",
        children: [
          {
            key: Routes.Report,
            icon: <WarningOutlined />,
            label: "Administradores",
          },
          {
            key: Routes.UserReport,
            icon: <UserOutlined />,
            label: "Usuarios",
          },
          {
            key: Routes.Driver,
            icon: <IdcardOutlined />,
            label: "Conductores",
          },
        ],
      },
      {
        key: Routes.SignOut,
        icon: <LogoutOutlined />,
        label: "Salir",
      },
    ];
  };

  return (
    <Layout className="container">
      { oldDomain && window.location.href.includes(oldDomain) ? <NewDomainModal /> : null }
      <Sider
        breakpoint="lg"
        collapsedWidth="60"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        {collapsed ? (
          <div className="trigger">
            <RightCircleOutlined onClick={() => setCollapsed(!collapsed)} />
          </div>
        ) : (
          <div className="trigger">
            <LeftCircleOutlined onClick={() => setCollapsed(!collapsed)} />
          </div>
        )}
        <Menu
          onClick={onClick}
          theme="dark"
          mode="inline"
          defaultOpenKeys={["admin"]}
          items={getMenuItems()}
          className="scroller"
        />
      </Sider>
      <Layout style={{ maxWidth: "calc(102% - 98px)" }}>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default PanelLayout;
