import {
  Button,
  Col,
  Form,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { getAllAdmins } from "../../../services/adminApi";
import { getRegions } from "../../../services/regionsApi";
import { createPriceBetweenRegions } from "../../../services/priceApi";

import "../../../shared/styles/form.scss";

export const PriceForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [admins, setAdmins] = useState([]);
  const [regions, setRegions] = useState<{ value: number; label: string }[]>(
    []
  );

  const [loading, setLoading] = useState(false);

  const getAdminsData = async () => {
    const admins = await getAllAdmins();
    const mappedAdmins = admins.map((a: any) => ({
      value: a.id,
      label: a.name,
    }));
    setAdmins(mappedAdmins);
  };

  const getRegionsData = async () => {
    const data = await getRegions();
    const mappedRegions = data.map((r) => ({ value: r.id, label: r.name }));
    setRegions(mappedRegions);
  };

  useEffect(() => {
    getAdminsData();
    getRegionsData();
  }, []);

  const onFinish = async (values: any) => {
    const { company, driverPrice, priority } = values;

    try {
      setLoading(true);

      const body = {
        ...values,
        driverPrice: driverPrice ?? null,
        company: company ?? null,
        priority: priority
      };

      await createPriceBetweenRegions(body);

      setLoading(false);

      navigate(WebRoutes.PricesRoute, {
        replace: true,
      });
    } catch (error: any) {
      setLoading(false);
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    }
  };

  return (
    <>
      <Header>Configuración de precio por regiones</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row justify="center">
          <Col span={15} className="sub-container create-container">
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="origin"
                label="Region A"
                rules={[
                  { required: true, message: "Por favor ingresa un nombre!" },
                ]}
              >
                <Select
                  placeholder="Seleccione una región"
                  showSearch
                  options={regions}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                name="destination"
                label="Region B"
                rules={[
                  { required: true, message: "Por favor ingresa un nombre!" },
                ]}
              >
                <Select
                  placeholder="Seleccione una región"
                  showSearch
                  options={regions}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                label="Prioridad"
                name="priority"
                rules={[
                  { required: true, message: "Por favor ingresa una prioridad!" }
                ]}
              >
                <InputNumber<number> />
              </Form.Item>

              <Form.Item
                label="Precio"
                name="price"
                rules={[
                  { required: true, message: "Por favor ingresa un precio!" },
                ]}
              >
                <InputNumber<number>
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                  min={1}
                />
              </Form.Item>

              <Form.Item label="Precio conductor" name="driverPrice">
                <InputNumber<number>
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                  min={0}
                />
              </Form.Item>

              <Form.Item name="company" label="Empresa">
                <Select
                  placeholder="Seleccione una empresa"
                  showSearch
                  options={admins}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space direction="horizontal">
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(WebRoutes.PricesRoute, {
                        replace: true,
                      })
                    }
                  >
                    Cancelar
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PriceForm;
