import { Col, Row } from "antd";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { useEffect, useRef } from "react";
import { updateConvesationNotificationStatus } from "../../../features/notifications/conversationSlice";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const localTimeZone = dayjs.tz.guess();

const ConversationArea = (): JSX.Element => {
  const currentConversation = useAppSelector(
    (state) => state.conversation.currentConversation
  );

  const ref: any = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.lastElementChild?.scrollIntoView();
    }
  });

  useEffect(() => {
    const newConversation = currentConversation.filter(
      (c: any) => c.status === "new"
    );
    dispatch(updateConvesationNotificationStatus(newConversation, "closed"));
  }, [dispatch, currentConversation]);

  return (
    <section
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      <Row
        style={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          maxHeight: "100%",
          overflowY: "auto",
          padding: 10,
        }}
        ref={ref}
      >
        {currentConversation.map((message: any) => {
          if (message?.from?.type === "client")
            return (
              <Col key={message.message_id} span={24}>
                <div className="conversation-msg-client-side-left">
                  <div className="msg-container-cls">
                    <div>{message.data?.message}</div>
                    <span>
                      {message?.create_at
                        ? dayjs
                            .utc(message.create_at)
                            .tz(localTimeZone)
                            .format("hh:mm A")
                        : ""}
                    </span>
                  </div>
                </div>
              </Col>
            );
          return (
            <Col key={message.message_id} span={24}>
              <div className="conversation-msg-client-side-right">
                <div className="msg-container-cls">
                  <div>{message.data?.message}</div>
                  <span>
                    {message?.create_at
                      ? dayjs
                          .utc(message.create_at)
                          .tz(localTimeZone)
                          .format("hh:mm A")
                      : ""}
                  </span>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default ConversationArea;
