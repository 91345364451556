import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import {
  ColumnsType,
  FilterValue,
  SorterResult,
} from "antd/lib/table/interface";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import {
  getAdminEmails,
  getAdminExcel,
  getAdminPaginatedDocuments,
} from "../../../services/adminApi";
import {
  endDateValue,
  startDocumentDateValue,
} from "../../../shared/constants/Resources";
import AdminDTO from "../../../shared/dtos/adminDTO";
import "../../../shared/styles/list.scss";
import "./styles.scss";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters: Record<string, FilterValue | null>;
}

export interface DataType {
  id: number;
  url: string;
  fileKey: string;
}

const AdminReportDocumentList = () => {
  const title = "Lista de Documentos de Compañias";
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<Moment>(
    moment(startDocumentDateValue)
  );
  const [startDateModal, setStartDateModal] = useState<Moment>(
    moment(startDocumentDateValue)
  );
  const [endDate, setEndDate] = useState<Moment>(moment(endDateValue));
  const [endDateModal, setEndDateModal] = useState<Moment>(
    moment(endDateValue)
  );
  const [emails, setEmails] = useState<Array<AdminDTO>>([]);
  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState<Array<AdminDTO>>([]);
  const [checkedEmails, setCheckedEmails] = useState([]);
  const [search, setSearch] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkedValue, setCheckedValue] = useState(true);

  const filterBySearch = (event: any) => {
    const query: string = event.target.value;

    setSearchText(query);

    let updatedList = [...emails];

    updatedList = updatedList.filter((item: any) => {
      return (
        item.admin_name
          .toLowerCase()
          .indexOf(query ? query.toLowerCase() : "") !== -1
      );
    });

    setFilteredList(updatedList);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Nombre del archivo",
      dataIndex: "fileName",
      sorter: false,
      width: "20%",
    },
    {
      title: "Url",
      dataIndex: "url",
      width: "40%",
      render: (url) => (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Nombre de la empresa",
      dataIndex: "adminName",
      width: "40%",
    },
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    filters: {},
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = async () => {
    setLoading(true);

    const { data, total } = await getAdminPaginatedDocuments({
      page: tableParams.pagination?.current || 1,
      take: tableParams.pagination?.pageSize || 10,
      searchText: search,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });

    const results: Array<AdminDTO> = await getAdminEmails({
      startDate: startDateModal.toDate(),
      endDate: endDateModal.toDate(),
    });

    setData(data);
    setLoading(false);
    setEmails(results);
    setCheckedEmails(
      results
        ? (results.map((admin: any) => {
            return admin.admin_id;
          }) as never[])
        : []
    );
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
      },
    });
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleClick = async () => {
    if (startDate && endDate) {
      setLoading(true);

      const { data, total } = await getAdminPaginatedDocuments({
        page: tableParams.pagination?.current || 1,
        take: tableParams.pagination?.pageSize || 10,
        searchText: search,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      });

      setData(data);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total,
        },
      });
    }
  };

  const handleClickDate = async () => {
    setIsModalLoading(true);
    setEmails([]);

    const results = await getAdminEmails({
      startDate: startDateModal.toDate(),
      endDate: endDateModal.toDate(),
    });

    setIsModalLoading(false);
    setEmails(results);
    setCheckedEmails(
      results
        ? (results.map((admin: any) => {
            return admin.admin_id;
          }) as never[])
        : []
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
    setCheckAll(true);
    setCheckedEmails(
      emails.map((admin: any) => {
        return admin.admin_id;
      }) as never[]
    );
  };

  const handleOk = async () => {
    setSearchText("");
    setConfirmLoading(true);
    for (const email of checkedEmails) {
      const excelDocument = await getAdminExcel(
        email,
        startDateModal.toDate(),
        endDateModal.toDate()
      );
      const link = document.createElement("a");
      link.href = excelDocument.file;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setIsModalOpen(false);
    setCheckedEmails([]);
    setData([]);
    handleClick();
    setConfirmLoading(false);
    setCheckAll(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCheckedEmails([]);
    setCheckAll(false);
  };

  const onChange = (event: any) => {
    setCheckedEmails(event);
  };

  const handleCheckAll = (e: any) => {
    setCheckAll(e.target.checked);
    setCheckedEmails(
      e.target.checked
        ? (emails.map((admin: any) => {
            return admin.admin_id;
          }) as never[])
        : ([] as never[])
    );
  };

  return (
    <Layout>
      <Header className="site-layout-sub-header-background">{title}</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row className="upper-row">
          <Form className="form-filter" onFinish={handleClick}>
            <Col span={3} className="input-container-table">
              <DatePicker
                value={startDate}
                onChange={(e: any) => setStartDate(e)}
              />
            </Col>
            <Col span={3} className="input-container-table">
              <DatePicker
                value={endDate}
                onChange={(e: any) => setEndDate(e)}
              />
            </Col>
            <Col span={3} className="input-container-table">
              <Input
                type="text"
                value={search}
                placeholder="Buscar por email"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col offset={1} span={12} className="input-container-table">
              <Button htmlType="submit" disabled={loading === true}>
                Filtrar
              </Button>
            </Col>
            <Col className="input-container-table">
              <Button onClick={showModal} disabled={loading === true}>
                Crear reporte
              </Button>
            </Col>
          </Form>
        </Row>
        <Row className="list-container">
          <Table
            style={{ minWidth: "100%" }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Row>
        <Modal
          title="Selecciona las compañias para generar los reportes"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={confirmLoading}
          okText={confirmLoading ? "Descargando" : "Ok"}
        >
          <Row className="input-margin">
            <Form className="form-filter" onFinish={handleClickDate}>
              <Col>
                <DatePicker
                  value={startDateModal}
                  onChange={(e: any) => setStartDateModal(e)}
                />
              </Col>
              <Col>
                <DatePicker
                  value={endDateModal}
                  onChange={(e: any) => setEndDateModal(e)}
                />
              </Col>
              <Button htmlType="submit" disabled={loading === true}>
                Filtrar
              </Button>
            </Form>
          </Row>
          <Row className="input-margin">
            <Input
              type="text"
              value={searchText}
              placeholder="Buscar por email"
              onChange={(e) => filterBySearch(e)}
            />
          </Row>
          <Row className="input-margin">
            <Checkbox
              value={checkedValue}
              checked={checkAll}
              onChange={handleCheckAll}
            >
              Seleccionar todos
            </Checkbox>
          </Row>
          <Row>
            <Col className="checkbox-container">
              <Checkbox.Group value={checkedEmails} onChange={onChange}>
                {isModalLoading ? (
                  <LoadingOutlined />
                ) : emails && searchText === "" ? (
                  emails.map((admin: any) => {
                    return (
                      <Row>
                        <Checkbox value={admin.admin_id}>
                          {admin.admin_name}
                        </Checkbox>
                      </Row>
                    );
                  })
                ) : (
                  filteredList.map((admin: any) => {
                    return (
                      <Row>
                        <Checkbox value={admin.admin_id}>
                          {admin.admin_name}
                        </Checkbox>
                      </Row>
                    );
                  })
                )}
              </Checkbox.Group>
            </Col>
          </Row>
        </Modal>
      </Content>
    </Layout>
  );
};

export default AdminReportDocumentList;
