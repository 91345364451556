import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_API_URL;

export const getUsersExcel = async () => {
  const result = await axios.get(
    `${apiUrl}admin/users/information/excel`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.file;
};

export const getUserById = async (userId: number) => {
  const result = await axios.get(
    `${apiUrl}admin/users/${userId}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};