import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";

const apiUrl = process.env.REACT_APP_GROUP_TRIPS_MOCROSERVICE_API;

interface PaginatedProps {
  page: number;
  take: number;
  name: string;
}

interface PriceBody {
  origin: number;
  destination: number;
  price: number;
  priority: number;
  company: number | null;
  driverPrice: number | null;
}

interface UpdatePrice {
  id: number;
  price: number;
  driverPrice: number | null;
}

export const getPaginatedPrices = async ({ page, take, name }: PaginatedProps) => {
  const result = await axios.get(
    `${apiUrl}regions/prices?start=${page}&limit=${take}&name=${name}`,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );

  return result.data;
};

export const createPriceBetweenRegions = async (data: PriceBody) => {
  return await axios.post(`${apiUrl}regions/prices`, data, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
};

export const updatePriceBetweenRegions = async (updatePrice: UpdatePrice) => {
  return await axios.put(
    `${apiUrl}regions/prices/${updatePrice.id}`,
    updatePrice,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
};
