import { ColumnsType } from "antd/lib/table";
import { DataType } from ".";

export const currencyColumns = (): ColumnsType<DataType> => [
  {
    title: "ID",
    dataIndex: "id",
    sorter: false,
    width: "10%",
  },
  {
    title: "Nombre",
    dataIndex: "name",
    sorter: false,
    width: "10%",
  },
  {
    title: "Codigo",
    dataIndex: "code",
    sorter: false,
    width: "10%",
  },
  {
    title: "Base",
    dataIndex: "base",
    sorter: false,
    width: "10%",
  },
  {
    title: "Tipo de Cambio",
    dataIndex: "rate",
    sorter: false,
    width: "10%",
  },
];
