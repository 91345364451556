import MapDTO from "../../shared/dtos/mapDTO";
import UserRequestDTO from "../../shared/dtos/userRequestDTO";

export const getDestinationsByUserRequestHelper = (
  userRequest: UserRequestDTO | null | undefined
) => {
  if (userRequest === null || userRequest === undefined) return null;

  const checkPoints = userRequest?.destinations || [];
  const wayPoints = checkPoints.filter(
    (cp) =>
      cp.address !== userRequest.destinationAddress &&
      cp.address !== userRequest.originAddress
  );

  const response = {
    dropOff: {
      id: `${userRequest.id}DropOff`,
      destinationLatitude: userRequest.destinationLatitude,
      destinationLongitude: userRequest.destinationLongitude,
      destinationAddress: userRequest.destinationAddress,
    },
    pickUp: {
      id: `${userRequest.id}PickUp`,
      originLatitude: userRequest.originLatitude,
      originLongitude: userRequest.originLongitude,
      originAddress: userRequest.originAddress,
    },
  } as MapDTO;

  if (wayPoints.length > 0) {
    response.wayPoints = wayPoints;
  }

  return response;
};

export default getDestinationsByUserRequestHelper;
