import { Switch, notification } from "antd";
import { updateUserManager, updateUserOfferExtraTrip } from "../../../services/userAPI";

export interface Props {
  userId: number | null | undefined;
  switchValue: boolean;
  isOfferExtraTrip?: boolean | false;
  setIgnoreFetch: any;
}

const UserManagerSwitch = ({
  userId,
  switchValue,
  setIgnoreFetch,
  isOfferExtraTrip
}: Props) => {

  const onChange = async (value: boolean) => {

    try {
      if (!isOfferExtraTrip) {
        await updateUserManager(userId, value).then(() => {
          notification.success({
            message: "Se actualizo el tipo de usuario exitosamente",
            placement: "bottom",
          });
        });
      } else {
        await updateUserOfferExtraTrip(userId, value).then(() => {
          notification.success({
            message: "Se actualizo la notificacion extra al usuario",
            placement: "bottom",
          });
        });
      }
      setIgnoreFetch(false);
    } catch (error) {
      notification.error({
        message: `Ha ocurrido un error al cambiar el tipo de usuario, intentelo más tarde.`,
        placement: "bottom",
      });
    }
  };

  return <Switch checked={switchValue} onChange={onChange} />;
};

export default UserManagerSwitch;
