import { useAppSelector } from "../../../app/hooks";
import { PassengerGroupDTO } from "../../../shared/dtos/PassengerGroupDTO";
import { useState, useEffect } from "react";
import { CheckPoint, GroupTripDTO } from "../../../shared/dtos/groupTripDTO";
import dayjs from "dayjs";
import { TimeActions, addSubstractTime, buildPoint } from "./utils";

type Group = GroupTripDTO[] | undefined;
const waitTime = process.env.WAIT_TIME_BY_TRIP_STOP || 180; // time in seconds

const useGroupTrip = (passengers: PassengerGroupDTO[], form: any) => {
  const [group, setGroup] = useState<Group>(undefined);
  const directionsResponse =
    useAppSelector<google.maps.DirectionsResult | null>(
      (state) => state.groupTripsMap.directionsResponse
    );

  useEffect(() => {
    const { isReturnTrip, date } = form.getFieldsValue(true);
    console.log("updated passengers", directionsResponse);
    const geocodedWP = directionsResponse?.geocoded_waypoints;
    if (
      passengers.length > 0 &&
      passengers.length <= 8 &&
      geocodedWP &&
      geocodedWP.length > 0
    ) {
      const geocodedWayPoints = [...geocodedWP];

      let time = 0;
      let distance = 0;
      let departureTime = "";
      let arrivalTime = "";

      const legs = directionsResponse?.routes[0]?.legs || [];

      //  create los wey_points
      const steps = isReturnTrip ? [...legs] : [...legs].reverse();
      const pointsData = isReturnTrip
        ? geocodedWayPoints
        : geocodedWayPoints.reverse();

      const dateHour = dayjs(date, "DD-MM-YYYY HH:mm").format(
        "YYYY-MM-DD HH:mm"
      );
      const checkpoints = pointsData?.reduce(
        (result: Array<CheckPoint>, waypoint: any, index: number) => {
          const tripTime = dayjs(date, "DD-MM-YYYY HH:mm").format("HH:mm");

          const passengersList = passengers
            .filter((p: any) => {
              // console.log("passenger here-->", p);
              // console.log("waypoint.place_id here-->", waypoint.place_id);
              return (
                p.origin?.googlemaps === waypoint.place_id ||
                p.destiny?.googlemaps === waypoint.place_id
              );
            })
            .map((p: any) => ({
              name: p.name,
              cellphone: p.cellphone,
              getOn: waypoint.place_id === p.origin?.googlemaps,
            }));

          if (index === 0) {
            const leg = steps[index];

            const address = isReturnTrip ? leg.start_address : leg.end_address;

            const location = isReturnTrip
              ? {
                  lat: leg.start_location.lat(),
                  lng: leg.start_location.lng(),
                }
              : {
                  lat: leg.end_location.lat(),
                  lng: leg.end_location.lng(),
                };

            arrivalTime = tripTime;

            departureTime = addSubstractTime(
              TimeActions.Add,
              dateHour,
              Number(waitTime)
            );

            if (isReturnTrip) {
              time += Number(waitTime);
            }

            result.push(
              buildPoint(
                address,
                location,
                passengersList,
                arrivalTime,
                departureTime
              )
            );
            return result;
          } else {
            const leg = steps[index - 1];
            const address = isReturnTrip ? leg.end_address : leg.start_address;

            const exist = result.some((r) => r.address?.address === address);
            if (exist) {
              return result;
            }

            const duration = leg.duration?.value; // this value is in seconds
            distance += Number(leg.distance?.value); // this value is in meters
            time += Number(duration);

            if (isReturnTrip) {
              arrivalTime = duration
                ? addSubstractTime(TimeActions.Add, dateHour, time)
                : "";
              time += Number(waitTime);
              departureTime = addSubstractTime(TimeActions.Add, dateHour, time);
            } else {
              departureTime = duration
                ? addSubstractTime(TimeActions.Substarct, dateHour, time)
                : "";
              time += Number(waitTime);
              arrivalTime = addSubstractTime(
                TimeActions.Substarct,
                dateHour,
                time
              );
            }

            const location = isReturnTrip
              ? { lat: leg.end_location.lat(), lng: leg.end_location.lng() }
              : {
                  lat: leg.start_location.lat(),
                  lng: leg.start_location.lng(),
                };

            result.push(
              buildPoint(
                address,
                location,
                passengersList,
                arrivalTime,
                departureTime
              )
            );
            return result;
          }
        },
        []
      );

      const trip = {
        id: "lote",
        request: "",
        requestId: "",
        scheduleAt: dateHour,
        time: (time / 60).toFixed(), // trip duration
        distance: (distance / 1000).toFixed(2), // trip distance
        checkPoints: isReturnTrip ? checkpoints : checkpoints?.reverse(),
        hour: isReturnTrip ? departureTime : arrivalTime, // trip departure time
        passegers: checkpoints?.reduce((total: number, cp: any) => {
          const cant = cp?.passengers?.filter((i: any) => i.getOn) || [];
          const tmp = Number(total) + cant.length;
          return tmp;
        }, 0),
        status: "new",
        driver: null,
        id_mowi: null,
      };
      setGroup([trip]);
    }
  }, [passengers, directionsResponse, form]);

  return group;
};

export default useGroupTrip;
