import { Col, Row, Space, Switch } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { setSelectedAdminId, setSelectedCountryId, userRequestListAsync } from "../../../features/userRequest/userRequestListSlice";
import UserRequestDTO from "../../../shared/dtos/userRequestDTO";
import UserRequestCard from "./userRequestCard";
import { DispatcherMap } from "../../map/Map";
import getDestinationsByUserRequestHelper from "../../../utils/helpers/getDestinationsByUserRequestHelper";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import { updateUserRequestValue } from "../../../features/userRequest/pendingUserRequestSlice";
import { useNavigate } from "react-router-dom";
import MapDTO from "../../../shared/dtos/mapDTO";
import ProviderDTO from "../../../shared/dtos/providerDTO";
import { getDriversGroupTrips } from "../../../services/groupTripsApi";
import { useAppSelector } from "../../../app/hooks";
import { TRIP_STATUS } from "../../../shared/constants/TripStatus";
import Sound from "react-sound";
import Filter from "../create/utils/filterComponent";

import "../../../shared/styles/dark.scss";
import "./styles.scss";
import { selectSelectedCountryId } from "../../../utils/helpers/selectorHelper";
import { setCountry } from "../../../features/map/mapSlice";
import { COUNTRIES } from "../../../utils/constants/countries";

interface UserRequestDataMapProps {
  directions?: MapDTO | null;
  provider?: ProviderDTO | null;
}

interface ActiveKey {
  active: boolean;
  provider: boolean | undefined;
}

const UserRequestList: React.FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [selectedUserRequest, setSelectedUserRequest] = useState<
    UserRequestDTO[]
  >([] as UserRequestDTO[]);
  const [isProviderAssinged, setIsProviderAssigned] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [userRequestDataMap, setUserRequestDataMap] =
    useState<UserRequestDataMapProps | null>(null);
  const [darkMode, setDarkMode] = useState(false);
  const [activeKey, setActiveKey] = useState<ActiveKey>({
    active: false,
    provider: undefined,
  });
  const [activeProviders, setActiveProviders] = useState<Array<ProviderDTO>>(
    []
  );
  const [shouldSound, setShouldSound] = useState(false);

  const selectedServiceTypeId = useAppSelector(
    (state) => state.serviceTypeList.selectedServiceTypeId
  );

  const userRequestList: UserRequestDTO[] = useSelector(
    (state: RootState) => state.userRequestList.value
  );

  const selectedAdminId: number | undefined = useSelector(
    (state: RootState) => state.userRequestList.selectedAdminId
  );

  const [selectedRequestsForPackage, setSelectedRequestsForPackage] = useState<
    UserRequestDTO[]
  >([]);
  const [refreshData, setRefreshData] = useState(true);
  const [countryId, setCountryId] = useState<number | undefined>(0);
  const [adminId, setAdminId] = useState<number | undefined>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (refreshData) {
      setIsLoading(true);
      let requestBody = {}
      let country = countryId && countryId !== 0
        ? COUNTRIES.find((x) => x.value === countryId)
        : selectSelectedCountryId()
        ? COUNTRIES.find((x) => x.value === selectSelectedCountryId())
        : null;

      if (country) {
        dispatch(setCountry(country));
        dispatch(setSelectedCountryId(country.value));
        setCountryId(country.value);
        requestBody = {
          ...requestBody,
          countryId: country?.value
        }
      }

      let currentAdminId = adminId && adminId !== 0 ? adminId : selectedAdminId;

      if (currentAdminId) {
        dispatch(setSelectedAdminId(currentAdminId));
        setAdminId(currentAdminId);
        requestBody = {
          ...requestBody,
          adminId: currentAdminId
        }
      }

      dispatch(
        userRequestListAsync(requestBody)
      ).then(() => setIsLoading(false));
      clearInterval(intervalId);
      setIntervalId(
        setInterval(async () => {
          await dispatch(
            userRequestListAsync(requestBody)
          );
        }, 2 * 60 * 1000)
      );
      setRefreshData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  const handleSelectRequest = useCallback(
    async (userRequest: UserRequestDTO) => {
      if (userRequest === null || userRequest === undefined) return null;

      let selected: UserRequestDTO[] = [];
      await setSelectedUserRequest((prev) => {
        if (activeKey.active && typeof activeKey.provider !== "undefined") {
          if (
            (activeKey.provider && userRequest.provider) ||
            (!activeKey.provider && !userRequest.provider)
          ) {
            const cant = prev.length;
            const updated = [...prev].filter((i) => i.id !== userRequest?.id);
            selected = updated;
            if (cant !== updated.length) return updated;
            selected = [...prev, userRequest];
            return selected;
          }
          selected = [...prev];
          return prev;
        }
        selected = [userRequest];
        return [userRequest];
      });
      setUserRequestDataMap(
        selected?.length > 0
          ? {
              directions: getDestinationsByUserRequestHelper(
                selected[selected.length - 1]
              ),
              provider: userRequest.provider,
            }
          : null
      );

      if (selected?.length > 0) {
        if (selected[0]?.provider) setIsProviderAssigned(true);
        if (!selected[0]?.provider) setIsProviderAssigned(false);
      }
    },
    [activeKey]
  );

  const handleDoubleClick = (userRequest: UserRequestDTO) => {
    if (!activeKey.active) {
      if (userRequest && userRequest?.providerId === 0) {
        dispatch(updateUserRequestValue(selectedUserRequest));
        navigate(WebRoutes.AssignProviderRoute, {
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    if (!selectedUserRequest || !selectedUserRequest[0]?.id) {
      const getDrivers = async () => {
        const drivers = await getDriversGroupTrips(
          -34.88582,
          -56.17122,
          500,
          selectedServiceTypeId
        );
        setActiveProviders(
          drivers.map((d: ProviderDTO) => {
            return {
              id: d?.id,
              coordinates: {
                lat: d?.latitude,
                lng: d?.longitude,
              },
              name: `${d?.firstName || ""} ${d?.lastName || ""}`,
              phone: d?.services ? d?.services[0]?.serviceNumber : "",
              service: {
                serviceModel: d?.services ? d?.services[0]?.serviceModel : "",
              },
            };
          })
        );
      };
      getDrivers();
      intervalId = setInterval(() => {
        getDrivers();
      }, 180000);
    }

    return () => {
      clearInterval(intervalId);
      setActiveProviders([]);
      intervalId = undefined;
    };
  }, [selectedUserRequest, selectedServiceTypeId]);

  const map = useMemo(() => {
    return (
      <DispatcherMap
        destinations={userRequestDataMap?.directions}
        activeProvider={
          userRequestDataMap?.provider ? userRequestDataMap.provider : null
        }
        providers={activeProviders?.length > 0 ? activeProviders : undefined}
        showProviders={activeProviders?.length > 0}
        darkMode={darkMode}
      />
    );
  }, [userRequestDataMap, activeProviders, darkMode]);

  const handleKeyPress = useCallback(
    async (event: any) => {
      if (!activeKey.active && event.key === "Alt") {
        let provider;
        if (selectedUserRequest?.length > 0) {
          provider = selectedUserRequest[0]?.provider ? true : false;
        }
        setActiveKey({ provider, active: true });
      }
    },
    [activeKey, selectedUserRequest]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleKeyUp = useCallback(
    (event: any) => {
      if (activeKey.active && event.key === "Alt")
        setActiveKey((prev) => ({ ...prev, active: false }));
    },
    [activeKey]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  const checkPlayStatus = () => {
    if (!shouldSound) {
      return "PAUSED";
    }

    const isSearching = userRequestList?.some(
      (userRequest) => userRequest.status === TRIP_STATUS.Searching
    );

    return isSearching ? "PLAYING" : "PAUSED";
  };

  const addOrRemoveFromSelectedRequests = (
    userRequest: UserRequestDTO,
    add: boolean
  ) => {
    if (add) {
      setSelectedRequestsForPackage([
        ...selectedRequestsForPackage,
        userRequest,
      ]);
    } else {
      setSelectedRequestsForPackage(
        selectedRequestsForPackage.filter((r) => r.id !== userRequest.id)
      );
    }
  };

  return (
    <div className={`${darkMode && "dark-mode"}`}>
      <Sound
        url={
          process.env.REACT_APP_SOUND_URL ||
          "https://s3-mowi-bucket.s3.sa-east-1.amazonaws.com/bipping.mp3"
        }
        playStatus={checkPlayStatus()}
        loop
      />

      <Header className="site-layout-sub-header-background">
        Listado de viajes
        <Space size="large">
          <label>
            Sonido
            <Switch style={{ marginLeft: "5px" }} onChange={setShouldSound} />
          </label>
          <label>
            Tema
            <Switch
              style={{ marginLeft: "5px" }}
              checkedChildren="Oscuro"
              unCheckedChildren="Claro"
              onChange={setDarkMode}
            />
          </label>
        </Space>
      </Header>
      <Content
        style={{
          margin: "16px",
        }}
      >
        <Filter
          isProviderAssinged={isProviderAssinged}
          isLoading={isLoading}
          selectedUserRequest={selectedUserRequest}
          setIsProviderAssigned={setIsProviderAssigned}
          setSelectedUserRequest={setSelectedUserRequest}
          selectedUserRequestForPackage={selectedRequestsForPackage}
          setRefreshData={setRefreshData}
          setCountryId={setCountryId}
          setAdminId={setAdminId}
        />
        <Row>
          <Col span={16} push={8}>
            {map}
          </Col>
          <Col span={8} pull={16} className="card-container">
            {userRequestList && userRequestList.length === 0 ? (
              isLoading === true ? (
                <LoadingOutlined className="loading" />
              ) : (
                <div className="empty-table">
                  <ExclamationCircleOutlined id="icon" />
                  <p>No hay datos</p>
                </div>
              )
            ) : (
              userRequestList &&
              userRequestList.map(
                (userRequest: UserRequestDTO, index: number) => {
                  return (
                    <Col
                      key={index}
                      onClick={() => {
                        handleSelectRequest(userRequest);
                      }}
                      onDoubleClick={() => handleDoubleClick(userRequest)}
                    >
                      <UserRequestCard
                        key={userRequest.id}
                        userRequest={userRequest}
                        selectedUserRequest={selectedUserRequest}
                        onSelect={addOrRemoveFromSelectedRequests}
                      />
                    </Col>
                  );
                }
              )
            )}
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default UserRequestList;
