import { CheckPoint, GroupTripDTO } from "../../../shared/dtos/groupTripDTO";
import dayjs from "dayjs";

var xlsx = require("xlsx");
/**
 * parse data of passengers from xlsx document
 * @param data
 * @returns
 */
export const parseDataFromXMLS = (data: Array<any>): Array<any> => {
  return data.map((l) => {
    return {
      id: l["__rowNum__"],
      name: `${l["NOMBRE"]} ${l["APELLIDO"]}`,
      origin: {
        id: null,
        name: l["DIRECCION DE ACOMPAÑANTE"],
        googlemaps: null,
        address: `${l["DIRECCION DE ACOMPAÑANTE"]},${l["ZONA DE  ACOMPAÑANTE"]}`,
        keys: [`${l["DIRECCION DE ACOMPAÑANTE"]}`],
        coordinate: {
          lat: 0,
          lng: 0,
        },
        available: false,
      },
      destiny: {
        id: null,
        name: l["LUGAR DEL SERVICIO"],
        googlemaps: null,
        address: l["LUGAR DEL SERVICIO"],
        keys: [`${l["LUGAR DEL SERVICIO"]}`],
        coordinate: {
          lat: 0,
          lng: 0,
        },
        available: false,
      },
      email: l["CORREO"],
      phone: l["TEL 1"],
      cellphone: l["TEL 2"],
      //entry_time: l["ENTRADA"],
      description: l["DESCRIPCION"],
      identifier: l["N.º"],
      status: "nuevo",
    };
  });
};

// import passengers from xlsx file
export const readUploadXLSXFile = (
  e: any,
  callBack: (data: Array<any>) => void,
  raw = true
) => {
  if (e.preventDefault) e.preventDefault();
  if (e.target.files) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e?.target?.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet, { raw });
      callBack(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  }
};

// get passengers data from checkpoint list
export const getPassengersData = (checkPoints: CheckPoint[]): Array<any> => {
  return checkPoints
    .map((passengerList: any) => {
      const passengers = passengerList.passengers || [];
      const tmpPassengersData = passengers.map((passenger: any) => passenger);
      return tmpPassengersData;
    })
    .flat()
    .filter((p) => p.getOn)
    .map((p) => ({
      name: p.name,
      phone: p.cellphone,
    }));
};

export const getDataFromGroupTrip = (selectedGroupTrip: GroupTripDTO) => {
  const checkPoints = selectedGroupTrip?.checkPoints || [];
  const totalCheckPoints: number = checkPoints.length;
  // origin data
  const origin = checkPoints[0].address || null;
  const originLatitude = Number(origin?.coordinate?.lat);
  const originLongitude = Number(origin?.coordinate?.lng);
  const originAddress = origin?.address;
  // destination data
  const destination = checkPoints[totalCheckPoints - 1].address || null;
  const destinationLatitude = Number(destination?.coordinate?.lat);
  const destinationLongitude = Number(destination?.coordinate?.lng);
  const destinationAddress = destination?.address;

  const date = dayjs(selectedGroupTrip.scheduleAt, "YYYY-MM-DD").format(
    "YYYY-MM-DD"
  );
  const hour =
    selectedGroupTrip.hour.split(":")[0].length < 2
      ? `0${selectedGroupTrip.hour}`
      : selectedGroupTrip.hour;
  const scheduleAt = `${date} ${hour}:00`;

  // way points list
  const wayptsList = checkPoints.filter(
    (i: CheckPoint, index: number) =>
      Number(index) !== 0 && Number(index) !== totalCheckPoints - 1
  );

  return {
    ...selectedGroupTrip,
    totalCheckPoints,
    originLatitude,
    originLongitude,
    originAddress,
    destinationLatitude,
    destinationLongitude,
    destinationAddress,
    wayptsList,
    scheduleAt,
  };
};
