import { Form, Input } from "antd";
import { useAppDispatch } from "../../../app/hooks";
import {
  addClient,
  editClient,
  setOpenModal,
} from "../../../features/groupTrip/groupTripsClients/grpupTripsClientsSlice";
import { useEffect } from "react";
import { FormInstance } from "antd/es/form/Form";
import UserProfileDTO from "../../../shared/dtos/userProfileDTO";

interface Props {
  form: FormInstance;
  setLoading: (flag: boolean) => void;
  selected: UserProfileDTO | undefined;
}

const FormClient = ({ form, setLoading, selected }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selected) {
      form.setFieldsValue({
        firstName: selected.firstName,
        lastName: selected.lastName,
        email: selected.email,
        mobile: selected.mobile,
      });
    }
  }, [selected, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const profile = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
    };

    const finish = () => {
      dispatch(setOpenModal(false));
    };

    if (selected?.id) {
      await dispatch(editClient(profile, selected.id, finish));
      setLoading(false);
    } else {
      const client = {
        ...profile,
        lote: true,
        type: 2,
        password: "null",
        loginBy: "manual",
        deviceType: "android",
        paymentMode: "MOWI",
      };
      await dispatch(addClient(client, finish));
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name="add-edit-client-group-trip"
      onFinish={onFinish}
      layout="vertical"
      preserve={false}
    >
      <Form.Item
        name="firstName"
        label="Primer Nombre"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="lastName" label="Segundo Nombre">
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[{ type: "email", required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="mobile" label="Celular" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};

export default FormClient;
