import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import {
  createServiceType,
  getServiceType,
  patchServiceType,
} from "../../../services/serviceTypeApi";
import { CALCULATOR_TYPE } from "../../../shared/constants/CalculatorType";
import ServiceTypeDTO from "../../../shared/dtos/serviceTypeDTO";
import Upload from "antd/lib/upload/Upload";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import { createServiceTypeFileApi } from "../../../services/filesApi";
import { RcFile } from "antd/lib/upload";

import "../../../shared/styles/form.scss";
import "../styles.scss";

export const ServiceTypeForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let { serviceTypeId } = useParams();

  const [loading, setLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const getServiceTypeData = async (serviceTypeId: number) => {
    await getServiceType(serviceTypeId).then((res: any) => {
      form.setFieldsValue({
        name: res.name,
        calculator: res.calculator,
        capacity: res.capacity,
        distance: res.distance,
        fixed: res.fixed,
        minute: res.minute,
        price: res.price,
        image: res.image,
        serviceClass: res.serviceClass,
      });
    });
  };

  useEffect(() => {
    if (serviceTypeId) {
      getServiceTypeData(+serviceTypeId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: any) => {
    const { calculator, capacity, distance, fixed, minute, name, price } =
      values;
    const serviceClass = "DRIVER";

    const serviceTypeDTO: ServiceTypeDTO = {
      name: name,
      calculator: calculator,
      capacity: capacity,
      distance: distance,
      fixed: fixed,
      minute: minute,
      price: price,
    };

    if (fileUrl) {
      serviceTypeDTO.image = fileUrl;
    }

    try {
      setLoading(true);
      if (serviceTypeId) {
        await patchServiceType(serviceTypeDTO, +serviceTypeId).then(() => {
          notification.success({
            message: `Tipo de Servicio #${serviceTypeId}`,
            description: "Se ha editado exitosamente",
            placement: "bottom",
          });
        });
      } else {
        serviceTypeDTO.serviceClass = serviceClass;
        await createServiceType(serviceTypeDTO).then(() => {
          notification.success({
            message: "Tipo de Servicio",
            description: "Se ha creado exitosamente",
            placement: "bottom",
          });
        });
      }

      setLoading(false);

      navigate(WebRoutes.ServiceTypeRoute, {
        replace: true,
      });
    } catch (error: any) {
      setLoading(false);
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    }
  };

  const uploadFile = async (file: RcFile) => {
    setIsFileLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    await createServiceTypeFileApi(formData).then((res: any) => {
      setFileUrl(res.location);
      setIsFileLoading(false);
      notification.success({
        message: "La imagen se ha cargado exitosamente",
        placement: "bottom",
      });
    });
    return false;
  };

  return (
    <>
      <Header>{serviceTypeId ? "Editar" : "Crear"} Tipo de Servicio</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row justify="center">
          <Col span={15} className="sub-container create-container">
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Nombre"
                rules={[
                  { required: true, message: "Por favor ingresa un nombre!" },
                ]}
              >
                <Input disabled={serviceTypeId ? true : false} />
              </Form.Item>

              <Form.Item
                name="capacity"
                label="Capacidad de pasajeros"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa una capacidad!",
                  },
                ]}
              >
                <InputNumber<number>
                  parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                  min={0}
                  className="form-input-container"
                />
              </Form.Item>

              <Form.Item
                label="Precio"
                name="price"
                rules={[
                  { required: true, message: "Por favor ingresa un precio!" },
                ]}
              >
                <InputNumber<number>
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                  min={0}
                  className="form-input-container"
                />
              </Form.Item>

              <Form.Item
                label="Precio Fijo"
                name="fixed"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa un precio fijo!",
                  },
                ]}
              >
                <InputNumber<number>
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                  min={0}
                  className="form-input-container"
                />
              </Form.Item>

              <Form.Item
                label="Minutos"
                name="minute"
                rules={[
                  { required: true, message: "Por favor ingresa un tiempo!" },
                ]}
              >
                <InputNumber<number>
                  parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                  min={0}
                  className="form-input-container"
                />
              </Form.Item>

              <Form.Item
                label="Distancia"
                name="distance"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa una distancia!",
                  },
                ]}
              >
                <InputNumber<number>
                  parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                  min={0}
                  className="form-input-container"
                />
              </Form.Item>

              <Form.Item
                name="calculator"
                label="Calculador"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa un calculador!",
                  },
                ]}
              >
                <Select
                  placeholder="Seleccione una región"
                  showSearch
                  options={CALCULATOR_TYPE}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item name="image" label="Imagen del tipo de servicio">
                <Upload
                  listType="picture"
                  beforeUpload={(res) => uploadFile(res)}
                  maxCount={1}
                >
                  <Button loading={isFileLoading} icon={<UploadOutlined />}>
                    Cargar archivo
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space direction="horizontal">
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(WebRoutes.ServiceTypeRoute, {
                        replace: true,
                      })
                    }
                  >
                    Cancelar
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default ServiceTypeForm;
