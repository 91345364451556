import { Button, Col, Descriptions, Popover, Row, message } from "antd";
import EmptyTripDTO from "../../../shared/dtos/emptyTripDto";
import { InfoCircleOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { deleteEmptyTripApi } from "../../../services/emptyTripApi";
import { useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { userRequestListAsync } from "../../../features/userRequest/userRequestListSlice";
import dayjs from "dayjs";

interface Props {
  emptyTrip: EmptyTripDTO;
}

const EmptyTripDetails = ({ emptyTrip }: Props) => {
  const [deleting, setDeleting] = useState(false);
  const dispatch = useAppDispatch();

  const deleteEmptyTrip = async (e: any) => {
    e.stopPropagation();
    if (emptyTrip?.id) {
      setDeleting(true);
      try {
        await deleteEmptyTripApi(emptyTrip?.id);
        message.success("Viaje vacío eliminado satisfactoriamente.");
      } catch (error) {
        message.error(
          "Ha ocurrido un error al intentar eliminar el viaje vacío."
        );
      }
      await dispatch(userRequestListAsync({}));
      setDeleting(false);
    }
  };

  return (
    <Popover
      content={
        <Row gutter={[12, 24]} style={{ maxWidth: 700 }}>
          <Col span={24}>
            <Descriptions title="Viaje vacío" bordered column={1}>
              <Descriptions.Item label="Origen">
                {emptyTrip.originAddress}
              </Descriptions.Item>
              <Descriptions.Item label="Destino">
                {emptyTrip.destinationAddress}
              </Descriptions.Item>
              {emptyTrip.estimatedDepartureTime && (
                <Descriptions.Item label="Tiempo estimado de salida">
                  {dayjs(emptyTrip.estimatedDepartureTime).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              size="small"
              icon={<MinusSquareOutlined />}
              type="primary"
              danger
              onClick={deleteEmptyTrip}
              disabled={deleting}
              loading={deleting}
            >
              Viaje vacio
            </Button>
          </Col>
        </Row>
      }
      trigger="hover"
      placement="right"
    >
      <Button icon={<InfoCircleOutlined />} type="link">
        Viaje vacio
      </Button>
    </Popover>
  );
};

export default EmptyTripDetails;
