export const AdminCreate = "Crear";
export const AdminUpdate = "Actualizar";
export const SuperAdminTypeId = 1;
export const AdminTypeId = 2;
export const OperatorTypeId = 3;
export const NameDescription = "name";
export const FirstNameDescription = "firstName";
export const LastNameDescription = "lastName";
export const PasswordDescription = "password";
export const EmailDescription = "email";
export const MobileDescription = "mobile";
export const DepartmentIdDescription = "departmentId";
export const CorporativoDescription = "corporativo";
export const EmptyString = "";
export const ManualDescription = "manual";
export const CASHDescription = "CASH";
export const AndroidDescription = "android";
export const AdminTypeIdDescription = "adminTypeId";
export const PaidDescription = "Pagado";
export const NotPaidDescription = "No pagado";
export const ExportToExcelDescription = "Exportar a Excel";
export const NoFinishDate = "No existe fecha de finalizacion";
export const ServiceTypeIdDescription = "serviceTypeId";
export const ServiceNumberDescription = "serviceNumber";
export const ServiceModelDescription = "serviceModel";
export const ProviderServiceStatusDescription = "status";
export const AdminWalletDeposit = "DEPOSIT";
export const AdminWalletConcept = "Deposito a cuenta";
const date = new Date();
export const startDateValue = new Date(
  date.getFullYear(),
  date.getMonth(),
  1
).toString();
export const startDocumentDateValue = new Date(
  date.getFullYear(),
  date.getMonth() - 2,
  1
).toString();
export const endDateValue = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  1
).toString();

export const UserAuthorizedCountriesDescription = "userAuthorizedCountries";
export const UserAuthorizedServiceTypesDescription =
  "userAuthorizedServiceTypes";
export const UserAuthorizedScheduleFromDescription =
  "userAuthorizedScheduleFrom";
export const UserAuthorizedScheduleToDescription = "userAuthorizedScheduleTo";
export const UserAuthorizedWeekdayDescription = "userAuthorizedWeekday";
export const PasswordRegexExpression = new RegExp(
  "^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[!+-.@#$%^&])[a-zA-Z\\d!+-.@#$%^&]{7,}$"
);
export const PasswordRequirementText =
  "La contraseña debe contener minimo 7 caracteres, una letra, un numero y un caracter especial !+-.@#$%^&";
