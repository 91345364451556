import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postUserRequestsAsDispatcher } from "../../services/userRequestApi";
import UserRequestDTO from "../../shared/dtos/userRequestDTO";
import ModelState from "../../shared/states/modelState";

const initialState: ModelState<any> = {
  value: {},
  status: "idle",
};

export const userRequestPostAsync = createAsyncThunk(
  "userrequest/",
  async (userRequest: UserRequestDTO) => {
    const response = await postUserRequestsAsDispatcher(userRequest);
    return response;
  }
);

export const userRequestPostSlice = createSlice({
  name: "userRequestPost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userRequestPostAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(userRequestPostAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(userRequestPostAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default userRequestPostSlice.reducer;
