import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Avatar, Col, List, Row, Space, Typography } from "antd";

const { Text } = Typography;

interface Props {
  passengers: Array<any>;
}

const GroupTripPassengerList = ({ passengers }: Props): JSX.Element => {
  return (
    <>
      <List
        itemLayout="horizontal"
        size="small"
        dataSource={passengers}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  size={30}
                  style={{
                    backgroundColor: item.getOn ? "#87d068" : " #f9c54f",
                  }}
                  icon={
                    item.getOn ? (
                      <DoubleRightOutlined />
                    ) : (
                      <DoubleLeftOutlined />
                    )
                  }
                />
              }
              title={
                <Row>
                  <Col span={12}>
                    <Text>{item.name}</Text>
                  </Col>
                  <Col span={12}>
                    <Space>
                      <PhoneOutlined />
                      <span>{item.cellphone}</span>
                    </Space>
                  </Col>
                </Row>
              }
              description={item?.description}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default GroupTripPassengerList;
