import { useState } from "react";
import FormDirection from "./FormDirection";
import { Button, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect } from "react";
import { manualValidateDirectionGroupTripsPassegerAsync } from "../../../features/groupTrip/passeger/groupTripPassegerSlide";
import {
  DirectionDTO,
  GoogleResponseDTO,
  PassengerGroupDTO,
} from "../../../shared/dtos/PassengerGroupDTO";

interface paramsValidate {
  show: boolean;
  setShow: (show: boolean) => void;
  type: string;
  passengerFromDispatcher?: PassengerGroupDTO | undefined;
  updateAddres?: (
    newAddress: any,
    passengerId: string | null,
    type: string
  ) => void;
}

export default function ModalNewDirection({
  show,
  setShow,
  type,
  passengerFromDispatcher,
  updateAddres,
}: paramsValidate) {
  const passeger: PassengerGroupDTO | null = useAppSelector(
    (state) => state.passeger.selected || null
  );
  const request = useAppSelector((state) => state.groupTripsRequest.selected);
  const [direction, setDirection] = useState<GoogleResponseDTO | null>(null);
  const dispatch = useAppDispatch();

  const passenerData = passengerFromDispatcher || passeger;

  const handleOk = () => {
    if (direction) {
      const newDirection: DirectionDTO = {
        id: null,
        name:
          type === "origin"
            ? passenerData?.origin?.name || ""
            : passenerData?.destiny?.name || "",
        address: direction?.address,
        coordinate: {
          lat: direction?.latitude,
          lng: direction?.longitude,
        },
        keys: [
          type === "origin"
            ? passenerData?.origin?.name || ""
            : passenerData?.destiny?.name || "",
        ],
        available: true,
        googlemaps: direction?.id,
      };
      if (passengerFromDispatcher && updateAddres) {
        updateAddres(newDirection, passengerFromDispatcher?.id, type);
      } else {
        passeger &&
          dispatch(
            manualValidateDirectionGroupTripsPassegerAsync(
              request?.id || "0",
              newDirection
            )
          );
      }
    }
  };

  const handleCancel = () => {
    setShow(false);
  };
  useEffect(() => {
    console.log("Googlemaps ... ", direction);
  }, [direction]);
  return (
    <>
      <Modal
        title={`Validar dirección del pasajero : ${passenerData?.name}`}
        open={show}
        footer={[
          <Button type="primary" key="submitModalPasseger" onClick={handleOk}>
            Aceptar
          </Button>,
          <Button
            type="primary"
            key="cancelModalPasseger"
            onClick={handleCancel}
          >
            Cancelar
          </Button>,
        ]}
      >
        <h3>
          {type === "origin"
            ? `Origen : ${passenerData?.origin?.name}`
            : `Destino : ${passenerData?.destiny?.name}`}
        </h3>

        <FormDirection setDir={setDirection} />
      </Modal>
    </>
  );
}
