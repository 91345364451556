import { DirectionsRenderer, Marker } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import MapDTO from "../../shared/dtos/mapDTO";
import { DestinoIcon, OrigenIcon } from "../../shared/constants/Images";

interface Props {
  destinations: MapDTO;
}

const CheckPoints = ({ destinations }: Props): JSX.Element => {
  const [directionsResponse, setDirectionsResponse] = useState<any | undefined>(
    undefined
  );

  useEffect(() => {
    const calculateRoute = async (destinations: MapDTO) => {
      const directionsService = new google.maps.DirectionsService();
      const origin = destinations.pickUp.originAddress;
      const destination = destinations.dropOff.destinationAddress;
      const points = destinations?.wayPoints || [];
      const waypoints = points.map((w) => ({
        location: w.address,
        stopover: true,
      }));

      const route = await directionsService.route({
        origin: origin,
        destination: destination,
        waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirectionsResponse(route);
    };

    calculateRoute(destinations);
  }, [destinations]);

  if (typeof directionsResponse !== "undefined") {
    return (
      <>
        {destinations?.pickUp?.originLatitude &&
          destinations?.pickUp?.originLongitude && (
            <Marker
              position={{
                lat: parseFloat(destinations.pickUp.originLatitude.toString()),
                lng: parseFloat(destinations.pickUp.originLongitude.toString()),
              }}
              title={destinations.pickUp?.originAddress}
              icon={OrigenIcon}
            />
          )}

        {destinations?.wayPoints?.map((destination) =>
          destination.latitude && destination.longitude ? (
            <Marker
              key={destination.id}
              position={{
                lat: parseFloat(destination.latitude.toString()),
                lng: parseFloat(destination.longitude.toString()),
              }}
              title={destination.address}
              icon={DestinoIcon}
            />
          ) : null
        )}
        {destinations?.dropOff?.destinationLatitude &&
          destinations?.dropOff?.destinationLongitude && (
            <Marker
              position={{
                lat: parseFloat(
                  destinations.dropOff.destinationLatitude.toString()
                ),
                lng: parseFloat(
                  destinations.dropOff.destinationLongitude.toString()
                ),
              }}
              title={destinations.dropOff?.destinationAddress}
              icon={DestinoIcon}
            />
          )}
        <DirectionsRenderer
          directions={directionsResponse}
          options={{ suppressMarkers: true }}
        />
      </>
    );
  }
  return <></>;
};

export default CheckPoints;
