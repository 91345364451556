import { UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Space,
  Switch,
  Upload,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import { postBannerAsync } from "../../../features/banner/postBannerSlice";
import { WebRoutes } from "../../../shared/constants/WebRoutes";

import "../../../shared/styles/form.scss";

export const BannerForm = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UploadFile>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const formData = new FormData();

    if (file) {
      formData.append("file", file as RcFile);
    } else {
      notification.error({
        message: "Debes seleccionar una imagen",
        placement: "bottom",
      });
      return;
    }

    const { name, forManager, forApp, link } = values;

    const data = {
      name,
      forManager: Boolean(forManager),
      forApp: Boolean(forApp),
      link: link || null,
    };

    if (!data.forManager && !data.forApp) {
      notification.error({
        message: "Debes seleccionar al menos una opción de visualización!",
        placement: "bottom",
      });
      return;
    }

    formData.append("data", JSON.stringify(data));

    setLoading(true);

    await dispatch(postBannerAsync(formData));

    setLoading(false);

    navigate(WebRoutes.BannerRoute, {
      replace: true,
    });
  };

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
        notification.error({
          message: "Solo puedes subir archivos con formato JPG y PNG",
          placement: "bottom",
        });

        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        notification.error({
          message: "La imagen debe pesar menos de 2mb!",
          placement: "bottom",
        });

        return;
      }

      setFile(file);

      return false;
    },
    maxCount: 1,
  };

  return (
    <>
      <Header>Crear nuevo Banner</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row justify="center">
          <Col span={15} className="sub-container create-container">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Nombre"
                name="name"
                rules={[
                  { required: true, message: "Por favor ingresa un nombre!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Visualizar en Manager"
                name="forManager"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Visualizar en App"
                name="forApp"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Subir imagen"
                valuePropName="file"
                rules={[
                  {
                    required: true,
                    message: "Por favor selecciona un archivo",
                  },
                ]}
              >
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Seleccionar imagen</Button>
                </Upload>

                <br />

                <Alert
                  message="Resolución recomendada: 740 x 200 px"
                  type="info"
                />
              </Form.Item>

              <Form.Item label="Link" name="link">
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space direction="horizontal">
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(WebRoutes.BannerRoute, {
                        replace: true,
                      })
                    }
                  >
                    Cancelar
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default BannerForm;
