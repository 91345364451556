import axios from "axios";
import { selectCurrentAdminToken } from "../utils/helpers/selectorHelper";
import UserRequestDTO from "../shared/dtos/userRequestDTO";
import EmptyTripDTO from "../shared/dtos/emptyTripDto";
const apiUrl = process.env.REACT_APP_API_URL;

export const getEmptyTripsApi = async () => {
  const result = await axios.get(`${apiUrl}emptytrip`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const createEmptyTripApi = async (emptyTrip: UserRequestDTO) => {
  const result = await axios.post(`${apiUrl}emptytrip`, emptyTrip, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });

  return result;
};

export const deleteEmptyTripApi = async (id: number) => {
  const result = await axios.delete(`${apiUrl}emptytrip/${id}`, {
    headers: {
      auth: selectCurrentAdminToken(),
    },
  });
  return result.data;
};

export const updateProviderByEmptyTripIdApi = async (
  id: number,
  newProviderId: number
) => {
  const result = await axios.patch(
    `${apiUrl}emptytrip/${id}/provider`,
    { newProviderId },
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data;
};

export const updateEmptyTripApi = async (emptyTrip: EmptyTripDTO) => {
  const result = await axios.put(
    `${apiUrl}emptytrip/${emptyTrip.id}`,
    emptyTrip,
    {
      headers: {
        auth: selectCurrentAdminToken(),
      },
    }
  );
  return result.data.data;
};
