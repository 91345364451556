import { Tabs } from "antd";
import NotificationTrip from "./notificationTrip";
import { useMemo } from "react";
import ConversationPanel from "./conversationPanel";
import NotificationItemList from "./notificationItemList";

interface Props {
  notifications: any;
}

const NotificationsTabPanel = ({ notifications }: Props): JSX.Element => {
  const notificationCancel = useMemo(() => {
    if (notifications?.button) {
      return notifications.button;
    }
    return [];
  }, [notifications?.button]);

  const notificationText = useMemo(() => {
    if (notifications?.text) {
      return notifications?.text?.reduce((result: any, el: any) => {
        const index =
          el.from?.type === "client" ? el.from?.number : el.to?.number;
        if (result[index]) {
          return {
            ...result,
            [index]: [...result[index], el],
          };
        }
        return {
          ...result,
          [index]: [el],
        };
      }, {});
    }
    return [];
  }, [notifications?.text]);

  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          label: "Cancelación de viajes",
          key: "1",
          children: <NotificationTrip notifications={notificationCancel} />,
        },
        {
          label: "Mensajes",
          key: "2",
          children: (
            <div
              style={{ maxHeight: "75vh" }}
              className="site-drawer-render-in-current-drawer-wrapper"
            >
              <NotificationItemList messages={notificationText} />
              <ConversationPanel />
            </div>
          ),
        },
      ]}
    ></Tabs>
  );
};

export default NotificationsTabPanel;
