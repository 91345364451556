import { StarOutlined } from "@ant-design/icons";
import { Avatar, Badge, Card, Col, Popover, Row, Space, Tag } from "antd";
import {
  CarIcon,
  MobileIcon,
  ProviderIcon,
  UserIcon,
} from "../../../../shared/constants/Images";
import ProviderDTO from "../../../../shared/dtos/providerDTO";
import { useMemo } from "react";

interface ProviderCardProps {
  provider: ProviderDTO;
  selectedProviderId?: number | null;
}

export const ProviderCard = ({
  provider,
  selectedProviderId,
}: ProviderCardProps) => {
  const numServices = useMemo(() => {
    return provider?.services?.length || 0;
  }, [provider?.services?.length]);

  return (
    <div
      style={{
        margin: "5px 8px 6px 8px",
        boxShadow:
          selectedProviderId === provider.id
            ? "rgba(0, 0, 0, 0.80) 0px 2px 5px"
            : "",
      }}
    >
      <Card
        hoverable
        bodyStyle={{
          padding: "5px 10px",
          background: selectedProviderId === provider.id ? "#e5e5e5" : "",
        }}
      >
        <Row gutter={[0, 4]} className="custom-cart-provider-to-assign">
          <Col span={18}>
            <Space>
              <img src={UserIcon} alt="conductor" />
              <p style={{ marginBottom: 0 }}>{provider?.name}</p>
            </Space>
          </Col>
          <Col span={6} className="align-data-to-right">
            <Space>
              <StarOutlined style={{ fontSize: "20px" }} />
              <p>{provider?.rating}</p>
            </Space>
          </Col>
          <Col span={12}>
            <Space>
              <img src={MobileIcon} alt="movil" />
              <p>{provider?.phone}</p>
            </Space>
          </Col>
          <Col span={12} className="align-data-to-right">
            <Popover
              placement="right"
              title={
                numServices > 1 ? "Servicios brindados por el conductor:" : ""
              }
              content={
                numServices > 1 ? (
                  <Row style={{ display: "flex", gap: 5, width: 250 }}>
                    {provider?.services?.map((s, index) => (
                      <Tag key={index}>{s.serviceType}</Tag>
                    ))}
                  </Row>
                ) : undefined
              }
            >
              <Space>
                <Badge
                  count={numServices > 1 ? provider?.services?.length : null}
                  size="small"
                >
                  <Avatar src={ProviderIcon} size="small" />
                </Badge>
                <p style={{ marginBottom: 0 }}>
                  {provider?.service?.serviceType || ""}
                </p>
              </Space>
            </Popover>
          </Col>
          <Col span={24}>
            <Space>
              <img src={CarIcon} alt="car" />
              <p>{provider?.service?.serviceModel || ""}</p>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProviderCard;
