import { Button, Tooltip } from "antd";
import { useState } from "react";
import ModalNewGroupRequest from "../common/modalNewGroupRequest";

type BtnType = "primary" | "link";
type OnClickFunction = () => void;
interface Props {
  btnType?: BtnType;
  text: string;
  onClick?: OnClickFunction;
  modalTitle?: string;
  icon?: JSX.Element;
  titleTooltip?: string;
}

const PopUpNewGroup = ({
  btnType = "primary",
  text,
  onClick,
  modalTitle,
  icon,
  titleTooltip,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    setOpen(true);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Tooltip title={titleTooltip} placement="topRight">
        <Button
          type={btnType}
          className={btnType === "primary" ? "btn-pop-up-new-group-trip" : ""}
          onClick={handleOnClick}
          icon={icon}
        >
          {text}
        </Button>
      </Tooltip>
      <ModalNewGroupRequest open={open} setOpen={setOpen} title={modalTitle} />
    </>
  );
};

export default PopUpNewGroup;
