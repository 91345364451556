import React, { useState, useCallback, useMemo } from "react";
import { setDistance } from "../../features/map/mapSlice";
import { DestinationDTO } from "../../shared/dtos/destinationsDTO";
import { OriginDTO } from "../../shared/dtos/originDTO";
import { useAppDispatch } from "../../app/hooks";
import { DestinoIcon, OrigenIcon } from "../../shared/constants/Images";

import {
  Marker,
  DirectionsService,
  DistanceMatrixService,
  DirectionsRenderer,
} from "@react-google-maps/api";

interface WayPointsDirections {
  pickUp?: OriginDTO;
  dropOff?: DestinationDTO;
}

interface WayPointsProps {
  directions: WayPointsDirections;
  shouldCalculateDistance?: boolean | false;
}

export const WayPoints: React.FunctionComponent<WayPointsProps> = (props) => {
  const { directions, shouldCalculateDistance } = props;

  const [response, setResponse] = useState<any>(null);

  const dispatch = useAppDispatch();

  const directionsCallback = useCallback((response: any) => {
    response?.status === "OK"
      ? setResponse(response)
      : console.log("response: ", response);
  }, []);

  const distanceMatrixCallback = useCallback(
    (response: any) => {
      const distanceValue = response?.rows[0]?.elements[0]?.distance?.value;
      const distance = distanceValue ? distanceValue / 100 : 0;

      const durationValue = response?.rows[0]?.elements[0]?.duration?.value;
      const duration = durationValue ? durationValue / 60 : 0;

      dispatch(setDistance({ distance, duration }));
    },
    [dispatch]
  );

  const directionsRender = useMemo(() => {
    return response ? (
      <DirectionsRenderer
        options={{
          directions: response,
          suppressMarkers: true,
        }}
      />
    ) : null;
  }, [response]);

  const distanceMatrixOptions = useMemo(() => {
    return {
      destinations: [
        {
          lat: directions.dropOff?.destinationLatitude ?? 0,
          lng: directions.dropOff?.destinationLongitude ?? 0,
        },
      ],
      origins: [
        {
          lat: directions.pickUp?.originLatitude ?? 0,
          lng: directions.pickUp?.originLongitude ?? 0,
        },
      ],
      travelMode: google.maps.TravelMode.DRIVING,
    };
  }, [directions]);

  const travelOptions = useMemo(() => {
    return {
      destination: {
        lat: directions.dropOff?.destinationLatitude ?? 0,
        lng: directions.dropOff?.destinationLongitude ?? 0,
      },
      origin: {
        lat: directions.pickUp?.originLatitude ?? 0,
        lng: directions.pickUp?.originLongitude ?? 0,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    };
  }, [directions]);

  if (!directions.pickUp) {
    return null;
  }

  return (
    <>
      <Marker
        key={directions.pickUp.id}
        position={{
          lat: directions.pickUp.originLatitude,
          lng: directions.pickUp.originLongitude,
        }}
        title={
          directions.pickUp.originAddress && directions.pickUp.originAddress
        }
        icon={OrigenIcon}
      />
      {directions.dropOff && (
        <Marker
          key={directions.dropOff.id}
          position={{
            lat: directions.dropOff.destinationLatitude,
            lng: directions.dropOff.destinationLongitude,
          }}
          title={
            directions.dropOff.destinationAddress &&
            directions.dropOff.destinationAddress
          }
          icon={DestinoIcon}
        />
      )}
      {directions.pickUp && directions.dropOff && (
        <>
          {shouldCalculateDistance && (
            <DistanceMatrixService
              options={distanceMatrixOptions}
              callback={distanceMatrixCallback}
            />
          )}
          <DirectionsService
            options={travelOptions}
            callback={directionsCallback}
          />
          {directionsRender}
        </>
      )}
    </>
  );
};
