import { Button, Col, Input, Row } from "antd";
import ContainerLayout from "../../common/containerLayout";
import LabelAndInput from "../../common/labelAndInput";
import { selectCurrentAdminPartnerFee } from "../../../utils/helpers/selectorHelper";
import { useState } from "react";
import { updateAdminPartnerFee } from "../../../services/adminApi";
import { useAppDispatch } from "../../../app/hooks";
import { updateAdminPartnerFeeValue } from "../../../features/admin/currentAdminSlice";

const AdminConfiguration = (): JSX.Element => {
  const [partnerFee, setPartnerFee] = useState(selectCurrentAdminPartnerFee());
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onHandleClick = async () => {
    setIsLoading(true);
    await updateAdminPartnerFee(partnerFee);
    await dispatch(updateAdminPartnerFeeValue(partnerFee));
    setIsLoading(false);
  };

  return (
    <ContainerLayout
      title="Configuracion del Administrador"
      showNotification={false}
    >
      <Row gutter={[0, 24]}>
        <Col span={24} className="custom-add-search-column-departments">
          <LabelAndInput label="Comision de partner">
            <Input
              value={partnerFee}
              defaultValue={selectCurrentAdminPartnerFee()}
              type="number"
              onChange={(e) => setPartnerFee(+e.target.value)}
              size="middle"
            />
          </LabelAndInput>
        </Col>
        <Col span={24}>
          <Button loading={isLoading} onClick={() => onHandleClick()}>
            Save
          </Button>
        </Col>
      </Row>
    </ContainerLayout>
  );
};

export default AdminConfiguration;
