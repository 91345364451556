import { createSlice } from "@reduxjs/toolkit";
import { GroupTripRequestDTO } from "../../../shared/dtos/groupTripRequestDTO";
import { ListState } from "../../../shared/states/listState";
import {
  getGroupTripsRequest,
  addGroupTripsRequest,
  deleteGroupTripsRequest,
} from "../../../services/groupTripRequestApi";
import { AppThunk } from "../../../app/store";
import { editGroupTripsRequest } from "../../../services/groupTripRequestApi";
import { message } from "antd";

interface Request extends ListState<GroupTripRequestDTO> {
  selected: GroupTripRequestDTO | undefined;
  loading: boolean;
}

const initialState: Request = {
  value: new Array<GroupTripRequestDTO>(),
  status: "idle",
  selected: undefined,
  loading: false,
};

const groupTripRequestSlide = createSlice({
  name: "groupTripsRequest",
  initialState,
  reducers: {
    set: (state, actions) => {
      state.value = actions.payload;
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
    editRequest: (state, actions) => {
      state.value = state.value.map((r) => {
        if (r.id === actions.payload.id) {
          return actions.payload;
        }
        return r;
      });
    },
    deleteRequest: (state, actions) => {
      state.value = state.value.filter((i) => i.id !== actions.payload);
    },
    setSelectedRequest: (state, actions) => {
      state.selected = state.value.find((r) => r.id === actions.payload);
    },
  },
});

export const {
  set,
  setLoading,
  editRequest,
  deleteRequest,
  setSelectedRequest,
} = groupTripRequestSlide.actions;

export const groupTripsRequestList = (client?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await getGroupTripsRequest({ clientId: client });
      const list = response.map((r: any) => {
        return {
          ...r,
          endDate: r.end_date,
          startDate: r.start_date,
          totalPassengers: r.passeger,
        };
      });
      dispatch(set(list));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const addGroupTripsRequestAsync = (
  request: GroupTripRequestDTO
): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await addGroupTripsRequest(request);
      message.success(response.message);
      dispatch(groupTripsRequestList());
    } catch (error) {
      console.log(error);
    }
  };
};

export const editGroupTripsRequestAsync = (
  request: GroupTripRequestDTO
): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await editGroupTripsRequest(request);
      dispatch(groupTripsRequestList());
      message.success(response.message);
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteGroupTripsRequestAsync = (id: string): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await deleteGroupTripsRequest(id);
      message.success(response.message);
      dispatch(groupTripsRequestList());
    } catch (error) {
      console.log(error);
    }
  };
};

export default groupTripRequestSlide.reducer;
