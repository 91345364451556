import { Collapse, List, Space } from "antd";
import NotificationPassenger from "./notificationPassenger";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { groupByProperty } from "../../../utils/helpers/groupByProperty";
import dayjs from "dayjs";
import { useAppSelector } from "../../../app/hooks";

const { Panel } = Collapse;

interface Props {
  notifications: any;
}

const NotificationTrip = ({ notifications }: Props) => {
  const [listNotificatoinsTrips, setListNotificatoinsTrips] = useState<
    Array<any>
  >([]);

  const tripsInNotifications = useAppSelector(
    (state) => state.notificationTrip.tripsInNotifications
  );

  useEffect(() => {
    const tmp = groupByProperty(notifications, "data", "payload");
    setListNotificatoinsTrips(Object.entries(tmp));
  }, [notifications]);

  return (
    <div className="panel-notification-container-collapse">
      {listNotificatoinsTrips.map((nt) => {
        const trip = nt[0];
        const passengers: Array<any> = nt[1];
        const tripDate = tripsInNotifications[trip]
          ? tripsInNotifications[trip][0]?.schedule_at
          : null;
        const checkPoints = tripsInNotifications[trip]
          ? tripsInNotifications[trip][0]?.check_points
          : [];

        const tripPassengers = checkPoints
          .map((cp: any) => {
            const tmpArray = cp.passengers.map((p: any) => {
              return {
                address: cp.address,
                checkIn: cp.checkIn,
                ...p,
              };
            });
            return [...tmpArray];
          })
          .flat()
          .filter((p: any) => p.getOn);

        return (
          <Collapse
            key={trip}
            accordion
            bordered={false}
            style={{ background: "#F3F6F9", borderRadius: 6 }}
            className="custom-collapse-panel-nitifications-cls"
            expandIcon={(panel) =>
              panel.isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
            }
          >
            <Panel
              header={
                <div className="custom-header-panel-passenger-notification-cls">
                  <div>
                    <Space size="large">
                      <span>{trip}</span>
                      <span>
                        {tripDate
                          ? dayjs(tripDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                          : ""}
                      </span>
                      <span>{`${passengers.length} Pasajeros`}</span>
                    </Space>
                  </div>
                </div>
              }
              key="1"
            >
              <List
                dataSource={passengers}
                renderItem={(item: any) => {
                  const passenger = tripPassengers.find(
                    (p: any) =>
                      p.cellphone.toString() === item?.from?.number?.toString()
                  );
                  return (
                    <List.Item>
                      <NotificationPassenger
                        name={passenger?.name || ""}
                        phone={item?.from?.number}
                        address={passenger?.address?.address || ""}
                      />
                    </List.Item>
                  );
                }}
              />
            </Panel>
          </Collapse>
        );
      })}
    </div>
  );
};

export default NotificationTrip;
