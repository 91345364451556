import { createSlice } from "@reduxjs/toolkit";
import {
  PassengerGroupDTO,
  validateDTO,
} from "../../../shared/dtos/PassengerGroupDTO";
import { ListState } from "../../../shared/states/listState";
import {
  getGroupTripsPasseger,
  addGroupTripsPasseger,
  editGroupTripsPasseger,
  deleteGroupTripsPasseger,
  validateDirecctionGroupTrip,
} from "../../../services/groupTripPassegerApi";
import { AppThunk } from "../../../app/store";
import { message } from "antd";
import { groupTripsDirectionList } from "../direction/groupTripDirectionSlide";
import { DirectionDTO } from "../../../shared/dtos/PassengerGroupDTO";
import { validateAddress } from "../../../services/googlemapApi";
import { addGroupTripsDirection } from "../../../services/groupTripDestinationApi";
import { addTrips } from "../../../services/groupTripsApi";

interface Passeger extends ListState<PassengerGroupDTO> {
  selected: PassengerGroupDTO | undefined;
  loading: boolean;
  validating: validateDTO;
}

const initialState: Passeger = {
  value: new Array<PassengerGroupDTO>(),
  status: "idle",
  selected: undefined,
  loading: false,
  validating: {
    active: false,
    total: 0,
    completed: 0,
  },
};

const groupTripPassegerSlide = createSlice({
  name: "groupTripsPasseger",
  initialState,
  reducers: {
    set: (state, actions) => {
      state.value = actions.payload;
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
    setValidanting: (state, actions) => {
      state.validating = actions.payload;
    },
    editPasseger: (state, actions) => {
      state.value = state.value.map((r) => {
        if (r.id === actions.payload.id) {
          return actions.payload;
        }
        return r;
      });
    },
    deletePasseger: (state, actions) => {
      state.value = state.value.filter((i) => i.id !== actions.payload);
    },
    setSelectedPasseger: (state, actions) => {
      console.log(
        "Select... ",
        actions.payload,
        state.value.find((r) => r.id === actions.payload.toString()),
        state.value
      );

      state.selected = state.value.find((r) => r.id === actions.payload);
    },
  },
});

export const {
  set,
  setLoading,
  setValidanting,
  editPasseger,
  deletePasseger,
  setSelectedPasseger,
} = groupTripPassegerSlide.actions;

export const groupTripsPassegerList = (request?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await getGroupTripsPasseger({ requestId: request });

      dispatch(set(response));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const addGroupTripsPassegerAsync = (
  passeger: PassengerGroupDTO,
  requestId: string,
  callBack: () => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await addGroupTripsPasseger([passeger], requestId);

      message.success(response.message);
      dispatch(groupTripsPassegerList(requestId));
      callBack();
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
      message.error("Error al insertar el pasajero");
      callBack();
    }
  };
};

export const editGroupTripsPassegerAsync = (
  passeger: PassengerGroupDTO,
  requestId: string
): AppThunk => {
  return async (dispatch) => {
    try {
      await editGroupTripsPasseger(requestId, passeger);
      dispatch(groupTripsPassegerList());
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
      message.error("Error al editar el pasajero");
    }
  };
};

export const manualValidateDirectionGroupTripsPassegerAsync = (
  requestId: string,
  direction: DirectionDTO
): AppThunk => {
  return async (dispatch) => {
    try {
      const objectOri = await addGroupTripsDirection(direction);
      const response = await validateDirecctionGroupTrip(
        requestId,
        objectOri.data.id
      );
      dispatch(groupTripsPassegerList(requestId));
      message.success(response.message);
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
      message.error("Error al editar el pasajero");
    }
  };
};

export const deleteGroupTripsPassegerAsync = (
  requestId: string,
  id: string
): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await deleteGroupTripsPasseger(requestId, id);
      message.success(response.message);
      dispatch(groupTripsPassegerList(requestId));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const validatePasseger = async (
  passeger: PassengerGroupDTO,
  dispatch: any,
  getState: Function
): Promise<PassengerGroupDTO> => {
  const direction: DirectionDTO[] = getState().groupTripDirection.value;

  const findOrigin = direction.find(
    (d: DirectionDTO) => d.name === passeger.origin.name
  );

  const findDestiny = direction.find(
    (d: DirectionDTO) => d.name === passeger.destiny.name
  );

  let dirOri: DirectionDTO = findOrigin
    ? findOrigin
    : {
        id: null,
        googlemaps: null,
        address: "No validado",
        name: passeger.origin.name,
        coordinate: {
          lat: 0,
          lng: 0,
        },
        keys: [passeger.origin.name],
        available: false,
      };
  if (!findOrigin) {
    const valOri = await validateAddress(passeger.origin.address);
    if (valOri) {
      const origin: DirectionDTO = {
        id: null,
        googlemaps: valOri.place_id,
        address: valOri.formatted_address,
        name: passeger.origin.name,
        coordinate: {
          lat: valOri.geometry.location.lat,
          lng: valOri.geometry.location.lng,
        },
        keys: [passeger.origin.name],
        available: true,
      };
      const objectOri = await addGroupTripsDirection(origin);

      dirOri = objectOri.data;
    }
  }

  let dirDest: DirectionDTO = findDestiny
    ? findDestiny
    : {
        id: null,
        googlemaps: null,
        address: "No validado",
        name: passeger.destiny.name,
        coordinate: {
          lat: 0,
          lng: 0,
        },
        keys: [passeger.destiny.name],
        available: false,
      };
  if (!findDestiny) {
    const valDest = await validateAddress(passeger.destiny.address);
    if (valDest) {
      const destiny: DirectionDTO = {
        id: null,
        googlemaps: valDest.place_id,
        address: valDest.formatted_address,
        name: passeger.destiny.name,
        coordinate: {
          lat: valDest.geometry.location.lat,
          lng: valDest.geometry.location.lng,
        },
        keys: [passeger.destiny.name],
        available: true,
      };
      const objectOri = await addGroupTripsDirection(destiny);
      dirDest = objectOri.data;
    }
  }
  dispatch(groupTripsDirectionList());
  const passegerR: PassengerGroupDTO = {
    ...passeger,
    origin: dirOri,
    destiny: dirDest,
  };
  return passegerR;
};
export const importPassengersAsync =
  (
    list: Array<PassengerGroupDTO>,
    callBack: (data: Array<any>) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      setValidanting({
        active: true,
        total: list.length,
        completed: 0,
      })
    );
    dispatch(groupTripsDirectionList());
    const posts: PassengerGroupDTO[] = [];
    for (const l of list) {
      const resp = await validatePasseger(l, dispatch, getState);
      posts.push(resp);
      dispatch(
        setValidanting({
          active: true,
          total: list.length,
          completed: posts.length,
        })
      );
    }
    console.log(posts);
    dispatch(
      setValidanting({
        active: false,
        total: 0,
        completed: 0,
      })
    );
    callBack(posts);
  };

export const addGroupTrip = (
  trip: Array<any>,
  redirect: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await addTrips(trip);
      if (response.status === "success") {
        message.success(response.data.message);
        redirect();
      }
    } catch (error) {
      message.error("Ha ocurrido un error al intentar realizar esta acción");
    }
  };
};

export default groupTripPassegerSlide.reducer;
