import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserRequests } from "../../services/userRequestApi";
import ProviderDTO from "../../shared/dtos/providerDTO";
import UserRequestDTO from "../../shared/dtos/userRequestDTO";
import { TRIP_STATUS } from "../../shared/constants/TripStatus";
import { COUNTRIES } from "../../utils/constants/countries";
import UserRequestProps from "../../propInterfaces/UserRequestProps";

interface UserRequest {
  value: Array<UserRequestDTO>;
  status: string;
  selectedCountryId: number | undefined;
  selectedAdminId: number | undefined;
}

const initialState: UserRequest = {
  value: new Array<UserRequestDTO>(),
  status: "idle",
  selectedCountryId: COUNTRIES[0].value,
  selectedAdminId: 0
};

export const userRequestListAsync = createAsyncThunk(
  "userrequest/",
  async ({ countryId, adminId }: UserRequestProps) => {
    const response = await getUserRequests(countryId, adminId);
    return response.data;
  }
);

export const userRequestListSlice = createSlice({
  name: "userRequestList",
  initialState,
  reducers: {
    removeCurrentUserRequestListValue: (state) => {
      state.value = [];
    },
    removeProviderFromLocalUserRequestValue: (state, action) => {
      let userRequestList: Array<UserRequestDTO> = state.value;
      const userRequestId: number = Number(action.payload.userRequestId);
      const index = userRequestList.findIndex((x) => x.id === userRequestId);
      if (index > -1) {
        let userRequest = userRequestList[index];
        userRequest.provider = null;
        userRequest.providerId = null;
        userRequestList[index] = userRequest;
        state.value = userRequestList;
      }
    },
    addProviderFromLocalUserRequestValue: (state, action) => {
      let userRequestList: Array<UserRequestDTO> = state.value;
      const provider: ProviderDTO = action.payload.selectedProvider;
      const index = userRequestList.findIndex(
        (x) => x.id === action.payload.userRequestId
      );
      if (index > -1) {
        let userRequest = userRequestList[index];
        userRequest.provider = provider;
        userRequest.providerId = provider.id;
        userRequestList[index] = userRequest;
        state.value = userRequestList;
      }
    },
    updateUserRequestListValue: (state, action) => {
      let userRequestList: Array<UserRequestDTO> = state.value;
      const userRequest: UserRequestDTO = action.payload;
      if (userRequest && userRequestList) {
        const index = userRequestList.findIndex(
          (x) => x.id === userRequest.id
        );
        if (userRequest.status!.toLowerCase() !== TRIP_STATUS.Cancelled.toString().toLowerCase() || userRequest.status!.toLowerCase() !== TRIP_STATUS.Completed.toString().toLowerCase()) {
          if (index > -1) {
            userRequestList = userRequestList.filter(
              (x) => x.id !== userRequest.id
            );
            userRequestList = [userRequest].concat(userRequestList);
          }
          else {
            state.value = [userRequest].concat(userRequestList);
          }
        }
        else {
          userRequestList = userRequestList.filter(
            (x) => x.id !== userRequest.id
          );
          state.value = userRequestList;
        }
        
        userRequestList = userRequestList.sort(function(a,b){
          if (a.scheduleAt && b.scheduleAt) {
            return +new Date(a.scheduleAt) - +new Date(b.scheduleAt);
          }
          else if (a.scheduleAt && b.createdAt) {
            return +new Date(a.scheduleAt) - +new Date(b.createdAt);
          }
          else if (a.createdAt && b.scheduleAt) {
            return +new Date(a.createdAt) - +new Date(b.scheduleAt);
          }
          return +new Date(a.createdAt!) - +new Date(b.createdAt!);
        });

        for (const userRequest of userRequestList) {
          if (userRequest.status!.toLowerCase() === TRIP_STATUS.Searching.toString().toLowerCase()) {
            userRequestList = userRequestList.filter(
              (x) => x.id !== userRequest.id
            );
            userRequestList = [userRequest].concat(userRequestList);
          }
        }
        
        state.value = userRequestList;
      }
    },
    deleteUserRequestsValue: (state, action) => {
      let userRequestList: Array<UserRequestDTO> = state.value;
      const userRequestId: number = Number(action.payload);
      if (userRequestId) {
        userRequestList = userRequestList.filter(
          (x) => x.id !== userRequestId
        );
        state.value = userRequestList;
      }
    },
    setSelectedCountryId: (state, actions) => {
      state.selectedCountryId = actions.payload;
    },
    setSelectedAdminId: (state, actions) => {
      state.selectedAdminId = actions.payload;
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(userRequestListAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(userRequestListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = state.value = action.payload;
      })
      .addCase(userRequestListAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const {
  removeCurrentUserRequestListValue,
  removeProviderFromLocalUserRequestValue,
  addProviderFromLocalUserRequestValue,
  updateUserRequestListValue,
  deleteUserRequestsValue,
  setSelectedCountryId,
  setSelectedAdminId
} = userRequestListSlice.actions;

export default userRequestListSlice.reducer;
