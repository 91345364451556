import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { DestinationDTO } from "../../shared/dtos/destinationsDTO";
import { OriginDTO } from "../../shared/dtos/originDTO";
import { setDestination, setOrigin } from "../../features/map/mapSlice";
import { useAppDispatch } from "../../app/hooks";
import { DirectionType } from "../../shared/constants/DirectionType";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux/es/hooks/useSelector";

interface IPlaceAutocomplete {
  type: string;
  placeholder?: any;
  callback?: (data: any) => void;
}

const geoDataToDestination = (
  value: any | null,
  response: any
): DestinationDTO => {
  return {
    id: value?.value?.place_id,
    destinationLatitude: response[0].geometry?.location?.lat(),
    destinationLongitude: response[0]?.geometry?.location?.lng(),
    destinationAddress: value?.label,
  };
};

const geoDataToOrigin = (value: any | null, response: any): OriginDTO => {
  return {
    id: value?.value?.place_id,
    originLatitude: response[0].geometry?.location?.lat(),
    originLongitude: response[0]?.geometry?.location?.lng(),
    originAddress: value?.label,
  };
};

const ComponentPlacesAutocomplete: React.FC<IPlaceAutocomplete> = ({
  type,
  placeholder,
  callback,
}) => {
  const country = useSelector(
    (state: RootState) => state?.map.country
  );
  const [value, setValue] = useState<any>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    geocodeByPlaceId(value?.value?.place_id).then((response: any) => {
      if (type === DirectionType.Origin) {
        const direction = geoDataToOrigin(value, response);
        dispatch(setOrigin(direction));
      }
      if (type === DirectionType.Destination) {
        const direction = geoDataToDestination(value, response);
        dispatch(setDestination(direction));
      }
    });
  }, [value, dispatch, type]);

  const multipleDestinationChange = (val: any) => {
    if (callback) {
      geocodeByPlaceId(val?.value?.place_id).then((response: any) => {
        const direction = geoDataToDestination(val, response);
        callback({ ...val, directionData: direction });
      });
    }
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        apiOptions={{ language: "es", region: "uy" }}
        selectProps={{
          value:
            type === DirectionType.MultiDestination && placeholder
              ? placeholder
              : value,
          onChange:
            type === DirectionType.MultiDestination
              ? multipleDestinationChange
              : setValue,
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: [country?.code ?? "uy"],
          },
        }}
      />
    </div>
  );
};

export default ComponentPlacesAutocomplete;
