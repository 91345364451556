import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DefaultPaginationProps from "../../propInterfaces/defaultPaginationProps";
import { GetUserList } from "../../services/userAPI";
import UserDTO from "../../shared/dtos/userDTO";
import { ValidationErrors } from "../ValidationErrors";
import { AxiosError } from "axios";

export const initialState = {
  value: { userDtos: new Array<UserDTO>(), total: 0, take: 10 },
  loading: false,
};

export const userListAsync = createAsyncThunk(
  "users/",
  async ({ page, take, searchText }: DefaultPaginationProps, { rejectWithValue }) => {
    try {
      const response = await GetUserList({ page, take, searchText });
      const userList = new Array<UserDTO>();

      for (const userFromDb of response.data as Array<UserDTO>) {
        let user: UserDTO = {
          id: userFromDb.id,
          firstName: userFromDb.firstName,
          lastName: userFromDb.lastName,
          email: userFromDb.email,
          mobile: userFromDb.mobile,
          rating: userFromDb.rating,
          paymentMode: userFromDb.paymentMode,
          department: userFromDb.department,
          administrator: userFromDb.administrator,
          type: userFromDb.type,
          offerExtraTrip: userFromDb.offerExtraTrip,
        };

        userList.push(user);
      }

      const userListObject = {
        userDtos: userList,
        total: response.total,
        take: 10,
      };

      return userListObject;
    } catch (err: any) {
      let error: AxiosError<ValidationErrors> = err; // cast the error for access

      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const userListSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    updateUserListValue: (state) => {
      state.value.userDtos = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userListAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.value.userDtos = state.value.userDtos.concat(
          action.payload.userDtos
        );
        state.value.total = action.payload.total;
      })
      .addCase(userListAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { updateUserListValue } = userListSlice.actions;

export default userListSlice.reducer;
