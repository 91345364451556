import { Button, Col, Form, Input, notification, Row, Space, Spin } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import {
  createRegion,
  getRegions,
  Region,
  updateRegion,
} from "../../../services/regionsApi";
import Wkt from "wicket";
import "wicket/wicket-gmap3";

import "../../../shared/styles/form.scss";
import { DrawingMap } from "../../map/DrawingMap";

let serializedPolygon = "";

export const RegionForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  let { regionId } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingRegions, setLoadingRegions] = useState(true);

  const [existingPolygonPaths, setExistingPolygonPaths] = useState<
    Array<Array<google.maps.LatLngLiteral>>
  >([]);
  const [editablePolygonPath, setEditablePolygonPath] = useState<
    Array<google.maps.LatLngLiteral>
  >([]);

  const buildPolygonsPayload = (regions: Region[]) => {
    const paths = [];

    for (const region of regions) {
      try {
        const wkt = new Wkt.Wkt();
        wkt.read(region.polygon);

        const { coordinates } = wkt.toJson();

        const regionCoords = coordinates[0].map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));

        const editable = region.id === +(regionId || 0);

        if (editable) {
          setEditablePolygonPath(regionCoords);
        } else {
          paths.push(regionCoords);
        }
      } catch (error) {}
    }

    setExistingPolygonPaths(paths);
  };

  const getRegionsFromApi = async () => {
    const data = await getRegions();

    const region = data.find((r) => r.id === +(regionId || 0));
    if (region) {
      form.setFieldValue("name", region.name);
    }
    buildPolygonsPayload(data);
    setLoadingRegions(false);
  };

  useEffect(() => {
    serializedPolygon = "";
    getRegionsFromApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    const wkt = new Wkt.Wkt();
    const wktPolygon = wkt.fromObject(polygon);

    serializedPolygon = wktPolygon.write();
  };

  const onFinish = async (values: any) => {
    if (serializedPolygon === "") {
      notification.warning({
        message: regionId
          ? "No ha realizado ningún cambio sobre la zona"
          : "Debe dibujar un poligono para crear la región",
        placement: "bottom",
      });
      return;
    }

    const { name } = values;

    try {
      setLoading(true);

      if (regionId) {
        await updateRegion(+regionId, name, serializedPolygon);
      } else {
        await createRegion(name, serializedPolygon);
      }

      notification.success({
        message: `La región se ha ${
          regionId ? "actualizado" : "creado"
        }  exitosamente`,
        placement: "bottom",
      });

      serializedPolygon = "";

      setLoading(false);

      navigate(WebRoutes.RegionsRoute, {
        replace: true,
      });
    } catch (error: any) {
      setLoading(false);
      notification.error({
        message: error.response.data.status,
        description:
          error.response.data.status === 401
            ? "Usted no tiene permiso para realizar esta operacion"
            : "Error en el servidor",
        placement: "bottom",
      });
    }
  };

  return (
    <>
      <Header>Crear nueva Región</Header>
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <Row justify="center">
          <Col span={8} className="sub-container create-container">
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Nombre"
                name="name"
                rules={[
                  { required: true, message: "Por favor ingresa un nombre!" },
                ]}
              >
                <Input disabled={!!regionId} />
              </Form.Item>

              <Space direction="horizontal">
                <Button type="primary" htmlType="submit" loading={loading}>
                  Guardar
                </Button>
                <Button
                  onClick={() =>
                    navigate(WebRoutes.RegionsRoute, {
                      replace: true,
                    })
                  }
                >
                  Cancelar
                </Button>
              </Space>
            </Form>
          </Col>
          <Col span={16}>
            <Spin
              tip="Cargando regiones..."
              size="large"
              spinning={loadingRegions}
            >
              <DrawingMap
                zoom={8}
                center={{ lat: -34.88582, lng: -56.17122 }}
                onFinishDrawing={onPolygonComplete}
                existingPolygonPaths={existingPolygonPaths}
                drawable={regionId === undefined}
                editablePolygonPath={editablePolygonPath}
              />
            </Spin>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default RegionForm;
