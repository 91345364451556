import { Col, Form, Input, Row, Select } from "antd";
import { PassengerGroupDTO } from "../../../../shared/dtos/PassengerGroupDTO";
import { useState, useEffect } from "react";
import ComponentPlacesAutocompleteProps from "../../groupTripsMap/PlaceAutocompleteProps";
import { useAppSelector } from "../../../../app/hooks";
import { ActionParams } from "./actionParams";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
interface FormProps {
  form: any;
  setIsModalOpen: (open: boolean) => void;
  editValues: PassengerGroupDTO | null;
  addPassengerAction: (params: ActionParams) => void;
}
const FormPasseger: React.FC<FormProps> = ({
  form,
  setIsModalOpen,
  editValues,
  addPassengerAction,
}) => {
  const request = useAppSelector((state) => state.groupTripsRequest.selected);
  const [origin, setOrigin] = useState({});
  const [destiny, setDestiny] = useState({});

  useEffect(() => {
    console.log("Origen ... ", origin);
    console.log("Destino ...", destiny);
    form.setFieldsValue({ origin: origin });
    form.setFieldsValue({ destiny: destiny });
  }, [origin, destiny, form]);
  const onFinish = (values: any) => {
    console.log("Valores a insertar ... ", values);

    const name = `${values.nombre} ${values.apellidos}`;
    delete values.nombre;
    delete values.apellidos;
    const cellphone = values.prefix
      ? `${values.prefix}${values.cellphone}`
      : `+598${values.cellphone}`;
    delete values.prefix;
    const newPasseger: PassengerGroupDTO = {
      id: values.identifier,
      ...values,
      name,
      request: "123123",
      cellphone,
      origin: {
        id: null,
        address: values.origin.address,
        name: values.origin.address,
        googlemaps: values.origin.id,
        keys: ["hola"],
        coordinate: {
          lat: values.origin.latitude,
          lng: values.origin.longitude,
        },
      },
      destiny: {
        id: null,
        address: values.destiny.address,
        name: values.destiny.address,
        googlemaps: values.destiny.id,
        keys: ["hola"],
        coordinate: {
          lat: values.destiny.latitude,
          lng: values.destiny.longitude,
        },
      },
      status: "Nuevo",
    };
    console.log("nuevo pasajero ", newPasseger);

    if (addPassengerAction) {
      addPassengerAction({
        passengerData: newPasseger,
        requestId: request?.id,
        showModal: setIsModalOpen,
      });
    }
    // dispatch(addGroupTripsPassegerAsync(newPasseger, request?.id || "0"));
    // setIsModalOpen(false);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option key={1} value="+598">
          Uru
        </Select.Option>
        <Select.Option key={2} value="54">
          Arg
        </Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{ prefix: "+598" }}
      //layout="vertical"
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name="identifier"
            label="Identificador"
            tooltip="Identificador unico en la empresa"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label="Email"
            tooltip="correo electronico"
            rules={[
              {
                required: true,
                message: "Por favor inserte un email!",
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            name="nombre"
            label="Nombre(s)"
            tooltip="Como te llamas?"
            rules={[
              {
                required: true,
                message: "Por favor inserte su nombre!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="apellidos"
            label="Apellidos"
            tooltip="Apellidos de tus padres?"
            rules={[
              {
                required: true,
                message: "Por favor inserte sus apellidos!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="origin"
            label="Origen"
            tooltip="Origen?"
            rules={[
              {
                required: true,
                message: "Por favor inserte un origen!",
                whitespace: true,
                type: "object",
              },
            ]}
          >
            <ComponentPlacesAutocompleteProps setPlace={setOrigin} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="destiny"
            label="Destino"
            tooltip="Destino?"
            rules={[
              {
                required: true,
                message: "Destino!",
                whitespace: true,
                type: "object",
              },
            ]}
          >
            <ComponentPlacesAutocompleteProps setPlace={setDestiny} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            name="cellphone"
            label="Telefono Celular"
            rules={[
              {
                required: true,
                message: "Por favor inserte un telefono celular!",
              },
            ]}
          >
            <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone" label="Telefono fijo">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="description" label="Descripcion">
        <Input.TextArea showCount maxLength={100} />
      </Form.Item>
    </Form>
  );
};

export default FormPasseger;
