import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  status: "idle",
};

export const pendingUserRequestSlice = createSlice({
  name: "pendingUserRequest",
  initialState,
  reducers: {
    updateUserRequestValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateUserRequestValue } = pendingUserRequestSlice.actions;

export default pendingUserRequestSlice.reducer;
