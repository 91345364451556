import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  TimePicker,
  notification,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WebRoutes } from "../../../shared/constants/WebRoutes";
import {
  CreateUserPrivilege,
  GetUserPrivileges,
  UpdateUserPrivilege,
} from "../../../services/userPermissionApi";
import UserPermissionDTO from "../../../shared/dtos/userPermissionDTO";
import { USER_PERMISSION_TYPE } from "../../../utils/constants/userPermissionType";
import { getServiceTypeList } from "../../../services/serviceTypeApi";
import { COUNTRIES } from "../../../utils/constants/countries";
import moment from "moment";
import { selectCurrentAdminId } from "../../../utils/helpers/selectorHelper";
import { GoogleMapsCountryDTO } from "../../../shared/dtos/googleMapsCountryDTO";
import {
  UserAuthorizedCountriesDescription,
  UserAuthorizedScheduleFromDescription,
  UserAuthorizedScheduleToDescription,
  UserAuthorizedServiceTypesDescription,
  UserAuthorizedWeekdayDescription,
} from "../../../shared/constants/Resources";
import Typography from "antd/lib/typography/Typography";

import "../../admin/list/styles.scss";
import "./styles.scss";
import { getUserById } from "../../../services/adminUserApi";
import UserDTO from "../../../shared/dtos/userDTO";

interface ServiceType {
  id: number;
  name: string;
}

interface DropdownValues {
  value: number;
  label: string;
}

interface FormValues {
  name: string[];
  value: Array<Number> | moment.Moment;
}

const DATE_FORMAT = "HH:mm";
const STARTING_HOUR = "00:00";

export const EditUserPrivilege = () => {
  let { userId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const adminId = selectCurrentAdminId();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserDTO>();
  const [userPrivileges, setUserPrivileges] = useState<
    Array<UserPermissionDTO>
  >(new Array<UserPermissionDTO>());
  const [serviceTypes, setServiceTypes] = useState<Array<DropdownValues>>(
    new Array<DropdownValues>()
  );

  useEffect(() => {
    const getServiceTypes = async () => {
      const data = await getServiceTypeList();
      const serviceTypesFromData: Array<DropdownValues> = data.map(
        (serviceType: ServiceType) => ({
          value: serviceType.id,
          label: serviceType.name,
        })
      );
      setServiceTypes(serviceTypesFromData);
      await retriveUserPrivileges(serviceTypesFromData);
    };
    const getUser = async () => {
      const user = await getUserById(Number(userId));
      setUser(user);
      await getServiceTypes();
    };
    const retriveUserPrivileges = async (
      serviceTypesFromData: Array<DropdownValues>
    ) => {
      const userPrivileges: Array<UserPermissionDTO> = await GetUserPrivileges(
        Number(userId)
      );
      setUserPrivileges(userPrivileges);
      const formFields: Array<FormValues> = [
        {
          name: [UserAuthorizedCountriesDescription],
          value: new Array<number>(),
        },
        {
          name: [UserAuthorizedServiceTypesDescription],
          value: new Array<number>(),
        },
        {
          name: [UserAuthorizedScheduleFromDescription],
          value: moment(STARTING_HOUR, DATE_FORMAT),
        },
        {
          name: [UserAuthorizedScheduleToDescription],
          value: moment(STARTING_HOUR, DATE_FORMAT),
        },
        {
          name: [UserAuthorizedWeekdayDescription],
          value: new Array<number>(),
        },
      ];

      if (userPrivileges) {
        for (const userPrivilege of userPrivileges) {
          switch (userPrivilege.permissionType) {
            case USER_PERMISSION_TYPE.Country:
              if (userPrivilege.permissions) {
                const permissions = JSON.parse(userPrivilege.permissions);
                if (permissions) {
                  const permissionToBeAdded = new Array<number>();
                  for (const permission of permissions) {
                    const country = COUNTRIES.find(
                      (x) => x.value === permission
                    );
                    if (country) {
                      permissionToBeAdded.push(country.value);
                    }
                  }
                  formFields.push({
                    name: [UserAuthorizedCountriesDescription],
                    value: permissionToBeAdded,
                  });
                }
              }
              break;
            case USER_PERMISSION_TYPE.Schedule:
              if (userPrivilege.permissions) {
                const permissions = JSON.parse(userPrivilege.permissions);
                if (permissions[0]) {
                  formFields.push({
                    name: [UserAuthorizedScheduleFromDescription],
                    value: moment(permissions[0], DATE_FORMAT),
                  });
                }
                if (permissions[1]) {
                  formFields.push({
                    name: [UserAuthorizedScheduleToDescription],
                    value: moment(permissions[1], DATE_FORMAT),
                  });
                }
              }
              break;
            case USER_PERMISSION_TYPE.ServiceType:
              if (userPrivilege.permissions) {
                const permissions = JSON.parse(userPrivilege.permissions);
                if (permissions) {
                  const permissionToBeAdded = new Array<number>();
                  for (const permission of permissions) {
                    const serviceType = serviceTypesFromData.find(
                      (x) => x.value === permission
                    );
                    if (serviceType) {
                      permissionToBeAdded.push(serviceType.value);
                    }
                  }
                  formFields.push({
                    name: [UserAuthorizedServiceTypesDescription],
                    value: permissionToBeAdded,
                  });
                }
              }
              break;
            case USER_PERMISSION_TYPE.Weekday:
              if (userPrivilege.permissions) {
                const permissions: Array<number> = JSON.parse(
                  userPrivilege.permissions
                );
                if (permissions) {
                  formFields.push({
                    name: [UserAuthorizedWeekdayDescription],
                    value: permissions,
                  });
                }
              }
              break;
            default:
              break;
          }
        }
      }

      form.setFields(formFields);
      setIsLoading(false);
    };
    getUser();
  }, [form, userId]);

  const onFinish = async (values: any) => {
    const {
      userAuthorizedCountries,
      userAuthorizedServiceTypes,
      userAuthorizedScheduleFrom,
      userAuthorizedScheduleTo,
      userAuthorizedWeekday,
    } = values;
    let missingPrivileges = [
      USER_PERMISSION_TYPE.Country,
      USER_PERMISSION_TYPE.Schedule,
      USER_PERMISSION_TYPE.ServiceType,
      USER_PERMISSION_TYPE.Weekday,
    ];

    try {
      if (userPrivileges.length > 0) {
        for (const userPrivilege of userPrivileges) {
          switch (userPrivilege.permissionType) {
            case USER_PERMISSION_TYPE.Country:
              if (userAuthorizedCountries) {
                if (userPrivilege) {
                  missingPrivileges = missingPrivileges.filter(
                    (x) => x !== USER_PERMISSION_TYPE.Country
                  );
                  const permission = JSON.stringify(userAuthorizedCountries);
                  userPrivilege.permissions = permission;
                  userPrivilege.updatedAt = new Date();
                  await UpdateUserPrivilege(userPrivilege.id!, userPrivilege);
                }
              }
              break;
            case USER_PERMISSION_TYPE.Schedule:
              if (userAuthorizedScheduleFrom && userAuthorizedScheduleTo) {
                if (userPrivilege) {
                  missingPrivileges = missingPrivileges.filter(
                    (x) => x !== USER_PERMISSION_TYPE.Schedule
                  );
                  const userSchedule = [
                    moment(userAuthorizedScheduleFrom, DATE_FORMAT).format(
                      DATE_FORMAT
                    ),
                    moment(userAuthorizedScheduleTo, DATE_FORMAT).format(
                      DATE_FORMAT
                    ),
                  ];
                  const permission = JSON.stringify(userSchedule);
                  userPrivilege.permissions = permission;
                  userPrivilege.updatedAt = new Date();
                  await UpdateUserPrivilege(userPrivilege.id!, userPrivilege);
                }
              }
              break;
            case USER_PERMISSION_TYPE.ServiceType:
              if (userAuthorizedServiceTypes) {
                if (userPrivilege) {
                  missingPrivileges = missingPrivileges.filter(
                    (x) => x !== USER_PERMISSION_TYPE.ServiceType
                  );
                  const permission = JSON.stringify(userAuthorizedServiceTypes);
                  userPrivilege.permissions = permission;
                  userPrivilege.updatedAt = new Date();
                  await UpdateUserPrivilege(userPrivilege.id!, userPrivilege);
                }
              }
              break;
            case USER_PERMISSION_TYPE.Weekday:
              if (userAuthorizedWeekday) {
                if (userPrivilege) {
                  missingPrivileges = missingPrivileges.filter(
                    (x) => x !== USER_PERMISSION_TYPE.Weekday
                  );
                  const permission = JSON.stringify(userAuthorizedWeekday);
                  userPrivilege.permissions = permission;
                  userPrivilege.updatedAt = new Date();
                  await UpdateUserPrivilege(userPrivilege.id!, userPrivilege);
                }
              }
              break;
            default:
              break;
          }
        }
      }

      if (missingPrivileges) {
        for (const missingPrivilege of missingPrivileges) {
          switch (missingPrivilege) {
            case USER_PERMISSION_TYPE.Country:
              if (userAuthorizedCountries) {
                const userPrivilege = new UserPermissionDTO();
                const permission = JSON.stringify(userAuthorizedCountries);
                userPrivilege.permissions = permission;
                userPrivilege.userId = Number(userId);
                userPrivilege.adminId = adminId;
                userPrivilege.permissionType = USER_PERMISSION_TYPE.Country;
                userPrivilege.createdAt = new Date();
                await CreateUserPrivilege(userPrivilege);
              }
              break;
            case USER_PERMISSION_TYPE.ServiceType:
              if (userAuthorizedServiceTypes) {
                const userPrivilege = new UserPermissionDTO();
                const permission = JSON.stringify(userAuthorizedServiceTypes);
                userPrivilege.permissions = permission;
                userPrivilege.userId = Number(userId);
                userPrivilege.adminId = adminId;
                userPrivilege.permissionType = USER_PERMISSION_TYPE.ServiceType;
                userPrivilege.createdAt = new Date();
                await CreateUserPrivilege(userPrivilege);
              }
              break;
            case USER_PERMISSION_TYPE.Weekday:
              if (userAuthorizedWeekday) {
                const userPrivilege = new UserPermissionDTO();
                const permission = JSON.stringify(userAuthorizedWeekday);
                userPrivilege.permissions = permission;
                userPrivilege.userId = Number(userId);
                userPrivilege.adminId = adminId;
                userPrivilege.permissionType = USER_PERMISSION_TYPE.Weekday;
                userPrivilege.createdAt = new Date();
                await CreateUserPrivilege(userPrivilege);
              }
              break;
            case USER_PERMISSION_TYPE.Schedule:
              if (userAuthorizedScheduleFrom && userAuthorizedScheduleTo) {
                const userPrivilege = new UserPermissionDTO();
                const userSchedule = [
                  moment(userAuthorizedScheduleFrom, DATE_FORMAT).format(
                    DATE_FORMAT
                  ),
                  moment(userAuthorizedScheduleTo, DATE_FORMAT).format(
                    DATE_FORMAT
                  ),
                ];
                const permission = JSON.stringify(userSchedule);
                userPrivilege.permissions = permission;
                userPrivilege.userId = Number(userId);
                userPrivilege.adminId = adminId;
                userPrivilege.permissionType = USER_PERMISSION_TYPE.Schedule;
                userPrivilege.createdAt = new Date();
                await CreateUserPrivilege(userPrivilege);
              }
              break;
            default:
              break;
          }
        }
      }
      notification.success({
        message: "Se modificaron los permisos correctamente",
        description: `los permisos del usuario ${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        } fueron modificados exitosamente`,
        placement: "bottom",
      });
    } catch (e) {
      notification.error({
        message: `Ha ocurrido un error al intentar modificar los permisos del usuario ${
          user?.firstName ?? ""
        } ${user?.lastName ?? ""}`,
        placement: "bottom",
      });
    }
    form.resetFields();

    navigate(WebRoutes.UserTableRoute, {
      replace: true,
    });
  };

  return (
    <>
      <Header>
        <Row justify="space-between">
          <Col>Modificar permisos de usuarios</Col>
          <Col>
            <h1>
              Modificando a: {user?.firstName ?? ""} {user?.lastName ?? ""}
            </h1>
          </Col>
        </Row>{" "}
      </Header>
      <Content
        style={{
          margin: "16px",
        }}
      >
        <Row justify="center">
          <>
            {isLoading === true ? (
              <LoadingOutlined className="loading" />
            ) : (
              <Col span={15} className="sub-container create-container">
                <h1>Informacion de los permisos del usuario</h1>
                <Typography className="subtitle">
                  Por defecto todos el usuario tienen autorizado pedir viajes en
                  cualquier lugar, a cualquier dia y cualquier hora
                </Typography>
                <Form
                  layout="vertical"
                  form={form}
                  name="userPermissions"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={UserAuthorizedCountriesDescription}
                        label="Paises autorizados"
                        tooltip="Cuales paises tiene autorizados?"
                      >
                        <Select
                          mode="multiple"
                          placeholder="Por favor seleccionar los paises"
                          options={COUNTRIES.map(
                            (country: GoogleMapsCountryDTO) => ({
                              value: country.value,
                              label: country.label,
                            })
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={UserAuthorizedServiceTypesDescription}
                        label="Tipos de servicio autorizados"
                        tooltip="Cuales tipos de servicios tiene autorizado?"
                      >
                        <Select
                          mode="multiple"
                          placeholder="Por favor seleccionar los tipos de servicios"
                          options={serviceTypes.map(
                            (serviceType: DropdownValues) => ({
                              value: serviceType.value,
                              label: serviceType.label,
                            })
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={15}>
                      <Form.Item
                        name={UserAuthorizedWeekdayDescription}
                        label="Dias autorizados"
                        tooltip="Cuales dias de la semana tiene autorizado?"
                      >
                        <Checkbox.Group
                          style={{
                            width: "100%",
                          }}
                        >
                          <Checkbox value="0">Domingo</Checkbox>
                          <Checkbox value="1">Lunes</Checkbox>
                          <Checkbox value="2">Martes</Checkbox>
                          <Checkbox value="3">Miercoles</Checkbox>
                          <Checkbox value="4">Jueves</Checkbox>
                          <Checkbox value="5">Viernes</Checkbox>
                          <Checkbox value="6">Sabado</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item
                      label="Horario autorizado"
                      tooltip="Cual es su horario deseado de servicio?"
                      style={{ width: "100%" }}
                    >
                      <Row justify="space-evenly">
                        <Col>
                          <Form.Item
                            label="Hora Inicial"
                            tooltip="Cual desea que sea la primera hora permitida?"
                            name={UserAuthorizedScheduleFromDescription}
                          >
                            <TimePicker style={{ width: "98%" }} />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            label="Hora Final"
                            tooltip="Cual desea que sea la ultima hora permitida?"
                            name={UserAuthorizedScheduleToDescription}
                          >
                            <TimePicker style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Row>
                  <Row justify="space-evenly">
                    <Button type="primary" htmlType="submit">
                      Guardar cambios
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(WebRoutes.UserTableRoute, {
                          replace: true,
                        })
                      }
                    >
                      Cancelar
                    </Button>
                  </Row>
                </Form>
              </Col>
            )}
          </>
        </Row>
      </Content>
    </>
  );
};

export default EditUserPrivilege;
