import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

interface IPlaceAutocomplete {
  setPlace: (place: object) => void;
}
const ComponentPlacesAutocompleteProps: React.FC<IPlaceAutocomplete> = ({
  setPlace,
}) => {
  const onSelect = (value: any) => {
    console.log("Select ...", value);
    geocodeByPlaceId(value?.value?.place_id).then((response: any) => {
      const direction = {
        id: value?.value?.place_id,
        latitude: response[0].geometry?.location?.lat(),
        longitude: response[0]?.geometry?.location?.lng(),
        address: value?.label,
      };
      setPlace(direction);
    });
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        apiOptions={{ language: "es", region: "uy" }}
        selectProps={{
          onChange: onSelect,
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["uy"],
          },
        }}
      />
    </div>
  );
};

export default ComponentPlacesAutocompleteProps;
