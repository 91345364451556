import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserRequestProps from "../../propInterfaces/UserRequestProps";
import { removeProviderFromUserRequest } from "../../services/userRequestApi";

const initialState = {
  value: {},
  status: "idle",
};

export const removeProviderFromUserRequestAsync = createAsyncThunk(
  "userRequest/removeProvider",
  async ({ userRequestId }: UserRequestProps) => {
    const response = await removeProviderFromUserRequest({
      userRequestId,
    });
    return response;
  }
);

export const removeProviderFromUserRequestSlice = createSlice({
  name: "removeProviderFromUserRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeProviderFromUserRequestAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(
        removeProviderFromUserRequestAsync.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.value = action.payload;
        }
      )
      .addCase(removeProviderFromUserRequestAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default removeProviderFromUserRequestSlice.reducer;
