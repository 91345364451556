import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import {
  sendNotificationMessage,
  updateNotificationStatusById,
} from "../../services/notificationWhatsappApi";

interface Initial {
  conversationOpen: boolean;
  currentConversation: any;
  sendingMessage: boolean;
}

const initialState: Initial = {
  conversationOpen: false,
  currentConversation: [],
  sendingMessage: false,
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversationOpen: (state, actions) => {
      state.conversationOpen = actions.payload;
    },
    setCurrentConversation: (state, actions) => {
      state.currentConversation = actions.payload;
    },
    setSendingMessage: (state, actions) => {
      state.sendingMessage = actions.payload;
    },
  },
});

export const {
  setConversationOpen,
  setCurrentConversation,
  setSendingMessage,
} = conversationSlice.actions;

export const sendMessage = (
  cellphone: string,
  message: string,
  callBack?: () => void | undefined
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSendingMessage(true));
      const response = await sendNotificationMessage(cellphone, message);
      if (response.status === "success") {
        if (callBack) {
          callBack();
        }
      }
      dispatch(setSendingMessage(false));
    } catch (error) {
      console.log(error);
      dispatch(setSendingMessage(false));
    }
  };
};

export const updateCurrentConversation = (messages: any): AppThunk => {
  return (dispatch, getState) => {
    const { currentConversation } = getState().conversation;
    if (currentConversation.length > 0) {
      const clientNumber =
        currentConversation[0]?.from?.type === "client"
          ? currentConversation[0]?.from?.number
          : currentConversation[0]?.to?.number;
      if (messages[clientNumber]) {
        dispatch(setCurrentConversation(messages[clientNumber]));
      }
    }
  };
};

export const updateConvesationNotificationStatus = (
  conversation: Array<any>,
  status: string
): AppThunk => {
  return (dispatch) => {
    conversation.forEach(async (c) => {
      await updateNotificationStatusById(c.id, status);
    });
  };
};

export default conversationSlice.reducer;
