import { Card, Layout, Typography } from "antd";
import React from "react";
import NotificationPanel from "./NotificationPanel/notificationPanel";

const { Header, Content } = Layout;
const { Title } = Typography;

interface Props {
  title?: string;
  children: React.ReactNode;
  showNotification?: boolean;
  defaultContainer?: boolean;
}

const ContainerLayout = ({
  title,
  children,
  showNotification = true,
  defaultContainer = true,
}: Props): JSX.Element => {
  return (
    <Layout className="container-layout-cls">
      <Header className="container-layout-header-cls">
        <Title level={4}>{title}</Title>
        {showNotification && <NotificationPanel />}
      </Header>
      <Content
        style={{
          margin: "0px 0px",
          padding: "0px 16px",
          maxHeight: "100%",
          height: "auto",
          overflowY: "scroll",
        }}
      >
        {defaultContainer ? (
          <Card
            className="custom-card-container-layout"
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            {children}
          </Card>
        ) : (
          children
        )}
      </Content>
    </Layout>
  );
};

export default ContainerLayout;
