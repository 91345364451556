import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAdmin } from "../../services/adminApi";
import NewAdminDTO from "../../shared/dtos/newAdminDTO";

export const initialState = {
  value: {},
  status: "idle",
};

export const postAdminAsync = createAsyncThunk(
  "admins/",
  async (newAdminDTO: NewAdminDTO) => {
    const response = await postAdmin(newAdminDTO);

    return response;
  }
);

export const postAdminSlice = createSlice({
  name: "adminList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postAdminAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postAdminAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(postAdminAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default postAdminSlice.reducer;
