import { Switch, notification } from "antd";
import {
  patchIsAdminProvider,
  patchIsBlackListed,
} from "../../../../services/adminProviderApi";
import { useState } from "react";
import { AppDispatch } from "../../../../app/store";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { getPaginatedProvidersAsync } from "../../../../features/driver/driverSlice";
import { TableParams } from "..";

export interface Props {
  providerId: number;
  switchValue: boolean;
  tableParams: TableParams;
  search: string;
  switchType: string;
}

const ProviderSwitch = ({
  providerId,
  switchValue,
  tableParams,
  search,
  switchType,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const onChange = async (value: boolean) => {
    setLoading(true);
    let filters: string = "";

    if (search) {
      filters = JSON.stringify({
        first_name: search,
        last_name: search,
      });
    }

    switch (switchType) {
      case "adminProvider":
        try {
          await patchIsAdminProvider(providerId, value).then(() => {
            notification.success({
              message: `Operación exitosa`,
              description: `El conductor se ${
                value
                  ? "agrego como conductor administrador"
                  : "removio como conductor administrador"
              } correctamente`,
              placement: "bottom",
            });
          });
        } catch (error) {
          notification.error({
            message: `Ha ocurrido un error al ${
              value
                ? "agregar como administrador"
                : "remover como administrador"
            } el conductor, intentelo más tarde.`,
            placement: "bottom",
          });
        }
        break;
      case "blacklist":
        try {
          await patchIsBlackListed(providerId, value).then(() => {
            notification.success({
              message: `Operación exitosa`,
              description: `El conductor se ${
                value ? "agrego a la lista negra" : "removio de la lista negra"
              } correctamente`,
              placement: "bottom",
            });
          });
        } catch (error) {
          notification.error({
            message: `Ha ocurrido un error al ${
              value ? "agregar a la lista negra" : "remover de la lista negra"
            } el conductor, intentelo más tarde.`,
            placement: "bottom",
          });
        }
        break;
    }

    dispatch(
      getPaginatedProvidersAsync({
        page: tableParams.pagination?.current || 1,
        take: tableParams.pagination?.pageSize || 10,
        filters: filters,
      })
    );

    setLoading(false);
  };

  return <Switch loading={loading} onChange={onChange} checked={switchValue} />;
};

export default ProviderSwitch;
