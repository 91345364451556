import { createSlice } from "@reduxjs/toolkit";
import UserRequestByDateDTO from "../../shared/dtos/userRequestExportDTO";
import { AppThunk } from "../../app/store";
import { endDateValue, startDateValue } from "../../shared/constants/Resources";
import { getUserRequestsStatementAdminPanelApi } from "../../services/adminPanelApi";

interface Value {
  userRequestAdminPanel: Array<UserRequestByDateDTO>;
  total: number;
  totalCompleted: number;
  take: number;
  totalSpent: number;
}

interface Filters {
  startDate: string;
  endDate: string;
  page: number;
  take: number;
  adminId: number | null;
  searchText: string | undefined;
  departmentName: string | undefined;
}
interface InitialState {
  value: Value;
  filtersAdminPanel: Filters;
  loading: boolean;
}

const defaultFilters = {
  startDate: startDateValue,
  endDate: endDateValue,
  page: 1,
  take: 10,
  adminId: null,
  searchText: undefined,
  departmentName: undefined,
};

const initialState: InitialState = {
  value: {
    userRequestAdminPanel: [],
    total: 0,
    take: 10,
    totalCompleted: 0,
    totalSpent: 0,
  },
  filtersAdminPanel: structuredClone(defaultFilters),
  loading: false,
};

const adminPanelSlice = createSlice({
  name: "adminPanel",
  initialState,
  reducers: {
    setValueAdminPanel: (state, actions) => {
      state.value = actions.payload;
    },
    setFiltersAdminPanel: (state, actions) => {
      state.filtersAdminPanel = actions.payload
        ? actions.payload
        : structuredClone(defaultFilters);
    },
    setLoadingAdminPanel: (state, actions) => {
      state.loading = actions.payload;
    },
  },
});

export const {
  setValueAdminPanel,
  setFiltersAdminPanel,
  setLoadingAdminPanel,
} = adminPanelSlice.actions;

interface InputsValues {
  startDate: any;
  endDate: any;
  page: number;
  take: number;
  bookingId?: string;
  searchText?: string;
  adminId: number | null;
  departmentName?: string;
}

export const getUserRequestAdminPanel = ({
  startDate,
  endDate,
  page,
  take = 10,
  bookingId,
  searchText,
  adminId,
  departmentName,
}: InputsValues): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingAdminPanel(true));
    const response = await getUserRequestsStatementAdminPanelApi({
      startDate,
      endDate,
      page,
      take,
      bookingId,
      searchText,
      adminId,
      departmentName,
    });
    const formatted = {
      userRequestAdminPanel: response.data,
      total: response.total,
      take: take,
      totalSpent: response?.totalSpent,
      totalCompleted: response?.totalCompleted,
    };

    dispatch(setValueAdminPanel(formatted));
    dispatch(setLoadingAdminPanel(false));
  };
};

export default adminPanelSlice.reducer;
