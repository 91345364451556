import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  groupTripsPassegerList,
  importPassengersAsync,
} from "../../../features/groupTrip/passeger/groupTripPassegerSlide";
import { groupTripsDirectionList } from "../../../features/groupTrip/direction/groupTripDirectionSlide";
import { addGroupTripsPasseger } from "../../../services/groupTripPassegerApi";
import { parseDataFromXMLS, readUploadXLSXFile } from "../common/utils";

const LoadTemplate = () => {
  const request = useAppSelector((state) => state.groupTripsRequest.selected);
  const [list, setList] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(groupTripsDirectionList());
  }, [dispatch]);

  const readUploadFile = (e: any) => {
    e.preventDefault();
    readUploadXLSXFile(e, (data) => {
      setList(data);
    });
  };

  useEffect(() => {
    console.log(list);
    dispatch(
      importPassengersAsync(parseDataFromXMLS(list), async (response) => {
        const insertarR = await addGroupTripsPasseger(
          response,
          request?.id || "0"
        );
        console.log("Respuesta del insertar ...", insertarR);

        dispatch(groupTripsPassegerList(request?.id || "0"));
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <form>
      <label htmlFor="upload"></label>
      <input type="file" name="upload" id="upload" onChange={readUploadFile} />
    </form>
  );
};

export default LoadTemplate;
