import { Switch, notification } from "antd";
import {
  approveProvider,
  disapproveProvider,
} from "../../../../services/adminProviderApi";
import { useState } from "react";
import { AppDispatch } from "../../../../app/store";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { getPaginatedProvidersAsync } from "../../../../features/driver/driverSlice";
import { TableParams } from "..";

export interface Props {
  providerId: number;
  disabled: boolean;
  status: string;
  tableParams: TableParams;
  search: string;
}

const DriverStatusSwitch = ({
  providerId,
  disabled,
  status,
  tableParams,
  search,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const onChange = async (approve: boolean) => {
    setLoading(true);
    let filters: string = "";

    if (search) {
      filters = JSON.stringify({
        first_name: search,
        last_name: search,
      });
    }

    try {
      if (approve) {
        await approveProvider(providerId).then(() =>
          dispatch(
            getPaginatedProvidersAsync({
              page: tableParams.pagination?.current || 1,
              take: tableParams.pagination?.pageSize || 10,
              filters: filters,
            })
          )
        );
      } else {
        await disapproveProvider(providerId).then(() =>
          dispatch(
            getPaginatedProvidersAsync({
              page: tableParams.pagination?.current || 1,
              take: tableParams.pagination?.pageSize || 10,
              filters: filters,
            })
          )
        );
      }

      setLoading(false);

      notification.success({
        message: `Operación exitosa`,
        description: `El conductor se ${
          approve ? "aprobó" : "banneo"
        } correctamente`,
        placement: "bottom",
      });
    } catch (error) {
      setLoading(false);

      notification.error({
        message: `Ha ocurrido un error al ${
          approve ? "aprobar" : "bannear"
        } el provider, intentelo más tarde.`,
        placement: "bottom",
      });
    }
  };
  return (
    <Switch
      loading={loading}
      disabled={disabled}
      onChange={onChange}
      checked={status === "approved"}
    />
  );
};

export default DriverStatusSwitch;
