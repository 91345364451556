const TRIP_STATUS = {
  Searching: 'SEARCHING',
  Scheduled: 'SCHEDULED',
  Started: 'STARTED',
  Cancelled: 'CANCELLED',
  Completed: 'COMPLETED',
  Accepted: 'ACCEPTED',
  Arrived: 'ARRIVED',
  Created: 'CREATED',
  PickedUp: 'PICKEDUP',
  Dropped: 'DROPPED',
  ALL: 'ALL'
};

export { TRIP_STATUS };
