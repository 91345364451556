import { Modal, Row, Typography} from "antd";
import { useState } from "react";

const newDomain = process.env.REACT_APP_NEW_DOMAIN;

const NewDomainModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <Modal
      title="Mowi esta por cambiar de dominio"
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Okay"
    >
      <Row style={{marginBottom: "20px"}}>
        <Typography>El dominio de la pagina ha cambiado a {" "}
          <a href={newDomain} target="_blank" rel="noopener noreferrer">
            admin mowiapp
          </a>
        </Typography>
      </Row>
    </Modal>
  );
};

export default NewDomainModal;
